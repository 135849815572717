<script setup lang="ts">
import { computed, onUnmounted, provide, readonly, ref, shallowRef } from "vue";
import { PropsOf } from "../../../utils/types";
import MButton from "../MButton/MButton.vue";
import {
  OpenDockedModalFunction,
  OpenDockedModalOptions,
  PanelOption,
  PanelOptions,
} from "./types";
import MSplitPageModal from "./MSplitPageModal.vue";
import { useApp } from "../../../store";
import { listen } from "../../../utils/bus";

const props = defineProps<{
  heading?: string;
  patientId?: string;
  panelOptions?: PanelOptions;
  closeButtonOptions?: PropsOf<typeof MButton>;
  showSeperator?: boolean;
}>();

const app = useApp();
const dockedModalPosition = ref<"left" | "right" | undefined>();
const dockedModalProps = shallowRef<PropsOf<typeof MSplitPageModal> | undefined>();

const DEFAULT_PANEL_OPTION_LEFT = readonly<PanelOption>({
  heading: undefined,
  width: 50,
  backgroundColour: "white",
});

const DEFAULT_PANEL_OPTION_RIGHT = readonly<PanelOption>({
  heading: undefined,
  width: 50,
  backgroundColour: "grey",
});

const panelOptionsValue = computed<PanelOptions>(() => {
  return {
    left: { ...DEFAULT_PANEL_OPTION_LEFT, ...props.panelOptions?.left },
    right: { ...DEFAULT_PANEL_OPTION_RIGHT, ...props.panelOptions?.right },
  };
});

const openDockedModal: OpenDockedModalFunction = async (options: OpenDockedModalOptions) => {
  app.setIsDockedModalOpen(true);
  dockedModalProps.value = options;
  dockedModalPosition.value = options.side ?? "left";
};
provide<OpenDockedModalFunction>("openDockedModal", openDockedModal);
defineExpose({ openDockedModal });

listen("open-docked-modal", (openDockedModalOptions: OpenDockedModalOptions) => {
  openDockedModal(openDockedModalOptions);
});

function resetDockedModal() {
  dockedModalPosition.value = undefined;
  dockedModalProps.value = undefined;
  app.setIsDockedModalOpen(false);
}

function handleModalClosed() {
  resetDockedModal();
}

onUnmounted(() => {
  resetDockedModal();
});
</script>

<template>
  <div class="m-split-page">
    <header v-if="props.heading || props.closeButtonOptions || props.patientId" class="page-header">
      <div v-if="props.heading || props.closeButtonOptions" class="heading-and-actions">
        <h1>{{ props.heading }}</h1>
        <div class="page-header-actions">
          <slot name="header-actions" />
          <m-button
            v-if="props.closeButtonOptions"
            ghost
            v-bind="props.closeButtonOptions"
            class="close-btn"
          >
            <i class="fa-solid fa-xmark q-mr-sm"></i>
            <span>{{ closeButtonOptions?.label ?? "Close" }}</span>
          </m-button>
        </div>
      </div>
      <m-patient-banner v-if="patientId" :patient-id="props.patientId" blue-accent />
    </header>

    <div class="page-content">
      <div
        class="panel left"
        :class="{
          'panel-white':
            props.panelOptions?.left?.backgroundColour === 'white' || // if user defined
            (props.panelOptions?.left?.backgroundColour === undefined && // default to white if right panel is not white
              props.panelOptions?.right?.backgroundColour !== 'white'),
          'panel-grey':
            props.panelOptions?.left?.backgroundColour === 'grey' || // if user defined
            props.panelOptions?.right?.backgroundColour === 'white', // if other side is white
        }"
        :style="{
          flex: `0 1 ${panelOptionsValue.left?.width}%`,
          minWidth: panelOptionsValue.left?.minWidth
            ? `${panelOptionsValue.left.minWidth}px`
            : undefined,
          overflow: dockedModalPosition === 'left' ? 'hidden' : undefined,
        }"
      >
        <header
          v-if="panelOptionsValue.left?.heading"
          class="panel-header"
          :inert="dockedModalPosition === 'left'"
        >
          <h2 class="panel-heading">{{ panelOptionsValue.left.heading }}</h2>
          <slot name="left-header-action" />
        </header>
        <div class="content" :inert="dockedModalPosition === 'left'">
          <slot name="left-panel-content" />
        </div>
      </div>

      <div v-if="props.showSeperator" class="seperator" />

      <div
        class="panel right"
        :class="{
          'panel-white':
            props.panelOptions?.right?.backgroundColour === 'white' || // if user defined
            (props.panelOptions?.right?.backgroundColour === undefined && // default to white if left panel is not white
              props.panelOptions?.left?.backgroundColour !== 'white'),
          'panel-grey':
            props.panelOptions?.right?.backgroundColour === 'grey' || // if user defined
            props.panelOptions?.left?.backgroundColour === 'white', // if other side is white
        }"
        :style="{
          flex: `0 1 ${panelOptionsValue.right?.width}%`,
          minWidth: panelOptionsValue.right?.minWidth
            ? `${panelOptionsValue.right?.minWidth}px`
            : undefined,
          overflow: dockedModalPosition === 'right' ? 'hidden' : undefined,
        }"
      >
        <header
          v-if="panelOptionsValue.right.heading"
          class="panel-header"
          :inert="dockedModalPosition === 'right'"
        >
          <h2 class="panel-heading">{{ panelOptionsValue.right.heading }}</h2>
          <slot name="right-header-action" />
        </header>
        <div class="content" :inert="dockedModalPosition === 'right'">
          <slot name="right-panel-content" />
        </div>
      </div>

      <Transition>
        <m-split-page-modal
          v-if="dockedModalPosition && dockedModalProps"
          class="modal"
          v-bind="dockedModalProps"
          :side="dockedModalPosition"
          :style="{
            left:
              dockedModalPosition === 'left'
                ? '0px'
                : `${100 - (panelOptionsValue['right'].width ?? 0)}%`,
            right:
              dockedModalPosition === 'right'
                ? '0px'
                : `${100 - (panelOptionsValue['left'].width ?? 0)}%`,
          }"
          @close="handleModalClosed"
        />
      </Transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.m-split-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  max-height: 100%;

  .page-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 100;
    box-shadow: 0 -18px 10px 20px rgba(0, 0, 0, 0.14);

    .heading-and-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: var(--page-header-height);
      padding: 0 var(--gap-2) 0 var(--gap-4);
      background: white;

      .close-btn {
        align-self: center;
      }
    }

    .page-header-actions {
      display: flex;
      align-items: center;
      gap: var(--gap-2);
    }

    .m-patient-banner {
      flex: 0 0 auto;
      z-index: 10;
      padding-top: 5px;
      padding-left: var(--gap-4);
      padding-right: var(--gap-4);
      min-height: 42.5px;
    }
  }

  .page-content {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    position: relative;
  }

  .panel {
    flex: 0 0 50%;
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    .panel-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--gap-4);

      .panel-heading {
        font-weight: bold;
        margin-right: var(--gap-3);
      }
    }
  }

  .panel-white {
    background: white;
    box-shadow: 0 2px 14px rgb(0 0 0 / 14%);
  }

  .panel-grey {
    background: var(--medicus-background);
  }

  .content {
    min-height: 100%;
    padding: var(--gap-5);
  }

  .seperator {
    width: 2px;
    background: var(--grey-light);
    flex: 0 0 auto;
  }

  .modal {
    &.v-enter-from,
    &.v-leave-to {
      .shadow {
        opacity: 0;
      }

      .modal {
        translate: 0 60px;
        opacity: 0;
      }
    }
  }
}
</style>
