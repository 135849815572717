<script setup lang="ts">
import { computed } from "@vue/reactivity";
import { AudioPlayer, SecondsToAudioLength, useAudioPlayer } from "../../../composables/playAudio";
import { reactive, watch } from "vue";
import { isString } from "vue-composable";
import MLayoutStack from "../MLayoutStack/MLayoutStack.vue";
import MButton from "../MButton";
import { QSlider } from "quasar";
import MSeparator from "../MSeparator";

const props = defineProps<{
  modelValue: string | Blob;
  duration?: number | string;

  player?: AudioPlayer;

  // auto-plays
  auto?: boolean;
}>();

const internalPlayer = useAudioPlayer();

watch(
  () => props.modelValue,
  (value) => {
    internalPlayer.audioUrl.value = isString(value) ? value : window.URL.createObjectURL(value);
  },
  { immediate: true },
);

const player = computed(() => props.player ?? reactive(internalPlayer));

function play() {
  player.value.play();
}

function pause() {
  player.value.pause();
}

defineExpose({
  player,

  play,
  pause,
});
</script>
<template>
  <div class="audio-player">
    <m-layout-stack class="items-center q-px-md" no-gap>
      <p class="current-length">{{ SecondsToAudioLength(player.position) }}</p>
      <p class="total-length">{{ SecondsToAudioLength(player.duration) }}</p>
      <q-slider
        v-model="player.position"
        :min="0"
        :max="player.duration"
        :label-value="SecondsToAudioLength(player.position)"
        :readonly="!player.duration"
        :label="!!player.duration"
        dense
      />
    </m-layout-stack>

    <m-separator />

    <m-layout-stack class="audio-controls items-center justify-center" horizontal>
      <m-button
        icon="fa-solid fa-backward"
        label="Rewind"
        icon-only
        color="secondary"
        @click="player.rewind"
      />
      <m-button
        icon="fa-solid fa-pause"
        label="Pause"
        icon-only
        color="secondary"
        @click="player.pause"
      />
      <m-button
        icon="fa-solid fa-play"
        label="Play"
        icon-only
        color="secondary"
        @click="player.play"
      />
      <m-button
        icon="fa-solid fa-forward"
        label="Forward"
        icon-only
        color="secondary"
        @click="player.forward"
      />
    </m-layout-stack>
    <div></div>
  </div>
</template>
<style lang="scss">
.audio-player {
  background: white;
  width: 350px;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;

  .current-length {
    font-weight: bold;
    font-size: 18px;
    color: var(--text-color);
  }
  .total-length {
    font-size: 14px;
    color: var(--text-color);
  }

  button {
    background: white;
    border: 1px solid #cdcdcd;
    box-sizing: border-box;
    border-radius: 4px;
    color: var(--text-color);

    height: 40px;
    width: 40px;

    &:hover:not([disabled]) {
      background: #e7eaee;
      cursor: pointer;
    }
  }
}
</style>
