<script lang="ts" setup></script>
<template>
  <dl class="description-list">
    <slot />
  </dl>
</template>
<style lang="scss" scoped>
dl {
  display: grid;
}
</style>
