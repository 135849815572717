<script lang="ts" setup>
import { ref } from "vue";
import { QBtn, QList, QSeparator } from "quasar";
import { useOnOutsidePress } from "vue-composable";
import { MenuItem, MenuItems } from "./_types";
import SidebarMenuItem from "./SidebarMenuItem";
import MLayoutStack from "../../../components/medicus/MLayoutStack";

defineProps<{
  title: string;
  items: MenuItems;
  additionalInfo?: MenuItem[];
}>();

const emit = defineEmits({
  close: () => true,
});

defineExpose({
  close,
});

const menuEl = ref<HTMLDivElement | null>(null);
function close() {
  emit("close");
}
useOnOutsidePress(menuEl, close);
</script>
<template>
  <div ref="menuEl" class="sidebar-menu">
    <h1>{{ title }}</h1>

    <q-btn
      class="sidebar-menu--exit-btn rotate-180"
      icon="fa-regular fa-arrow-right-from-bracket"
      flat
      dense
      @click="close"
    />
    <q-list class="top-sidebar-menu">
      <template v-for="(item, i) in items" :key="`sidebar-menu-${item.label}`">
        <q-separator v-if="i > 0" />

        <sidebar-menu-item :item="item" />
      </template>
    </q-list>
    <q-list v-if="additionalInfo" class="sidebar-additional-info">
      <template v-for="(info, i) in additionalInfo" :key="`additional-info-${i}`">
        <q-separator />
        <m-item>
          <m-layout-stack>
            <b>{{ info.label }}</b>
            <div>{{ info.value }}</div>
          </m-layout-stack>
        </m-item>
      </template>
    </q-list>
  </div>
</template>
<style lang="scss">
.sidebar-menu {
  height: 100%;
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;

  padding-top: 5px;

  > h1 {
    // margin-left: 15px;
    padding: 10px 10px 0 25px;
  }

  // overflow: auto;

  .q-list {
    overflow: auto;
    padding: 10px;
  }
  .q-separator {
    margin: 5px;
  }

  .q-item {
    min-height: 35px !important;
  }

  .sidebar-menu--exit-btn {
    position: absolute;
    right: 20px;
    font-size: 20px !important;
    color: var(--grey-darker);
  }

  .top-sidebar-menu {
    flex: 1;
  }
  .sidebar-additional-info {
    pointer-events: none;
  }
}
</style>
