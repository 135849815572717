<script lang="ts" setup>
import type { SnackbarMessage } from "./interfaces";
import SnackbarMessageComp from "./SnackbarMessage.vue";

const props = defineProps<{
  messages: Array<SnackbarMessage & { id: number }>;
}>();

defineEmits({
  remove: (_item: SnackbarMessage & { id: number }) => true,
});

function remove(m: SnackbarMessage & { id: number }) {
  // eslint-disable-next-line vue/no-mutating-props
  props.messages.splice(props.messages.indexOf(m), 1);
}
</script>
<template>
  <section class="snackbar-container-success">
    <transition-group
      name="snackbar-message"
      tag="div"
      class="success-notifications flex flex-col gap-3 items-center"
    >
      <SnackbarMessageComp
        v-for="message in messages.filter((message) => message.type === 'success')"
        :key="`snackbar-message-${message.id}`"
        class="snackbar-message"
        :message="message"
        :dense="false"
        @remove="remove(message)"
      />
    </transition-group>
  </section>
  <section class="snackbar-container-non-success">
    <transition-group name="snackbar-message" tag="div" class="flex flex-col gap-3 justify-end">
      <SnackbarMessageComp
        v-for="message in messages.filter((message) => message.type !== 'success')"
        :key="`snackbar-message-${message.id}`"
        class="snackbar-message"
        :message="message"
        :dense="false"
        @remove="remove(message)"
      />
    </transition-group>
  </section>
</template>
<style lang="scss">
.snackbar-container-success,
.snackbar-container-non-success {
  position: fixed;
  top: 1em;
  margin: 1rem;
  margin-top: 0;
  max-width: 460px;
  z-index: 9000;
}

.snackbar-container-success {
  left: 50%;
  transform: translateX(-50%);
}

.snackbar-container-non-success {
  right: 1rem;
}

.snackbar-message-enter-from {
  opacity: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.snackbar-message-leave-from {
  max-height: 1000px;
}
.snackbar-message.snackbar-message-leave-to {
  max-height: 0;
  opacity: 0;
  margin-bottom: 0;
  overflow: hidden;

  .snackbar-message-badge {
    opacity: 0;
  }
}
.snackbar-message-enter-active {
  transition:
    transform 0.3s,
    opacity 0.3s;
}
.snackbar-message-leave-active {
  transition:
    max-height 0.45s cubic-bezier(0, 1, 0, 1),
    opacity 0.3s,
    margin-bottom 0.3s;
}

@keyframes headShake {
  0% {
    transform: translate(-25%, -25%);
  }

  6.5% {
    transform: translate(calc(-25% - 6px), -25%) rotateY(-9deg);
  }

  18.5% {
    transform: translate(calc(-25% + 5px), -25%) rotateY(7deg);
  }

  31.5% {
    transform: translate(calc(-25% - 3px), -25%) rotateY(-5deg);
  }

  43.5% {
    transform: translate(calc(-25% + 2px), -25%) rotateY(3deg);
  }

  50% {
    transform: translate(-25%, -25%);
  }
}
</style>
