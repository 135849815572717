<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps<{
  title: string;
  body?: string;
  isOpen?: boolean;
}>();

defineExpose({
  toggle,
});

const isOpen = ref(false);

watch(
  () => props.isOpen,
  (v) => (isOpen.value = v),
  {
    immediate: true,
    flush: "pre",
  },
);

function toggle(open?: boolean) {
  isOpen.value = open ?? !isOpen.value;
}

function handleHeaderClick(e: MouseEvent) {
  e.preventDefault();
  toggle();
  window.getSelection()?.empty(); // Prevent double click selecting text
}
</script>

<template>
  <details class="m-guidance-block" :open="isOpen">
    <summary class="header" @click="handleHeaderClick">
      <div class="icon-chevron fa-solid fa-chevron-right" />
      <span>{{ props.title }}</span>
    </summary>
    <div class="body">{{ props.body }}<slot /></div>
  </details>
</template>

<style lang="scss" scoped>
.m-guidance-block {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 3px;
  color: var(--text-color-lightest);
  transition: var(--transition);

  .icon-chevron {
    margin-right: var(--gap-2);
    color: var(--grey-darkest);
    font-size: 16px;

    &::before {
      display: block;
      transition: var(--transition);
    }
  }

  &:hover {
    color: var(--theme-blue);
    background: var(--grey-lightest);
    cursor: pointer;

    .icon-chevron {
      color: var(--theme-blue);
    }
  }
}

.body {
  display: grid;
  grid-template-rows: 0fr;
  margin-left: 9px;
  margin-top: var(--gap-1);
  margin-bottom: var(--gap-3);
  border-left: 4px solid var(--grey-light);
  padding: var(--gap-1) var(--gap-1) var(--gap-1) var(--gap-3);
  transition: grid-template-rows 2000ms;
  overflow: hidden;
  white-space: pre-line;

  * {
    overflow: hidden;
  }
}

.m-guidance-block[open] {
  &:not(:hover) {
    .header {
      color: var(--text-color);
    }
  }

  .icon-chevron::before {
    rotate: 90deg;
  }
}
</style>
