<script setup lang="ts">
import { ref, watch } from "vue";
import MTabs from ".";
import MButton from "../MButton";
import MTab from "../MTab";

const button = ref<InstanceType<typeof MButton>>();

watch(button, (b) => {
  if (!b) return;
  (b.$el as HTMLElement).querySelector("button").focus();
});
</script>
<template>
  <div class="flex flex-col gap-2">
    <m-tabs value="test1">
      <m-tab name="test" label="Test tab">
        <span>Test tab content</span>
      </m-tab>
      <m-tab name="test1" label="Other tab">
        <span>Test tab 1 content</span>
      </m-tab>
    </m-tabs>

    <hr />

    <m-tabs value="test">
      <template #header:test> New <b>tab</b> header </template>
      <m-tab name="test" label="test">
        <span>Test tab header custom</span>
      </m-tab>
    </m-tabs>

    <div style="background-color: yellow">
      <m-tabs value="test">
        <m-tab name="test" label="tab">
          <span>Tab</span>
        </m-tab>
      </m-tabs>
    </div>

    <div style="width: 200px">
      <m-tabs value="test" fit>
        <m-tab name="test" label="tab — fit">
          <MButton ref="button" class="force-focus">Tab</MButton>
        </m-tab>
      </m-tabs>
    </div>

    <div style="width: 200px">
      <m-tabs value="test" no-padding>
        <m-tab name="test" label="tab — no padding">
          <MButton ref="button" class="force-focus">Tab</MButton>
        </m-tab>
      </m-tabs>
    </div>
  </div>
</template>
