export interface Badge {
  text: string;
  type: "amber" | "green" | "red";
}

export interface PatientJournalGroup {
  title: string;
  items: PatientJournalRecord[];
}

export interface PatientJournalRecord {
  type: JournalEntryType;
  title: string;
  id: string;
  entryDateTime: string;
  descriptionText: string;
  entryPublishedBy: string;
  badges: Badge[];
  data: any;
  action: {
    type: Object;
    default: null;
  };

  isMarkedIncorrect?: boolean;
  isDraft?: boolean;
}

export type DateHeading = {
  id: string;
  title: string;
  isDateHeading: boolean;
  allItemsIndex: number;
};

export enum JournalEntryType {
  ENCOUNTER = "encounter",
  NOTE = "note",
  ALLERGY_HISTORY = "allergy-history",
  IMMUNISATION = "immunisation",
  MEDICATION_ADMINISTRATION = "medication-administration",
  FIT_NOTE = "fit-note",
  PRESCRIPTION = "prescription",
  MEDICATION_STATEMENT_PRESCRIBED_ELSEWHERE = "medication-statement-prescribed-elsewhere",
  MEDICATION_STATEMENT_OVER_THE_COUNTER = "medication-statement-over-the-counter",
  OBSERVATION = "observation",
  FUTURE_ACTION = "future-action",
  REFERRAL = "referral",
  DOCUMENT = "document",
  PROCEDURE = "procedure",
  PROBLEM = "problem",
  INVESTIGATION = "investigation",
  INVESTIGATION_REQUEST = "investigation-request",
  COMMUNICATION = "communication",
  COMMUNICATION_THREAD = "communication-thread",
}
