import axios from "../../../../utils/axios";
import { CreatePrescriptionQueryPrescription, PrescriberEndorsementEnumTrait } from "../types";
import { SearchPredefinedDosagesQueryResult } from "./types";

export const getDosageSuggestion = (patientId: string, dmdCode: string) =>
  axios
    .get<{
      predefinedDosages: Array<{
        title: string;
        ageRestrictedGroupedItems: Array<{
          ageRestriction: string;
          minimumAge: string;
          dosageSuggestions: Array<{
            label: string;
            value: SearchPredefinedDosagesQueryResult;
          }>;
        }>;
      }>;
      unGroupedPredefinedDosages: Array<{
        label: string;
        value: SearchPredefinedDosagesQueryResult;
      }>;
    }>("/clinical/data/gb/fdb/dmd/dose/search", {
      cancelRequestId: "gb-fdb-dmd-dose-search",
      params: {
        patientId,
        dmdCode,
      },
    })
    .then((x) => x.data);

export type MetaInformationResult = {
  restrictedIssueQuantityUnitsOfMeasure: Array<{
    label: string;
    value: { description: string | null; conceptId: string | null; descriptionId: string | null };
  }> | null;
  issueQuantityUnitOfMeasureLabel: string | null;

  category: string;

  bnfUrl: string | null;
  bnfcUrl: string | null;

  allowedPrescriptionTypes: Array<{ label: string; value: string }>;
  alternativeItems: Array<{
    label: string;
    value: {
      description: string;
      itemCode: string;
    };
  }>;
  allowablePrescriberEndorsements: Array<PrescriberEndorsementEnumTrait>;

  packSizes: Array<string>;
};
export const getMetaInformation = (code: string) =>
  axios
    .get<MetaInformationResult>("clinical/data/gb/nhs/dmd/meta", {
      cancelRequestId: "nhs-dmd-meta",
      params: {
        code,
      },
    })
    .then((x) => x.data);

export const getIssueQuantity = (
  prescription: CreatePrescriptionQueryPrescription,
  canAutoCalculate: boolean | null,
) => {
  const { dosageInstruction, productCode, expectedDaysSupply, issueQuantity } = prescription;

  const days = expectedDaysSupply ? +expectedDaysSupply : 0;

  if (
    !canAutoCalculate ||
    dosageInstruction.useManualDosageText ||
    days < 1 ||
    Number.isNaN(days) ||
    !productCode
  )
    return Promise.resolve(null);

  const { maximum, minimum } = dosageInstruction.dose;

  return axios
    .get<{ issueQuantity: string }>("clinical/data/gb/nhs/prescription/calculate/issue-quantity", {
      cancelRequestId: "prescription--calculate--issue-quantity",
      params: {
        productCode: productCode,
        usingManualText: false,
        doseQuantityUnit: dosageInstruction.dose.snomedCtCode?.conceptId,
        doseQuantityValue: dosageInstruction.dose.value,

        doseQuantityRangeMinimumUnit: minimum?.snomedCtCode?.conceptId,
        doseQuantityRangeMinimumValue: minimum?.value,

        doseQuantityRangeMaximumUnit: maximum?.snomedCtCode?.conceptId,
        doseQuantityRangeMaximumValue: maximum?.value,

        periodValue: dosageInstruction.period.value,
        periodUnit: dosageInstruction.period.unit,

        frequency: dosageInstruction.frequency,

        expectedDaysSupply: expectedDaysSupply,
        issueQuantityUnit: issueQuantity.snomedCtCode?.conceptId,
      },
    })
    .then((x) => x.data);
};
