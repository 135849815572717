<script setup lang="ts">
import { isString } from "@vue/shared";
import { computed } from "vue";
import { Item } from "./interfaces";

export interface Props {
  item: Item;

  title: string;
  path: string | ((e: Item) => string);

  width?: string;
  cellClass?: any;
}

const props = defineProps<Props>();

const text = computed(() =>
  isString(props.path) ? props.item[props.path] : props.path?.(props.item),
);
</script>
<template>
  <div>
    <slot>
      {{ text }}
    </slot>
  </div>
</template>
