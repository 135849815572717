<template>
  <div class="appointment-queue flex flex-col flex-nowrap gap-2">
    <h3 v-if="title">{{ title }}</h3>

    <div class="queue-place-list scroll-area">
      <div class="header q-mb-sm">
        <div v-if="queue">
          <h3 v-if="heading" class="header-title">{{ heading }}</h3>

          <div class="empty-text">
            <p v-if="queueTimes" class="q-mb-none">
              <q-icon name="fa-light fa-clock" />
              <span class="q-ml-xs">{{ queueTimes }}</span>
            </p>

            <p class="flex items-center q-mb-none">
              <q-icon v-if="deliveryModeIcon" :name="deliveryModeIcon" />
              <span class="q-ml-xs">{{ queue.summary.defaultDeliveryMode.label }}</span>
            </p>

            <p class="q-mb-none">
              <q-icon name="fa-solid fa-map-marker-alt" />
              <span class="q-ml-xs">{{ queue?.summary.site?.name ?? "None recorded" }}</span>
            </p>

            <p class="q-mb-none">
              <q-icon name="fa-light fa-clock" />
              <span class="q-ml-xs">{{ formattedUnseenCount }}</span>
            </p>
          </div>
        </div>

        <slot name="controls">
          <div class="flex justify-between items-center">
            <slot
              v-if="showQueueControls && !displayOnly && queue.summary.status.isActive"
              name="controls"
            >
              <m-dropdown-button
                :options="queueActions"
                icon="fa-solid fa-ellipsis-vertical"
                menu
                icon-only
                ghost
                label="Delivery Mode Menu"
              />
            </slot>
          </div>
        </slot>
      </div>

      <m-layout-stack>
        <div v-if="queue" class="flex-auto">
          <m-layout-stack>
            <m-banner
              v-if="!displayOnly && queue.summary.openToNewAppointments.isClosed"
              type="info"
              class="q-my-sm"
            >
              <p>Closed to new patients</p>
            </m-banner>

            <m-banner v-if="!displayOnly && queue.summary.isAtCapacity" type="warning">
              <p>All available time booked.</p>
            </m-banner>

            <m-banner
              v-if="!displayOnly && queue.summary.hasHighPriorityAppointments"
              type="warning"
            >
              <p>High priority appointments.</p>
            </m-banner>

            <p
              v-if="!hideNoAppointmentsLabel && !queue?.appointments.length"
              class="text-center empty-text q-mt-md"
            >
              There are no appointments in this queue.
            </p>

            <table v-if="formattedAppointments.length || !displayOnly" class="calendar-table">
              <tbody>
                <tr v-for="(appointment, i) in formattedAppointments" :key="i">
                  <td>
                    <m-appointment-card
                      :class="{
                        'cursor-pointer': $attrs.onQueuePlaceClicked,
                        selected: selectedItems.has(appointment.id),
                      }"
                      :hide-reason-for-appointment="hideReasonsForAppointments"
                      :appointment="appointment"
                      @click="onClick($event, appointment)"
                    />
                  </td>
                </tr>

                <template v-if="!displayOnly && queue.summary.openToNewAppointments.isOpen">
                  <tr>
                    <td>
                      <div :class="{ flex: queue.appointments.length }">
                        <m-button
                          ghost
                          label="Book appointment"
                          @click="$emit('add-patient-to-queue')"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </m-layout-stack>
        </div>

        <slot v-else></slot>
      </m-layout-stack>

      <div v-if="showFooter" class="appointment-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { QIcon } from "quasar";
import { PropType, defineComponent, reactive } from "vue";
import { useEvent } from "vue-composable";
import { Action } from "../../MAction";
import MBanner from "../../MBanner/MBanner.vue";
import MButton from "../../MButton/MButton.vue";
import MDropdownButton from "../../MDropdownButton";
import MLayoutStack from "../../MLayoutStack";
import MAppointmentCard from "../MAppointmentCard/MAppointmentCard.vue";
import { AppointmentQueue } from "./types";

export default defineComponent({
  components: {
    MButton,
    MBanner,
    MAppointmentCard,
    MLayoutStack,
    QIcon,
    MDropdownButton,
  },

  props: {
    /** The appointment queue */
    queue: {
      type: Object as PropType<AppointmentQueue | null>,
      required: false,
      default: null,
    },

    /** The heading displayed at the top of the component */
    heading: {
      type: String,
      required: false,
      default: null,
    },

    /** The heading displayed at the top of the component */
    title: {
      type: String,
      required: false,
      default: null,
    },

    /** Which type of appointments should be counted - assigned or unassigned */
    appointmentTypeCount: {
      required: false,
      type: String as PropType<"ASSIGNED" | "UNASSIGNED">,
      default: undefined,
    },

    /** Whether the queue can be closed/opened and if appointments can be added */
    displayOnly: {
      type: Boolean,
      required: false,
      default: true,
    },

    /** Whether the queue controls (open/close queue) should be displayed */
    showQueueControls: {
      required: false,
      type: Boolean,
      default: false,
    },

    /** Whether the footer should be displayed or not */
    showFooter: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideNoAppointmentsLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    addToQueueLabel: {
      type: String,
      required: false,
      default: "Add patient",
    },

    hideReasonsForAppointments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    "add-patient-to-queue",
    "appointment-clicked",
    "cancel-queue",
    "edit-queue",
    "drag-start",
    "drag-end",
    "update-open-to-new-appointments",
    "print-queue",
  ],

  setup(props, { emit }) {
    let draggingItem = null as any;

    const selectedItems = reactive(new Map());

    function onDragStart(e: DragEvent, data: any) {
      e.dataTransfer!.dropEffect = "move";

      e.dataTransfer!.setDragImage(new Image(), 0, 0);

      const items = selectedItems.size ? Array.from(selectedItems.values()) : [e.target];

      e.dataTransfer!.setData(
        "json",
        JSON.stringify(
          selectedItems.size
            ? Array.from(selectedItems.keys()).map(
                (x) => props.queue?.appointments?.find((t: any) => t.id === x),
              )
            : [data],
        ),
      );

      draggingItem = document.createElement("div");
      draggingItem.className = "flex flex-col";
      draggingItem.style.position = "absolute";
      draggingItem.style.boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)";
      draggingItem.style.top = e.clientY + 1 + "px";
      draggingItem.style.left = e.clientX + 1 + "px";
      draggingItem.style.zIndex = 1;

      for (const item of items) {
        item.style.opacity = "0.5";
        draggingItem.appendChild(item.cloneNode(true));
      }

      document.body.appendChild(draggingItem);

      // target.style.opacity = "0.5";
      draggingItem.__target = items;

      this.$emit("drag-start");
    }

    useEvent(
      window,
      "dragover",
      (e) => {
        if (draggingItem) {
          draggingItem.style.top = `${e.clientY + 1}px`;
          draggingItem.style.left = `${e.clientX + 1}px`;
        }
      },
      { passive: true },
    );

    useEvent(window, "dragend", () => {
      if (draggingItem) {
        draggingItem.__target.forEach((e: any) => (e.style.opacity = "1"));
        selectedItems.clear();

        draggingItem.remove();
        draggingItem = null;

        this.$emit("drag-end");
      }
    });

    function onClick(e: MouseEvent, appointment: any) {
      if (
        e.ctrlKey ||
        (e.shiftKey && (selectedItems.size === 0 || selectedItems.has(appointment.id)))
      ) {
        if (selectedItems.has(appointment.id)) {
          selectedItems.delete(appointment.id);
        } else {
          selectedItems.set(appointment.id, e.currentTarget);
        }
        return;
      }
      if (e.shiftKey) {
        const items = props.queue?.appointments ?? [];

        const lastItemId = Array.from(selectedItems.keys()).reverse()[0] || appointment.id;
        const lastItemIndex = items.findIndex((x: any) => x.id === lastItemId);
        const itemIndex = items.findIndex((x: any) => x.id === appointment.id);

        const selected = items.slice(
          Math.min(lastItemIndex, itemIndex),
          Math.max(lastItemIndex, itemIndex) + 1,
        );

        for (const item of selected) {
          selectedItems.set(item.id, document.querySelector(`div[id='${item.id}']`));
        }
        return;
      }
      emit("appointment-clicked", appointment);
    }
    return {
      onDragStart,
      onClick,
      selectedItems,
    };
  },

  computed: {
    /** Format the appointments to fit the appointment card */
    formattedAppointments(): any[] {
      if (!this.queue || !this.queue.appointments) return [];
      return this.queue.appointments.map((a: any) => {
        return {
          ...a,
          title: a.title ?? a.patient.name,
          description: a.reasonForAppointment ?? a.description ?? "No reason given",
          formattedDuration: a.duration === 1 ? `${a.duration} min` : `${a.duration} mins`,
        };
      });
    },

    deliveryModeLabel() {
      if (!this.queue?.summary?.defaultDeliveryMode) return undefined;

      if (this.queue.summary.defaultDeliveryMode.isFaceToFace) {
        return "Face to Face";
      }

      if (this.queue.summary.defaultDeliveryMode.isHomeVisit) {
        return "Home Visit";
      }

      if (this.queue.summary.defaultDeliveryMode.isVideo) {
        return "Video";
      }

      return "Call";
    },

    deliveryModeIcon() {
      if (!this.queue?.summary?.defaultDeliveryMode) return undefined;

      if (this.queue.summary.defaultDeliveryMode.isFaceToFace) {
        return "fa-solid fa-user-doctor";
      }

      if (this.queue.summary.defaultDeliveryMode.isHomeVisit) {
        return "fa-solid fa-house";
      }

      if (this.queue.summary.defaultDeliveryMode.isVideo) {
        return "fa-solid fa-video";
      }

      return "fa-solid fa-phone";
    },

    queueActions(): Array<Action> {
      const queue = this.queue;
      if (!queue) return [];
      return [
        {
          label: queue.summary.openToNewAppointments.isOpen
            ? "Close queue to new patients"
            : "Re-open queue to new patients",
          click: this.updateOpenToNewAppointments,
        },
        {
          label: "Edit queue",
          click: () => this.$emit("edit-queue", queue),
        },
        queue.summary.canBeCancelled
          ? {
              label: "Cancel queue",
              click: this.cancelAppointmentQueue,
            }
          : false,
        {
          label: "Print queue",
          click: () => this.$emit("print-queue", queue),
        },
      ].filter(Boolean) as Array<Action>;
    },

    /** Format the queue's start & end date/times */
    queueTimes(): string | null {
      if (!this.queue) return null;

      // @ts-ignore
      const timeFormatter = (dateTime: string) => this.$formatDateTime(dateTime).split(", ")[1];

      const start = timeFormatter(this.queue.summary.startDateTime);
      const end = timeFormatter(this.queue.summary.endDateTime);

      return `${start} - ${end}`;
    },

    /** Format the unseen appointments count and duration */
    formattedUnseenCount(): string | null {
      // @ts-ignore
      const count = this.queue?.summary.unseen.count;
      // @ts-ignore
      const duration = this.queue?.summary.unseen.duration;

      return `Left to be seen: ${count} patient${count === 1 ? "" : "s"} ${
        duration ? `(${duration})` : ""
      }`;
    },
  },

  methods: {
    updateOpenToNewAppointments() {
      // @ts-ignore
      this.$emit("update-open-to-new-appointments", this.queue.id);
    },

    cancelAppointmentQueue() {
      this.$dialog({
        title: "Cancel Queue?",
        text: "Are you sure you would like to cancel this appointment queue? This action cannot be undone.",
        cancelLabel: "No, don't cancel",
        okLabel: "Yes, cancel",
      }).onOk(() => {
        this.$emit("cancel-queue", this.queue?.id);
      });
    },
  },
});
</script>

<style lang="scss">
.appointment-queue {
  max-height: 100%;
  .queue-place-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    background: var(--grey-lightest);
    border-radius: 6px;

    padding: var(--gap-3);

    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      border: none;

      font-family: Arial, sans-serif;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      color: var(--theme-grey);
    }

    .controls {
      display: flex;
      justify-content: space-between;
    }

    .list-container {
      flex: 1 1 auto;

      ul {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
      }
    }

    .appointment-footer {
      background: var(--grey-lightest);
      box-shadow:
        0px -4px 4px rgba(0, 0, 0, 0.04),
        inset 0px 1px 0px var(--border-colour);
    }

    .calendar-table {
      width: 100%;
      border-collapse: collapse;

      td {
        margin: 0;
        padding: 3px 0;

        min-height: 45px;

        text-align: start;
        vertical-align: top;

        font-size: 12px;
        line-height: 150%;
        font-family: Arial, sans-serif;
        /* identical to box height, or 117% */

        /* Primary / Blue Dark / 75 */

        color: var(--theme-grey);
      }

      tr > td:nth-child(2) {
        height: 50px;
        width: 100%;
        padding-left: 3px;
        // height: 100%;
      }
    }

    .add-patient-to-queue-button {
      height: 100%;
      min-height: 45px;
      display: flex;
      border: 1.5px dashed var(--grey-darker);
      border-radius: 4px;
      width: 100%;

      color: var(--grey-darker);
      font-style: normal;
      font-size: 14px;
      line-height: 150%;

      align-items: center;
      cursor: pointer;

      .on-hover {
        display: none;
      }
      .no-hover {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-items: center;
      }

      &:hover {
        background-color: var(--grey);
        color: var(--theme-grey);

        .no-hover {
          display: none;
        }

        .on-hover {
          display: flex;
          flex: 1 1 auto;
          align-items: center;
          justify-items: center;
          text-decoration: underline;
        }
      }

      .add-patient-to-queue-button-text {
        margin-left: 16px;
        cursor: pointer;
        background: transparent;
        color: var(--grey-darkest);
        align-self: center;
      }
    }
  }
}
</style>
