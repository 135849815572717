<script setup lang="ts">
import { onMounted } from "vue";

const props = defineProps<{
  params: Record<string, any>;
}>();
onMounted(() => {});

function formatReferenceRange(referenceRange) {
  let referenceRangeLabel = "";
  if (referenceRange.lowerReferenceLimit && referenceRange.upperReferenceLimit) {
    referenceRangeLabel += `${referenceRange.lowerReferenceLimit} – ${referenceRange.upperReferenceLimit}`;
  } else if (referenceRange.lowerReferenceLimit) {
    referenceRangeLabel += `≥ ${referenceRange.lowerReferenceLimit}`;
  } else if (referenceRange.upperReferenceLimit) {
    referenceRangeLabel += `≤ ${referenceRange.upperReferenceLimit}`;
  }
  if (referenceRange.description) {
    referenceRangeLabel += `: ${referenceRange.description}`;
  }
  if (referenceRangeLabel === "") {
    return false;
  }
  return referenceRangeLabel;
}
</script>

<template>
  <m-layout-stack v-if="props.params.value?.referenceRanges?.length" no-gap>
    <div
      v-for="(referenceRange, index) in props.params.value.referenceRanges"
      :key="index"
      class="reference-range"
    >
      <span class="text-cursor">
        {{ formatReferenceRange(referenceRange) ?? " - " }}
      </span>
    </div>
  </m-layout-stack>
  <div v-else>-</div>
</template>
