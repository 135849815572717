<script setup lang="ts">
import { NO_OP } from "vue-composable";
import MAppointmentDiary from "./MAppointmentDiary.vue";
import { AppointmentDiary } from "./types";
import { NOOP } from "@vue/shared";
import { onMounted, ref } from "vue";

const diary: AppointmentDiary = {
  scheduleType: "diary",
  id: "01924c8d-e5de-738c-86ce-e2724261bdab",
  startDateTime: "2024-10-07 08:00:00",
  endDateTime: "2024-10-07 12:00:00",
  summary: {
    site: {
      id: "01924c8d-e55b-7383-9d18-a88be9403226",
      name: "Andover Site",
    },
    room: {
      id: "01924c8d-e566-720c-bff5-e9c76e1021a2",
      name: "Minor Surgery Room",
    },
    service: {
      id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
      name: "Minor Illness Clinic",
    },
    status: {
      value: "active",
      label: "Active",
      isActive: true,
      isCancelled: false,
    },
    startDateTime: "2024-10-07 08:00:00",
    endDateTime: "2024-10-07 12:00:00",
    defaultDeliveryMode: {
      value: "face-to-face",
      label: "Face-to-face",
      isFaceToFace: true,
      isHomeVisit: false,
      isPhone: false,
      isVideo: false,
    },
    usualAppointmentDuration: 20,
    assignees: [
      {
        id: "01924c8d-f582-73ec-960a-2bfcb2d7133c",
        displayName: "Mr Adele Mitchell",
      },
    ],
    booked: {
      count: 3,
      duration: "1 hr",
    },
    available: {
      count: 0,
      duration: "3 hrs",
    },
    additionalInformation: [],
    defaultAppointmentType: {
      id: "01924c8d-e5db-7175-872e-c4d685719d41",
      appointmentService: {
        id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic",
        patientFacingName: null,
        appointmentTypes: [],
      },
      status: {
        value: "active",
        label: "Active",
        isActive: true,
        isArchived: false,
      },
      name: "Minor Illness Clinic Default",
      patientFacingName: "Appointment",
      patientFacingOrInternalName: "Appointment",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      defaultUsualAppointmentDuration: 20,
      defaultNHSNationalSlotTypeCategory: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      internalBookingOpensHoursBeforeStartTime: null,
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      patientFacingServicesBookingOpensHoursBeforeStartTime: null,
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      gpConnectBookingOpensHoursBeforeStartTime: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
      availableProcedures: [],
    },
    nhsNationalSlotTypeCategoryDefault: {
      value: "30101",
      label: "General Consultation Acute",
      isActive: true,
      isCareRelatedEncounter: true,
    },
    internalBookingOpensDateTime: null,
    patientFacingServicesAvailability: null,
    patientFacingServicesBookingOpensDateTime: null,
    canBeCancelled: false,
    supportsTimedAppointments: true,
    supportsUntimedAppointments: true,
    canBookUntimedAppointments: true,
    isAtCapacity: false,
    hasHighPriorityUntimedAppointments: false,
  },
  entries: [
    {
      isLocalAppointment: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      id: "01924c8f-6080-7052-bf6c-f54f012c63ab",
      versionId: "41b7ae6957218c448f79f07131234f87",
      overlapsWithOtherAppointments: false,
      patient: {
        id: "01924c8d-d15c-714c-ba5d-9b9dc6d63c49",
        name: "Mrs Rachel Keef",
        firstNames: "Mrs Rachel",
        familyName: "Keef",
        officialDisplayName: "Mrs Rachel Keef",
        nameParts: {
          prefix: "Mrs",
          givenName: "Rachel",
          middleNames: "Lori",
          familyName: "Keef",
          suffix: null,
        },
        preferredName: null,
        nhsNumber: "9730325944",
        dateOfBirth: "1987-10-02",
        homePhoneNumber: null,
        mobilePhoneNumber: null,
        homeAddress: "100 THE FAIRWAY, LONDON, N13 5QL, GBR",
        emailAddress: null,
        deceased: false,
        risks: [],
      },
      type: {
        value: "timed",
        label: "Timed",
        isTimed: true,
        isUntimed: false,
      },
      slotType: {
        value: "appointment",
        label: "Appointment",
        isAppointment: true,
        isSlot: false,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      isHighPriority: false,
      isHiddenFromPatientFacingServices: false,
      serviceName: "Minor Illness Clinic",
      intendedOrContainerStartDateTime: "2024-10-07 08:00:00",
      intendedOrContainerEndDateTime: "2024-10-07 08:20:00",
      startDateTime: "2024-10-07 08:00:00",
      endDateTime: "2024-10-07 08:20:00",
      duration: 20,
      queueStartDateTime: null,
      queueEndDateTime: null,
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      assignees: "Mr Adele Mitchell",
      location: "Andover Site, Minor Surgery Room",
      reasonForAppointment: "Asthma review.",
      additionalInformation: null,
      patientSuppliedInformationForClinician: null,
      compiledReasonForAppointment: "Asthma review.",
      displayStatus: {
        value: "booked",
        label: "Booked",
        isLate: false,
        isArrived: false,
        isCalledIn: false,
        isBooked: true,
        isDidNotAttend: false,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
      },
      createdDateTime: "2024-10-02 10:28:37",
      deliveryMode: {
        value: "video",
        label: "Video",
        isFaceToFace: false,
        isHomeVisit: false,
        isPhone: false,
        isVideo: true,
      },
      appointmentStatus: {
        value: "pending",
        label: "Pending",
        isPending: true,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
        isDidNotAttend: false,
      },
      arrivalStatus: null,
      virtualMeetingRoomId: null,
      encounterId: null,
      encounterIsDraft: null,
      isPending: true,
      formattedTimesAndDuration: "08:00 - 08:20 (20 mins)",
      formattedDateTimeRange: "Mon 07 Oct 2024, 08:00 - 08:20",
      cancelledDateTime: null,
      rescheduledDateTime: null,
      cancellationReason: null,
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      queueId: null,
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 08:20:00",
      endDateTime: "2024-10-07 08:40:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 08:40:00",
      endDateTime: "2024-10-07 09:00:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalAppointment: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      id: "0192665e-7f3c-7375-ba92-d0daeaaa4c8a",
      versionId: "fe1cf2b50624d1a54025ebc2a5fc4424",
      overlapsWithOtherAppointments: false,
      patient: {
        id: "01924c8d-b996-7082-b1d1-e01652d63ad1",
        name: "Miss Amy Kriens",
        firstNames: "Miss Amy",
        familyName: "Kriens",
        officialDisplayName: "Miss Amy Kriens",
        nameParts: {
          prefix: "Miss",
          givenName: "Amy",
          middleNames: "Kay",
          familyName: "Kriens",
          suffix: null,
        },
        preferredName: null,
        nhsNumber: "9730326541",
        dateOfBirth: "1951-10-02",
        homePhoneNumber: null,
        mobilePhoneNumber: null,
        homeAddress: "1 ARNOS ROAD, LONDON, N11 1AP, GBR",
        emailAddress: null,
        deceased: false,
        risks: [],
      },
      type: {
        value: "timed",
        label: "Timed",
        isTimed: true,
        isUntimed: false,
      },
      slotType: {
        value: "appointment",
        label: "Appointment",
        isAppointment: true,
        isSlot: false,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      isHighPriority: false,
      isHiddenFromPatientFacingServices: false,
      serviceName: "Minor Illness Clinic",
      intendedOrContainerStartDateTime: "2024-10-07 09:00:00",
      intendedOrContainerEndDateTime: "2024-10-07 09:20:00",
      startDateTime: "2024-10-07 09:00:00",
      endDateTime: "2024-10-07 09:20:00",
      duration: 20,
      queueStartDateTime: null,
      queueEndDateTime: null,
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      assignees: "Mr Adele Mitchell",
      location: "Andover Site, Minor Surgery Room",
      reasonForAppointment: null,
      additionalInformation: null,
      patientSuppliedInformationForClinician: null,
      compiledReasonForAppointment: "No reason given.",
      displayStatus: {
        value: "late",
        label: "Late",
        isLate: true,
        isArrived: false,
        isCalledIn: false,
        isBooked: false,
        isDidNotAttend: false,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
      },
      createdDateTime: "2024-10-07 10:45:22",
      deliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      appointmentStatus: {
        value: "pending",
        label: "Pending",
        isPending: true,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
        isDidNotAttend: false,
      },
      arrivalStatus: null,
      virtualMeetingRoomId: null,
      encounterId: null,
      encounterIsDraft: null,
      isPending: true,
      formattedTimesAndDuration: "09:00 - 09:20 (20 mins)",
      formattedDateTimeRange: "Mon 07 Oct 2024, 09:00 - 09:20",
      cancelledDateTime: null,
      rescheduledDateTime: null,
      cancellationReason: null,
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      queueId: null,
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 09:20:00",
      endDateTime: "2024-10-07 09:40:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 09:40:00",
      endDateTime: "2024-10-07 10:00:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 10:00:00",
      endDateTime: "2024-10-07 10:20:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 10:20:00",
      endDateTime: "2024-10-07 10:40:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 10:40:00",
      endDateTime: "2024-10-07 11:00:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 11:00:00",
      endDateTime: "2024-10-07 11:20:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 11:20:00",
      endDateTime: "2024-10-07 11:40:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "not-available",
        label: "Not available",
        isAvailable: false,
        isNotAvailable: true,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 11:40:00",
      endDateTime: "2024-10-07 12:00:00",
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      duration: 20,
      appointmentType: {
        id: "01924c8d-e5db-7175-872e-c4d685719d41",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe72fee23",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "Minor Illness Clinic",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "Minor Illness Clinic Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 20,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "not-available",
          label: "Not available",
          isAvailable: false,
          isNotAvailable: true,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 20,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "Minor Illness Clinic",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
  ],
  untimedAppointments: [
    {
      isLocalAppointment: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      id: "0192664a-e6a7-704b-aaa9-ee16ec0dd320",
      versionId: "ecc324db0b593fb3c1d42fca73efe37c",
      overlapsWithOtherAppointments: false,
      patient: {
        id: "01924c8d-fec1-700c-bad0-e917e9cbb17a",
        name: "Mr Lucas Lee",
        firstNames: "Mr Lucas",
        familyName: "Lee",
        officialDisplayName: "Mr Lucas Lee",
        nameParts: {
          prefix: "Mr",
          givenName: "Lucas",
          middleNames: "Chris",
          familyName: "Lee",
          suffix: "Jr.",
        },
        preferredName: null,
        nhsNumber: "9449305919",
        dateOfBirth: "2016-09-21",
        homePhoneNumber: null,
        mobilePhoneNumber: "+441455363780",
        homeAddress: "8 Stevens Flat, New Adrian, Leicestershire, DG11 3JQ, GBR",
        emailAddress: "katie.davies@davies.com",
        deceased: false,
        risks: [],
      },
      type: {
        value: "untimed",
        label: "Untimed",
        isTimed: false,
        isUntimed: true,
      },
      slotType: {
        value: "appointment",
        label: "Appointment",
        isAppointment: true,
        isSlot: false,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      isHighPriority: false,
      isHiddenFromPatientFacingServices: false,
      serviceName: "Minor Illness Clinic",
      intendedOrContainerStartDateTime: "2024-10-07 08:00:00",
      intendedOrContainerEndDateTime: "2024-10-07 12:00:00",
      startDateTime: null,
      endDateTime: null,
      duration: 20,
      queueStartDateTime: null,
      queueEndDateTime: null,
      diaryStartDateTime: "2024-10-07 08:00:00",
      diaryEndDateTime: "2024-10-07 12:00:00",
      assignees: "Mr Adele Mitchell",
      location: "Andover Site, Minor Surgery Room",
      reasonForAppointment: null,
      additionalInformation: null,
      patientSuppliedInformationForClinician: null,
      compiledReasonForAppointment: "No reason given.",
      displayStatus: {
        value: "booked",
        label: "Booked",
        isLate: false,
        isArrived: false,
        isCalledIn: false,
        isBooked: true,
        isDidNotAttend: false,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
      },
      createdDateTime: "2024-10-07 10:23:57",
      deliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      appointmentStatus: {
        value: "pending",
        label: "Pending",
        isPending: true,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
        isDidNotAttend: false,
      },
      arrivalStatus: null,
      virtualMeetingRoomId: null,
      encounterId: null,
      encounterIsDraft: null,
      isPending: true,
      formattedTimesAndDuration: "20 mins",
      formattedDateTimeRange: "Mon 07 Oct 2024, 08:00 - 12:00",
      cancelledDateTime: null,
      rescheduledDateTime: null,
      cancellationReason: null,
      diaryId: "01924c8d-e5de-738c-86ce-e2724261bdab",
      queueId: null,
    },
  ],
};

const container = ref<HTMLDivElement | null>(null);

onMounted(() => {
  if (container.value) {
    container.value.scrollBy({
      top: 200,
    });
  }
});
</script>

<template>
  <div ref="container" style="width: 500px; height: 500px; overflow: auto">
    <m-appointment-diary
      :title="diary.summary.service.name"
      :diary="diary"
      :show-diary-controls="true"
      heading="GP Surgery"
      @click="NO_OP"
      @cancel-diary="NOOP"
    />
  </div>
</template>
