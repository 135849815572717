<script setup lang="ts">
import { callWithErrorHandling, getCurrentInstance, provide, ref } from "vue";
import { randomID } from "../../../../../utils/helpers";
import MButton from "../../../../medicus/MButton";
import PatientBanner from "../../../../medicus/PatientBanner";

const props = defineProps<{
  title?: string;
  patientId?: string;

  type?: "tab" | "dialog" | "small" | "form" | "large" | "full";
  bgGrey?: boolean;
}>();
const emit = defineEmits({
  close: () => true,
});

const type = ref(props.type);
const padding = ref(true);
const bgGrey = ref(props.bgGrey);

provide("setModalType", (t: "tab" | "dialog" | "small" | "form" | "large" | "full") => {
  type.value = t;
});
provide("removePadding", (s: boolean) => {
  padding.value = !s;
});
provide("setBgGrey", (s: boolean) => {
  bgGrey.value = s;
});

provide("setModalSmall", () => {
  // decrepated, use setModalType('small')
  type.value = "small";
  // TODO remove this in the future, until setModalSmall is used
  if (import.meta.env.DEV) {
    const warnHandler = getCurrentInstance()?.appContext.app.config.warnHandler;
    if (warnHandler) {
      warnHandler(
        'setModalSmall(true) is deprecated, use setModalType("small")',
        getCurrentInstance().proxy,
        "",
      );
    } else {
      callWithErrorHandling(
        () => {
          throw new Error("setModalSmall(true) is deprecated, use setModalType('small')");
        },
        null,
        0,
      );
    }
  }
});

provide("reloadModal", () => {
  // decrepated, use setModalType('small')
  // TODO remove this in the future, until reloadModal is used
  if (import.meta.env.DEV) {
    const warnHandler = getCurrentInstance()?.appContext.app.config.warnHandler;
    if (warnHandler) {
      warnHandler(
        'reloadModal(true) is deprecated, use setModalType("small")',
        getCurrentInstance().proxy,
        "",
      );
    } else {
      callWithErrorHandling(
        () => {
          throw new Error("reloadModal(true) is deprecated, use setModalType('small')");
        },
        null,
        0,
      );
    }
  }
});

function handleCloseBtnClicked() {
  emit("close");
}

const labelId = randomID("modal-label");
</script>

<template>
  <div
    class="drawer-modal"
    :class="[
      type,
      {
        'with-padding': padding,
      },
    ]"
    :aria-labelledby="labelId"
    role="dialog"
  >
    <div class="modal--header">
      <h1 :id="labelId">
        {{ title }}
      </h1>
      <div class="modal-buttons">
        <m-button
          class="modal--header-close-btn"
          icon="fa-regular fa-xmark"
          color="secondary"
          title="Close Modal"
          label="Close Modal"
          icon-only
          borderless
          data-testid="modal-closeBtn"
          @click="handleCloseBtnClicked"
        />
      </div>
    </div>

    <patient-banner v-if="patientId" :patient-id="patientId" secondary blue-accent no-border />
    <div v-else />

    <div
      class="modal-content"
      :class="[bgGrey ? 'medicus-grey-background' : 'medicus-white-background']"
    >
      <slot />
    </div>
  </div>
</template>
<style lang="scss">
.drawer-modal {
  display: grid;
  grid-template-rows: 48px auto 1fr;
  grid-template-areas:
    "header"
    "banner"
    "content";

  justify-self: center;

  background: white;
  border-radius: 4px;

  width: 700px;
  // margin: calc(40px + 7vw) auto 0;
  margin: auto;
  overflow: hidden;

  // height: 100%;
  max-height: 100%;
  min-height: 200px;

  box-shadow: var(--box-shadow);

  @media (min-width: 1050px) {
    min-width: 1000px;
  }

  &.tab {
    .modal--header {
      border: none;
      box-shadow: none;
      padding-bottom: 0;
    }

    .m-tabs {
      margin-top: 0;
      > .scroll-area {
        background: var(--grey-lightest);
      }
    }
    .q-tab-panels {
      // background: var(--grey-lightest);
      height: 100%;
    }
    .q-panel {
      display: flex;
    }
    .q-tab-panel {
      height: auto;
      flex: 1 1 auto;
      overflow: auto;
    }
    .q-tabs {
      box-shadow: inset 0px -1px 0px var(--border-colour);
    }
    .q-tab {
      box-shadow: inset 0px -1px 0px var(--border-colour);
    }
  }
  &.dialog {
  }
  &.small {
    height: auto;
    max-height: 100%;
    width: 700px !important;
    min-width: 700px !important;

    .modal-content {
      overflow: auto;
      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }
  &.form {
    width: 700px !important;
    min-width: 700px !important;
    .modal-content {
      overflow: auto;
    }
  }
  &.large {
    min-width: 1000px !important;
    max-width: 1000px !important;
    height: 100%;
    .modal-content {
      overflow: auto;

      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }
  &.full {
    width: 100%;
    min-width: 100%;
    height: 100%;

    .modal-content {
      overflow: auto;

      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }

  &.with-padding {
    .modal-content {
      padding: 1em 2em 2em;

      &:has(.m-form) {
        padding: 0;
      }
    }
  }

  .modal--header {
    grid-area: header;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 4px 0 16px;

    box-shadow: inset 0px -1px 0px var(--border-colour);

    .modal--header-close-btn {
      height: 40px;
      width: 40px;
    }

    .q-icon {
      color: var(--grey-lightest-non-text);
      font-size: 22px !important;
    }
  }

  > .modal-content {
    display: flex;
    grid-area: content;
    height: 100%;
  }

  > .encounter-patient {
    grid-area: banner;
    padding: 3px 16px;

    &:has(.badges) {
      padding-bottom: 2px;
    }
  }
}
</style>
