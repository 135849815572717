<script setup lang="ts">
import useModelToggle from "quasar/src/composables/private/use-model-toggle";
import { inject, provide, ref, watch } from "vue";
import { useVModel } from "vue-composable";
import { onBeforeRouteUpdate } from "vue-router";
import bus from "../../../utils/bus";
import { showDialog } from "../../../utils/dialog";
import { randomID } from "../../../utils/helpers";
import MButton from "../MButton";
import MPatientBanner from "../PatientBanner";

const props = defineProps<{
  modelValue: boolean | any;

  type?: "tab" | "dialog" | "small" | "form" | "large" | "full";

  title?: string;
  small?: boolean;
  full?: boolean;
  fillHeight?: boolean;
  contentClass?: any;
  wrapperClass?: any;

  // makes the modal not check for formTouched
  transient?: boolean;
  bgGrey?: boolean;

  zIndex?: number | string;
}>();

const emit = defineEmits({
  close: () => true,
  "update:modelValue": () => true,
  hide: () => true,
  beforeHide: () => true,
});

const isOpen = useVModel(props, "modelValue");
const toggle = useModelToggle({
  showing: isOpen,
});

function close() {
  formTouched.value = false;
  hide();
  emit("close");
  bus.emit("modal:close", true);
}
function hide() {
  toggle.hide();
  modalTitle.value = props.title || "";
  modalType.value = props.type || "";
}
provide("close", close);
provide("closeModal", close);

provide("success", (shouldClose) => {
  if (shouldClose) {
    close();
  }
});
provide("cancel", (shouldClose) => {
  if (shouldClose) {
    close();
  }
});

provide("minimise", hide);
const modalTitle = ref(props.title);
watch(
  () => props.title,
  (t) => (modalTitle.value = t),
);
provide("modalTitle", modalTitle);
const setTitle = inject("setTitle", false as false | ((t: string) => void));
provide("setTitle", (t: string) => {
  if (setTitle) {
    setTitle(t);
  }
  modalTitle.value = t;
});
const modalType = ref<string | undefined>(props.type);
provide("modalType", modalType);
watch(
  () => props.type,
  (t) => (modalType.value = t),
);
provide("setModalType", (t: string) => {
  modalType.value = t;
});
watch(
  () => props.type,
  (t) => (modalType.value = t),
);
const modalFull = ref(props.full);
provide("setModalFull", (s: boolean) => {
  modalFull.value = s;
});
watch(
  () => props.full,
  (t) => (modalFull.value = t),
);
const modalSmall = ref(props.small);
provide("setModalSmall", (s: boolean) => {
  modalSmall.value = s;
});
watch(
  () => props.small,
  (t) => (modalSmall.value = t),
);
const removePadding = ref(false);
provide("removePadding", (v: boolean) => {
  removePadding.value = v;
});
// const useTeleport = IS_APP || true;
let requestFocusCb: undefined | Function = undefined;
provide("requestFocus", (cb: Function) => {
  if (requestFocusCb) return;
  requestFocusCb = cb;
});
const modelContentEl = ref<HTMLElement>();

function autoFocus(v: HTMLElement, n = 0) {
  const focussableElements =
    'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), input[type=search]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

  const items = Array.from<HTMLElement>(v.querySelectorAll(focussableElements) || []).filter(
    (x) => "focus" in x,
  );

  if (items.length > 0) {
    const textInput = items.find(
      (item) =>
        item.tagName.toLowerCase() === "input" &&
        (item.attributes["type"].value.toLowerCase() === "text" ||
          item.attributes["type"].value.toLowerCase() === "search"),
    );

    if (textInput) {
      textInput.focus();
    } else {
      items[0].focus();
    }
  } else if (!requestFocusCb && v === modelContentEl.value) {
    setTimeout(() => autoFocus(v, n + 1), 10);
  }
}

watch(
  modelContentEl,
  (v, _, onCleanup) => {
    if (v) {
      onCleanup(() => {
        removePadding.value = false;
      });
    } else {
      requestFocusCb = undefined;
    }
  },
  { flush: "post" },
);
const wrapperEl = ref<HTMLElement>();
watch(
  wrapperEl,
  (v, _, onCleanup) => {
    if (!v) return;

    autoFocus(v);
    if (props.type === "dialog") return;
    try {
      // const trap = createFocusTrap(v, {
      //   allowOutsideClick: true,
      //   initialFocus: false,
      // });
      // // trap?.activate();
      // onCleanup(() => trap?.deactivate());
    } catch {
      // ignore
    }
  },
  {
    flush: "post",
  },
);
const formTouched = ref(false);
provide("formTouched", (e: boolean) => (formTouched.value = e));

function shouldClose(e?: Event) {
  e?.preventDefault();
  if (formTouched.value && !props.transient) {
    showDialog({
      title: "Unsaved Changes",
      text: "You have unsaved changes. Are you sure you want to close the form?",
      cancelLabel: "No, don't close",
      okLabel: "Yes, close",
      type: "dialog",
    }).onOk(() => {
      close();
    });

    return false;
  } else {
    close();
  }

  return true;
}
function onKeyPress(e: KeyboardEvent) {
  if (e.key === "Escape") {
    shouldClose();
  }
}

onBeforeRouteUpdate(() => {
  if (formTouched.value) {
    return shouldClose();
  }
  return true;
});

const greyBackground = ref(props.bgGrey);
watch(
  () => props.bgGrey,
  (g) => (greyBackground.value = g),
);
provide("setBgGrey", (v?: boolean) => (greyBackground.value = v));

const patientId = ref<string>();
provide("setPatientId", (v?: string) => (patientId.value = v));

//HACK: Disabling focus-trap in MModal due to focusing conflict with Quasar list (see https://medicus-health.atlassian.net/browse/ENG-12444)
// useFocusTrap(wrapperEl);

function getParents(el: HTMLElement) {
  const a = [];
  do {
    a.push(el);
    el = el.parentElement!;
  } while (el);
  return a;
}

watch(
  isOpen,
  (showing, _, onCleanup) => {
    if (showing) {
      const activeElements = getParents(document.activeElement as HTMLElement);
      onCleanup(() => {
        for (const el of activeElements) {
          if ("focus" in el) {
            el.focus();
            return;
          }
        }
      });
    }
  },
  {
    immediate: true,
  },
);
const labelId = randomID("modal-title-");

const contentEl = ref<HTMLDivElement | null>(null);
// enableFocusTrap(contentEl);

defineExpose({
  isOpen,
});
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <teleport to="#modal">
    <div
      v-if="isOpen"
      ref="wrapperEl"
      class="modal-wrapper"
      :class="wrapperClass"
      role="dialog"
      tabindex="0"
      :aria-labelledby="labelId"
      :style="{ zIndex }"
      @keydown="onKeyPress"
    >
      <div
        ref="contentEl"
        class="modal"
        :class="[
          modalSmall && 'modal-small',
          modalFull && 'modal-full',
          contentClass,
          modalType && 'modal-' + modalType,
          fillHeight && 'fill-height',
        ]"
      >
        <div class="modal-header">
          <h1 :id="labelId">{{ modalTitle }}</h1>

          <div class="flex modal-buttons gap-3">
            <slot name="modal-title-buttons" />

            <m-button
              class="modal-close-btn"
              icon="fa-light fa-xmark"
              color="secondary"
              title="Close Modal"
              label="Close Modal"
              icon-only
              borderless
              data-testid="modal-closeBtn"
              @click="shouldClose"
            />
          </div>
        </div>
        <div v-if="patientId" class="modal-patient-info">
          <m-patient-banner :patient-id="patientId" blue-accent no-border />
        </div>
        <div
          ref="modelContentEl"
          class="modal-content"
          :class="{ 'medicus-grey-background': greyBackground }"
          :style="[removePadding ? { padding: 0 } : {}]"
        >
          <suspense>
            <slot />
            <template #fallback>
              <div class="flex flex-auto items-center justify-center h-full w-full">
                <span class="empty-text">Loading</span>
              </div>
            </template>
          </suspense>
        </div>

        <div
          v-if="$slots.footer"
          class="modal-footer"
          :class="{ 'medicus-grey-background': greyBackground }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss">
.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 6001;
  background: rgba(0, 0, 0, 0.5);
  padding: 1em 1em;
  overflow: hidden;

  @media (min-width: 810px) {
    padding: 35px;
  }

  &.dialog-warning {
    z-index: 10000 !important;
  }
  .modal {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: auto;
    width: auto;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 4px;
    @media (min-width: 1050px) {
      min-width: 1000px;
    }

    &.fill-height {
      height: 100%;
    }

    .modal-header {
      box-shadow: inset 0px -1px 0px var(--border-colour);
      display: flex;
      justify-content: space-between;
      padding: 14px 21px;

      .modal-close-btn {
        height: 30px;
      }
    }
    .modal-patient-info > .encounter-patient {
      // margin-top: 1em;
      padding: 10px 30px;
    }

    .modal-content {
      overflow: hidden;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
    &.modal-tab {
      .modal-header {
        border: none;
        padding-bottom: 0;
        box-shadow: none;
        // .header-title {
        //   padding-bottom: 0px;
        // }
      }
      .m-tabs {
        margin-top: 0;
        > .scroll-area {
          background: var(--grey-lightest);
        }
      }
      .q-tab-panels {
        // background: var(--grey-lightest);
        height: 100%;
      }
      .q-panel {
        display: flex;
      }
      .q-tab-panel {
        height: auto;
        flex: 1 1 auto;
        overflow: auto;
      }
      .q-tabs {
        box-shadow: inset 0px -1px 0px var(--border-colour);
      }
      .q-tab {
        box-shadow: inset 0px -1px 0px var(--border-colour);
      }
    }
  }
  .modal-small {
    height: auto;
    max-height: 100%;
    width: 700px !important;
    min-width: 700px !important;
    min-height: 200px;
    align-self: center;
    margin: auto;
    .modal-content {
      padding: 2em;
      overflow: auto;
      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }
  // used for confirmation dialog just alerts and stuff
  .modal-dialog {
    height: auto;
    max-height: 100%;
    width: auto !important;
    min-height: 100px;
    min-width: 300px !important;
    align-self: center;
    margin: auto;
    .modal-content {
      // background: #fff;
      padding: 1em 2em;
      overflow: auto;
      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }
  .modal-form {
    width: 700px !important;
    min-width: 700px !important;
    align-self: center;
    margin: auto;
    .modal-content {
      // background: #fff;
      padding: 1em 2em;
      overflow: auto;

      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }
  .modal-large {
    min-width: 1000px !important;
    max-width: 1000px !important;
    .modal-content {
      padding: 1em 2em;
      overflow: auto;

      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }
  .modal-full {
    min-width: 100%;
    .modal-content {
      padding: 1em 2em;
      overflow: auto;

      &:not(.medicus-grey-background) {
        background: #fff;
      }
    }
  }
  .m-split-screen {
    height: 100%;
  }
  .modal-buttons i {
    color: var(--grey-lightest-non-text);
    font-size: 22px !important;
  }

  @media (max-width: 700px) {
    padding: 0;
    height: inherit;
    min-height: max(100vh, 100%) !important;

    overflow: auto;

    .modal {
      margin: 0;
      height: max(100vh, 100%) !important;
      min-height: max(100vh, 100%) !important;
      max-height: max(100vh, 100%) !important;

      min-width: max(100vw, 100%) !important;
      max-width: max(100vw, 100%) !important;
      width: max(100vw, 100%) !important;

      border-radius: 0;
    }

    .modal-dialog {
      width: auto !important;
      min-width: 300px !important;
      margin: auto;
    }
  }
}
</style>
