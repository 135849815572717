var Se = Object.defineProperty;
var ke = (i, n, a) => n in i ? Se(i, n, { enumerable: !0, configurable: !0, writable: !0, value: a }) : i[n] = a;
var h = (i, n, a) => (ke(i, typeof n != "symbol" ? n + "" : n, a), a);
import { reactive as O, ref as u, computed as L, watchEffect as ae, watch as A, onUnmounted as De, readonly as Te, unref as ce, markRaw as Ce, openBlock as B, createElementBlock as N, createElementVNode as E, defineComponent as Le, createVNode as Ee, normalizeClass as Me } from "vue";
import { ConsoleLogger as xe, LogLevel as Pe, DefaultDeviceController as de, MeetingSessionConfiguration as Ue, DefaultMeetingSession as ze, DefaultActiveSpeakerPolicy as Fe, AudioProfile as Oe } from "amazon-chime-sdk-js";
import { useLocalStorage as H, isObject as C, usePromise as Be, debounce as Ne } from "vue-composable";
class se {
  constructor(n, a) {
    h(this, "attendeeId");
    h(this, "audioVideo");
    h(this, "externalUserId");
    h(this, "dropped");
    h(this, "present");
    h(this, "speaking");
    h(this, "muted");
    h(this, "tileState");
    h(this, "videoEl");
    this.attendeeId = n, this.audioVideo = a;
    const s = O(this);
    a.realtimeSubscribeToVolumeIndicator(n, this.volumeIndicator.bind(s));
  }
  bindVideo(n) {
    if (!(!this.tileState || !n)) {
      if (n === this.videoEl) {
        console.log("[meeting] same element bind video", this.attendeeId);
        return;
      }
      console.log("[meeting] bind video", this.attendeeId, n), this.audioVideo.bindVideoElement(this.tileState.tileId, n), this.videoEl = n;
    }
  }
  volumeIndicator(n, a, s, I, l) {
    console.log("[meeting] muted", s, ...arguments), typeof s == "boolean" && (this.muted = s), this.speaking = (a ?? 0) >= 0.2;
  }
}
function ue(i, n) {
  let a = !1;
  do {
    const { value: s, done: I } = i.next();
    if (s !== void 0 && n(s))
      return s;
    a = I;
  } while (!a);
}
function* Re(i, n) {
  let a = !1;
  do {
    const { value: s, done: I } = i.next();
    s !== void 0 && n(s) && (yield s), a = I;
  } while (!a);
}
function lt(i = {}) {
  const n = u(), a = u(), s = new xe("SDK", Pe.WARN), I = s, l = new de(s, {
    enableWebAudio: !0
  }), Q = de.getAudioContext, M = u(), x = u(), P = u(), _ = u(), T = u(), y = u(), R = u(!1), X = u(!1), { storage: j } = H("meeting-video"), { storage: q } = H("meeting-audio-output"), { storage: W } = H("meeting-audio-input"), g = u(), re = {
    audioInputsChanged(e) {
      M.value = e, e && (_.value = D(e, W.value, _.value));
    },
    audioOutputsChanged(e) {
      x.value = e, e && (T.value = D(e, q.value, T.value));
    },
    videoInputsChanged(e) {
      P.value = e, e && (y.value = D(e, j.value, y.value));
    }
  };
  l.addDeviceChangeObserver(re);
  const m = O(/* @__PURE__ */ new Map()), $ = L(() => Array.from(m.values())), ve = L(() => ue(m.values(), (e) => {
    var t;
    return (t = e.tileState) == null ? void 0 : t.localTile;
  })), fe = L(() => Array.from(Re(m.values(), (e) => {
    var t;
    return !((t = e.tileState) != null && t.localTile);
  }))), S = u(!1), p = u(!1), k = u(!1);
  ae(() => {
    console.log("[meeting] connected", p.value);
  });
  const ge = {
    audioVideoDidStart() {
      p.value = !0, S.value = !1, k.value = !1, console.log("[meeting] start", S.value, p.value);
    },
    audioVideoDidStartConnecting() {
      S.value = !0, p.value = !1, k.value = !1, console.log("[meeting] connecting", S.value, p.value);
    },
    audioVideoDidStop() {
      console.log("[meeting] stop"), p.value = !1, S.value = !1, k.value = !0;
    },
    videoTileDidUpdate(e) {
      if (console.log("[meeting] tile", e), e.localTile && console.log("[meeting] local user id ", e.boundAttendeeId), e.boundAttendeeId) {
        let t = m.get(e.boundAttendeeId);
        t || (t = O(new se(e.boundAttendeeId, g.value.audioVideo)), m.set(e.boundAttendeeId, t)), t.tileState = e;
      }
    },
    videoTileWasRemoved(e) {
      const t = ue(m.values(), (o) => {
        var d;
        return ((d = o.tileState) == null ? void 0 : d.tileId) === e;
      });
      t && (t.tileState = void 0);
    }
  };
  function me(e, t) {
    const o = g.value;
    o && o.audioVideo.bindVideoElement(e.tileId, ce(t));
  }
  function he(e) {
    const t = g.value;
    t && t.audioVideo.unbindVideoElement(e.tileId);
  }
  let Z = null, ee = null;
  const te = u(!1);
  async function oe(e, t, o = !1) {
    m.clear(), Z = e, ee = t;
    const d = new Ue(e, t);
    g.value = Ce(new ze(d, I, l)), await K(o);
    const f = g.value.audioVideo;
    f.start(), f.subscribeToActiveSpeakerDetector(new Fe(
      0.9,
      0.3,
      0.02,
      0.5
      /*0, 0.9, 0, 0.8*/
    ), (r) => {
      console.log("[meeting] active speakers", r);
      for (const V of $.value)
        V.speaking = r.indexOf(V.attendeeId) >= 0;
    }), f.setAudioProfile(Oe.fullbandSpeechMono()), f.realtimeSubscribeToAttendeeIdPresence((r, V, U, w) => {
      var z, F;
      let c = m.get(r);
      c || (c = O(new se(r, f)), m.set(r, c), (z = i.onAttendeeJoin) == null || z.call(i, c)), c && (console.log("attendee", r, { present: V, dropped: w }), c.present = V, c.dropped = w, c.externalUserId = U, (w || !c.present) && ((F = i.onAttendeeDrop) == null || F.call(i, c)));
    });
  }
  function pe() {
    return oe(Z, ee);
  }
  function D(e, t, o) {
    return e.find((d) => d.deviceId === (o == null ? void 0 : o.deviceId) || d.deviceId == t || "default") || e[0];
  }
  async function K(e = !1) {
    const [t, o, d] = await Promise.all([
      l.listAudioInputDevices(),
      l.listVideoInputDevices(),
      l.listAudioOutputDevices()
    ]);
    return M.value = t, x.value = d, P.value = o, e && (_.value = D(M.value, W.value), T.value = D(x.value, q.value), i.noVideo !== !0 && (y.value = D(P.value, j.value))), {
      audioInputs: t,
      videosInputs: o,
      audioOutputs: d
    };
  }
  A(() => {
    var e;
    return (e = g.value) == null ? void 0 : e.audioVideo;
  }, (e) => {
    e && e.addObserver(ge);
  }), A([_, p], ([e]) => {
    console.log("[meeting] setting audio", e), e !== void 0 && (e === null ? l.stopAudioInput() : l.startAudioInput(C(e) ? e.deviceId : e)), i.noStoreDefault || (W.value = C(e) ? e.deviceId : e);
  }, { flush: "sync" }), A(T, (e) => {
    e !== void 0 && l.chooseAudioOutput(C(e) ? e.deviceId : e), i.noStoreDefault || (q.value = C(e) ? e.deviceId : e);
  }, { flush: "sync" }), A(y, (e) => {
    e !== void 0 && ne(e), i.noStoreDefault || (j.value = C(e) ? e.deviceId : e);
  }, { flush: "sync" });
  const { exec: ne, loading: Ve, error: Ae, promise: Ie } = Be((e) => e === null ? l.stopVideoInput() : l.startVideoInput(e));
  A([n, g], ([e, t], o, d) => {
    !e || !t || (t.audioVideo.bindAudioElement(e), d(() => {
      t.audioVideo.unbindAudioElement();
    }));
  }), A([a, X, g, y, k], async ([e, t, o, d]) => {
    var r;
    if (!e)
      return;
    const f = o;
    if (t) {
      f ? o.audioVideo.stopLocalVideoTile() : l.stopVideoPreviewForVideoInput(e);
      return;
    }
    d ? (await Ie.value, await l.acquireVideoInputStream().catch(() => ne(d)), f ? (console.log("binding to ", e), (r = o.audioVideo.getLocalVideoTile()) == null || r.bindVideoElement(e), o.audioVideo.startLocalVideoTile()) : l.startVideoPreviewForVideoInput(e)) : await K(!0);
  }), A([g, R], ([e, t]) => {
    const o = e == null ? void 0 : e.audioVideo;
    o && (t ? o.realtimeMuteLocalAudio() : o.realtimeUnmuteLocalAudio());
  }), De(() => {
    ie();
  });
  async function ie() {
    var t;
    const e = (t = g.value) == null ? void 0 : t.audioVideo;
    if (e) {
      e.stopContentShare(), e.stopLocalVideoTile(), e.unbindAudioElement();
      try {
        await e.stopAudioInput(), await e.stopVideoInput(), await e.chooseAudioOutput(null);
      } catch (o) {
        console.error("Unable to set device to null on leave.", o);
      }
      console.log("[meeting] audioVideo stop"), e.stop();
    }
    try {
      await l.stopAudioInput(), await l.stopVideoInput(), await l.chooseAudioOutput(null);
    } catch (o) {
      console.error("Unable to set deviceController to null on leave.", o);
    }
    m.clear(), k.value = !0, te.value = !0;
  }
  const J = u(0), le = u(0);
  A([R, p, _, le], ([e], t, o) => {
    const d = l.createAnalyserNodeForAudioInput();
    if (!d) {
      setTimeout(() => {
        le.value++;
      }, 10);
      return;
    }
    if (e) {
      J.value = 0;
      return;
    }
    let f = 0;
    o(() => cancelAnimationFrame(f));
    const r = new Uint8Array(d.fftSize);
    let V = 0;
    const U = () => {
      if (V === 0) {
        d.getByteTimeDomainData(r);
        const w = 0.01;
        let c = w;
        for (const ye of r)
          c = Math.max(c, (ye - 128) / 128);
        let z = (Math.log(w) - Math.log(c)) / Math.log(w), F = Math.min(Math.max(z * 100, 0), 100);
        J.value = F;
      }
      V = (V + 1) % 2, f = requestAnimationFrame(U);
    };
    f = requestAnimationFrame(U);
  }, {
    immediate: !0,
    flush: "post"
  });
  function be(e) {
    var t;
    return e.dropped !== !0 && (e.tileState ? ((t = e.tileState) == null ? void 0 : t.localTile) === !1 : e.present === !0);
  }
  const b = L(() => $.value.filter(be)), we = 1e3;
  let Y = -1, G = "-1";
  function _e() {
    var t;
    const e = b.value;
    return console.log("curr attendee", e.find((o) => o === v.value && o.speaking), e.find((o) => o.speaking), ((t = v.value) == null ? void 0 : t.dropped) == !0 ? v.value : void 0, e[0]), // if the last speaker is currently speaking
    e.find((o) => o === v.value && o.speaking) || // if there's a new person speaking
    e.find((o) => o.speaking);
  }
  const v = u();
  return v.value = _e() || b.value[0], console.log("curr attendee lastSpeaker", v.value), ae(() => {
    var t, o;
    if ((t = v.value) != null && t.speaking) {
      console.log("curr attendee is speaking");
      return;
    }
    if (b.value.length === 1) {
      console.log("curr attendee not enough attendees", b.value.length), v.value = b.value[0];
      return;
    }
    let e = b.value.find((d) => d.speaking);
    if ((e == null ? void 0 : e.attendeeId) === G) {
      console.log("curr attendee next speaker is already next", e == null ? void 0 : e.attendeeId, G);
      return;
    }
    if (!e)
      if (((o = v.value) == null ? void 0 : o.dropped) !== !0) {
        console.log("curr attendee noone speaking and last still online");
        return;
      } else {
        v.value = b.value[0];
        return;
      }
    Y && clearTimeout(Y), console.log("curr attendee clearning timout"), console.log("curr attendee trying to call timeout", e.attendeeId, e.speaking), G = e.attendeeId, Y = setTimeout(() => {
      v.value = e;
    }, we);
  }), {
    connect: oe,
    disconnect: ie,
    reconnect: pe,
    forceInputsRefresh: K,
    bindVideoElement: me,
    unbindVideoElement: he,
    meetingSession: Te(g),
    audioInputDevices: M,
    audioOutputDevices: x,
    videoInputDevices: P,
    connected: p,
    connecting: S,
    disconnected: k,
    canReconnect: te,
    videoStarting: Ve,
    videoFailed: Ae,
    audioInput: _,
    audioOutput: T,
    videoInput: y,
    isMuted: R,
    noVideo: X,
    localTile: ve,
    remoteTiles: fe,
    attendees: m,
    attendeeList: $,
    deviceController: l,
    localVideoElement: a,
    audioElement: n,
    localAudioPercentage: J,
    lastSpeaker: v,
    validAttendees: b,
    getAudioContext: Q
  };
}
const je = {
  viewBox: "0 0 640 512",
  width: "1.2em",
  height: "1.2em"
}, qe = /* @__PURE__ */ E("path", {
  fill: "currentColor",
  d: "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2s-6.3 25.5 4.1 33.7l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L472.1 344.7c15.2-26 23.9-56.3 23.9-88.7v-40c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 21.2-5.1 41.1-14.2 58.7L416 300.8V96c0-53-43-96-96-96s-96 43-96 96v54.3L38.8 5.1zM344 430.4c20.4-2.8 39.7-9.1 57.3-18.2l-43.1-33.9c-12.1 3.7-24.9 5.7-38.2 5.7c-70.7 0-128-57.3-128-128v-8.7L144.7 210c-.5 1.9-.7 3.9-.7 6v40c0 89.1 66.2 162.7 152 174.4V464h-48c-13.3 0-24 10.7-24 24s10.7 24 24 24h144c13.3 0 24-10.7 24-24s-10.7-24-24-24h-48v-33.6z"
}, null, -1), We = [
  qe
];
function $e(i, n) {
  return B(), N("svg", je, [...We]);
}
const Ke = { name: "fa6-solid-microphone-slash", render: $e }, Je = {
  key: 0,
  class: "relative h-7 w-6 flex items-center justify-center text-white fill-white"
}, Ye = /* @__PURE__ */ E("div", { class: "rounded-full bg-black opacity-20 h-full w-full absolute z-0" }, null, -1), Ge = /* @__PURE__ */ E("div", { class: "left-level rounded-full bg-white h-1 w-1 transition" }, null, -1), He = /* @__PURE__ */ E("div", { class: "center-level rounded-full bg-white h-1 w-1 transition" }, null, -1), Qe = /* @__PURE__ */ E("div", { class: "right-level rounded-full bg-white h-1 w-1 transition" }, null, -1), Xe = [
  Ge,
  He,
  Qe
], Ze = { key: 2 }, et = {
  name: "VolumeLevel"
}, at = /* @__PURE__ */ Le({
  ...et,
  props: {
    muted: { type: Boolean },
    speaking: { type: Boolean },
    stay: { type: Boolean }
  },
  setup(i) {
    const n = i, a = L(() => n.speaking), s = u(!1), I = Ne(() => {
      s.value = !0;
    }, 2e3);
    return A(a, (l) => {
      l ? s.value = !0 : I();
    }), (l, Q) => l.muted ? (B(), N("div", Je, [
      Ye,
      Ee(ce(Ke), { class: "z-10" })
    ])) : a.value || l.stay ? (B(), N("div", {
      key: 1,
      class: Me(["volume-level rounded-full bg-info w-6 h-6 flex gap-0.50 items-center justify-center", { "audio-level": a.value }])
    }, Xe, 2)) : (B(), N("span", Ze));
  }
});
export {
  se as Attendee,
  at as VolumeLevel,
  lt as useAmazonChime
};
