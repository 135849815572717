<template>
  <component
    :is="onClick ? 'button' : 'div'"
    class="list-item-card"
    :class="{
      selected: modelValue,
      'has-selection': allowSelect,
      'disabled-item': disabled,
      'w-full medicus-outline': onClick,
    }"
    :on-click="onClick"
  >
    <slot name="checkbox">
      <m-checkbox
        v-if="allowSelect && (multi || multiple)"
        :model-value="modelValue"
        :aria-labelledby="labelId"
        @update:modelValue="!disabled && $emit('update:modelValue', $event)"
      />
      <m-radio
        v-else-if="allowSelect"
        :model-value="modelValue"
        :val="true"
        :aria-labelledby="labelId"
        @update:modelValue="!disabled && $emit('update:modelValue', $event)"
      />
    </slot>

    <div :id="labelId" class="w-full">
      <div v-if="title || $slots.title || badge" class="list-item-title">
        <h4>
          <slot name="title"
            >{{ title }} <m-badge v-if="badge" class="q-ml-lg" v-bind="badge" />
          </slot>
        </h4>
      </div>
      <div class="list-item-container">
        <slot />
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { checkLabel, randomID } from "../../../utils/helpers";
import MBadge from "./../MBadge";
import MCheckbox from "./../inputs/MCheckbox";
import MRadio from "./../inputs/MRadio";

const props = defineProps({
  modelValue: Boolean,

  allowSelect: Boolean,

  title: String,
  badge: Object,

  disabled: Boolean,

  multi: Boolean,
  multiple: Boolean,

  onClick: Function,

  srLabel: String,
});

const labelId = randomID("list-item-card_");

// eslint-disable-next-line no-undef
if (IS_APP && (import.meta.env.DEV || location.host.startsWith("uat."))) {
  checkLabel(
    props,
    "MListItemCard",
    "srLabel",
    computed(() => !!props.onClick),
  );
}
</script>

<style lang="scss">
.list-item-card {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  padding: 13px;

  display: flex;
  flex-direction: row;

  align-items: flex-start;
  gap: 1em;

  min-height: 40px;
  flex: 0 0 auto;

  &.selected {
    border: 2px solid var(--focus-ring, #007acc);
    padding: 11px;
  }
  // &.has-selection {
  //   cursor: pointer;
  // }

  &.disabled-item {
    opacity: 0.7;
    cursor: default;
  }

  .m-radio,
  .m-checkbox {
    width: 20px;
  }

  .list-item-title {
    margin-top: 3px;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;

    color: var(--text-color);

    // margin-bottom: 1em;
  }

  .list-item-container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
}
</style>
