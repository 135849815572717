<template>
  <div class="m-simple-cards gap-1" :class="[borderTop && 'border-top', scroll && 'h-full']">
    <div v-if="title || helper || $slots.helper || action" class="simple-cards-header">
      <div class="flex gap-1 items-center">
        <slot name="title">
          <h3>{{ title }}</h3>
        </slot>

        <q-icon v-if="$slots.helper || helper" name="fa-light fa-question-circle">
          <q-tooltip :offset="[5, 5]">
            <slot name="helper">
              <pre>{{ helper }}</pre>
            </slot>
          </q-tooltip>
        </q-icon>
      </div>

      <m-layout-stack horizontal gap="1">
        <span v-if="preActionText" class="self-center">{{ preActionText }}</span>
        <m-dropdown-button
          v-if="action && action.actions"
          class="flex-0 action-button"
          ghost
          v-bind="action"
        >
          <q-list>
            <m-item
              v-for="(a, ia) in action.actions"
              :key="ia"
              v-close-popup
              clickable
              :to="a.to"
              @click="a.click"
            >
              <q-item-section>
                <q-item-label>{{ a.label }}</q-item-label>
              </q-item-section>
            </m-item>
          </q-list>
        </m-dropdown-button>
        <m-button v-else-if="action" class="flex-0" ghost v-bind="action" />
      </m-layout-stack>
    </div>

    <ul :class="['simple-cards-content', scroll && 'scroll-area']">
      <template v-for="(node, index) in renderChildren" :key="`cards-${index}`">
        <li v-if="node">
          <component :is="node" />
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { QIcon, QItemLabel, QItemSection, QList, QTooltip } from "quasar";
import { Fragment, computed, useSlots } from "vue";
import MButton from "../MButton";
import MDropdownButton from "../MDropdownButton";
import MItem from "../MItem";
import MLayoutStack from "../MLayoutStack/MLayoutStack.vue";

defineProps({
  title: String,

  action: Object,
  helper: String,

  borderTop: Boolean,
  scroll: Boolean,

  preActionText: String,
});

const slots = useSlots();

const renderChildren = computed(() => {
  return slots.default?.().flatMap((node) => {
    if (node.type === Fragment) {
      return node.children;
    }

    return node;
  });
});
</script>

<style lang="scss">
.m-simple-cards {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  // border-top: 1px solid var(--grey-light);
  // padding-top: 12px;

  &.border-top {
    border-top: 1px solid var(--grey-light);
  }

  .simple-cards-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .medicus-button-container {
      align-self: flex-end;
    }
  }

  .simple-cards-content {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0;
    margin: 0;

    list-style: none;
  }

  .action-button {
    padding: 0;
    margin-right: 16px;
  }
}
</style>
