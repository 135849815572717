<template>
  <m-tabs
    class="m-dynamic-tabs"
    :model-value="value?.value"
    bg-grey
    @update:modelValue="onModelValue"
  >
    <template v-if="$slots.head || actions" #head>
      <slot name="head">
        <div>
          <m-action :model-value="actions" ghost /></div
      ></slot>
    </template>

    <m-tab v-for="(tab, i) in items" :key="tab.id || i" :name="tab.value" :label="tab.label">
      <m-dynamic
        v-if="value && value.value"
        :ref="dynamicEl"
        :key="reload ? value.value : undefined"
        :class="[!noScrollContainer && 'scroll-area fit']"
        :no-data="!!value.data"
        v-bind="{ ...$attrs, ...dataProps, ...value.data }"
        :url="value.value"
      />
    </m-tab>
  </m-tabs>
</template>
<script lang="ts">
import { computed } from "@vue/reactivity";
import { ComponentPublicInstance, ref, watch } from "vue";
import { useVModel, useValueSync } from "vue-composable";
import { useRouter } from "vue-router";
import { DrawerUrlOptions } from "../../../store/drawers";

const VMODEL = {};

export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
export interface Item {
  id?: string;
  label?: string;
  value?: string;
  data?: Record<string, any>;

  title?: string;
  // items?: Item[];
}

export interface Action {
  label: string;
  onClick: Function;
  click: Function;
  to?: string;
  toSlideover?: string | DrawerUrlOptions | false;
  toModal?: string | DrawerUrlOptions | false;

  actions?: any;
}

export interface DropdownAction extends Action {
  menu?: boolean;
  split?: boolean;

  actions: Action[];
}

const props = withDefaults(
  defineProps<{
    modelValue: any;
    items: Item[];

    noScrollContainer?: boolean;

    noPrefetch?: boolean;

    absolute?: boolean;

    base?: string;
    dataProps?: object;

    subtitle?: boolean;
    noRefresh?: boolean;

    reload?: boolean;

    inline?: boolean;

    startOpen?: boolean;

    actions: Action | DropdownAction | Array<Action | DropdownAction>;

    queryKey?: string;
  }>(),
  {
    modelValue: () => VMODEL,
  },
);

defineEmits({
  "update:modelValue": (e: string) => true,
});

function getDefaultItem(items: Item[]) {
  const first = items?.[0];
  // if (first?.title) {
  //   return first?.items?.[0];
  // }
  return first;
}

let value =
  props.modelValue !== VMODEL ? useVModel(props, "modelValue") : ref(getDefaultItem(props.items));
if (props.modelValue !== VMODEL && !value.value) {
  value.value = getDefaultItem(props.items);
}
watch(
  () => props.items,
  (items) => {
    if (props.modelValue === VMODEL) {
      value.value = getDefaultItem(items);
    }
  },
);

const dynamicEl = ref<{ comp: ComponentPublicInstance }>();

function onModelValue(e: string) {
  value.value = props.items.find((x) => x.value === e)!;
}

if (props.queryKey) {
  const queryKey = props.queryKey;
  const router = useRouter();
  const routedItem = computed({
    get: () =>
      props.items.find((x) => (x.id || x.label) === router.currentRoute.value.query[queryKey]),
    set: (v) => {
      router.push({
        query: {
          ...router.currentRoute.value.query,
          [queryKey]: v?.id || v?.label,
        },
      });
    },
  });

  useValueSync(routedItem, value);
}
</script>
<style lang="scss">
.m-dynamic-tabs {
  overflow: auto;

  .q-tab {
    &:first-of-type {
      margin-left: 0 !important;
    }
  }
}
</style>
