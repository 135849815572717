import axios from "../../../../utils/axios";
import { ForPatientQueryResult, SearchPredefinedDosagesQueryResult } from "./types";

export const getForPatientBodySurfaceArea = (
  patientId: string,
  fdbPredefinedDosageSuggestion: SearchPredefinedDosagesQueryResult,
  patientHeight?: string | null,
  patientWeight?: string | null,
  doseQuantity?: string | null,
) => {
  if (
    !patientWeight ||
    !patientHeight ||
    (fdbPredefinedDosageSuggestion.isDoseARange && !doseQuantity)
  )
    return;
  return axios
    .get<ForPatientQueryResult>("/clinical/data/gb/fdb/dmd/dose/calculate/for-patient-bsa", {
      cancelRequestId: "fdb-patient-bsa-dose-calculation",
      params: {
        patientId,
        patientWeight,
        patientHeight,
        doseMasterId: fdbPredefinedDosageSuggestion.doseMasterId,
        indicationId: fdbPredefinedDosageSuggestion.indicationId,
        productId: fdbPredefinedDosageSuggestion.productId,
        routePrescribedId: fdbPredefinedDosageSuggestion.routePrescribedId,
        doseQuantity,
      },
    })
    .then((x) => ({ ...x.data, dose: fdbPredefinedDosageSuggestion }));
};

export const getForPatientWeight = (
  patientId: string,
  fdbPredefinedDosageSuggestion: SearchPredefinedDosagesQueryResult,
  patientWeight?: string | null,
  doseQuantity?: string | null,
) => {
  if (!patientWeight || (fdbPredefinedDosageSuggestion.isDoseARange && !doseQuantity)) return;
  return axios
    .get<ForPatientQueryResult>("/clinical/data/gb/fdb/dmd/dose/calculate/for-patient-weight", {
      cancelRequestId: "fdb-patient-weight-dose-calculation",
      params: {
        patientId,
        patientWeight,
        doseMasterId: fdbPredefinedDosageSuggestion.doseMasterId,
        indicationId: fdbPredefinedDosageSuggestion.indicationId,
        productId: fdbPredefinedDosageSuggestion.productId,
        routePrescribedId: fdbPredefinedDosageSuggestion.routePrescribedId,
        doseQuantity,
      },
    })
    .then((x) => ({ ...x.data, dose: fdbPredefinedDosageSuggestion }));
};

export async function getDosageCalculation(
  patientId: string,
  suggestion: SearchPredefinedDosagesQueryResult,
  quantity: string | null,
  weight: string | null,
  height: string | null,
) {
  switch (suggestion.calculationRequired) {
    case "body-surface-area":
      return await getForPatientBodySurfaceArea(patientId, suggestion, height, weight, quantity);
    case "weight":
      return await getForPatientWeight(patientId, suggestion, weight, quantity);
    default:
      return await Promise.resolve(undefined);
  }
}
