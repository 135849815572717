<script setup lang="ts">
import { onMounted, provide, ref } from "vue";
import MInput from ".";

const action = {
  label: "custom",
  click() {},
};

const rules = [() => "Custom 1", () => "Custom 2", () => "Custom 3"];
const actions = [{ label: "Action 1" }, { label: "Action 2" }];
const validationController = [] as { validation: Function }[];
const counterText = ref("test");

onMounted(() => {
  validationController.forEach((x) => x.validation());
});

provide("validationController", validationController);
</script>
<template>
  <div class="flex flex-col gap-3 q-ma-md">
    <m-input label="Input" />
    <m-input label="Input with helper" helper="Helper test" />
    <m-input label="Input with helper + required" helper="Helper test" required />
    <m-input label="Description" description="Input description" />
    <m-input label="Placeholder" placeholder="Placeholder" />
    <m-input label="with suffix">
      <template #append>kg</template>
    </m-input>

    <m-input label="Horizontal" horizontal />
    <m-input label="Horizontal with description" description="horizontal description" horizontal />
    <m-input label="Horizontal width:200px" horizontal label-width="200px" />
    <m-input label="Horizontal with suffix" horizontal>
      <template #append>kg</template>
    </m-input>

    <m-input
      label="Horizontal width:200px + description"
      description="horizontal description"
      horizontal
      label-width="200px"
    />

    <m-input label="Required" required />
    <m-input label="Required Text" required required-text="custom field required" />
    <m-input label="Disable" disable />

    <m-input label="Rules" model-value="test" :rules="rules" />
    <m-input label="Rules show All errors" model-value="test" :rules="rules" show-all-errors />

    <m-input label="With action" :action="action" />

    <m-input label="Textarea" type="textarea" />
    <m-input label="Textarea Description" description="Input description" type="textarea" />
    <m-input label="Textarea Placeholder" placeholder="Placeholder" type="textarea" />
    <m-input label="Textarea with value" type="textarea" model-value="test\ntest1\ntest2\ntest3" />
    <m-input label="Textarea horizontal" type="textarea" horizontal />
    <m-input
      label="Textarea d horizontal"
      description="Input description"
      type="textarea"
      horizontal
    />
    <m-input label="Textarea p horizontal" placeholder="Placeholder" type="textarea" horizontal />
    <m-input
      label="Textarea v horizontal"
      type="textarea"
      model-value="test\ntest1\ntest2\ntest3"
      horizontal
    />

    <m-input label="Autogrow" :autogrow="true" />

    <m-input label="Actions" :actions="actions" />

    <m-input label="Actions left" :actions="actions" actions-left />

    <m-input label="Actions Horizontal" horizontal :actions="actions" />

    <m-input label="Uppercase" uppercase model-value="test" />
    <m-input label="Decimal places 1" model-value="0.00001" decimal-places="1" />
    <m-input label="Decimal places 2" model-value="0.00001" decimal-places="2" />

    <m-input
      label="Value should be ignored"
      model-value="Correct value"
      value="Not the correct value, this should not be showing, please reject change"
    />
    <m-input label="Suffix" model-value="suffix on the right" suffix="Suffix" />
    <m-input label="Prepend" model-value="prepend on the left">
      <template #prepend>Prepend</template>
    </m-input>

    <m-input v-model="counterText" label="Counter" counter />
    <m-input label="Counter empty" :model-value="null" counter />

    <m-input v-model="counterText" label="Counter + maxlength" counter maxlength="10" />

    <m-input label="Counter error" model-value="12345678901" counter maxlength="10" />
    <m-input
      label="Counter custom message"
      model-value="12345678901"
      counter
      maxlength="10"
      maxlength-text="test max length error 10"
    />

    <m-input
      label="Counter override text"
      model-value="12345678901"
      counter
      maxlength="10"
      counter-text="Override counter text"
    />
    <m-input
      label="Counter override text"
      model-value="12345678901"
      counter
      maxlength="10"
      :counter-text="(len, str, max) => `counter ${len} of ${max} -- current string:'${str}'`"
    />

    <m-input label="small" small />
    <m-input label="medium" medium />
    <m-input label="large" large />

    <m-input :border="false" model-value="This input has no border" />
  </div>
</template>
