<template>
  <m-card>
    <m-layout-stack
      :class="{ 'marked-incorrect': inboundDocument.isMarkedIncorrect }"
      data-testid="document-journal-presenter"
    >
      <m-layout-stack flex horizontal>
        <div class="flex-auto">
          {{ inboundDocument.documentTypeLabel }}
          <div v-if="clinicalAndOrganisation" class="card-description">
            <span>
              {{ clinicalAndOrganisation }}
            </span>
          </div>
        </div>
        <i v-if="inboundDocument.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel
        }}</i>
        <m-action
          v-if="!noAction"
          :model-value="{ label: 'View document', click: openDocumentModal }"
        />
      </m-layout-stack>

      <m-layout-stack v-if="showBadges" horizontal class="q-mb-sm">
        <m-badge v-if="inboundDocument.hasPlaceholderFile" text="GP2GP Placeholder File" gray />
        <m-badge
          v-if="inboundDocument.filingStatus === 'awaiting-filing'"
          text="Awaiting Filing"
          amber
          class="status-badge"
          data-testid="awaiting-filing-badge"
        />
        <m-badge
          v-if="inboundDocument.hiddenFromPatientFacingServices"
          text="Redacted from patient online access"
          gray
          data-testid="topic-hidden-from-patient-facing-services-badge"
        />
        <m-badge
          v-if="inboundDocument.confidentialFromThirdParties"
          text="Confidential from Third Parties"
          gray
          data-testid="topic-confidential-from-third-parties-badge"
        />
        <m-badge
          v-for="(linkedProblem, index) in inboundDocument.linkedProblems"
          :key="`document-linked-problem-${index}`"
          :text="linkedProblem.problemCodeDescription"
          gray
          :data-testid="`document-linked-problem-badge-${index}`"
        />
      </m-layout-stack>
    </m-layout-stack>

    <m-separator
      v-if="inboundDocument.linkedProblemLabel || inboundDocument.careRecordElements.length"
      class="q-ma-sm"
    />

    <m-labeled-text v-if="inboundDocument.linkedProblemLabel" label="Problem">
      <strong class="care-record-presenter">{{ inboundDocument.linkedProblemLabel }}</strong>
    </m-labeled-text>

    <m-list>
      <ConsultationCareRecordElements
        :items="inboundDocument.careRecordElements"
        :read-only="false"
        :context-hidden-fps="inboundDocument.hiddenFromPatientFacingServices"
        :context-confidential-ftp="inboundDocument.confidentialFromThirdParties"
      />
    </m-list>
  </m-card>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { PatientJournalRecord } from "../types";
import { openModal } from "../../../../composables/dialog/drawer";
import ConsultationCareRecordElements from "../elements/consultation-topic/ConsultationCareRecordElements.vue";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  noAction?: boolean;
}>();

const inboundDocument = ref(props.entry.data);

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (inboundDocument.value.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const showBadges = computed(() => {
  return (
    inboundDocument.value.filingStatus === "awaiting-filing" ||
    inboundDocument.value.hiddenFromPatientFacingServices ||
    inboundDocument.value.confidentialFromThirdParties ||
    inboundDocument.value.linkedProblems.length ||
    inboundDocument.value.hasPlaceholderFile
  );
});

const clinicalAndOrganisation = computed(() => {
  const textAsArray = [
    inboundDocument.value.title,
    inboundDocument.value.clinicalSpeciality,
    inboundDocument.value.organisationName,
  ];
  const text = textAsArray.filter((x) => x).join(" • ");
  return text === "" ? false : text;
});

function openDocumentModal() {
  openModal(`/clinical/document/modals/preview/${inboundDocument.value.id}`, {
    close: true,
  });
}
</script>
<style>
.card-description {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #68778d;
}
.status-badge {
  align-self: start;
}
</style>
