<script setup lang="ts">
import { useMobileSplashScreen } from "../../store/drawers/mobileSplash";
import MedicusLogo from "../../svg/MedicusLogo.vue";
import MButton from "../medicus/MButton";

const store = useMobileSplashScreen();
</script>
<template>
  <div v-if="store.open" id="splash-mobile">
    <div>
      <m-button
        class="float-right cursor-pointer"
        icon="close-icon"
        ghost
        small
        name="fa-light fa-xmark"
        @click="store.close()"
      />
    </div>

    <medicus-logo class="logo" white />
    <!-- <div class="sub-title">Medicus isn't available on mobile devices for clinicians yet</div> -->

    <svg
      width="291"
      height="335"
      viewBox="0 0 291 335"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M197.654 103.721C200.568 104.138 202.808 106.644 202.808 109.673C202.808 112.86 200.331 115.467 197.197 115.674C197.218 116.714 197.246 117.694 197.281 117.825C197.354 118.093 197.466 118.331 197.617 118.538C197.768 118.745 198.09 119.066 198.582 119.503C199.315 120.151 199.807 120.702 200.058 121.155C200.31 121.608 200.436 122.103 200.436 122.64C200.436 123.569 200.078 124.36 199.362 125.015C198.646 125.669 197.687 125.996 196.484 125.996C195.349 125.996 194.435 125.691 193.741 125.082C193.219 124.619 192.855 124.002 192.648 123.23C192.535 122.805 192.854 122.405 193.291 122.353C193.716 122.303 194.093 122.601 194.224 123.008C194.38 123.495 194.605 123.875 194.899 124.15C195.329 124.553 195.866 124.754 196.51 124.754C197.175 124.754 197.737 124.531 198.196 124.083C198.655 123.641 198.884 123.127 198.884 122.54C198.884 122.215 198.806 121.916 198.649 121.642C198.492 121.368 198.151 120.999 197.625 120.535C197.105 120.076 196.745 119.726 196.543 119.486C196.269 119.156 196.071 118.829 195.948 118.504C195.78 118.074 195.696 117.562 195.696 116.969C195.696 116.928 195.696 116.314 195.697 115.587C192.9 115.071 190.781 112.62 190.781 109.673C190.781 106.644 193.021 104.138 195.936 103.721V80H197.654V103.721Z"
        fill="#848D9C"
      />
      <path
        d="M152.928 161.348C152.73 161.541 152.726 161.858 152.919 162.055C153.112 162.253 153.428 162.257 153.626 162.064L152.928 161.348ZM196.12 124.523L196.434 124.912V124.912L196.12 124.523ZM197.517 124.681L197.907 124.368V124.368L197.517 124.681ZM153.626 162.064C167.776 148.269 191.836 128.641 196.434 124.912L195.805 124.135C191.212 127.86 167.116 147.516 152.928 161.348L153.626 162.064ZM197.127 124.994L239.05 177.22L239.83 176.594L197.907 124.368L197.127 124.994ZM196.434 124.912C196.643 124.743 196.949 124.773 197.127 124.994L197.907 124.368C197.392 123.727 196.456 123.607 195.805 124.135L196.434 124.912Z"
        fill="#848D9C"
      />
      <rect
        x="0.384639"
        y="0.58428"
        width="94.9729"
        height="160.217"
        rx="7.5"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 150.699 158.482)"
        stroke="white"
      />
      <rect
        width="19.5863"
        height="7.45514"
        rx="3.72757"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 156.408 311.556)"
        fill="#46556A"
      />
      <path
        d="M207.689 317.246L126.628 301.444C124.731 301.074 123.525 299.243 123.935 297.354L152.075 167.795C152.719 164.827 155.659 162.892 158.64 163.473L178.212 167.288C179.54 167.547 180.384 168.829 180.097 170.151C179.692 172.013 180.88 173.818 182.751 174.183L211.083 179.706C212.953 180.07 214.797 178.857 215.201 176.995C215.489 175.672 216.798 174.81 218.126 175.069L235.775 178.51C238.757 179.091 240.651 181.969 240.006 184.937L211.867 314.496C211.457 316.385 209.586 317.616 207.689 317.246Z"
        stroke="#F4F5F7"
      />
      <rect
        width="57.7796"
        height="3.72757"
        rx="1"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 172.311 192.596)"
        fill="#46556A"
      />
      <rect
        width="40.1519"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 170.728 199.882)"
        fill="#46556A"
      />
      <rect
        width="54.8417"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 169.938 203.524)"
        fill="#46556A"
      />
      <rect
        width="46.0278"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 169.146 207.167)"
        fill="#46556A"
      />
      <ellipse
        rx="4.89658"
        ry="4.65946"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 158.826 194.714)"
        fill="#6EBA90"
      />
      <rect
        width="57.7796"
        height="3.72757"
        rx="1"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 166.179 220.827)"
        fill="#848D9C"
      />
      <rect
        width="40.1519"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 164.597 228.112)"
        fill="#848D9C"
      />
      <rect
        width="54.8417"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 163.806 231.755)"
        fill="#848D9C"
      />
      <rect
        width="46.0278"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 163.015 235.397)"
        fill="#848D9C"
      />
      <ellipse
        rx="4.89658"
        ry="4.65946"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 152.695 222.945)"
        fill="#4078B6"
      />
      <rect
        width="57.7796"
        height="3.72757"
        rx="1"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 160.048 249.057)"
        fill="#46556A"
      />
      <rect
        width="40.1519"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 158.465 256.343)"
        fill="#46556A"
      />
      <rect
        width="54.8417"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 157.673 259.985)"
        fill="#46556A"
      />
      <rect
        width="46.0278"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 156.883 263.628)"
        fill="#46556A"
      />
      <ellipse
        rx="4.89658"
        ry="4.65946"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 146.563 251.175)"
        fill="#E16E7C"
      />
      <rect
        width="57.7796"
        height="3.72757"
        rx="1"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 153.917 277.288)"
        fill="#C1C6CD"
      />
      <rect
        width="40.1519"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 152.334 284.573)"
        fill="#C1C6CD"
      />
      <rect
        width="54.8417"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 151.542 288.216)"
        fill="#C1C6CD"
      />
      <rect
        width="46.0278"
        height="1.86379"
        rx="0.931893"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 150.751 291.858)"
        fill="#C1C6CD"
      />
      <ellipse
        rx="4.89658"
        ry="4.65946"
        transform="matrix(0.981523 0.191343 -0.212245 0.977217 140.431 279.406)"
        fill="white"
      />
      <rect x="155.558" y="79.6785" width="82.4724" height="5.87748" rx="2" fill="#848D9C" />
      <line x1="87.5997" y1="42.2421" x2="120.893" y2="75.5351" stroke="#C1C6CD" stroke-width="2" />
      <line x1="52.5284" y1="42.2421" x2="85.8214" y2="75.5351" stroke="#C1C6CD" stroke-width="2" />
      <line x1="17.3438" y1="42.2421" x2="50.6368" y2="75.5351" stroke="#C1C6CD" stroke-width="2" />
      <line
        x1="-18.2929"
        y1="42.2421"
        x2="15.0001"
        y2="75.5351"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 253.357 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line x1="120.892" y1="42.2421" x2="154.185" y2="75.5351" stroke="#C1C6CD" stroke-width="2" />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 220.064 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line x1="154.185" y1="42.2421" x2="187.478" y2="75.5351" stroke="#C1C6CD" stroke-width="2" />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 186.771 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line x1="187.479" y1="42.2421" x2="220.772" y2="75.5351" stroke="#C1C6CD" stroke-width="2" />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 153.478 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line x1="220.772" y1="42.2421" x2="253.253" y2="74.7231" stroke="#C1C6CD" stroke-width="2" />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 120.185 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 85.1147 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 49.9302 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="47.0834"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 14.2935 42.9492)"
        stroke="#C1C6CD"
        stroke-width="2"
      />
      <line x1="253.545" y1="42.1372" x2="252.733" y2="75.4302" stroke="#C1C6CD" stroke-width="2" />
      <line x1="221.064" y1="42.5432" x2="220.252" y2="75.8362" stroke="#C1C6CD" stroke-width="2" />
      <line x1="187.771" y1="42.5432" x2="186.959" y2="75.8362" stroke="#C1C6CD" stroke-width="2" />
      <line x1="154.478" y1="42.5432" x2="153.666" y2="75.8362" stroke="#C1C6CD" stroke-width="2" />
      <line x1="121.185" y1="42.5432" x2="120.373" y2="75.8362" stroke="#C1C6CD" stroke-width="2" />
      <line x1="86.1147" y1="42.5432" x2="85.3027" y2="75.8362" stroke="#C1C6CD" stroke-width="2" />
      <line x1="50.9302" y1="42.5432" x2="50.1181" y2="75.8362" stroke="#C1C6CD" stroke-width="2" />
      <line x1="15.2935" y1="42.5432" x2="14.4814" y2="75.8362" stroke="#C1C6CD" stroke-width="2" />
      <path opacity="0.5" d="M253 42L-8 1" stroke="#46556A" />
      <path
        d="M-6 44C-6 42.8954 -5.10457 42 -4 42H252.889C253.388 42 253.868 42.1859 254.236 42.5213L287.18 72.5213C288.531 73.7515 287.661 76 285.833 76H-4C-5.10457 76 -6 75.1046 -6 74V44Z"
        stroke="#C1C6CD"
        stroke-width="2"
      />
    </svg>
    <div class="description">
      <!-- We'd love to hear your feedback on which features should be available from your phone to guide
      our product roadmap. -->
      {{ store.message }}
    </div>

    <!-- <div>
      <button>Get in touch</button>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
#splash-mobile {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;

  min-width: 320px;
  width: 100vw;

  z-index: 1001;

  flex: 1 1 auto;
  height: 100vh;
  // min-height: 667px;
  background: #091c39;

  color: white;

  & > div {
    margin: 16px 24px;
  }

  // svg {
  //   flex: 1 1 auto;
  // }

  .close-icon {
    font-size: 2em !important;
  }

  button {
    width: 100%;
    background: #0099ff;
    border: 1px solid #007acc;
    box-sizing: border-box;
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.06),
      0px 1px 2px rgba(0, 0, 0, 0.02);
    border-radius: 4px;

    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 138% */

    text-align: center;

    /* White */

    color: #ffffff;

    padding: 12px 0;
  }

  .logo {
    height: 16px;
    overflow: hidden;
    flex: none;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* or 167% */

    text-align: center;
    letter-spacing: 0.2px;
  }

  .description {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    /* or 157% */

    text-align: center;
    letter-spacing: 0.2px;
  }
}
</style>
