<template>
  <m-card
    :class="{ 'cursor-pointer': props.readOnly }"
    sr-label="View allergy"
    @click="allergyOverview()"
  >
    <AllergyJournalElement :item="entry.data" :read-only="props.readOnly" />
  </m-card>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { PatientJournalRecord } from "../types";
import { openSlideover } from "../../../../composables/dialog/drawer";
import AllergyJournalElement from "../elements/AllergyJournalElement.vue";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly?: boolean;
  clickable?: boolean;
}>();

function allergyOverview() {
  return props.readOnly
    ? openSlideover("/clinical/allergy/overview-allergy/" + props.entry.id, {
        close: true,
      })
    : undefined;
}
</script>
