<template>
  <m-validation-component :errors="displayErrors">
    <m-labeled-text
      class="m-checkbox-group"
      v-bind="$attrs"
      container="fieldset"
      label-container="legend"
      text-container="div"
      :horizontal="horizontalLabel"
      :label-width="labelWidth"
      :label="label"
      :helper="helper"
      :instructions="instructions"
      :required="$attrs.required"
      :data-testid="$attrs['data-testid'] || name || undefined"
    >
      <div
        class="flex flex-col checkbox-group-content"
        :class="{ horizontal, 'two-columns': props.twoColumns }"
        @keydown.capture="onKeyDown"
      >
        <slot>
          <m-checkbox
            v-for="(item, i) in items"
            :key="i"
            :model-value="isSelected(item)"
            :val="item.value"
            :label="item.label"
            :class="item.class"
            :style="item.style"
            :disable="disable || item.disable"
            :data-testid="
              $attrs['data-testid'] || name
                ? `${$attrs['data-testid'] || name}:${item.value}`
                : undefined
            "
            :tabindex="actualValue ? (isSelected(item) ? '0' : '-1') : i === 0 ? '0' : '-1'"
            @update:modelValue="onCheckboxChange($event, item)"
          />
        </slot>
      </div>
    </m-labeled-text>
  </m-validation-component>
</template>
<script lang="ts" setup>
import { Key } from "ts-key-enum";
import { getCurrentInstance, onMounted } from "vue";
import { useHorizontalLabel, useInputRules } from "../../../composables/horizontalLabel";
import MLabeledText from "./../MLabeledText";
import MCheckbox from "./../inputs/MCheckbox";
import MValidationComponent from "./../inputs/MValidationComponent";

export type Item = {
  label: string;
  value: any;

  class?: any;
  style?: any;
  disable?: boolean;
};

const props = defineProps({
  label: String,
  name: String,
  helper: String,
  instructions: String,

  horizontal: {
    type: Boolean,
    default: undefined,
  },

  twoColumns: {
    type: Boolean,
  },

  labelWidth: String,

  rules: Array,

  horizontalLabel: {
    type: Boolean,
    default: undefined,
  },

  modelValue: {
    type: [Array, null] as any,
  },
  items: Array as () => Array<Item>,

  disable: Boolean,
  showAllErrors: Boolean,

  requiredText: String,
});

const { value: actualValue, displayErrors } = useInputRules(
  props,
  undefined,
  undefined,
  undefined,
  () => props.requiredText || "Please choose at least one option",
);
const horizontalLabel = useHorizontalLabel(props, (x) => x.horizontalLabel);

function onCheckboxChange(value: any, item: Item) {
  const arr = actualValue.value ? [...actualValue.value] : [];
  let index = -1;

  if (typeof item.value === "object") {
    index = arr.findIndex((arrItem) => isSameObject(arrItem, item.value));
  } else {
    index = arr.indexOf(item.value);
  }

  if (index > -1) {
    arr.splice(index, 1);
  }
  if (value) {
    arr.push(item.value);
  }
  actualValue.value = arr;
}

function isSelected(item: Item) {
  let selected = false;

  if (typeof item.value === "object") {
    actualValue.value.some((val: { [x: string]: any }) => {
      if (typeof val === "object") {
        const objectsMatch = isSameObject(val, item.value);
        if (objectsMatch) selected = true;
      }
    });
  }

  if (actualValue.value?.indexOf(item.value) >= 0) {
    selected = true;
  }

  return selected;
}

function isSameObject<T extends Record<string, any>>(obj1: T, obj2: T): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // Check if the values are objects themselves
    if (val1 && typeof val1 === "object" && val2 && typeof val2 === "object") {
      return isSameObject(val1, val2);
    }

    return val1 === val2;
  });
}

function onKeyDown(ev: KeyboardEvent) {
  const element = ev.target as HTMLElement;
  const parent = element.parentElement;

  const first = parent?.firstElementChild as HTMLElement;
  const last = parent?.lastElementChild as HTMLElement;

  switch (ev.key) {
    case Key.ArrowUp:
    case Key.ArrowLeft: {
      ev.preventDefault();
      const sibbling = element.previousElementSibling as HTMLElement;
      if (!sibbling?.parentElement) {
        last.focus();
      } else {
        sibbling.focus();
      }
      break;
    }
    case Key.ArrowDown:
    case Key.ArrowRight: {
      ev.preventDefault();
      const sibbling = element.nextElementSibling as HTMLElement;
      if (!sibbling?.parentElement) {
        first.focus();
      } else {
        sibbling.focus();
      }
      break;
    }
  }
}

const proxy = getCurrentInstance().proxy!;
onMounted(() => {
  const el = proxy.$el as HTMLElement;
  const items = Array.from(el.querySelectorAll(`[role="checkbox"]`));
  items.forEach((x, i) => {
    x.setAttribute("checkbox-index", i.toString());
  });
});
</script>

<style lang="scss">
// .m-radiogroup {
//   min-height: 56px;
// }
// NOTE this is to give higher css priority on this style, because of the horizontal label
.validation-component {
  > .m-checkbox-group {
    border: none;
    margin: 0;
    padding: 0;
    .checkbox-group-content {
      // > *:not(:first-child) {
      //   // margin-top: 0.5em;
      // }

      &.horizontal {
        flex-direction: row;
        justify-content: space-evenly;

        > * {
          flex: 1 1 auto;
          // margin-top: 0.5em;
        }
      }
    }

    &.horizontal {
      align-items: initial;
      > .label {
        margin-top: 2px !important;
      }

      > .text {
        flex: 0 0 auto;
        margin-top: 0 !important;
      }
    }

    &.horizontal > .label {
      align-self: flex-start;
      margin: 0;
    }

    .m-checkbox {
      padding-right: 14px;
    }
  }
}

.two-columns {
  width: 100%;
}
</style>
