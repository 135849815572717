<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View communication"
    @click="openCareRecordCommunicationDrawer"
  >
    <CommunicationJournalElement :item="entry.data" :read-only="readOnly" :clickable="clickable" />
  </m-card>
</template>

<script setup lang="ts">
import { openModal, openSlideover } from "../../../../composables/dialog/drawer";
import CommunicationJournalElement from "../elements/CommunicationJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function openCareRecordCommunicationDrawer() {
  if (props.readOnly) {
    if (props.entry.data.communication.patientQuestionnaireResponse) {
      openModal(`/clinical/communication/care-record-communication-overview/${props.entry.id}`, {
        close: true,
      });
    } else {
      openSlideover(
        `/clinical/communication/care-record-communication-overview/${props.entry.id}`,
        { close: true },
      );
    }
  }
}
</script>
