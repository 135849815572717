<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View observation"
    @click="observationOverview()"
  >
    <ObserverationJournalElement :item="entry.data" :read-only="readOnly" />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import MCard from "../../MCard";
import ObserverationJournalElement from "../elements/ObservationJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function observationOverview() {
  props.readOnly
    ? openSlideover("/clinical/observation/overview/" + props.entry.id, { close: true })
    : undefined;
}
</script>
