<script setup lang="ts">
import { ref } from "vue";
import { useSetupAccount } from "../../store/resetPassword";
import MForm from "../../components/medicus/MForm";
import MInput from "../../components/medicus/inputs/MInput";
import MButton from "../../components/medicus/MButton";
import { useAuth } from "../../store";

import MBanner from "../../components/medicus/MBanner";
const store = useSetupAccount();
const auth = useAuth();
const token = ref("");
const passwordChanged = ref(false);

async function doSubmit() {
  const a = await store.resetPassword.exec(token.value);
  if (!a) return;
  return auth.refreshUserInfo().then(() => auth.doRedirect());
}

if (store.resetKey) {
  store.validateResetKey.exec();
}
</script>

<template>
  <m-banner v-if="!store.resetKey" type="danger" data-cy="noResetKeyError" class="q-mb-lg">
    The change password token is not recognised. Please
    <router-link class="q-mb-lg" :to="{ name: 'forgot-password' }">request a new one</router-link>.
  </m-banner>
  <div v-else-if="passwordChanged === true" title="Password Successfully Changed">
    <p>
      Your password has been successfully changed.
      <br />
      <router-link class="login-link q-mb-lg" :to="{ name: 'login' }"
        >You can now login.
      </router-link>
    </p>
  </div>
  <m-form v-else hide-required-message @submit="doSubmit">
    <p>Enter the 6 digit from your authenticator app to verify the password reset.</p>
    <m-input
      v-model="token"
      label="Token"
      required
      :display-errors="store.resetPassword.error?.response?.data?.errors?.command"
    />

    <template #footer>
      <m-button type="submit" class="flex-auto" label="Verify" />
    </template>
  </m-form>
</template>
