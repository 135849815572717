import { AxiosError } from "axios";
import axios from "../../../../utils/axios";

export async function getSafetyChecks(patientId: string, productCode: string) {
  const [sensitivity, interaction, highRisk, patientCondition, pharmacological, safetyAlerts] =
    await Promise.allSettled([
      getSensitivityCheck(patientId, productCode),
      getInteractionCheck(patientId, productCode),
      getHighRiskCheck(patientId, productCode),
      getPatientConditionCheck(patientId, productCode),
      getPharmacologicalEquivalentsCheck(patientId, productCode),
      getMedicusSafetyAlerts(patientId, productCode),
    ]);

  return {
    sensitivity: processResponse(sensitivity),
    interaction: processResponse(interaction),
    highRisk: processResponse(highRisk),
    patientCondition: processResponse(patientCondition),
    pharmacological: processResponse(pharmacological),
    safetyAlerts: processResponse(safetyAlerts),
  };
}

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;
  describe("prescription getSafetyChecks", () => {
    const mock = getAxiosMock();

    test("make required requests", async () => {
      const patientId = "patientId";
      const productCode = "productCode";

      [
        "/clinical/data/gb/fdb/sensitivity-check",
        "/clinical/data/gb/fdb/interaction-check",
        "/clinical/data/gb/fdb/high-risk-check",
        "/clinical/data/gb/fdb/patient-condition-check",
        "/clinical/data/gb/fdb/pharmacological-equivalents-check",
      ].forEach((endpoint) =>
        mock.onGet(endpoint, { params: { patientId, productCode } }).reply(200, { test: 1 }),
      );

      ["/clinical/data/prescription/safety-alerts"].forEach((endpoint) =>
        mock
          .onGet(endpoint, { params: { patientId, productCode } })
          .reply(200, { safetyAlerts: [] }),
      );

      // mock
      //   .onGet(undefined, {
      //     params: {
      //       patientId,
      //       productCode,
      //     },
      //   })
      //   .reply(200, { test: 1 });

      await expect(getSafetyChecks("patientId", "productCode")).resolves.toMatchObject({
        highRisk: {
          test: 1,
        },
        interaction: {
          test: 1,
        },
        patientCondition: {
          test: 1,
        },
        pharmacological: {
          test: 1,
        },
        sensitivity: {
          test: 1,
        },
        safetyAlerts: { safetyAlerts: [] },
      });

      expect(mock.history.get).toMatchObject([
        {
          cancelRequestId: "gb--fdb--sensitivity-check",
          url: "/clinical/data/gb/fdb/sensitivity-check",
        },
        {
          cancelRequestId: "gb--fdb--interaction-check",
          url: "/clinical/data/gb/fdb/interaction-check",
        },
        {
          cancelRequestId: "gb--fdb--high-risk-check",
          url: "/clinical/data/gb/fdb/high-risk-check",
        },
        {
          cancelRequestId: "gb--fdb--patient-condition-check",
          url: "/clinical/data/gb/fdb/patient-condition-check",
        },
        {
          cancelRequestId: "gb--fdb--pharmacological-equivalents-check",
          url: "/clinical/data/gb/fdb/pharmacological-equivalents-check",
        },
        {
          cancelRequestId: "medicus-safety-alerts",
          url: "/clinical/data/prescription/safety-alerts",
        },
      ]);
    });

    test("should resolve if one fails", async () => {
      const patientId = "patientId";
      const productCode = "productCode";

      mock.onGet("/clinical/data/gb/fdb/sensitivity-check").reply(400, {
        errors: {
          test: "error",
        },
      });

      [
        "/clinical/data/gb/fdb/interaction-check",
        "/clinical/data/gb/fdb/high-risk-check",
        "/clinical/data/gb/fdb/patient-condition-check",
        "/clinical/data/gb/fdb/pharmacological-equivalents-check",
      ].forEach((endpoint) =>
        mock.onGet(endpoint, { params: { patientId, productCode } }).reply(200, { test: 1 }),
      );

      ["/clinical/data/prescription/safety-alerts"].forEach((endpoint) =>
        mock
          .onGet(endpoint, { params: { patientId, productCode } })
          .reply(200, { safetyAlerts: [] }),
      );

      await expect(getSafetyChecks("patientId", "productCode")).resolves.toMatchObject({
        highRisk: {
          test: 1,
        },
        interaction: {
          test: 1,
        },
        patientCondition: {
          test: 1,
        },
        pharmacological: {
          test: 1,
        },
        sensitivity: {
          errors: {
            test: "error",
          },
        },
        safetyAlerts: { safetyAlerts: [] },
      });
    });

    test("should show errors even if if all fails", async () => {
      mock.onGet(undefined).reply(400, {
        errors: {
          test: "error",
        },
      });

      await expect(getSafetyChecks("patientId", "productCode")).resolves.toMatchObject({
        highRisk: {
          errors: {
            test: "error",
          },
        },
        interaction: {
          errors: {
            test: "error",
          },
        },
        patientCondition: {
          errors: {
            test: "error",
          },
        },
        pharmacological: {
          errors: {
            test: "error",
          },
        },
        sensitivity: {
          errors: {
            test: "error",
          },
        },
        safetyAlerts: {
          errors: {
            test: "error",
          },
        },
      });
    });
  });
}

function processResponse<T>(p: PromiseSettledResult<T>) {
  if (p.status === "rejected") {
    const error: AxiosError = p.reason;
    const errors: Record<string, string> | undefined = (error.response?.data as any)?.errors;
    return {
      errors: errors ?? "An unknown error occurred. Please try again.",
    };
  }
  return p.value;
}

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;
  describe("prescription processResponse", () => {
    test("should return the response", async () => {
      const value = {
        test: 1,
      };
      const [r] = await Promise.allSettled([Promise.resolve(value)]);

      expect(r).toMatchObject({
        status: "fulfilled",
        value,
      });

      expect(processResponse(r)).toBe(value);
    });

    test("on reject should show response errors", async () => {
      const errors = {
        response: {
          data: {
            errors: {
              test: ["eee"],
            },
          },
        },
      };
      const [r] = await Promise.allSettled([Promise.reject(errors)]);
      r.status === "rejected";
      expect(r).toMatchObject({
        status: "rejected",
        reason: errors,
      });
      expect(processResponse(r)).toMatchObject(errors.response.data);
    });

    test("reject should be generic message if no errors have been passed", async () => {
      const errors = {
        test: ["eee"],
      };
      const [r] = await Promise.allSettled([Promise.reject(errors)]);
      expect(r).toMatchObject({
        status: "rejected",
        reason: errors,
      });
      expect(processResponse(r)).toMatchObject({
        errors: "An unknown error occurred. Please try again.",
      });
    });
  });
}

export const getSensitivityCheck = (patientId: string, productCode: string) =>
  axios
    .get<{
      codeDegrades: null | { message: string; codes: null | Array<string> };
      alerts: null | Array<string>;
    }>("/clinical/data/gb/fdb/sensitivity-check", {
      cancelRequestId: "gb--fdb--sensitivity-check",
      params: {
        productCode,
        patientId,
      },
    })
    .then((x) => x.data);

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("prescription getSensitivityCheck", () => {
    const mock = getAxiosMock();
    test("request", async () => {
      const patientId = "patientId";
      const productCode = "productCode";

      mock
        .onGet("/clinical/data/gb/fdb/sensitivity-check", {
          params: {
            patientId,
            productCode,
          },
        })
        .replyOnce(200, { test: 1 });

      const res = await getSensitivityCheck(patientId, productCode);

      expect(res).toMatchObject({
        test: 1,
      });
    });
  });
}

export const getInteractionCheck = (patientId: string, productCode: string) =>
  axios
    .get<{
      codeDegrades: null | { message: string; codes: null | Array<string> };
      lowRiskInteractionAlerts: null | Array<string>;
      moderateRiskInteractionAlerts: null | Array<string>;
      significantRiskInteractionAlerts: null | Array<string>;
      highRiskInteractionAlerts: null | Array<string>;
    }>("/clinical/data/gb/fdb/interaction-check", {
      cancelRequestId: "gb--fdb--interaction-check",
      params: {
        productCode,
        patientId,
      },
    })
    .then((x) => x.data);

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("prescription getInteractionCheck", () => {
    const mock = getAxiosMock();
    test("request", async () => {
      const patientId = "patientId";
      const productCode = "productCode";

      mock
        .onGet("/clinical/data/gb/fdb/interaction-check", {
          params: {
            patientId,
            productCode,
          },
        })
        .replyOnce(200, { test: 1 });

      const res = await getInteractionCheck(patientId, productCode);

      expect(res).toMatchObject({
        test: 1,
      });
    });
  });
}

export const getHighRiskCheck = (patientId: string, productCode: string) =>
  axios
    .get<{
      codeDegrades: null | { message: string; codes: null | Array<string> };
      alerts: null | Array<string>;
    }>("/clinical/data/gb/fdb/high-risk-check", {
      cancelRequestId: "gb--fdb--high-risk-check",
      params: {
        productCode,
        patientId,
      },
    })
    .then((x) => x.data);

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("prescription getHighRiskCheck", () => {
    const mock = getAxiosMock();
    test("request", async () => {
      const patientId = "patientId";
      const productCode = "productCode";

      mock
        .onGet("/clinical/data/gb/fdb/high-risk-check", {
          params: {
            patientId,
            productCode,
          },
        })
        .replyOnce(200, { test: 1 });

      const res = await getHighRiskCheck(patientId, productCode);

      expect(res).toMatchObject({
        test: 1,
      });
    });
  });
}

export const getPatientConditionCheck = (patientId: string, productCode: string) =>
  axios
    .get<{
      codeDegrades: null | { message: string; codes: null | Array<string> };
      contraindications: {
        highAlerts: Array<string>;
        lowAlerts: Array<string>;
      };
      drugWarnings: Array<{ heading: string; alerts: Array<string> }>;
      precautions: Array<string>;
    }>("/clinical/data/gb/fdb/patient-condition-check", {
      cancelRequestId: "gb--fdb--patient-condition-check",
      params: {
        productCode,
        patientId,
      },
    })
    .then((x) => x.data);

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("prescription getPatientConditionCheck", () => {
    const mock = getAxiosMock();
    test("request", async () => {
      const patientId = "patientId";
      const productCode = "productCode";
      mock
        .onGet("/clinical/data/gb/fdb/patient-condition-check", {
          params: {
            patientId,
            productCode,
          },
        })
        .replyOnce(200, { test: 1 });

      const res = await getPatientConditionCheck(patientId, productCode);

      expect(res).toMatchObject({
        test: 1,
      });
    });
  });
}

export const getPharmacologicalEquivalentsCheck = (patientId: string, productCode: string) =>
  axios
    .get<{
      codeDegrades: null | { message: string; codes: null | Array<string> };
      drugEquivalentAlerts: Array<string>;
      ingredientEquivalenceAlerts: Array<string>;
      therapyEquivalenceAlerts: Array<string>;
    }>("/clinical/data/gb/fdb/pharmacological-equivalents-check", {
      cancelRequestId: "gb--fdb--pharmacological-equivalents-check",
      params: {
        productCode,
        patientId,
      },
    })
    .then((x) => x.data);

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("prescription getPharmacologicalEquivalentsCheck", () => {
    const mock = getAxiosMock();
    test("request", async () => {
      const patientId = "patientId";
      const productCode = "productCode";
      mock
        .onGet("/clinical/data/gb/fdb/pharmacological-equivalents-check", {
          params: {
            patientId,
            productCode,
          },
        })
        .replyOnce(200, { test: 1 });

      const res = await getPharmacologicalEquivalentsCheck(patientId, productCode);

      expect(res).toMatchObject({
        test: 1,
      });
    });
  });
}

export const getMedicusSafetyAlerts = (patientId: string, productCode: string) =>
  axios
    .get<{
      safetyAlerts: Array<{
        label: string;
        value: {
          description: string | null;
          conceptId: string | null;
          descriptionId: string | null;
        };
        type: string;
      }> | null;
    }>("/clinical/data/prescription/safety-alerts", {
      cancelRequestId: "medicus-safety-alerts",
      params: {
        patientId,
        productCode,
      },
    })
    .then((x) => x.data);

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("prescription getMedicusSafetyAlerts", () => {
    const mock = getAxiosMock();
    test("request", async () => {
      const patientId = "patientId";
      const productCode = "productCode";

      mock
        .onGet("/clinical/data/prescription/safety-alerts", {
          params: {
            patientId,
            productCode,
          },
        })
        .replyOnce(200, { safetyAlerts: [] });

      const res = await getMedicusSafetyAlerts(patientId, productCode);

      expect(res).toMatchObject({
        safetyAlerts: [],
      });
    });
  });
}
