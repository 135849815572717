<script setup lang="ts">
import { useDrawerStore } from "../../../store/drawers";
import Render from "./render";
import { watch } from "vue";
import { useRoute } from "vue-router";

const store = useDrawerStore();

function onClose(index: number) {
  if (store.queue.length !== index + 1) {
    console.warn("You can only close the last drawer");
    return;
  }
  store.closeLast();
}

const route = useRoute();

watch(
  () => route.params.componentPath,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      store.clear();
    }
  },
);
</script>
<template>
  <teleport to="#modal">
    <div v-if="store.queue.length > 0" class="drawer-container">
      <template v-for="(item, index) in store.queue" :key="`${index}:${item.componentUrl}`">
        <div v-if="index === store.queue.length - 1" class="drawer-backdrop" />

        <render
          v-bind="item"
          :options="item"
          :last="index === store.queue.length - 1"
          @close="
            () => {
              onClose(index);
            }
          "
          @cancel="
            () => {
              onClose(index);
            }
          "
          @open="
            () => {
              item.onOpen?.();
            }
          "
        />
      </template>
    </div>
  </teleport>
</template>
<style lang="scss">
.drawer-container {
  position: fixed;
  display: block;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: max(100%, 100vh);
  width: max(100%, 100vw);

  .drawer-backdrop {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    background: rgba(0, 0, 0, 0.5);

    overflow: hidden;
  }

  > * {
    z-index: 9999;
  }
}
</style>
