<script setup lang="ts">
import { ref, computed, inject, onMounted } from "vue";
import { PickAndFlatten, PropsOf } from "../../../utils/types";
import MItem from "../MItem/MItem.vue";
import MActionList from ".";

export type ActionListItems = PickAndFlatten<PropsOf<typeof MActionList>, "items">;

const props = defineProps<{
  items: (PropsOf<typeof MItem> & { label: string })[];
  disabled?: boolean;
  onClose?: Function;
}>();

const closeMenu = inject<Function>("closeMenu");
const isActionInProgress = ref(false);
const disabled = computed(() => props.disabled || isActionInProgress.value);

onMounted(() => {
  // Focus first action on load
  const firstItem = document.querySelector(".m-action-list-item");
  if (firstItem && "focus" in firstItem && typeof firstItem.focus === "function") {
    firstItem.focus();
  }
});
</script>
<template>
  <q-list
    @click="
      (e: MouseEvent) => {
        e?.stopPropagation();

        if (closeMenu && typeof closeMenu === 'function') {
          closeMenu();
        }
      }
    "
  >
    <m-item
      v-for="(option, index) in props.items"
      :key="`option-item-${index}`"
      v-bind="option"
      v-close-popup
      clickable
      role="menuitem"
      :disabled="disabled"
      class="m-action-list-item"
    >
      <q-item-section>
        <q-item-label>{{ option.label }}</q-item-label>
      </q-item-section>
    </m-item>
  </q-list>
</template>
