<script lang="ts" setup>
import { QBtn } from "quasar";
import { ref } from "vue";
import MButton from "../MButton";
import MLayoutStack from "./../MLayoutStack";
import MMenu from "../MMenu";

defineProps({
  label: {
    type: String,
    default: "Filters",
  },
  border: Boolean,
  outline: Boolean,
  ghost: Boolean,
  icon: String,
  color: {
    type: String,
    default: "secondary",
  },
  loading: Boolean,
  filterCount: [Number, Boolean],
  hasFilter: Boolean,
  borderless: Boolean,

  dense: Boolean,
});

const buttonRef = ref<InstanceType<typeof MButton>>();
const menuRef = ref<InstanceType<typeof MMenu>>();
const menuOpen = ref<boolean>(false);

function closeMenu() {
  menuRef.value?.hide();
}

function toggleMenu() {
  menuOpen.value = !menuOpen.value;
}
</script>
<template>
  <div>
    <m-button
      ref="buttonRef"
      :class="['m-filter-button', dense && 'dense']"
      :border="border"
      :outline="outline"
      :ghost="ghost"
      :icon="icon"
      :color="color"
      :loading="loading"
      :borderless="borderless"
      @click="toggleMenu"
    >
      <m-layout-stack class="filter-label items-center" horizontal gap="1">
        <!-- <q-icon class="filter-button--icon" name="fa-solid fa-bars-filter" /> -->
        <i v-if="filterCount || hasFilter" class="filter-button--icon fa-solid fa-filter" />
        <i v-else class="filter-button--icon fa-solid fa-bars-filter"></i>

        <span v-if="label">{{ label }} </span>
        <div v-if="filterCount || hasFilter" class="filter-count">
          <!-- <span>{{ filterCount }}</span> -->
        </div>
      </m-layout-stack>
    </m-button>
    <m-menu
      ref="menuRef"
      v-model="menuOpen"
      :target="buttonRef"
      class="filter-button-content"
      no-auto-open
    >
      <q-btn
        flat
        dense
        class="cursor-pointer medicus-outline filter-close-button"
        icon="fa-light fa-xmark"
        @click="closeMenu"
      />
      <div>
        <slot />
      </div>
    </m-menu>
  </div>
</template>

<style lang="scss">
.m-filter-button {
  // height: auto !important;
  position: relative;

  &.dense {
    height: auto !important;
    > .medicus-button {
      padding: 0 !important;
    }
  }

  .filter-label {
    color: var(--grey-darkest);
  }

  .filter-count {
    width: 5px;
    height: 5px;

    font-size: 12px;
    line-height: 16px;

    display: flex;

    align-items: center;
    justify-content: center;
    align-self: flex-start;

    margin-top: 3px;

    border-radius: 99px;
    background: var(--status-green);

    span {
      color: white;
    }
  }
}

.filter-button-content {
  min-width: 500px;
  max-width: 700px;

  padding: 1rem 1.5rem;

  // position: relative;

  .filter-close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .task-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    flex-direction: column;
  }
}
</style>
