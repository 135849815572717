<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View over-the-counter medication"
    @click="medicationStatementOverview()"
  >
    <MedicationStatementIOverTheCounterJournalElement
      :item="entry.data"
      :read-only="readOnly"
      :clickable="clickable"
    />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import MedicationStatementIOverTheCounterJournalElement from "../elements/MedicationStatementIOverTheCounterJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function medicationStatementOverview() {
  return props.readOnly
    ? openSlideover(
        `/clinical/medication-statement/overview-${props.entry.data.entryType}/${props.entry.id}`,
        { close: true },
      )
    : undefined;
}
</script>
