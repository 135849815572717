<script lang="ts" setup>
import { useAuth } from "../store/auth";
const store = useAuth();

store.refreshUserInfo();
</script>

<template>
  <m-layout-stack>
    <p class="text-center">
      There are no Medicus workspaces linked to the username
      <br />
      <strong>{{ store.user?.username }}</strong
      >.
      <m-button class="float-right w-full mt-1" data-testid="login-button" @click="store.logout()">
        Logout
      </m-button>
    </p>
  </m-layout-stack>
</template>

<style>
.mt-1 {
  margin-top: 1rem;
}
</style>
