<template>
  <InvestigationJournalElement :investigation-report="props.entry.data" :read-only="false" />
</template>
<script setup lang="ts">
import InvestigationJournalElement from "../elements/InvestigationJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();
</script>
<style lang="scss">
.grey-darkest {
  color: var(--grey-darkest);
}
</style>
