<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View note"
    @click="
      props.readOnly
        ? openSlideover(`/clinical/note/overview/${props.entry.id}`, {
            close: true,
          })
        : undefined
    "
  >
    <div
      :class="[
        {
          clickable: !readOnly,
          'marked-incorrect': entry.data.isMarkedIncorrect,
        },
        'note-presenter',
      ]"
    >
      <m-layout-stack horizontal>
        <div class="full-width">
          <span v-if="entry.data.clinicalCodeDescription">
            {{ entry.data.clinicalCodeDescription }}
          </span>
          <span v-else>Note</span>
        </div>
        <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel
        }}</i>
      </m-layout-stack>

      <span v-if="getRecordedBy" class="secondary-text"> Recorded by {{ getRecordedBy }} </span>

      <m-layout-stack
        v-if="
          entry.data.hiddenFromPatientFacingServices ||
          entry.data.confidentialFromThirdParties ||
          entry.data.patientBannerFlags?.length ||
          entry.data.linkedProblems?.length ||
          entry.data.isExplicitlyIncludedInSCR
        "
        horizontal
        wrap
        class="q-mt-sm"
      >
        <m-badge
          v-for="(patientBannerFlag, index) in entry.data.patientBannerFlags"
          :key="`patient-banner-flag-${index}`"
          amber
          :text="patientBannerFlag"
          :data-testid="`risks-badge-${index}`"
        />
        <m-badge
          v-if="entry.data.isExplicitlyIncludedInSCR"
          text="Explicitly included in SCR"
          gray
          data-testid="care-record-element-explicitly-included-badge"
        />
        <m-badge
          v-if="entry.data.hiddenFromPatientFacingServices"
          text="Redacted from patient online access"
          gray
          data-testid="topic-hidden-from-patient-facing-services-badge"
        />
        <m-badge
          v-if="entry.data.confidentialFromThirdParties && entry.data.confidentialFromThirdParties"
          text="Confidential from Third Parties"
          gray
          data-testid="topic-confidential-from-third-parties-badge"
        />
        <m-badge
          v-for="(linkedProblem, index) in entry.data.linkedProblems"
          :key="`note-linked-problem-${index}`"
          :text="linkedProblem.problemCodeDescription"
          gray
          :data-testid="`note-linked-problem-badge-${index}`"
        />
      </m-layout-stack>
      <m-separator v-if="entry.data.note" class="q-ma-sm" />
      <div v-if="entry.data.note">
        <p class="pre-wrap">{{ entry.data.note }}</p>
      </div>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import { PatientJournalRecord } from "../types";
import { computed } from "vue";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly?: boolean;
  clickable?: boolean;
}>();

const getRecordedBy = computed(() => {
  const textAsArray = [props.entry.data.recordedBy, props.entry.data.recordedByOrganisation];
  const text = textAsArray.filter((x) => x).join(" • ");
  return text === "" ? false : text;
});

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.entry.data.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
