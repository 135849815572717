<script lang="ts" setup>
import { ref, watchEffect } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import MedicusLogo from "../../svg/MedicusLogo.vue";
import MedicusBottomEffect from "../../svg/MedicusBottomEffect.vue";
import AuthContainer from "../../components/ui/AuthContainer.vue";
import DialogContainer from "../../components/DialogContainer.vue";

const props = withDefaults(
  defineProps<{
    title?: string;
    subTitle?: string;
  }>(),
  {
    title: "",
    subTitle: "",
  },
);

const actualTitle = ref("");

watchEffect(() => {
  actualTitle.value = props.title;
});

function handleTitle(e: string) {
  actualTitle.value = e;
}

onBeforeRouteUpdate((_, _1, next) => {
  actualTitle.value = "";
  next();
});
</script>
<template>
  <div class="simple-wrapper">
    <medicus-logo class="medicus-logo" />

    <auth-container
      :title="actualTitle || title || $route?.meta.title"
      :sub-title="subTitle"
      role="main"
    >
      <template v-if="$slots['sub-title']" #sub-title>
        <slot name="sub-title" />
      </template>
      <slot>
        <router-view @title="handleTitle" />
      </slot>
    </auth-container>

    <dialog-container />

    <medicus-bottom-effect class="medicus-bottom-effect" />
  </div>
</template>
<style lang="scss">
.simple-wrapper {
  display: flex;
  min-height: 100%;
  background-color: var(--theme-darkest-blue);

  @media (min-width: 600px) {
    padding: 40px 0;

    .bottom-effect-change {
      fill: #ffffff;
    }
  }
}

.medicus-logo {
  position: fixed;
  height: 19px;
  top: 30px;
  left: 30px;

  .medicus-logo-text {
    fill: #051e3c;
  }

  @media (min-width: 600px) {
    height: 24px;

    .medicus-logo-text {
      fill: #ffffff;
    }
  }
}

.medicus-bottom-effect {
  position: fixed;

  width: 62px;
  right: 20px;
  bottom: 20px;

  @media (min-width: 600px) {
    width: 166px;
    right: 30px;
    bottom: 30px;
  }
}
</style>
