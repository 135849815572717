<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View immunisation details"
    @click="immunisationOverview"
  >
    <ImmunisationJournalElement :item="entry.data" :read-only="readOnly" :clickable="clickable" />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import ImmunisationJournalElement from "../elements/ImmunisationJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function immunisationOverview() {
  return props.readOnly
    ? openSlideover(`/clinical/immunisation/immunisation-overview/${props.entry.id}`, {
        close: true,
      })
    : undefined;
}
</script>
