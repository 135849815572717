<script setup lang="ts">
import { Ref, computed, inject, nextTick, onMounted, ref, watch } from "vue";
import axios from "../../../utils/axios";
import { generateUUIDv7 } from "../../../utils/helpers";
import { EntryType } from "../consultation/MConsultationTopicHeading/types";
import MInlineTextEntryInput from "../MInlineTextEntryInput";
import MInlineEntryListItem from "../MInlineEntryListItem";
import {
  Action,
  SaveNotePartial,
  Note,
  Entry,
  FetchEntriesResponse,
  PostNotesPayload,
  PostNotesResponse,
  ENTRY_CREATED_EVENT_NAME,
  EntryCreatedEventData,
  ENTRY_DELETED_EVENT_NAME,
  EntryDeletedEventData,
  REFRESH_ALL_ENTRY_LISTS_EVENT_NAME,
} from "./types";
import { useDebouncedThrottledFunction } from "../../../composables/throttleDebounce";
import { openModal, openSlideover } from "../../../composables/dialog/drawer";
import { CursorValues, SnomedClinicalCode } from "../MInlineTextEntryInput/types";
import { DrawerOptions, useDrawerStore } from "../../../store/drawers";
import { listen } from "../../../utils/bus";
import { toRef } from "vue";
import { conceptsToOpenModal, createAllergy, createObservation } from "./utils";
import MAction from "../MAction";
import { AxiosResponse, isAxiosError } from "axios";
import { OpenDockedModalFunction } from "../MSplitPage/types";
import { NO_OP } from "vue-composable";
import { showDialog } from "../../../utils/dialog";
import { useRouter } from "vue-router";
//// PROPS
const props = defineProps<{
  /**
   * The API URL to fetch entries.
   *
   * @example '/clinical/encounter/consultation-topic/topic-heading-entries/018f7728-ecf3-707f-be9a-996e1778d477'
   * @see /MInlineEntryList/types.ts#FetchEntriesResponse
   */
  getUrl: string;

  /**
   * The API URL to post care record notes changes to.
   *
   * @example '/clinical/encounter/consultation-topic/heading/018f7728-ecf3-707f-be9a-996e1778d477/change-notes'
   * @see /MInlineEntryList/types.ts#PostNotesPayload
   * @see /MInlineEntryList/types.ts#PostNotesResponse
   */
  postUrl: string;

  /**
   * Text to display in the heading element above the list of entries.
   */
  label: string;

  /**
   * List of actions to display in the quick actions menu.
   */
  actions?: Action[];

  /**
   * Text to display when there are no entries.
   */
  emptyText?: string;

  /**
   * Text to display between the label heading and entries, to help guide the user on how to interact with the list.
   */
  instructionsText?: string;

  /**
   * Initial data to show. Setting this will prevent the list from fetching entries on load.
   */
  initialData?: { entries: Entry[]; sortOrderHash: string };

  /**
   * The size of the heading element to display.
   */
  labelSize?: "h4" | "h3";

  /**
   * Whether to display entries as marked incorrect.
   */
  markedIncorrect?: boolean;

  /**
   * Whether to make list read only. Prevents inline-editing of note entries.
   */
  readOnly?: boolean;

  /**
   * The context id.
   */
  contextId: string;

  /**
   * The context type that the care record elements will go into.
   */
  contextType: string;

  /**
   * The id of the patient.
   */
  patientId: string;

  /**
   * Shows the available / actions as a button
   */
  showActions?: boolean;

  /**
   * Whether, on load, the list should focus an entry (first entry if read-only, otherwise new entry input)
   */
  autoFocusOnLoad?: boolean;

  disableInput?: boolean;
}>();

//// EVENTS
const emit = defineEmits({
  entriesUpdated: () => true,
  focusNextInput: (_el?: HTMLElement) => true,
});

//// STATE PROPERTIES
const entries = ref<Entry[]>([]);
const sortOrderHash = ref("");
const saveStatus = ref("");
const showSaveStatus = ref(false);
const hasPendingSave = ref(false);
const isSaving = ref(false);
const hideSaveStatusTimerId = ref<number | null>(null);
const isInert = ref(false);

const router = useRouter();
const isOnline = inject<Readonly<Ref<boolean>>>("isOnline", toRef(true));
const openDockedModal = inject<OpenDockedModalFunction>("openDockedModal", NO_OP);

//// TEMPLATE REFS
const refsListItems = ref<InstanceType<typeof MInlineEntryListItem>[]>([]);
const refsInputs = ref<InstanceType<typeof MInlineTextEntryInput>[]>([]);

//// REACTIVE PROPERTIES
const headingLabelID = ref(
  `heading-${props.label.toLowerCase().replace(/\s+/g, "-")}-${generateUUIDv7()}`,
);

const emptyText = computed(() => {
  return props.emptyText ?? "No entries.";
});

const isDisabled = computed(() => {
  return isOnline.value === false || props.markedIncorrect === true;
});

const sortOrder = computed(() => {
  return entries.value
    .filter((entry) => {
      const isNote = entry.type === EntryType.CARE_RECORD_NOTE && !entry.code;
      const shouldRemoveEmptyNote =
        isNote && (!entry.text || entry.text.length === 0) && !entry.__preserveEmptyNoteInSortOrder;

      if (shouldRemoveEmptyNote) {
        return false; // filter out empty text entries
      }

      return true;
    })
    .map((entry) => {
      return {
        id: entry.id,
        entryType: entry.type,
      };
    });
});

const getFormattedActions = computed(() => {
  let formattedActions = [];

  if (props.actions) {
    for (const action of props.actions) {
      let a = action;
      a.onClick = () => {
        // create a little empty entry and push to the end of the list
        // as the function will then replace it with the following
        const lastEntry: Entry = {
          id: generateUUIDv7(),
          hasUnsavedChanges: false,
          text: "",
          label: "",
          srLabel: "",
          type: "note",
          __idForList: generateUUIDv7(),
        };

        entries.value.push(lastEntry);
        handleActionSelected(a, lastEntry.id);
      };
      formattedActions.push(a);
    }
  }

  return {
    label: "Actions",
    actions: formattedActions,
    ghost: true,
  };
});

//// PUBLIC PROPERTIES / FUNCTIONS
defineExpose({ addNewEntry, handleActionSelected, entries, headingLabelID });

//// WATCHERS & LISTENERS
watch(entries, () => {
  if (
    !props.readOnly &&
    (entries.value.length === 0 ||
      entries.value[entries.value.length - 1].type !== "note" ||
      (entries.value[entries.value.length - 1].type === "note" &&
        entries.value[entries.value.length - 1].code))
  ) {
    addNewEntry(entries.value.length, undefined, false);
  }
});

watch(
  () => entries.value?.length,
  () => {
    emit("entriesUpdated");
  },
);

watch(isSaving, (newIsSaving) => {
  // Once save is complete, save immediately if there is a pending save
  if (newIsSaving === false && hasPendingSave.value === true) {
    saveEntries();
  }
});

listen(REFRESH_ALL_ENTRY_LISTS_EVENT_NAME, () => {
  fetchEntries();
});

listen<EntryCreatedEventData>(ENTRY_CREATED_EVENT_NAME, async (data) => {
  if (data.context?.contextId === props.contextId) {
    fetchAndFocus();
  }
});

listen<EntryDeletedEventData>(ENTRY_DELETED_EVENT_NAME, async (data) => {
  if (data.context?.contextId === props.contextId) {
    fetchAndFocus();
  }
});

//// LIFECYCLE HOOKS
onMounted(() => {
  // Check if initial data provided, otherwise fetch data
  if (props.initialData) {
    if (!(props.initialData.entries && props.initialData.sortOrderHash)) {
      throw new Error(
        "MInlineEntryList error: initialData requires both entries and sortOrderHash to be defined.",
      );
    }

    entries.value = props.initialData.entries;
    sortOrderHash.value = props.initialData.sortOrderHash;
  } else {
    fetchEntries().then(() => {
      if (props.autoFocusOnLoad === true) {
        if (props.readOnly) {
          if (entries.value.length > 0) {
            refsListItems.value[0]?.focus();
          }
        } else {
          focusLastInput();
        }
      }
    });
  }
});

//// GENERAL FUNCTIONS
async function fetchEntries() {
  isInert.value = true;

  const { data } = await axios.get<FetchEntriesResponse>(props.getUrl);

  if (!data.sortOrderHash) {
    throw new Error("MInlineEntryList Error: sortOrderHash string not found in GET response.");
  } else {
    sortOrderHash.value = data.sortOrderHash;
  }

  if (!data.entries) {
    throw new Error("MInlineEntryList Error: entries array not found in GET response.");
  } else {
    entries.value = data.entries;
  }

  isInert.value = false;
}

async function fetchAndFocus() {
  const promise = fetchEntries().then(() => {
    emit("entriesUpdated");

    nextTick(() => {
      focusLastInput();
    });
  });

  return promise;
}

async function saveEntries() {
  // If save API is currently in progress, set hasPendingSave to true.
  // When isSaving becomes false, saveEntries will be called again.
  if (isSaving.value === true) {
    hasPendingSave.value = true;
    return;
  }

  // Handle save status — only show 'Saving...' if the API takes longer than 500ms
  hasPendingSave.value = false;
  isSaving.value = true;

  if (hideSaveStatusTimerId.value !== null) {
    clearTimeout(hideSaveStatusTimerId.value);
    hideSaveStatusTimerId.value = null;
  }

  saveStatus.value = "Saving...";
  const showTimerId = setTimeout(() => {
    showSaveStatus.value = true;
  }, 500);

  // Create change-notes payload
  const notesToSave = getNotesChanges();
  const latestSortOrder = sortOrder.value;

  // IMPORTANT: For any notes about to be deleted on the backend,
  // the ID of the entry must be replaced with a new one
  const entriesAboutToBeDeleted = entries.value.filter(
    (entry) => !latestSortOrder.some((sortOrderItem) => sortOrderItem.id === entry.id),
  );
  if (entriesAboutToBeDeleted.length > 0) {
    const entriesWithUpdatedIds = entries.value.map((entry) => {
      if (entriesAboutToBeDeleted.includes(entry)) {
        return { ...entry, id: generateUUIDv7() };
      }

      return entry;
    });

    entries.value = entriesWithUpdatedIds;
  }

  // Call change-notes API and update hash with response
  try {
    const response = await axios.post<
      undefined,
      AxiosResponse<PostNotesResponse>,
      PostNotesPayload
    >(props.postUrl, {
      notesToSave,
      sortOrder: latestSortOrder,
      sortOrderHash: sortOrderHash.value,
    });

    sortOrderHash.value = response.data.sortOrderHash;

    // Show Saved status message
    clearTimeout(showTimerId);
    saveStatus.value = "Saved";
    showSaveStatus.value = true;
  } catch (error) {
    saveStatus.value = "Error occurred";
    showSaveStatus.value = false;

    if (isAxiosError(error) && error.response?.data.errors?.sortOrderHash) {
      showDialog({
        title: "Version Mismatch",
        text: "Your changes to this list were unable to be saved due to a version mismatch.\n\nTo make changes, please make sure you have the latest version by refreshing the page.",
        noCancel: true,
        okLabel: "Refresh page",
        okColor: "",
      }).onOk(() => {
        router.go(0);
      });
    } else {
      showDialog({
        title: "Unexpected Error Occurred",
        text: "Due to an unexpected error your changes to this list may not have been saved.\n\nPlease refresh the page and try again.",
        noCancel: true,
        okLabel: "Refresh page",
        okColor: "",
      }).onOk(() => {
        router.go(0);
      });
    }
  } finally {
    // Reset all notes fields hasUnsavedChanges property to false, unless the value has changed since this save was called
    resetHasUnsavedChanges(notesToSave);

    // Triggers a save if hasPendingSave was set to true — must remain after resetHasUnsavedChanges
    isSaving.value = false;
  }

  // Wait 2.5s before hiding the saved label
  hideSaveStatusTimerId.value = window.setTimeout(() => {
    showSaveStatus.value = false;
  }, 2500);
}

const {
  debouncedThrottled: debouncedThrottledSaveEntries,
  cancelDebouncedThrottled: cancelDebouncedThrottledSaveEntries,
} = useDebouncedThrottledFunction(() => saveEntries());

function addNewEntry(
  indexToInsert?: number,
  value: string = "",
  focusAfter: boolean = true,
): string {
  const newEntry = getNewNoteEntryObject("text");
  if (value !== "\n") newEntry.text = value;

  if (value) newEntry.hasUnsavedChanges = true;

  entries.value.splice(
    indexToInsert !== undefined ? indexToInsert : entries.value.length,
    0,
    newEntry,
  );

  if (focusAfter) {
    nextTick(() => {
      const input = refsInputs.value.find((input) => input.props.id === newEntry.id);
      input?.focus();
    });
  }

  return newEntry.id;
}

function resetHasUnsavedChanges(notesToReset: SaveNotePartial[]) {
  notesToReset.forEach((note) => {
    const entry = entries.value.find((entry) => entry.id === note.uuid);

    if (entry) {
      if ("text" in entry && entry.text === note.text) {
        entry.hasUnsavedChanges = false;
      }

      if ("code" in entry) {
        entry.hasUnsavedChanges = false;
      }
    }
  });
}

function openClinicalCodeModal(entryId: string, onSuccess?: Function) {
  const url = `/clinical/note/edit-note/${entryId}`;
  const additionalDeletePayloadData = {
    sortOrderHash: sortOrderHash,
    sortOrder: sortOrder.value.filter((sortOrderItem) => sortOrderItem.id !== entryId),
  };

  openModal(url, {
    additionalDeletePayloadData,
    onSuccess,
  });
}

function openClinicalCodeSlideover(entryId: string, onSuccess?: Function) {
  openSlideover(`/clinical/note/overview/${entryId}`, {
    onSuccess,
  });
}

function focusInput(id: string) {
  if (props.readOnly) return;

  // If any modals/drawers are open, do not steal focus.
  // Assumption: MInlineEntryList will NOT be used on modal/drawer
  if (useDrawerStore().queue.length > 0) {
    return;
  }

  const input = refsInputs.value.find((input) => input.props.id === id);

  if (input) input.focus();
}

function focusLastInput() {
  const lastEntry = entries.value[entries.value.length - 1];

  if (lastEntry) {
    focusInput(lastEntry.id);
  }
}

function getDrawerOptions(entry: Entry) {
  return <DrawerOptions>{
    additionalDeletePayloadData: {
      sortOrderHash: sortOrderHash,
      sortOrder: sortOrder.value.filter((sortOrderItem) => sortOrderItem.id !== entry.id),
    },
    additionalCreatePayloadData: {},
    onSuccess: fetchAndFocus,
  };
}

//// GETTERS / TRANSFORMERS
function getNewNoteEntryObject(type: "text" | "code"): Note {
  const newID = generateUUIDv7();

  const newNote: Note = {
    id: newID,
    type: EntryType.CARE_RECORD_NOTE,
    hasUnsavedChanges: false,
    __idForList: generateUUIDv7(),
  };

  if (type === "text") {
    newNote.text = "";
  }

  if (type === "code") {
    newNote.code = {
      conceptId: "",
      description: "",
      descriptionId: "",
    };
  }

  return newNote;
}

function getNotesChanges(): SaveNotePartial[] {
  return entries.value
    .filter((entry) => {
      // Do not persist empty text notes
      if (
        entry.type === EntryType.CARE_RECORD_NOTE &&
        ("code" in entry || entry.text?.length >= 1) &&
        "hasUnsavedChanges" in entry &&
        entry.hasUnsavedChanges === true
      ) {
        return true;
      }

      return false;
    })
    .map((entry) => {
      if ("text" in entry && entry.text?.length > 0) {
        return {
          uuid: entry.id,
          entryType: entry.type,
          text: entry.text,
        };
      } else if ("code" in entry && entry.code !== undefined) {
        return {
          uuid: entry.id,
          entryType: entry.type,
          code: entry.code.value,
        };
      }
    });
}

//// HANDLERS
function handleBackspaceAtStart(id: string) {
  // Check if first entry, if so, do nothing
  const index = entries.value.findIndex((entry) => entry.id === id);
  if (index === 0) return;

  // Check that entry above is a text entry, otherwise do nothing
  const entry = entries.value[index];
  const previousEntry = entries.value[index - 1];
  const isPreviousEntryNonText =
    previousEntry.type !== EntryType.CARE_RECORD_NOTE || "code" in previousEntry;

  if (isPreviousEntryNonText) {
    const previousEntryRef = refsListItems.value.find((nonTextEntry) => {
      return nonTextEntry.$props.id === previousEntry.id;
    });

    previousEntryRef?.focus();
  } else {
    // Append entry value to previous entry, then remove this entry
    if (entry.type === EntryType.CARE_RECORD_NOTE && "text" in entry) {
      const previousEntryNoteLength = previousEntry.text.length;
      previousEntry.text = previousEntry.text + entry.text;

      nextTick(() => {
        const previousEntryInput = refsInputs.value.find(
          (input) => input.props.id === previousEntry.id,
        );
        previousEntryInput?.focus(previousEntryNoteLength);
      });

      entries.value.splice(index, 1);
    }
  }
}

function handleCodeSelected(code: SnomedClinicalCode, id: string) {
  const entryIndex = entries.value.findIndex((entry) => entry.id === id);
  let isAModalCreate = false;
  // Check for allergy and observation codes, open modal
  // this includes concept ids that will have exact match or will match parent concept id of the searched SNOMED CT code

  conceptsToOpenModal.forEach((concept) => {
    if (
      ~code.value.parentConceptIds.indexOf(concept.conceptId) ||
      concept.conceptId == code.value.conceptId
    ) {
      isAModalCreate = true;
      const newId = generateUUIDv7();

      const currentSortOrder = sortOrder.value;
      const newSortOrderItem = {
        id: newId,
        entryType: concept.type,
      };
      let newSortOrder = {};
      if (sortOrder.value.some((sortOrderItem) => sortOrderItem.id === id)) {
        newSortOrder = currentSortOrder.map((sortOrderItem) => {
          if (sortOrderItem.id === id) {
            return newSortOrderItem;
          }

          return sortOrderItem;
        });
      } else {
        currentSortOrder.splice(entryIndex, 0, newSortOrderItem);
        newSortOrder = currentSortOrder;
      }

      const additionalCreatePayloadData = {
        uuid: newId,
        sortOrder: newSortOrder,
        sortOrderHash: sortOrderHash,
      };

      if (concept.type === EntryType.ALLERGY) {
        if (!props.patientId || !props.contextId) {
          throw new Error("Props patientId and contextId are required to create allergy.");
        }

        createAllergy(
          props.patientId,
          props.contextId,
          props.contextType,
          code.value.conceptId,
          additionalCreatePayloadData,
        );
      } else if (concept.type === EntryType.OBSERVATION) {
        if (!props.patientId || !props.contextId) {
          throw new Error("Props patientId and contextId are required to create observation.");
        }

        createObservation(
          props.patientId,
          props.contextId,
          props.contextType,
          code.value.conceptId,
          additionalCreatePayloadData,
        );
      }
    }
  });

  if (isAModalCreate) {
    return;
  } else {
    // If regular code, replace entry and save
    addCodeEntry(code, entryIndex);
  }
}

function addCodeEntry(code: SnomedClinicalCode, indexToInsert?: number = 0) {
  const id = generateUUIDv7();
  const newCodeEntry: Entry = {
    id,
    code,
    type: EntryType.CARE_RECORD_NOTE,
    onClickUrl: `/clinical/data/note/edit-note/${id}`,
    srLabel: `Edit care record note: ${code.value.description}`,
    hasUnsavedChanges: true,
  };
  entries.value.splice(indexToInsert, 1, newCodeEntry);

  nextTick(() => {
    cancelDebouncedThrottledSaveEntries();
    saveEntries();

    const lastEntry = entries.value[entries.value.length - 1];
    if (lastEntry.type !== EntryType.CARE_RECORD_NOTE || "code" in lastEntry) {
      // Code added at end, need a text input below to focus
      addNewEntry(entries.value.length);
    } else {
      // Focus last input
      nextTick(() => {
        const input = refsInputs.value.find((input) => input.props.id === lastEntry.id);
        input?.focus();
      });
    }
  });
}

/**
 * Handles the value change event for all entries
 * @param id The ID of the Entry Input
 * @param value The new value from the Entry Input
 * @param saveImmediately Overrides the debounce/throttle delay if true
 */
function handleInputValueChange(id: string, value: string, saveImmediately: boolean = false) {
  const index = entries.value.findIndex((entry) => entry.id === id);
  if (index < 0) throw new Error("No entry for ID");
  const entryToUpdate = entries.value[index] as Note;

  if ("text" in entryToUpdate) {
    entryToUpdate.text = value;
    entryToUpdate.hasUnsavedChanges = true;

    if (saveImmediately === true) {
      cancelDebouncedThrottledSaveEntries();
      saveEntries();
    } else {
      debouncedThrottledSaveEntries();
    }
  }
}

/**
 * Handles blurring of inputs.
 * If unsaved changes, cancels debounced save function, and instead saves immediately.
 * All empty inputs delete on blur.
 * @param id The ID of the input that blurred
 * @param inputValue The latest text value of the input
 */
function handleInputBlur(id: string, inputValue: string) {
  // Find input from entries list
  const index = entries.value.findIndex((entry) => entry.id === id);
  if (index < 0) return;

  const entry: Note = entries.value[index] as Note;

  if (entry.type === EntryType.CARE_RECORD_NOTE && !entry.code) {
    // If entry is empty and has siblings, cancel throttle/debounce, delete entry, and save immediately

    // Delete conditions
    const isNoteEmpty = inputValue === "";
    const isNoteLastEntry = index === entries.value.length - 1;
    const isPrevSiblingTextNote =
      index > 0
        ? entries.value[index - 1].type === EntryType.CARE_RECORD_NOTE &&
          !entries.value[index - 1].code
        : false;
    const shouldDelete =
      (isNoteEmpty && !isNoteLastEntry) ||
      (isNoteEmpty && isNoteLastEntry && isPrevSiblingTextNote);

    if (shouldDelete) {
      // Delete empty input
      cancelDebouncedThrottledSaveEntries();
      entries.value.splice(index, 1);
      saveEntries();
    } else if (entry.hasUnsavedChanges) {
      // If entry has unsaved changes, cancel throttle/debounce and save immediately
      cancelDebouncedThrottledSaveEntries();
      saveEntries();
    }
  }
}

function handleEnterKeydown(id: string, event: CursorValues) {
  // If enter pressed on empty input, do nothing
  const isEmptyInput = event.leftOfSelection === null && event.rightOfSelection === null;
  if (isEmptyInput) {
    emit("focusNextInput");
    return;
  }

  const currentEntry = entries.value.find((entry) => entry.id === id) as Note;
  const currentEntryIndex = entries.value.findIndex((entry) => entry.id === id);

  // Split — values both side. Add right hand side to new entry below.
  if (
    event.leftOfSelection !== null &&
    event.rightOfSelection !== null &&
    currentEntry.type === EntryType.CARE_RECORD_NOTE &&
    "code" in currentEntry === false
  ) {
    handleInputValueChange(currentEntry.id, event.leftOfSelection);
    addNewEntry(currentEntryIndex + 1, event.rightOfSelection); // Blur event will trigger saveEntries
  }

  // Cursor at very left of input – add new entry above and focus
  else if (event.leftOfSelection === null && event.rightOfSelection !== null) {
    addNewEntry(currentEntryIndex);
  }

  // Cursor at very right of input. Update text deletion, if any. Add new entry below, and focus
  else if (event.leftOfSelection !== null && event.rightOfSelection === null) {
    if (
      currentEntry.type === EntryType.CARE_RECORD_NOTE &&
      currentEntry.code === null &&
      currentEntry.text !== event.leftOfSelection
    ) {
      handleInputValueChange(id, event.leftOfSelection);
    }

    const indexToInsert = currentEntryIndex + 1;
    const entryAtIndex = entries.value[indexToInsert];
    if (
      entryAtIndex &&
      entryAtIndex.type === EntryType.CARE_RECORD_NOTE &&
      "text" in entryAtIndex &&
      entryAtIndex.text === ""
    ) {
      const input = refsInputs.value.find((input) => input.props.id === entryAtIndex.id);
      input?.focus();
    } else {
      addNewEntry(indexToInsert);
    }
  }
}

function handleActionSelected(action: Action, entryId: string) {
  const entryTypeKeyForAction = Object.keys(EntryType).find(
    (entryTypeKey) => EntryType[entryTypeKey] === action.type,
  );
  const newEntryType: EntryType = EntryType[entryTypeKeyForAction];
  const newEntryID = generateUUIDv7();
  let entryIndex = entries.value.findIndex((entryItem) => entryItem.id === entryId);
  // If the entry is not in the entires already add it to the bottom
  if (entryIndex === -1) entryIndex = entries.value.length;
  const newEntry: Partial<Entry> = {
    id: newEntryID,
    type: newEntryType,
    hasUnsavedChanges: true,
  };

  if (action.type === "note") {
    // need to preserve empty notes in the sort order when creating notes via a modal
    newEntry.__preserveEmptyNoteInSortOrder = true;
  }

  entries.value.splice(entryIndex, 1, newEntry);

  nextTick(() => {
    let props = {
      ...action.props,
      contextId: action.contextId,
      contextType: action.contextType,
    };

    if (action.openInDockedModal && openDockedModal !== NO_OP) {
      openDockedModal({
        side: action.dockedModalPosition,
        componentOptions: {
          url: action.url,
          props,
          additionalCreatePayloadData: {
            uuid: newEntryID,
            sortOrder: sortOrder,
            sortOrderHash: sortOrderHash,
          },
        },
        lifeCycleHooks: {
          onCancel: () => handleActionModalCancel(entryIndex),
          onSuccess: fetchAndFocus,
        },
      });
    } else {
      openModal(action.url, {
        props,
        additionalCreatePayloadData: {
          uuid: newEntryID,
          sortOrder: sortOrder,
          sortOrderHash: sortOrderHash,
        },
        onCancel: () => handleActionModalCancel(entryIndex),
        onSuccess: fetchAndFocus,
      });
    }
  });
}

function handleActionModalCancel(entryIndex: number) {
  if (props.readOnly) {
    entries.value.splice(entryIndex, 1);
  } else {
    const newEntry = getNewNoteEntryObject("text");
    entries.value.splice(entryIndex, 1, newEntry);

    nextTick(() => {
      focusInput(newEntry.id);
    });
  }
}

function handleListItemClick(entry: Entry) {
  if (!entry.onClickUrl) {
    const entryIndex = entries.value.findIndex((e) => e.id === entry.id);

    throw new Error(
      `Entry of type '${
        entry.type
      }' at index ${entryIndex} does not have onClickUrl defined.\nCurrent sort order:\n${JSON.stringify(
        sortOrder.value,
      )}`,
    );
  }

  if (entry.code) {
    if (props.readOnly) {
      openClinicalCodeSlideover(entry.id, fetchAndFocus);
    } else {
      openClinicalCodeModal(entry.id, fetchAndFocus);
    }

    return;
  }

  const drawerOptions = getDrawerOptions(entry);

  if ((props.readOnly && !entry.openAsModal) || entry.isFinalised) {
    openSlideover(entry.onClickUrl, drawerOptions);
  } else {
    const entryTypeAction = props.actions?.find((action) => action.type === entry.type);

    if (entryTypeAction?.openInDockedModal && openDockedModal) {
      openDockedModal({
        side: entryTypeAction?.dockedModalPosition,
        componentOptions: {
          url: entry.onClickUrl,
          props: {
            contextId: props.contextId,
            contextType: props.contextType,
          },
          additionalDeletePayloadData: {
            sortOrderHash: sortOrderHash.value,
            sortOrder: sortOrder.value.filter((sortOrderItem) => sortOrderItem.id !== entry.id),
          },
        },
        lifeCycleHooks: {
          onSuccess: fetchAndFocus,
        },
      });
    } else {
      openModal(entry.onClickUrl, drawerOptions);
    }
  }
}

function handleCreateBeforeAfter(
  value: string,
  contextEntryId: string,
  insertDirection: "before" | "after",
) {
  const contextIndex = entries.value.findIndex((entry) => entry.id === contextEntryId);
  const insertIndex = insertDirection === "before" ? contextIndex : contextIndex + 1;
  addNewEntry(insertIndex, value);
}
</script>
<template>
  <div
    :class="{
      'inline-entry-list-container': true,
      offline: isOnline === false,
      'marked-incorrect': props.markedIncorrect,
    }"
    :inert="isInert || props.disableInput || isOnline === false"
  >
    <header>
      <!-- Heading -->
      <component
        :is="props.labelSize ?? 'h4'"
        v-if="props.label"
        :id="headingLabelID"
        class="heading"
        >{{ props.label }}</component
      >

      <!-- Saving Status Label -->
      <transition
        name="fade"
        @after-leave="
          () => {
            saveStatus = '';
          }
        "
      >
        <div v-if="showSaveStatus" class="save-status">{{ saveStatus }}</div>
      </transition>
      <div v-if="showActions" class="card-button">
        <MAction v-if="actions" :model-value="getFormattedActions" />
      </div>
    </header>

    <!-- Slot to insert banners -->
    <slot />

    <!-- Instructions -->
    <div v-if="props.instructionsText" class="instructions">{{ props.instructionsText }}</div>

    <!-- Entries Container -->
    <div class="entries">
      <!-- Empty List Text -->
      <div v-if="props.readOnly && entries.length === 0" class="empty-text">{{ emptyText }}</div>

      <!-- Entry Container -->
      <div v-for="entry of entries" :key="entry.__idForList" class="entry" :entry-type="entry.type">
        <!-- Bullet -->
        <div
          class="bullet"
          :class="{
            empty: entry.type === EntryType.CARE_RECORD_NOTE && !entry.code && entry.text === '',
          }"
        />

        <!-- Inline Editable Text Entry -->
        <m-inline-text-entry-input
          v-if="
            !props.readOnly &&
            entry.type === EntryType.CARE_RECORD_NOTE &&
            !entry.code &&
            entry.text?.length >= 0 &&
            !entry.disabled &&
            !entry.isMarkedIncorrect
          "
          :id="entry.id"
          ref="refsInputs"
          :value="entry.text"
          :labelled-by="headingLabelID"
          :disabled="isDisabled"
          :actions="props.actions"
          class="entry-input"
          @update:model-value="handleInputValueChange"
          @blur="(inputValue) => handleInputBlur(entry.id, inputValue)"
          @enter-keydown="(values) => handleEnterKeydown(entry.id, values)"
          @backspace-at-start="() => handleBackspaceAtStart(entry.id)"
          @code-selected="(code) => handleCodeSelected(code, entry.id)"
          @action-selected="(action) => handleActionSelected(action, entry.id)"
        />

        <!-- List Item Entry -->
        <m-inline-entry-list-item
          v-else
          :id="entry.id"
          ref="refsListItems"
          :on-click="() => handleListItemClick(entry)"
          :code="entry.code"
          :text="entry.text"
          :marked-incorrect="entry.isMarkedIncorrect"
          :hide-before-after-inputs="props.readOnly"
          :disabled="entry.disabled"
          :disabled-reason="entry.disabledReason"
          @create-before="(val: string) => handleCreateBeforeAfter(val, entry.id, 'before')"
          @create-after="(val: string) => handleCreateBeforeAfter(val, entry.id, 'after')"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.inline-entry-list-container {
  &[inert] {
    .entries {
      background-color: var(--grey-lightest);
    }
  }
}

header {
  display: flex;
  align-items: center;
}

.instructions {
  display: block;
  color: var(--text-color-lightest);
  font-style: italic;
  margin-bottom: 2px;
}

.topic-heading-header {
  display: flex;
  align-items: center;
}

header {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 6px;

  .save-status {
    font-size: 14px;
    color: #777;
    font-style: italic;
    margin-bottom: 2px;
  }

  &:has(h3) {
    margin-bottom: 8px;
    .save-status {
      margin-bottom: 0;
      margin-top: 1px;
    }
  }
}

.help {
  display: none;
}

.entries {
  border: 1px solid var(--grey-lightest-non-text);
  background: white;
  padding: 6px 4px;
  border-radius: 4px;
  min-height: 35px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 6%);

  &:focus-within {
    outline: 2px solid var(--focus-ring);
    outline-offset: 1px;
  }

  &:has(.empty-text) {
    padding: 12px;
  }
}

.offline .entries {
  border: 0;
  box-shadow: none;
}

.entry {
  display: flex;
  padding-left: 8px;
  position: relative;
  min-height: 26px;

  &:has(> .structured-entry, > .code) {
    .bullet {
      margin-top: 10px;
    }
  }
}

.bullet {
  width: 5px;
  height: 5px;
  margin-top: 11px;
  flex: 0 0 auto;
  background: var(--grey-darkest);
  border: 1.5px solid var(--grey-darkest);
  border-radius: 50%;
  color: var(--grey-darkest);
  transition:
    background var(--transition-duration) ease-out,
    border-color var(--transition-duration) ease-out;

  &.empty {
    background: transparent;
    border-color: var(--grey-dark);
  }
}

.entry:has(.list-item) {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
