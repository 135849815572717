<!-- eslint-disable vue/no-v-html -->
<template>
  <MLayoutStack>
    <component
      :is="element.componentName"
      v-bind="element.props"
      :model-value="element.modelValueKey ? formValues[element.modelValueKey] : null"
      @update:modelValue="handleValueUpdate"
      @blur="handleOnBlur"
    >
      <template v-if="props.element.children">
        <MConsultationTemplateFormElement
          v-for="(item, index) in props.element.children"
          :key="'element' + index"
          :element="item"
          :form-values="formValues"
          @formValueUpdate="handleFormValueUpdate"
        />
      </template>
      <template v-if="element.content">
        {{ element.content }}
      </template>
      <span
        v-if="element.markdown"
        v-html="marked.parse(element.markdown, { breaks: true, gfm: true, renderer })"
      ></span>
    </component>
  </MLayoutStack>
</template>

<script setup lang="ts">
import MConsultationTemplateFormElement from "./MConsultationTemplateFormElement.vue";
import MLayoutStack from "../../MLayoutStack/MLayoutStack.vue";
import { marked } from "marked";
import { defineEmits } from "vue";
const props = defineProps({
  element: Object,
  formValues: Object,
});

const emit = defineEmits<{
  (e: "formValueUpdate"): void;
}>();

const renderer = new marked.Renderer();
const linkRenderer = renderer.link;
renderer.link = (href, title, text) => {
  const html = linkRenderer.call(renderer, href, title, text);
  return html.replace(/^<a /, '<a target="_blank" ');
};

function handleFormValueUpdate() {
  emit("formValueUpdate");
}

function handleOnBlur() {
  if (props.element.hasOwnProperty("triggerAction") && props.element.triggerAction == "blur") {
    emit("formValueUpdate");
  }
}
function handleValueUpdate() {
  if (props.element.hasOwnProperty("triggerAction") && props.element.triggerAction == "change") {
    emit("formValueUpdate");
  }
}
</script>
