import { ComponentOptions } from "vue";

export const ComponentTypes = Object.entries(
  import.meta.glob("./types/*.vue", { eager: true }),
).reduce(
  (p, c) => {
    const [key, value] = c;
    const name = key.replace("./types/", "").replace(".vue", "");
    p[name.toLocaleLowerCase()] = value.default;
    return p;
  },
  {} as Record<string, ComponentOptions>,
);
