<script lang="ts" setup>
import { UnwrapRef, reactive, watch } from "vue";
import { useDialog } from "../composables/dialog";
import Dialog from "./Dialog.vue";
import MDynamic from "./medicus/MDynamic";
const dialog = useDialog();
type DialogItem = UnwrapRef<typeof dialog> & {};

defineEmits({
  hide: () => true,
  beforeHide: () => true,
});

const openedDialogs = reactive<Array<DialogItem>>([]);
watch(
  dialog,
  (d) => {
    if (d) {
      openedDialogs.push(d);

      d.close = () => removeDialog(d);
    }
  },
  {
    immediate: true,
  },
);

function removeDialog(dialog: DialogItem) {
  openedDialogs.splice(openedDialogs.indexOf(dialog), 1);
}

function onResolve(dialog: DialogItem) {
  dialog.resolve();
  removeDialog(dialog);
}
function onHide(dialog: DialogItem) {
  dialog.reject();
  removeDialog(dialog);
}
</script>
<template>
  <Dialog
    v-for="(d, i) in openedDialogs"
    :key="`dialog-container-${d?.component}-${i}`"
    model-value
    v-bind="d"
    @hide="onHide(d)"
    @ok="onResolve(d)"
  >
    <template v-if="d.component">
      <m-dynamic
        v-if="typeof d.component === 'string'"
        :url="d.component"
        v-bind="d.componentProps"
        @resolve="onResolve(d)"
        @hide="onHide(d)"
      />
      <component :is="d.component" v-else v-bind="d.componentProps" />
    </template>
  </Dialog>
</template>
