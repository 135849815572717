<template>
  <m-labeled-text
    :label="label"
    :horizontal="horizontal"
    :label-width="labelWidth"
    :helper="helper"
    :instructions="instructions"
  >
    <template v-if="$slots.helper" #helper> <slot name="helper" /></template>
    <template #text>
      <q-btn-toggle
        v-model="templateValue"
        class="button-toggle"
        toggle-color="primary"
        :options="options"
        no-caps
      />
    </template>
  </m-labeled-text>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useFormValue } from "../../../../composables/namedValue";
import MLabeledText from "../../MLabeledText";
import { QBtnToggle } from "quasar";

export default defineComponent({
  components: { MLabeledText, QBtnToggle },
  props: {
    modelValue: [Object, String, Number],
    name: String,

    options: {
      type: Array,
      default() {
        return [];
      },
    },

    label: String,

    horizontal: {
      type: Boolean,
      default: undefined,
    },
    labelWidth: String,

    helper: String,
    instructions: String,
  },
  setup(props) {
    const templateValue = useFormValue(props);

    return {
      templateValue,
    };
  },
});
</script>

<style lang="scss">
.button-toggle {
  box-shadow: none;
  background: #ffffff;

  // border: 1px solid #c1c6cd;
  border-radius: 4px;

  button {
    height: 35px;
  }

  // .q-btn__wrapper {
  //   padding: 2px 32px;
  //   min-height: 33px;
  // }

  .q-btn__content {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    text-align: center;

    /* Dark copy */
    color: var(--text-color);
  }

  .bg-primary {
    background: var(--grey-light) !important;

    // .q-btn__content {
    //   font-weight: bold;
    // }
  }

  button {
    border-top: 1px solid #c1c6cd;
    border-bottom: 1px solid #c1c6cd;

    border-right: 1px solid #c1c6cd;
  }

  button:last-child {
    border: 1px solid #c1c6cd;
    border-left: none;
  }

  button:nth-child(1) {
    border: 1px solid #c1c6cd;
    // border-top-left-radius: 4px;
    // border-bottom-left-radius: 4px;
  }

  button {
    &:first {
      border: 1px solid #c1c6cd;
    }
  }
}
</style>
