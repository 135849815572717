<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>Investigation request: {{ item.investigationRequestItems.join(" • ") }}</span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel(item)
      }}</i>
    </m-layout-stack>
    <m-layout-stack
      v-if="
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.linkedProblems.length
      "
      horizontal
      class="q-mt-sm"
    >
      <m-badge
        v-if="item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices"
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices"
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`investigation-request-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`investigation-request-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
export type InvestigationRequestJournalItem = {
  isRetrospectivelyAmended: boolean;
  investigationRequestItems: [];
  isMarkedIncorrect: boolean;
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  linkedProblems: { problemCodeDescription: string }[];
};

defineProps<{
  item: InvestigationRequestJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

function formatRetrospectivelyAmendedLabel(item: InvestigationRequestJournalItem) {
  let label = [];
  if (item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
}
</script>
