<template>
  <m-link class="cursor-pointer" :class="{ incorrect: item.isIncorrect }" @click="playFile">
    {{ item.fileName || "Play dictation" }}
  </m-link>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { globalPlayer, usePlayAudio } from "../../../composables/playAudio";
import axios from "../../../utils/axios";
import MLink from "../MLink";

const props = defineProps<{
  item: {
    id: string;
    fileName: string;
    comment: string;
    createdBy: string;
    createdById: string;
    createdDateTime: string;
    isUpdated: boolean;
    isIncorrect?: boolean;
  };
  viewUrl?: string;
}>();

const audioPlayer = ref<ReturnType<typeof usePlayAudio>>();

const view = computed(() => {
  return props.viewUrl && props.item.id
    ? `${axios.defaults.baseURL}${
        props.viewUrl.endsWith("/") ? props.viewUrl : props.viewUrl + "/"
      }${props.item.id}`
    : "";
});

function playFile() {
  audioPlayer.value = usePlayAudio(view.value);
  audioPlayer.value.play();
}

watch(globalPlayer, (v) => {
  if (v !== audioPlayer.value) {
    audioPlayer.value = undefined;
  }
});
</script>
