<script setup lang="ts">
import { formatDate } from "../../../utils/date";
import { openModal } from "../../../composables/dialog/drawer";
import AssigneeButton from "./AssigneeButton";
import MButton from "../MButton";
import MBadge from "../MBadge";
import MLayoutStack from "../MLayoutStack";
import MPatientBanner from "../PatientBanner";
import MBanner from "../MBanner";
import { PropType, computed } from "vue";
import { date } from "quasar";
import { Task } from "../MTaskList/types";

const props = defineProps({
  hidePatientBanner: Boolean,
  item: {
    type: Object as PropType<Task>,
    required: true,
  },
  hideChangeAssignee: Boolean,
  taskType: String, // can be derived from item
  changeTaskAssigneeRoute: {
    type: String,
    required: true,
  },
  noScroll: Boolean,
  noPadding: Boolean,
  enableFullScreen: Boolean,
  isFullScreen: Boolean,
  itemTitle: String,
  changeTaskDueDateRoute: {
    type: String,
    required: true,
  },
  disableEditDueDate: Boolean,
  darkPatientBanner: Boolean,
  hideComments: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits({
  toggleFullScreen: () => true,
});

function onOpenComments() {
  return openModal(`/tasks/comments/${props.item?.id}`, {
    params: {
      taskType: props.item.type,
    },
    props: {
      taskType: props.item.type,
      id: props.item.id,
    },
  });
}

const taskIsDue = (task: { dueDate: string }): boolean =>
  date.formatDate(new Date(), "YYYY-MM-DD") ==
  date.formatDate(new Date(task.dueDate), "YYYY-MM-DD");

const taskIsOverdue = (task: { dueDate: string }): boolean =>
  date.formatDate(new Date(task.dueDate), "YYYY-MM-DD") < date.formatDate(new Date(), "YYYY-MM-DD");

const dueDateBadgeLabel = computed(() => {
  return `Due ${formatDate(props.item.dueDate)}`;
});
</script>

<template>
  <div class="m-task-preview">
    <slot name="item:header" v-bind="item" />

    <m-patient-banner
      v-if="!hidePatientBanner && item.patient?.id"
      class="task-preview-patient-info"
      :patient-id="item.patient.id"
      banner
      blue-accent
    />

    <m-layout-stack class="toolbar medicus-grey-background items-center" horizontal prepend append>
      <div>
        <m-badge
          :text="item.statusLabel"
          :green="item.isCompleted"
          :blue="!item.isCompleted"
          data-testid="task-status-badge"
        >
        </m-badge>
      </div>

      <div v-if="item.highPriority">
        <m-badge amber data-testid="task-high-priority-badge" text="High priority" />
      </div>

      <div v-if="item.dueDate">
        <m-badge
          :text="dueDateBadgeLabel"
          :blue="!taskIsDue(item) && !taskIsOverdue(item)"
          :amber="taskIsDue(item) || taskIsOverdue(item)"
          data-testid="task-due-date-badge"
        />
      </div>

      <div>
        <m-button
          v-if="!hideComments"
          :count="item?.commentCount"
          label="Comments"
          ghost
          @click="onOpenComments"
        >
        </m-button>
      </div>

      <div class="flex-auto" />

      <assignee-button
        v-if="!hideChangeAssignee"
        :name="item?.assignee?.name"
        :initials="item?.assignee?.initials"
        :description="item?.assignee?.organisationName"
        :type="item?.assignee?.type"
        :task-type="item?.type"
        :task-id="item?.id"
        :disabled="item?.disableChangeAssignee"
      />

      <m-button
        v-if="enableFullScreen"
        class="maximise-button"
        :icon="`fa-light ${isFullScreen ? 'fa-minimize' : 'fa-maximize'}`"
        ghost
        icon-only
        :label="isFullScreen ? 'Minimise' : 'Maximise'"
        data-testid="maximise-button"
        @click="emit('toggleFullScreen')"
      />
    </m-layout-stack>

    <div
      v-if="false && item && (itemTitle || $slots['item:title'])"
      class="flex flex-nowrap justify-between items-center item-header"
    >
      <h3 class="item-title">
        <slot name="item:title" v-bind="item">
          {{ itemTitle }}
        </slot>
      </h3>

      <slot name="item:button" v-bind="item">
        <div id="assignee" class="flex gap-2 items-center" />
      </slot>
    </div>

    <div v-if="noScroll" class="preview-content" :class="{ 'padded-content': !noPadding }">
      <m-banner v-if="item.snoozeUntil" type="info" data-testid="snoozed-until-banner">
        <p>Snoozed until {{ formatDate(item.snoozeUntil) }}.</p>
      </m-banner>

      <slot v-bind="{ item }" />
    </div>
    <div v-else class="h-full scroll-area flex-auto full" visible>
      <div class="preview-content" :class="{ 'padded-content': !noPadding }">
        <m-banner v-if="item.snoozeUntil" type="info" data-testid="snoozed-until-banner">
          <p>Snoozed until {{ formatDate(item.snoozeUntil) }}.</p>
        </m-banner>
        <slot v-bind="{ item }" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.m-task-preview {
  padding-top: 0;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 100%;

  .padded-content {
    padding: 16px;
    padding-bottom: 0px;
  }

  .task-preview-patient-info {
    border-radius: 0;
    border-left: none;
    border-top: none;
    min-height: var(--page-header-height);
    flex: 0 0 auto;

    .encounter-patient-info {
      margin-top: 3px;
    }
  }

  .item-header {
    margin-right: 3px;
    border-bottom: 1px solid var(--border-colour);

    padding: 0 1em;

    min-height: 60px;

    .item-title {
      font-family: Georgia;
      font-style: normal;
      font-weight: normal;
      //font-size: 18px;
      line-height: 28px;

      color: var(--text-color);
    }

    .m-select {
      label {
        margin-top: 6px;
      }
    }
  }

  .preview-content {
    overflow: hidden;
    background: var(--grey-lightest);
    flex: 1 1 auto;
    height: 100%;
  }

  .toolbar {
    padding: 0.5rem 0;

    border-bottom: 1px solid #dadde1;

    .maximise-button {
      color: var(--theme-blue);

      .medicus-button {
        font-size: 20px !important;
      }
    }
    .dropdown-options-button {
      color: var(--theme-blue);

      font-size: 22px !important;
    }
    .more-actions-button {
      color: var(--theme-blue);

      .q-icon {
        font-size: 22px !important;
      }
    }
  }
}

.filter-title {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;

  color: var(--text-color);
}

.task-comments-modal {
  .comments-modal-footer {
    border-top: 1px solid var(--border-colour);
  }
}
</style>
