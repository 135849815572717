<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
      'note-presenter',
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>
          {{ item.clinicalCodeDescription }}
          {{ item.note }}
        </span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <div></div>
    <m-layout-stack
      v-if="
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.patientBannerFlags?.length ||
        item.linkedProblems?.length ||
        item.isExplicitlyIncludedInSCR
      "
      horizontal
      wrap
      class="q-mt-sm"
    >
      <m-badge
        v-for="(patientBannerFlag, index) in item.patientBannerFlags"
        :key="`consultation-patient-banner-flag-${index}`"
        amber
        :text="patientBannerFlag"
        :data-testid="`risks-badge-${index}`"
      />
      <m-badge
        v-if="item.isExplicitlyIncludedInSCR"
        text="Explicitly included in SCR"
        gray
        data-testid="care-record-element-explicitly-included-badge"
      />
      <m-badge
        v-if="
          item.hiddenFromPatientFacingServices &&
          item.hiddenFromPatientFacingServices !== contextHiddenFromPatientFacingServices
        "
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`note-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`note-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

type NoteItem = {
  entryType: string;
  id: string;
  note: string;
  clinicalCodeDescription?: string;
  recordedBy: string;
  recordedByOrganisation?: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isExplicitlyIncludedInSCR: boolean;
  isRetrospectivelyAmended: boolean;
  patientBannerFlags: string[];
  linkedProblems: { problemCodeDescription: string }[];
  createdDateTime?: string;
};

const props = defineProps<{
  item: NoteItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
<style>
.note-presenter .note-content {
  white-space: pre-wrap;
}
</style>
