<script lang="ts" setup>
import { NOOP } from "@vue/shared";
import { getActivePinia } from "pinia";
import { inject, onUnmounted, toRef } from "vue";
import { usePrescriptionStore } from "../../../../store/transitient/prescription";
import { parseRoute } from "../../../../utils/route";
import CreatePrescriptionForm from "../CreatePrescriptionForm";
import PrescriptionScreen from "../PrescriptionScreen";

const parsed = parseRoute(inject("drawer-url"));
const prescriptionId = parsed.id!;

// end

const props = defineProps<{
  openIssue?: boolean;

  contextId?: string;
  contextType?: string;
  success?: Function;
  showIssueLaterButton?: boolean;
  issueLaterLabel?: string;
  issueLaterColor?: string;
  hideIssueNow?: boolean;
}>();

defineEmits({
  success: () => true,
});
const setTitle = inject<(v: string) => void>("setTitle", NOOP);
setTitle("New Prescription");

// init
const store = usePrescriptionStore();
await store.initById(prescriptionId, "issue-one-off");

store.form.contextId =
  parsed.query.contextId ||
  (toRef(
    props,
    "contextId",
  ) as any) /* this type is correct, just typescript does not support this ATM*/;

store.form.contextType =
  parsed.query.contextType ||
  (toRef(
    props,
    "contextType",
  ) as any) /* this type is correct, just typescript does not support this ATM*/;

store.form.action = "issue-one-off";

onUnmounted(() => {
  // clean store
  store.$dispose();

  // clear store state
  const pinia = getActivePinia();
  delete pinia!.state.value[store.$id];
});
// / init
</script>
<template>
  <PrescriptionScreen>
    <CreatePrescriptionForm
      form-url="/clinical/prescription/create/create-prescription/authorise"
      hide-add-another
      :hide-issue-now="hideIssueNow"
      :show-issue-later-button="showIssueLaterButton"
      :issue-later-label="issueLaterLabel"
      :issue-later-color="issueLaterColor"
      :additional-ignore-fields="['originalPrescriptionId']"
      @success="success"
    />
  </PrescriptionScreen>
</template>
