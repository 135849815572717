<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>
          {{ item.referralCode }}
          <template v-if="item.priority"> ({{ item.priority }})</template>
          <template v-if="item.referralCode"> • {{ item.referralCode }}</template>
        </span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <span v-if="item.referringClinician" class="secondary-text"
      >Made by {{ item.referringClinician }}</span
    >
    <m-layout-stack
      v-if="
        item.cancelledStatusLabel ||
        item.incompleteStatusLabel ||
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.linkedProblems.length ||
        item.isExplicitlyIncludedInSCR
      "
      horizontal
      class="q-mt-sm"
    >
      <m-badge v-if="item.cancelledStatusLabel" gray :text="item.cancelledStatusLabel" />
      <m-badge v-if="item.incompleteStatusLabel" amber :text="item.incompleteStatusLabel" />
      <m-badge
        v-if="item.isExplicitlyIncludedInSCR"
        text="Explicitly included in SCR"
        gray
        data-testid="care-record-element-explicitly-included-badge"
      />
      <m-badge
        v-if="
          item.hiddenFromPatientFacingServices &&
          item.hiddenFromPatientFacingServices !== contextHiddenFromPatientFacingServices
        "
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`outbound-referral-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`outbound-referral-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type OutboundReferralJournalItem = {
  isExplicitlyIncludedInSCR: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  incompleteStatusLabel: string;
  cancelledStatusLabel: string;
  referringClinician: string;
  priority: string;
  referralCode: string;
  isMarkedIncorrect: boolean;
  isRetrospectivelyAmended: boolean;
};

const props = defineProps<{
  item: OutboundReferralJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
