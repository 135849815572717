import { Ref, computed, onMounted, onUnmounted, ref, unref, watch } from "vue";
import { NO_OP, RefElement, RefTyped, isElement, unwrap, wrap } from "vue-composable";

interface ResizeObserverOptions {
  box?: "content-box" | "border-box" | "device-pixel-content-box";
}
export declare interface IntersectionObserverResult {
  supported: boolean;
  elements: Ref<IntersectionObserverEntry[]>;
  observe: (el: RefTyped<Element>) => void;
  unobserve: (el: RefTyped<Element>) => void;
  disconnect: () => void;
  readonly contentBoxSize: Ref<
    readonly {
      readonly blockSize: number;
      readonly inlineSize: number;
    }[]
  >;
}

export function useResizeObserver(
  el: RefElement,
  options?: RefTyped<ResizeObserverOptions>,
): IntersectionObserverResult;
export function useResizeObserver(
  options?: RefTyped<ResizeObserverOptions>,
): IntersectionObserverResult;
export function useResizeObserver(
  refEl?: RefElement | RefTyped<ResizeObserverOptions>,
  refOptions?: RefTyped<ResizeObserverOptions>,
) {
  const supported = "IntersectionObserver" in window;
  const wrappedElement = refEl ? wrap(refEl) : undefined;
  const element =
    wrappedElement && (isElement(wrappedElement.value) || !wrappedElement.value)
      ? wrappedElement
      : undefined;
  const options = computed(() =>
    refOptions ? unwrap(refOptions) : !element ? unwrap(refEl) : undefined,
  );
  const elements = ref<ResizeObserverEntry[]>([]);
  const contentBoxSize = computed(() => elements.value.flatMap((x) => x.contentBoxSize));
  const handling = (entries) => {
    elements.value = entries;
  };
  const observer = ref();
  if (supported) {
    watch(
      options,
      () => {
        if (observer.value) {
          observer.value.disconnect();
        }
        observer.value = new ResizeObserver(handling);
        const targets = elements.value.map((x) => x.target);
        targets.forEach(observer.value.observe);
      },
      { deep: true, immediate: true },
    );
  }
  const observe = supported
    ? (element: Element) => {
        const e = unwrap(element);
        observer.value.observe(e);
      }
    : NO_OP;
  const unobserve = supported
    ? (element: Element) => {
        const e = unwrap(element);
        observer.value.unobserve(e);
      }
    : NO_OP;
  const disconnect = () => observer.value.disconnect();
  // if the element is passed we should add hooks
  if (element) {
    // if value is defined it is already being observed
    // if (!element.value) {
    onMounted(() => {
      if (element.value) {
        observe(unref(element) as Element);
      }
    });
    // }
    onUnmounted(() => {
      disconnect();
    });
  }
  // // debug is still work in progress, would be nice to provide some
  // // information about the target
  // /* istanbul ignore next */
  // const debug = () => {
  //   if (elements.value.length === 0) {
  //     (process.env.NODE_ENV !== 'production') && console.warn('[IntersectionObserver] no elements provided, did you mount the component?')
  //     return;
  //   }
  //   // TODO: add border to the elements
  // };
  return {
    supported,
    elements,
    observe,
    unobserve,
    disconnect,
    contentBoxSize,
  };
}
