<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { usePanicButton } from "../../../store/panicButton";
import { dispatch } from "../../../utils/bus";

const store = usePanicButton();
const containerEl = ref<HTMLDivElement | null>(null);
const respondingToAlertsIds = ref<string[]>([]);

type CancelCountdown = { id: string; intervalId: number; timeRemaining: number };

const cancelTimers = ref<CancelCountdown[]>([]);

onMounted(() => {
  dispatch("close-menus", true);
  // nextTick(() => enableFocusTrap(containerEl));
});

watch(
  () => store.alertsToDisplay,
  (newAlertsToDisplay) => {
    newAlertsToDisplay
      .filter((alert) => alert.isCancelled)
      .forEach((cancelledAlert) => {
        if (cancelTimers.value.every((timer) => timer.id !== cancelledAlert.id)) {
          const countdown: CancelCountdown = {
            id: cancelledAlert.id,
            timeRemaining: 5,
            intervalId: window.setInterval(() => {
              const timer = cancelTimers.value.find((timer) => timer.id === cancelledAlert.id);
              if (timer) {
                timer.timeRemaining = timer.timeRemaining - 1;

                if (timer.timeRemaining === 0) {
                  store.removePanicAlert(cancelledAlert.id);
                }
              }
            }, 1000),
          };

          cancelTimers.value.push(countdown);
        }
      });
  },
  { deep: true },
);

function getDateString(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = date.toLocaleDateString("en-GB", options).replace(",", "");

  return formattedDate;
}
</script>
<template>
  <div ref="containerEl" class="panic-alerts-container">
    <div v-for="alert of store.alertsToDisplay" :key="alert.id" class="panic-alert">
      <h2 class="heading">Panic Alert</h2>
      <p>
        <span class="bold">{{ alert.triggeringStaffName }}</span> has pressed the panic button in
        <span class="bold">{{ alert.locationName }}</span
        >.
      </p>
      <p>
        Button pressed:
        <span class="bold">{{ getDateString(alert.alertStartedDateTime) }}</span>
      </p>
      <p
        v-if="
          alert.responses && alert.responses.some((response) => response.action === 'responding')
        "
      >
        <span
          v-for="(response, index) in alert.responses.filter(
            (response) => response.action === 'responding',
          )"
          :key="response.staffId"
          >{{ index !== 0 ? " and " : "" }}<span class="bold">{{ response.staffName }}</span>
        </span>
        {{
          alert.responses.filter((response) => response.action === "responding").length === 1
            ? " is "
            : " are "
        }}
        responding.
      </p>
      <p v-if="alert.isCancelled" class="cancelled">
        This panic alert has been cancelled and will dismiss in
        {{ cancelTimers.find((timer) => timer.id === alert.id)?.timeRemaining }} second{{
          cancelTimers.find((timer) => timer.id === alert.id)?.timeRemaining !== 1 ? "s" : ""
        }}.
      </p>
      <div class="actions">
        <m-button
          :disable="respondingToAlertsIds.includes(alert.id) || alert.isCancelled"
          :on-click="
            () => {
              respondingToAlertsIds.push(alert.id);
              store.respondToAlert(alert.id, 'not-responding').catch(() => {
                const index = respondingToAlertsIds.findIndex((id) => id === alert.id);
                respondingToAlertsIds.splice(index, 1);
              });
            }
          "
          color="secondary"
          >Not responding</m-button
        >
        <m-button
          :disable="respondingToAlertsIds.includes(alert.id) || alert.isCancelled"
          :on-click="
            () => {
              respondingToAlertsIds.push(alert.id);
              store.respondToAlert(alert.id, 'responding').catch(() => {
                const index = respondingToAlertsIds.findIndex((id) => id === alert.id);
                respondingToAlertsIds.splice(index, 1);
              });
            }
          "
          >Respond</m-button
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.panic-alerts-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  background: rgb(0 0 0 / 50%);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: auto;
  padding-bottom: 25dvh;
}

.panic-alert {
  background: white;
  max-width: 420px;
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  padding: 20px;
  transition: var(--transition);

  &:first-of-type {
    margin-top: 10dvh;
  }

  &:only-of-type {
    margin-top: 25dvh;
  }
}

.heading {
  line-height: 1.2;
  margin-bottom: 14px;
  font-size: 22px;
}

p {
  line-height: 1.4;

  + p {
    margin-top: 14px;
  }
}

p.cancelled {
  font-weight: bold;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.bold {
  font-weight: bold;
}
</style>
