<script lang="ts" setup>
import { computed, ref } from "vue";
import { useApp, useAuth, usePanicButton } from "../../../../store";
import SidebarMenu, { MenuItems } from "../../SidebarMenu";

const authStore = useAuth();
const appStore = useApp();
const panicButtonStore = usePanicButton();
const title = "User Settings";
const sidebarMenuRef = ref<typeof SidebarMenu | null>(null);
const items = computed(() => {
  const newItems: MenuItems = [
    {
      label: "Change Workspace",
      permission: true,
      to: {
        name: "tenants",
      },
    },
    {
      label: "My Account",
      permission: true,
      to: "/staff/my-account",
    },
    {
      label: "Keyboard Shortcuts",
      permission: true,
      onClick: () => {
        appStore.setKeyboardShortcusModalVisible(true);
        sidebarMenuRef.value?.close();
      },
    },
    {
      label: "Logout",
      permission: true,
      to: {
        name: "logout",
      },
    },
  ];

  if (panicButtonStore.allAlertsCount > 0) {
    newItems.unshift({
      label: `Active Panic Alerts (${panicButtonStore.allAlertsCount})`,
      permission: true,
      onClick: () => {
        panicButtonStore.showPanicAlertModal();
        sidebarMenuRef.value?.close();
      },
    });
  }

  return newItems;
});
const additionalInfo = computed(() => {
  let data = [];
  if (authStore.currentTenant?.name) {
    data.push({ label: "Organisation", value: authStore.currentTenant.name });
  }

  if (authStore.currentLocationValue?.label) {
    data.push({ label: "Location", value: authStore.currentLocationValue.label });
  }
  return data;
});
</script>
<template>
  <SidebarMenu
    ref="sidebarMenuRef"
    :title="title"
    :items="items"
    :additional-info="additionalInfo"
    role="navigation"
    aria-label="User menu"
  />
</template>
