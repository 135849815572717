<script lang="ts" setup>
import MTimelineBarChart, { TimelineBarValue } from "./MTimelineBarChart.vue";
import MLayoutStack from "../../MLayoutStack/MLayoutStack.vue";
import { generateUUIDv7 } from "../../../../utils/helpers";

const startDate = new Date(2024, 1, 1);
const endDate = new Date(2025, 1, 1);
const regularData: TimelineBarValue[] = [
  {
    id: generateUUIDv7(),
    startDate: { day: 10, month: 1, year: 2024 },
    endDate: { day: 10, month: 3, year: 2024 },
  },
];
const sparseData: TimelineBarValue[] = [];
const fullData: TimelineBarValue[] = [];
</script>

<template>
  <m-layout-stack vertical gap="3">
    <m-timeline-bar-chart :data="regularData" :range="{ start: startDate, end: endDate }" />
    <m-timeline-bar-chart :data="sparseData" :range="{ start: startDate, end: endDate }" />
    <m-timeline-bar-chart :data="fullData" :range="{ start: startDate, end: endDate }" />
    <m-timeline-bar-chart :data="[]" :range="{ start: startDate, end: endDate }" />
  </m-layout-stack>
</template>
