<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
    data-testid="fit-note-presenter"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">Fit Note: {{ item.decision }}</div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <div v-if="getCaseAndCondition()">
      <span class="secondary-text">
        {{ getCaseAndCondition() }}
      </span>
    </div>

    <m-layout-stack
      v-if="
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.linkedProblems.length
      "
      horizontal
      class="q-mt-sm"
    >
      <m-badge
        v-if="
          item.hiddenFromPatientFacingServices &&
          item.hiddenFromPatientFacingServices !== contextHiddenFromPatientFacingServices
        "
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`fit-note-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`fit-note-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type FitNoteJournalItem = {
  entryType: string;
  id: string;
  decision: string;
  validPeriod: string;
  diagnosis: string;
  recordedBy: string;
  recordedByOrganisation: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
  linkedProblems: any[];
  createdDateTime?: string;
};

const props = defineProps<{
  item: FitNoteJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

function getCaseAndCondition() {
  const textAsArray = [props.item.validPeriod, props.item.diagnosis];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
}
</script>
