<template>
  <q-list separator>
    <q-item
      v-for="(thread, index) in threadsFiltered"
      :key="index"
      v-ripple
      clickable
      :active="thread.id === threadSelectedId"
      @click="$emit('thread-selected-id', thread.id)"
    >
      <q-item-section>
        <q-item-label>{{ fetchLabel(thread) }}</q-item-label>
        <q-item-label caption>{{ fetchCaption(thread) }}</q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
import { QItem, QItemSection, QList, QItemLabel } from "quasar";

export default {
  components: {
    QItem,
    QItemSection,
    QList,
    QItemLabel,
  },
  props: {
    threadsFiltered: {
      type: Array,
      default: null,
    },
    threadSelectedId: {
      type: String,
      default: null,
    },
  },

  methods: {
    fetchLabel(thread) {
      let threadItem = thread.threadItems[thread.threadItems.length - 1];

      return this.fetchLabelByType(threadItem, threadItem.type);
    },
    fetchLabelByType(threadItem, type) {
      switch (type) {
        case "inbound-letter":
          return threadItem.inboundLetter.sender;
        case "outbound-sms":
          return "SMS to: " + threadItem.outboundSms.patient.label;
        case "inbound-sms":
          return "SMS from: " + threadItem.inboundSms.patient.label;
        default:
          return "Unsupported type: " + type;
      }
    },
    fetchCaption(thread) {
      let threadItem = thread.threadItems[thread.threadItems.length - 1];

      return this.fetchCaptionByType(threadItem, threadItem.type);
    },
    fetchCaptionByType(threadItem, type) {
      switch (type) {
        case "inbound-letter":
          return threadItem.inboundLetter.summary;
        case "outbound-sms":
          return threadItem.outboundSms.status;
        case "inbound-sms":
          return threadItem.inboundSms.status;
        default:
          return "Unsupported type: " + type;
      }
    },
  },
};
</script>
