<template>
  <div class="m-split-screen" :class="{ 'no-padding': noPadding, half }">
    <div
      v-if="noContentScroll"
      class="content"
      :class="{
        'slide-item-opened': !persistent && showValueTrue,
        'medicus-grey-background': bgGrey,
        'medicus-white-background': !bgGrey,
      }"
    >
      <slot />
    </div>

    <div
      v-else
      class="content scroll-area"
      :class="[bgGrey ? 'medicus-grey-background' : 'medicus-white-background']"
      :style="{ 'slide-item-opened': !persistent && showValueTrue }"
    >
      <slot />
    </div>

    <template v-if="persistent">
      <div v-if="noContentScroll" class="persistent-item">
        <slot name="item" v-bind="{ item: modelValue }" />
      </div>
      <div v-else class="persistent-item scroll-area">
        <slot name="item" v-bind="{ item: modelValue }" />
      </div>
    </template>
    <template v-else>
      <transition>
        <m-slideover-card v-if="showValueTrue" class="slide-item" :name="title" @close="close">
          <template v-if="$slots['item-title']" #header>
            <slot name="item-title" v-bind="{ item: modelValue }">
              <h1 class="title">{{ title }}</h1>
            </slot>
          </template>
          <slot name="item" v-bind="{ item: modelValue, close }" />
        </m-slideover-card>
      </transition>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, provide, ref, watch } from "vue";
import { NO_OP } from "vue-composable";
import MSlideoverCard from "../MSlideoverCard";

export default defineComponent({
  name: "MSplitScreen",
  components: {
    MSlideoverCard,
  },

  props: {
    modelValue: [Array, Object, Boolean],

    itemClass: String,

    title: String,

    persistent: Boolean,
    noPadding: Boolean,
    noContentScroll: Boolean,
    bgGrey: Boolean,

    half: Boolean,
  },

  setup(props, { emit }) {
    const setPageContentStyle = inject("setPageContentStyle", NO_OP);
    const empty = computed(() => !!props.modelValue);

    const showValueTrue = ref(!!props.modelValue);

    watch(
      () => !!props.modelValue,
      (v) => {
        showValueTrue.value = v;
      },
    );

    const showValue = computed({
      get() {
        return !!props.modelValue;
      },
      set() {
        showValueTrue.value = false;
        setTimeout(() => emit("update:modelValue", null), 300);
      },
    });

    provide("closeSlideover", () => (showValue.value = false));

    const close = () => (showValue.value = false);

    return {
      setPageContentStyle,
      empty,

      showValue,
      showValueTrue,

      close,
    };
  },
});
</script>

<style lang="scss">
.m-split-screen {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;

  &.no-padding {
    .content {
      padding: 0 !important;
    }
  }

  &.page-padding {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .content {
    flex: 1 0 33%;

    padding: 16px;
    padding-bottom: 0px;

    &.slide-item-opened {
      padding-right: 0px;
    }
  }

  &.half {
    .content {
      flex: 1 0 50%;
    }
    .persistent-item {
      flex: 1 1 50%;
    }
  }

  .persistent-item {
    flex: 2 1 66%;
    .scroll-area {
      height: 100%;
    }
  }

  .slide-item {
    // position: absolute;
    right: 0;
    height: 100%;
    max-height: 100%;

    // box-shadow: 0px 2px 16px 16px rgba(0, 0, 0, 0.08);

    .m-form-section,
    .m-simple-table {
      border-left: none;
      border-right: none;
    }
  }
}
</style>
