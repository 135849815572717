import { computed, getCurrentInstance } from "vue";

export function useInheritBackgroundColor() {
  const { proxy } = getCurrentInstance()!;

  return computed(() => {
    let parent: HTMLElement | null = proxy?.$el;

    while ((parent = parent?.parentElement ?? null)) {
      const bg = window.getComputedStyle(parent).backgroundColor;
      if (bg !== "rgba(0, 0, 0, 0)") {
        return bg;
      }
    }
    return "var(--medicus-background)";
  });
}
