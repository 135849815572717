<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View Investigation Request"
    @click="investigationRequestOverview()"
  >
    <InvestigationRequestJournalElement :item="entry.data" :read-only="false" />
  </m-card>
</template>
<script setup lang="ts">
import InvestigationRequestJournalElement from "../elements/InvestigationRequestJournalElement.vue";
import MCard from "../../MCard";
import { PatientJournalRecord } from "../types";
import { openSlideover } from "../../../../composables/dialog/drawer";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function investigationRequestOverview() {
  return props.readOnly
    ? openSlideover(`/clinical/investigation-request/overview/${props.entry.id}`, {
        close: true,
      })
    : undefined;
}
</script>
