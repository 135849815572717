<script setup lang="ts">
import { provide } from "vue";
import { useVModel } from "vue-composable";
import MAction from "../MAction";
import MDynamic from "../MDynamic";

const props = defineProps<{
  modelValue?: ({ url: string } & Record<string, any>) | null | undefined;
}>();

const value = useVModel(props, "modelValue");

function close() {
  value.value = null;
}

provide("close", close);
</script>
<template>
  <m-layout-stack
    v-if="value"
    class="drilldown-component medicus-grey-background"
    style="z-index: 100"
  >
    <m-action :model-value="{ label: '&lt; Back', onClick: close }" />
    <m-dynamic v-bind="value" />
  </m-layout-stack>
</template>
<style lang="scss">
.drilldown-component {
  // absolute top-0 left-0 overflow-auto medicus-grey-background q-pr-sm
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  padding: 1rem;

  max-width: 100%;
  max-height: 100%;

  height: 100%;
  width: 100%;
}
</style>
