<script setup lang="ts">
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { issuePasswordReset } from "../../api/iam";
import MInput from "../../components/medicus/inputs/MInput";
import MButton from "../../components/medicus/MButton";
import MSeparator from "../../components/medicus/MSeparator";

const route = useRoute();

const email = ref(route?.query.username?.toString() ?? null);
const error = ref(false);
const sent = ref(false);

// Regular expression for RFC compliant email validation:
// 1. ^[a-zA-Z0-9._%+-]+: Starts with one or more alphanumeric characters, dots, underscores, percentage signs, plus signs, or hyphens.
// 2. @: Followed by an '@' symbol.
// 3. [a-zA-Z0-9.-]+: Followed by one or more alphanumeric characters, dots, or hyphens.
// 4. \.: Followed by a dot ('.').
// 5. [a-zA-Z]{2,}$: Ends with two or more alphabetical characters (top-level domain).
const isValidEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

async function doForgotPassword() {
  if (!email.value) {
    error.value = true;
    return;
  }

  try {
    await issuePasswordReset({
      username: email.value,
    });
    sent.value = true;
  } catch (e) {
    // Show sent message, even though the email doesn't exist
    if (e?.response?.status === 401) {
      sent.value = true;
      return;
    }

    error.value = e?.response?.data?.errors?.command ?? true;
  }
}

const router = useRouter();
function doBackLogin() {
  router.push({
    name: "login",
  });
}
</script>
<template>
  <div class="flex flex-col gap-3">
    <template v-if="sent">
      <p class="sub-title">
        <span>
          If a user account exists for <b>{{ email }}</b>
          then we have sent an email with instructions on how to reset your password.
        </span>
      </p>
      <m-button color="secondary" @click="doBackLogin">Back to login page</m-button>
    </template>
    <template v-else-if="error === true">
      <p class="sub-title">
        <span>
          An email could not be sent to <b>{{ email }}</b
          >. Please check the email address entered.
        </span>
      </p>
    </template>
    <template v-else>
      <p class="sub-title">
        Enter the email address you use to login and we'll send instructions on how to reset your
        password.
      </p>
      <m-input
        v-model="email"
        autocomplete="username"
        data-cy="emailField"
        type="email"
        @keydown.enter="doForgotPassword"
      />

      <span v-if="Array.isArray(error)" type="error" class="error-text">
        <p v-for="errorTxt in error" :key="errorTxt">{{ errorTxt }}</p>
      </span>

      <m-button
        class="w-full"
        data-cy="signupButton"
        :disable="!email || !isValidEmailRegex.test(email)"
        @click="doForgotPassword"
      >
        Send reset instructions
      </m-button>

      <m-separator text="or" />

      <m-button color="secondary" @click="doBackLogin">Back to login page</m-button>
    </template>
  </div>
</template>
<style lang="scss">
.go-to-login {
  text-decoration-line: underline;
  color: var(--grey-darkest);
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
