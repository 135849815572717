<template>
  <div style="width: 100%">
    <div class="row" style="border-bottom: 1px solid var(--border-colour)">
      <div class="col-12 q-pa-xl">
        <h4 class="q-mb-sm q-mt-none">
          {{ selectedTask ? selectedTask.taskDisplayName : "" }} Tasks
        </h4>
      </div>
    </div>
    <div class="row" style="height: calc(100% - 145px)">
      <div
        class="col-xs-12 col-md-3"
        style="border-right: 1px solid var(--border-colour); height: 100%"
      >
        <MTaskListSideNav
          :selected-task="selectedTask"
          :selected-task-item-id="selectedTaskItemId"
          :selected-task-items="selectedTaskItems"
          :display-filter-option="false"
          :status-options="
            selectedTask ? selectedTask.statusOptions.filter((v) => v.value === 'todo') : null
          "
          @task-item-selected-id="selectTaskItemId"
          @task-status-selected="selectTaskStatus"
        />
      </div>
      <div v-if="selectedTaskItem" class="col-xs-12 col-md-9">
        <MContactPatientTask
          v-if="selectedTask.taskName === 'contact-patient'"
          :task-item="selectedTaskItem"
          :staff="staff"
        />
        <div v-else>unsupported task type yet. Please add the task type implementation above.</div>
      </div>
    </div>
  </div>
</template>

<script>
import MTaskListSideNav from "./MtaskListSideNav.vue";
import MContactPatientTask from "./tasks/MContactPatientTask.vue";

export default {
  name: "MTaskListHomepage",

  components: { MTaskListSideNav, MContactPatientTask },

  props: {
    tasks: {
      type: Array,
      default: null,
    },
    staff: {
      type: Array,
      default: null,
    },
  },

  data: () => ({
    selectedTaskId: null,
    selectedTaskItemId: null,
    selectedTaskStatus: [],
  }),

  computed: {
    selectedTask() {
      let task = this.tasks.filter((v) => v.taskName.indexOf(this.selectedTaskId) > -1);

      return task.length > 0 ? task[0] : null;
    },
    selectedTaskItems() {
      return this.selectedTask
        ? this.selectedTask.items.filter((v) => {
            let result = false;

            this.selectedTaskStatus.forEach((status) => {
              if (!result) {
                result = v.status.value.indexOf(status.value) > -1;
              }
            });

            return result;
          })
        : [];
    },
    selectedTaskItem() {
      let result = this.selectedTask
        ? this.selectedTask.items.filter((v) => v.id.indexOf(this.selectedTaskItemId) > -1)
        : null;

      return result ? result[0] : null;
    },
  },

  watch: {
    $route(newRoute) {
      if (newRoute.hasOwnProperty("query")) {
        this.checkQuery(newRoute.query);
      }
    },
  },

  mounted() {
    this.$dispatch("showSubnav", true);

    if (this.$route.hasOwnProperty("query")) {
      this.checkQuery(this.$route.query);
    }
  },

  methods: {
    selectTaskItemId(taskItemId) {
      this.selectedTaskItemId = taskItemId;

      this.$router.push({
        query: { task: this.selectedTaskId, taskItem: taskItemId },
      });
    },
    selectTaskStatus(status) {
      this.selectedTaskStatus = status;
    },
    checkQuery(query) {
      if (query.hasOwnProperty("task")) {
        this.selectedTaskId = query.task;
      }
      if (query.hasOwnProperty("taskItem")) {
        this.selectedTaskItemId = query.taskItem;
      }

      if (!query.hasOwnProperty("task")) {
        this.$router.push({
          query: { task: "contact-patient" },
        });
      }
    },
  },
};
</script>
