<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        {{ item.type }}{{ item.value ? ":" : "" }} {{ item.value
        }}{{ item.approximateValue ? " (approximate)" : "" }}
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <m-layout-stack
      v-if="
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.linkedProblems.length ||
        item.isExplicitlyIncludedInSCR
      "
      horizontal
      class="q-mt-sm"
    >
      <m-badge
        v-if="item.isExplicitlyIncludedInSCR"
        text="Explicitly included in SCR"
        gray
        data-testid="care-record-element-explicitly-included-badge"
      />
      <m-badge
        v-if="
          item.hiddenFromPatientFacingServices &&
          item.hiddenFromPatientFacingServices !== contextHiddenFromPatientFacingServices
        "
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`observation-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`observation-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type ObservationJournalItem = {
  entryType: string;
  id: string;
  type: string;
  value: string;
  approximateValue: boolean;
  additionalInformation?: string;
  recordedBy: string;
  recordedByOrganisation: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isExplicitlyIncludedInSCR: boolean;
  isRetrospectivelyAmended: boolean;
  linkedProblems: any[];
  createdDateTime: any;
};

const props = defineProps<{
  item: ObservationJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
