<template>
  <m-labeled-text class="m-audio-input" :instructions="props.instructions">
    <div class="audio-content">
      <m-layout-stack class="items-center" no-gap>
        <!-- <audio ref="audioRef" /> -->
        <template v-if="recording">
          <p class="current-length">{{ lengthString }}</p>
          <svg
            v-if="recording"
            height="35"
            width="250"
            fill="#0099FF"
            :viewBox="`0 0 1700 235`"
            aria-hidden="true"
          >
            <!-- <g transform="scale(1, 1)"> -->
            <rect
              v-for="(h, i) in bars"
              :key="`bar_${i}`"
              :x="`${(i * 100) / bars.length}%`"
              :y="255 - h"
              width="20"
              :height="h"
            />
            <!-- </g> -->
          </svg>
        </template>
        <template v-else>
          <p class="current-length">{{ SecondsToAudioLength(player.position) }}</p>
          <p class="total-length">{{ SecondsToAudioLength(player.duration) }}</p>
          <q-slider
            v-model="player.position"
            :min="0"
            :max="player.duration"
            :label-value="SecondsToAudioLength(player.position)"
            :readonly="!player.duration"
            :label="!!player.duration"
            dense
            aria-label="Recording"
          />
        </template>
        <!-- <audio v-else :key="downloadUrl" ref="playerEl" controls :src="downloadUrl" preload="auto">
      </audio> -->
      </m-layout-stack>

      <m-separator />

      <m-layout-stack class="audio-controls items-center justify-center" horizontal>
        <template v-if="recording">
          <button type="button" @click="pauseRecording">
            <q-icon name="fa-solid fa-pause" />
            <span class="sr-only">Pause recording</span>
          </button>
        </template>
        <template v-else-if="player.playing">
          <button type="button" @click="player.rewind">
            <q-icon name="fa-solid fa-backward" />
            <span class="sr-only">Rewind</span>
          </button>
          <button type="button" @click="player.pause">
            <q-icon name="fa-solid fa-pause" />
            <span class="sr-only">Pause</span>
          </button>
          <button type="button" @click="player.forward">
            <q-icon name="fa-solid fa-forward" />
            <span class="sr-only">Forward</span>
          </button>
        </template>
        <template v-else>
          <button type="button" style="color: #ff0303" @click="startRecording">
            <q-icon name="fa-solid fa-circle" />
            <span class="sr-only">Start recording</span>
          </button>
          <button type="button" :disabled="!player.duration" @click="player.play">
            <q-icon name="fa-solid fa-play" />
            <span class="sr-only">Play recording</span>
          </button>
          <button type="button" :disabled="!player.duration" @click="player.rewind">
            <q-icon name="fa-solid fa-backward" />
            <span class="sr-only">Rewind</span>
          </button>
          <button type="button" :disabled="!player.duration" @click="player.forward">
            <q-icon name="fa-solid fa-forward" />
            <span class="sr-only">Forward</span>
          </button>

          <button type="button" :disabled="!player.duration" @click="deleteValue">
            <q-icon name="fa-solid fa-trash" />
            <span class="sr-only">Delete recording</span>
          </button>
        </template>
      </m-layout-stack>
    </div>
  </m-labeled-text>
</template>
<script lang="ts" setup>
import { QIcon, QSlider } from "quasar";
import { computed, onBeforeUnmount, reactive, ref, watch } from "vue";
import { NO_OP, useNow, usePromiseLazy } from "vue-composable";
import { useRouter } from "vue-router";

import MLabeledText from "../../MLabeledText";
import MLayoutStack from "../../MLayoutStack";
import MSeparator from "../../MSeparator";

import { useDialog } from "../../../../composables/dialog";
import { useNamedValue } from "./../../../../composables/namedValue";
import { SecondsToAudioLength, useAudioPlayer } from "./../../../../composables/playAudio";

const props = defineProps({
  modelValue: String,

  name: String,

  instructions: String,
});

const value = useNamedValue(props);
const recordedChunks = ref<any[]>([]);

const recorder = ref<MediaRecorder>();
const recording = ref(false);

const context = ref<AudioContext>();

let visualiseCleanup = NO_OP;

const bars = ref<number[]>([]);

async function startRecording() {
  if (recorder.value) {
    resumeRecording();
    return;
  }
  const options = { mimeType: "audio/webm" };
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  const rec = (recorder.value = new MediaRecorder(stream, options));

  rec.addEventListener("dataavailable", function (e) {
    if (e.data.size > 0) {
      recordedChunks.value.push(e.data);
    }
  });
  rec.start();
  recording.value = true;
  visualise(stream);
}
function stopRecording() {
  if (!recorder.value) return;
  recording.value = false;
  recorder.value.pause();
  recorder.value.stream.getAudioTracks().forEach((x) => x.stop());
  visualiseCleanup();
}

function pauseRecording() {
  if (!recorder.value) return;
  recorder.value.requestData();
  recorder.value.pause();
  // recorder.value.stream.getAudioTracks().forEach((x) => x.stop());
  recorder.value.stream.getAudioTracks().forEach((x) => (x.enabled = false));
  recording.value = false;
}
function resumeRecording() {
  if (!recorder.value) return;
  recorder.value.stream.getAudioTracks().forEach((x) => (x.enabled = true));
  recorder.value.resume();
  recording.value = true;
}

function visualise(stream: MediaStream) {
  if (!context.value) {
    context.value = new AudioContext();
  }
  const analyser = context.value.createAnalyser();
  const source = context.value.createMediaStreamSource(stream);
  source.connect(analyser);

  visualiseCleanup = watch(recording, (r) => {
    if (r) {
      renderBars();
    }
  });

  function renderBars() {
    if (!recording.value) {
      // source.disconnect();
      // analyser.disconnect();
      return;
    }
    requestAnimationFrame(renderBars);
    const array = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(array);
    const barCount = 42;
    for (let i = 0; i < barCount; i++) {
      bars.value[i] = array[i] / 2;
    }
  }
  renderBars();
}

onBeforeUnmount(stopRecording);

const recordedBlob = computed(() => {
  if (!recordedChunks.value || !recordedChunks.value.length) return;
  return new Blob([...recordedChunks.value], { type: "audio/webm; codecs=opus" });
});

const downloadUrl = computed(() => {
  const blob = recordedBlob.value;
  if (!blob) return;
  return window.URL.createObjectURL(blob);
});

const { result: base64Content, exec: processBlob } = usePromiseLazy(blobToBase64);
watch(base64Content, (r) => (value.value = r?.split(",")[1]));

watch(recordedBlob, processBlob);

const length = ref<number>(0);
const startedAt = ref<number>();
const { now } = useNow({ refreshMs: 100 });

const recordingTime = computed(() => {
  const ms = startedAt.value ? now.value - startedAt.value : 0;
  if (ms < 0) {
    return length.value;
  }
  return length.value + ms;
});

const lengthString = computed(() => {
  return msToLength(recordingTime.value);
});

function msToLength(s: number | undefined | null) {
  return SecondsToAudioLength(s ? s / 1000 : s);
}

watch(
  recording,
  (r) => {
    if (r) {
      startedAt.value = Date.now();
    } else {
      const start = startedAt.value!;
      const end = Date.now();
      startedAt.value = undefined;
      const ms = end - start;

      length.value += ms;
    }
  },
  { flush: "sync" },
);

function blobToBase64(blob: Blob | undefined): Promise<string> {
  if (!blob) return Promise.resolve("");
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

// async function getBase64() {
//   const b = await blobToBase64(
//     new Blob(recordedChunks.value!, { type: "audio/webm; codecs=opus" })
//   );
//   console.log("base64", b);
// }

const player = reactive(useAudioPlayer());
watch(downloadUrl, (u) => {
  player.audioUrl = u;
});

const router = useRouter();
const dialog = useDialog();
function deleteValue() {
  dialog.value = {
    title: "Delete Dictation?",
    text: "Are you sure you want to delete this dictation?",
    cancelLabel: "No, cancel",
    type: "dialog",
    okLabel: "Yes, delete",
    router,
    reject() {
      dialog.value = undefined;
    },
    resolve() {
      dialog.value = undefined;
      recordedChunks.value = [];
      length.value = 0;

      stopRecording();
      recorder.value = undefined;
    },
  };
}
</script>
<style lang="scss">
.m-audio-input {
  .audio-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    flex: 1 1 auto;

    padding: 10px;
    border: 1px solid var(--border-colour);
    border-radius: 6px;
  }

  .current-length {
    font-weight: bold;
    font-size: 18px;
    color: var(--text-color);
  }
  .total-length {
    font-size: 14px;
    color: var(--text-color);
  }

  button {
    background: white;
    border: 1px solid #cdcdcd;
    box-sizing: border-box;
    border-radius: 4px;
    color: var(--text-color);

    height: 40px;
    width: 40px;

    text-align: center;

    &:hover:not([disabled]) {
      background: #e7eaee;
      cursor: pointer;
    }
  }
}
</style>
