<script lang="ts" setup>
import { computed, ref, toRef, watch } from "vue";
import { useFormat } from "vue-composable";
import { ExtractComponentProps } from "../../../utils/types";
import MAction from "../MAction";
import MLayoutStack from "../MLayoutStack";
import MInboxPreview from "../MInboxPreview";
import MPage from "./../MPage";
import MSplitScreen from "./../MSplitScreen";
import { InboxItem } from "./types";
import MInboxItem from "../MInboxItem/MInboxItem.vue";

const props = defineProps({
  ...(MPage.props as ExtractComponentProps<typeof MPage>),
  items: Array as () => InboxItem[],
  modelValue: Object,
  description: String,
  noPadding: Boolean,
  noScroll: Boolean,
  itemTitle: String,
  showFilter: Boolean,
  showingText: String,
  itemCountText: {
    type: String,
    default: "Showing {0} item(s)",
  },
  dateCustomText: String,
  createAction: Object,
  hidePatientBanner: Boolean,
});

const emit = defineEmits({
  "update:modelValue": (_item: any) => true,
});

const setItem = (item: any) => {
  emit("update:modelValue", item);
};

const itemsShowingText = useFormat(
  toRef(props, "itemCountText"),
  computed(() => [props.items?.length || 0]),
);

const inboxPage = ref<InstanceType<typeof MPage> | null>();

const snoozedDate = ref(props.modelValue?.snoozeUntil);

watch(
  () => props.modelValue,
  (s) => (snoozedDate.value = s?.snoozeUntil),
);
</script>
<template>
  <m-page
    ref="inboxPage"
    class="m-inbox-page"
    :description="description"
    no-overflow
    no-padding
    bg-white
    no-gutter
    hide-header
  >
    <m-split-screen
      class="inbox-split-screen"
      :model-value="modelValue"
      persistent
      no-padding
      no-content-scroll
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <slot name="items" v-bind="{ items }">
        <div class="flex flex-col gap-1 flex-nowrap h-full">
          <h1 v-if="title" class="title">
            {{ title }}
          </h1>

          <slot name="before:item" />

          <m-layout-stack class="justify-between items-center q-mr-md" gap="1" horizontal>
            <span class="inbox-items-showing" data-testid="inbox-items-showing">
              {{ itemsShowingText }}
            </span>
            <div>
              <m-action :model-value="createAction" />
            </div>
          </m-layout-stack>
          <div class="inbox-items scroll-area">
            <div
              v-for="(item, i) in items"
              :key="`${i}_${item.id}_${item.assignee?.id}_${item.snoozeUntil}`"
            >
              <slot
                name="item"
                v-bind="{
                  items,
                  item,
                  index: i,
                  selected: item === modelValue,
                  setItem: () => setItem(item),
                }"
              >
                <m-inbox-item
                  v-slot="bindings"
                  v-bind="item"
                  :selected="item?.id === modelValue?.id"
                  @click="setItem(item)"
                >
                  <slot name="item:content" v-bind="bindings" />
                </m-inbox-item>
              </slot>
            </div>
            <div
              v-if="items?.length === 0"
              class="q-mx-md empty-text flex items-center justify-center h-full"
            >
              <slot name="empty:items" />
            </div>
          </div>
        </div>
      </slot>

      <template #item="itemBind">
        <div class="h-full flex flex-col flex-nowrap">
          <template v-if="itemBind.item">
            <m-inbox-preview
              :hide-patient-banner="hidePatientBanner"
              :item="itemBind.item"
              :no-padding="noPadding"
              :item-title="itemBind.item.name"
            >
              <slot v-bind="itemBind" />
            </m-inbox-preview>
          </template>

          <template v-else>
            <div class="inbox-item-content flex items-center justify-center empty-text">
              No chat has been selected.
            </div>
          </template>
        </div>
      </template>
    </m-split-screen>
  </m-page>
</template>
<style lang="scss">
.m-inbox-page {
  padding-top: 0;

  .content {
    max-width: 320px !important;

    .top-border > .m-inbox-page-item {
      border-top: 1px solid var(--border-colour);
    }
  }

  .padded-content {
    padding: 16px;
    padding-bottom: 0px;
  }

  .inbox-split-screen {
    height: 100%;

    .content .title {
      margin: 10px 16px 4px 16px;
    }
  }

  .persistent-item {
    border: 1px solid var(--border-colour);
    border-bottom: none;
    border-top: none;
  }

  .inbox-items-showing {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 19px */

    color: var(--grey-darkest);

    margin-left: 1rem;
    display: block;
  }

  .inbox-item-content {
    height: 100%;
    overflow: hidden;
    background: var(--grey-lightest);
  }
}

.filter-title {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;

  color: var(--text-color);
}
</style>
