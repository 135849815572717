<template>
  <div
    class="appointment-diary"
    :class="$attrs.class"
    :data-date="diary.summary.startDateTime.split(' ')[0]"
  >
    <h4 v-if="heading" ref="headingEl" class="diary-title">{{ heading }}</h4>

    <!-- Info -->
    <div class="diary-info">
      <div class="diary-info-icon">
        <q-icon name="fa-light fa-clock" title="Diary Time Range" />
      </div>
      <div class="diary-info-value">{{ durationText }}</div>

      <template
        v-if="!diary.summary.supportsTimedAppointments && diary.summary.defaultDeliveryMode"
      >
        <div class="diary-info-icon">
          <q-icon v-if="defaultDeliveryModeIcon" :name="defaultDeliveryModeIcon" />
        </div>
        <div class="diary-info-value">{{ diary.summary.defaultDeliveryMode?.label }}</div>
      </template>

      <template v-if="!hideLocation">
        <div class="diary-info-icon">
          <span class="sr-only">Location</span>
          <q-icon name="fa-solid fa-map-marker-alt" title="Location" />
        </div>
        <div class="diary-info-value">{{ formattedLocation }}</div>
      </template>

      <div class="diary-info-icon">
        <q-icon name="fa-solid fa-calendar-check" aria-hidden="true" title="Booked" />
      </div>
      <div class="diary-info-value">
        <span v-if="diary.summary.booked.count"
          >Booked: {{ diary.summary.booked.count }} patients ({{ diary.summary.booked.duration }})
        </span>

        <span v-else>No patients booked</span>
      </div>

      <div class="diary-info-icon">
        <q-icon name="fa-light fa-hourglass-half" aria-hidden="true" />
      </div>
      <div class="diary-info-value">
        <span v-if="diary.summary.available.duration"
          >Unallocated time: {{ diary.summary.available.duration }}
        </span>
        <span v-else class="q-ml-xs">No unallocated time</span>
      </div>
    </div>

    <!-- Actions -->
    <m-dropdown-button
      v-if="dropdownOptions.length && diary.summary.status.isActive"
      class="diary-actions"
      icon="fa-solid fa-ellipsis-vertical"
      ghost
      color="secondary"
      icon-only
      label="Show diary options"
      menu
      :options="dropdownOptions"
    />

    <!-- Notifications -->
    <section class="diary-notifications">
      <m-banner v-if="diary.summary.isAtCapacity" type="warning">
        <span>All available time booked.</span>
      </m-banner>

      <m-banner
        v-if="
          diary.summary.supportsTimedAppointments &&
          diary.summary.supportsUntimedAppointments &&
          diary.summary.hasHighPriorityUntimedAppointments
        "
        type="warning"
      >
        <span>High priority untimed appointments.</span>
      </m-banner>
    </section>

    <section class="appointments">
      <!-- Timed Appointments -->
      <div
        v-for="(entry, i) in formattedEntries"
        :key="`slot-${i}`"
        class="appointments-group"
        :class="{ 'rule-set-calendar-entry': entry.diaryEntryType?.isRuleSet }"
      >
        <template v-if="entry.diaryEntryType?.isRuleSet">
          <div v-if="diary.summary.supportsTimedAppointments" class="slots">
            <template
              v-for="(ruleSetEntry, index) in entry.entries"
              :key="`supportsTimedAppointments-${index}`"
            >
              <component
                :is="displayOnly ? 'div' : 'button'"
                v-if="ruleSetEntry.slotType.isSlot || ruleSetEntry.slotType.isSqueezeInSlot"
                :class="`slot available-slot ${!displayOnly ? 'interactive medicus-outline' : ''}`"
                @click="displayOnly ? undefined : handleSlotClicked(ruleSetEntry)"
                @click.right="
                  displayOnly ? undefined : handleAvailableSlotRightClick($event, ruleSetEntry)
                "
              >
                <div class="sr-only">
                  Book starting {{ ruleSetEntry.start }} from
                  {{ ruleSetEntry.formattedDuration }}
                </div>
                <div class="slot-info no-hover" aria-hidden="true">
                  <p class="start-time">{{ ruleSetEntry.start }}</p>
                  <p class="duration">
                    {{ ruleSetEntry.formattedDuration }} •
                    {{ ruleSetEntry.appointmentType.name }}
                  </p>
                </div>
                <div class="overlay" aria-hidden="true">
                  <span v-if="ruleSetEntry.slotType.isSlot"
                    >{{ slotLabel }} {{ ruleSetEntry.start }}</span
                  >
                  <span v-else-if="ruleSetEntry.slotType.isSqueezeInSlot">Squeeze in here</span>
                </div>
              </component>

              <div
                v-else-if="ruleSetEntry.slotType.isSlotReservation"
                class="slot slot-reservation"
              >
                <p>Booking In Progress</p>
              </div>

              <m-appointment-card
                v-else-if="ruleSetEntry.slotType.isUnavailabilityPeriod"
                class="slot unavailability-period"
                allow-right-click
                @click="handleUnavailabilityPeriodClick(ruleSetEntry)"
              >
                <div>
                  <p>
                    <strong>{{ ruleSetEntry.start }}</strong> <span> {{ ruleSetEntry.title }}</span>
                  </p>
                  <p style="color: var(--theme-grey-darkest)">
                    <span v-if="ruleSetEntry.description"
                      >{{ ruleSetEntry.description }} ({{ ruleSetEntry.formattedDuration }})</span
                    >
                    <span v-else>{{ ruleSetEntry.formattedDuration }}</span>
                  </p>
                </div>

                <template #context-menu-options>
                  <m-action-list ref="list" :items="getEntryActions(ruleSetEntry)" />
                </template>
              </m-appointment-card>

              <m-appointment-card
                v-else-if="ruleSetEntry.slotType.isAppointment"
                class="slot booked-item"
                :appointment="ruleSetEntry"
                :hide-reason-for-appointment="hideReasonsForAppointments"
                :hide-move-appointment-to-same-diary-option="
                  !diary.summary.supportsTimedAppointments
                "
                @click="$emit('appointment-clicked', ruleSetEntry)"
              />
            </template>
          </div>
        </template>

        <div v-else class="regular-slots-container">
          <div class="slots">
            <template v-for="(regularSlot, index) in entry" :key="index">
              <component
                :is="displayOnly ? 'div' : 'button'"
                v-if="regularSlot.slotType.isSlot || regularSlot.slotType.isSqueezeInSlot"
                :class="`slot available-slot ${!displayOnly ? 'interactive medicus-outline' : ''}`"
                @click="displayOnly ? undefined : handleSlotClicked(regularSlot)"
                @click.right="
                  displayOnly ? undefined : handleAvailableSlotRightClick($event, regularSlot)
                "
              >
                <div class="sr-only">
                  Book {{ heading }} Appointment, {{ regularSlot.start }} for
                  {{ regularSlot.formattedDuration }}
                </div>

                <div class="slot-info no-hover" aria-hidden="true">
                  <p class="start-time">{{ regularSlot.start }}</p>
                  <p class="duration">
                    {{ regularSlot.formattedDuration }} •
                    {{ regularSlot.appointmentType.name }}
                  </p>
                </div>

                <div class="overlay" aria-hidden="true">
                  <span v-if="regularSlot.slotType.isSlot"
                    >{{ slotLabel }} {{ regularSlot.start }}</span
                  >
                  <span v-else-if="regularSlot.slotType.isSqueezeInSlot">Squeeze in here</span>
                </div>
              </component>

              <div v-else-if="regularSlot.slotType.isSlotReservation" class="slot slot-reservation">
                <p>Booking In Progress</p>
              </div>

              <m-appointment-card
                v-else-if="regularSlot.slotType.isUnavailabilityPeriod"
                allow-right-click
                class="slot unavailability-period"
                @click="handleUnavailabilityPeriodClick(regularSlot)"
              >
                <div>
                  <p>
                    <strong>{{ regularSlot.start }}</strong> <span> {{ regularSlot.title }}</span>
                  </p>
                  <p style="color: var(--theme-grey-darkest)">
                    <span v-if="regularSlot.description"
                      >{{ regularSlot.description }} ({{ regularSlot.formattedDuration }})</span
                    >
                    <span v-else>{{ regularSlot.formattedDuration }}</span>
                  </p>
                </div>

                <template #context-menu-options>
                  <m-action-list ref="list" :items="getEntryActions(regularSlot)" />
                </template>
              </m-appointment-card>

              <m-appointment-card
                v-else-if="regularSlot.slotType.isAppointment"
                class="slot booked-item"
                :appointment="regularSlot"
                :hide-reason-for-appointment="hideReasonsForAppointments"
                @click="$emit('appointment-clicked', regularSlot)"
              />
            </template>
          </div>
        </div>
      </div>

      <!-- Untimed Appointments -->
      <template v-if="diary.summary.canBookUntimedAppointments || diary.untimedAppointments.length">
        <div
          v-if="diary.summary.supportsUntimedAppointments"
          class="appointments-group untimed-appointments-group"
        >
          <div
            v-if="diary.summary.supportsTimedAppointments"
            class="appointments-group-title"
            :style="{ top: headingHeight + 'px' }"
          >
            <p>Untimed Appointments</p>

            <div class="diary-info">
              <div class="diary-info-icon">
                <q-icon v-if="defaultDeliveryModeIcon" :name="defaultDeliveryModeIcon" />
              </div>
              <div class="diary-info-value">{{ diary.summary.defaultDeliveryMode?.label }}</div>
            </div>
          </div>

          <p
            v-if="!displayOnly && !diary.untimedAppointments.length"
            class="empty-text text-center q-mt-sm"
          >
            There are no appointments
          </p>

          <div class="slots">
            <div
              v-for="appointment in diary.untimedAppointments"
              :key="appointment.id"
              class="appointment q-mb-md"
            >
              <m-appointment-card
                class="booked-item"
                :appointment="appointment"
                @click="$emit('appointment-clicked', appointment)"
              />
            </div>
          </div>

          <div
            v-if="!displayOnly && diary.summary.canBookUntimedAppointments"
            class="book-untimed-appointment"
            :class="{ flex: diary.untimedAppointments.length }"
          >
            <m-button
              class="book-untimed-appointment-button"
              :disabled="displayOnly"
              ghost
              label="Book untimed appointment"
              @click="$emit('book-untimed-appointment', diary)"
            />
          </div>
        </div>
      </template>
    </section>
    <MMenu v-model="isContextMenuShowing" :target="contextMenuTarget" no-auto-open>
      <m-action-list :items="slotActions" />
    </MMenu>
  </div>
</template>

<script setup lang="ts">
import { QIcon, date } from "quasar";
import MBanner from "../../MBanner/MBanner.vue";
import MButton from "../../MButton/MButton.vue";
import MActionList, { ActionListItems } from "../../MActionList/MActionList.vue";
import MMenu from "../../MMenu";
import MDropdownButton from "../../MDropdownButton";
import MAppointmentCard from "../MAppointmentCard/MAppointmentCard.vue";
import { AppointmentDiary, FormattedSlot, RawSlot, RuleSet } from "./types";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { AppointmentDeliveryMode } from "../MAppointmentCard";
import { showDialog } from "../../../../utils/dialog";

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    diary: AppointmentDiary;
    title?: string;
    heading?: string;
    hideHeader?: boolean;
    hideLocation?: boolean;
    displayOnly?: boolean;
    showDiaryControls: boolean;
    allowAppointmentContextMenu?: boolean;
    slotLabel?: string;
    hideReasonsForAppointments?: boolean;
  }>(),
  {
    slotLabel: "Book",
  },
);

const emit = defineEmits([
  "cancel-diary",
  "edit-diary",
  "slot-clicked",
  "appointment-clicked",
  "squeeze-patient-in",
  "book-untimed-appointment",
  "edit-slot-appointment-type",
  "convert-slot-to-break",
  "convert-slot-to-rota-activity-assignment",
  "remove-break",
  "remove-rota-activity-assignment",
  "print-diary",
  "rota-activity-assignment-clicked",
]);

const observer = ref<ResizeObserver | null>(null);
const headingHeight = ref<number | null>(null);
const isContextMenuShowing = ref<boolean>(false);

// Template refs
const headingEl = ref<HTMLElement>();
const contextMenuTarget = ref<InstanceType<typeof HTMLElement> | null>(null);
const contextMenuSlot = ref<RawSlot | null>(null);

const slotActions = computed<ActionListItems>(() => {
  const items: ActionListItems = [
    {
      label: "Change appointment type",
      onClick: editSlotAppointmentType,
    },
  ];

  if (props.diary.summary.assignees.length) {
    items.push(
      {
        label: "Convert slot to break",
        onClick: convertSlotToBreak,
      },
      {
        label: "Convert slot to other activity",
        onClick: convertSlotToRotaActivityAssignment,
      },
    );
  }

  return items;
});

function getEntryActions(entry): ActionListItems {
  const items: ActionListItems = [];

  if (entry.eventEntityType.isBreakAssignment) {
    items.push({
      label: "Remove break",
      onClick: () => removeBreak(entry.eventId),
    });
  }

  if (entry.eventEntityType.isRotaActivityAssignment) {
    items.push({
      label: `Remove ${entry.title}`,
      onClick: () => removeRotaActivityAssignment(entry.eventId),
    });
  }

  return items;
}

const dropdownOptions = computed(() => {
  let options = [];
  if (props.showDiaryControls) {
    options = [
      {
        label: "Edit diary",
        click: () => emit("edit-diary", props.diary),
      },
      props.diary.summary.canBeCancelled && {
        label: "Cancel diary",
        click: cancelAppointmentDiary,
        disabled: !props.diary.summary.canBeCancelled,
      },
      {
        label: "Print diary",
        click: () => emit("print-diary", props.diary),
      },
    ].filter(Boolean) as Array<{ label: string } & Record<string, any>>;
  }

  if (props.allowAppointmentContextMenu) {
    options.unshift({
      label: "Squeeze patient in",
      click: () => emit("squeeze-patient-in", props.diary),
    });
  }

  return options;
});

const defaultDeliveryModeIcon = computed((): string | undefined => {
  return deduceDeliveryModeIcon(props.diary?.summary?.defaultDeliveryMode);
});

const formattedLocation = computed((): string | null => {
  let location: string | undefined = props.diary.summary.site?.name;
  if (!location && !props.diary.summary.room?.name) {
    return "None recorded";
  } else if (location && !props.diary.summary.room?.name) {
    return location;
  } else if (!location && props.diary.summary.room?.name) {
    return props.diary.summary.room?.name;
  }
  return `${location}, ${props.diary.summary.room?.name}`;
});

const formattedEntries = computed((): any[] => {
  if (props.diary.entries.length === 0) return [];

  const entries = [];
  let slotGroup = [];

  for (const [i, entry] of props.diary.entries.entries()) {
    if (entry.diaryEntryType.isSlot) {
      slotGroup.push(formatSlot(entry as RawSlot));

      const nextEntry = props.diary.entries[i + 1];
      if (nextEntry === undefined || nextEntry.diaryEntryType.isRuleSet) {
        entries.push(slotGroup);
        slotGroup = [];
      }
    }

    if (entry.diaryEntryType.isRuleSet) {
      entries.push({
        ...entry,
        entries: (entry as RuleSet).entries.map((e: RawSlot) => formatSlot(e)),
      });
    }
  }

  return entries;
});

const start = computed(() => {
  return parseDate(props.diary.summary.startDateTime);
});

const end = computed(() => {
  return parseDate(props.diary.summary.endDateTime);
});

const durationText = computed(() => {
  return date.formatDate(start.value, "HH:mm") + " - " + date.formatDate(end.value, "HH:mm");
});

onMounted(() => {
  observer.value = new ResizeObserver(handleResize);

  if (headingEl.value) {
    observer.value.observe(headingEl.value);
  }
});

onBeforeUnmount(() => {
  observer.value?.disconnect();
});

function parseDate(e: string | Date | number): Date {
  return e instanceof Date
    ? e
    : typeof e === "number"
    ? new Date(e)
    : new Date(Date.parse(e as any));
}

function formatSlot(slot: RawSlot): FormattedSlot {
  const start = parseDate(slot.startDateTime!);
  const end = parseDate(slot.endDateTime!);
  const duration = date.getDateDiff(end, start, "minutes");
  return {
    ...slot,
    id: slot.id ?? slot.appointmentId ?? slot.diaryId ?? "no-id",
    start: date.formatDate(start, "HH:mm"),
    end: date.formatDate(end, "HH:mm"),
    formattedDuration: duration === 1 ? `${duration} min` : `${duration} mins`,
    siteName: props.diary.summary.site?.name,
  };
}

function cancelAppointmentDiary() {
  let text =
    "Are you sure you would like to cancel this appointment diary? This action cannot be undone.";
  const bookedCount = props.diary?.summary.booked?.count;
  if (bookedCount) {
    text = `This appointment diary has ${bookedCount} appointment${
      bookedCount == 1 ? "" : "s"
    } that will also be cancelled.`;
    text = text + " Are you sure you would like to cancel the diary? This action cannot be undone.";
  }
  showDialog({
    title: "Cancel Diary?",
    text,
    cancelLabel: "No, don't cancel",
    okLabel: "Yes, cancel",
  }).onOk(() => {
    emit("cancel-diary", props.diary);
  });
}

function deduceDeliveryModeIcon(value?: AppointmentDeliveryMode) {
  if (!value) return "";

  if (value.isFaceToFace) {
    return "fa-solid fa-user-doctor";
  }
  if (value.isHomeVisit) {
    return "fa-solid fa-house";
  }
  if (value.isVideo) {
    return "fa-solid fa-video";
  }
  return "fa-solid fa-phone";
}

function handleSlotClicked(slot) {
  slot.diaryIsAtCapacity = props.diary.summary.isAtCapacity;

  slot.assignedPractitioners = props.diary.summary.assignees.map((a) => {
    return {
      staffId: a.id,
      name: a.displayName,
    };
  });

  emit("slot-clicked", slot);
}

function handleUnavailabilityPeriodClick(entry) {
  if (entry.eventEntityType.isRotaActivityAssignment) {
    emit("rota-activity-assignment-clicked", entry);
  }
}

function handleResize(entries: ResizeObserverEntry[]) {
  for (const entry of entries) {
    headingHeight.value = (entry.target as HTMLElement).offsetHeight - 1;
  }
}

function handleAvailableSlotRightClick(event: MouseEvent, slot: RawSlot) {
  if (!props.allowAppointmentContextMenu) return;

  // Only show context menu if no modifier key is being held when right clicking
  if (!(event.ctrlKey || event.shiftKey || event.altKey || event.metaKey)) {
    event.preventDefault();
    let currentEl: HTMLElement | null = event.target as HTMLElement;
    let slotEl = null;

    // Target may be an element inside of the slot button
    // Keep checking parent elements until a button found
    while (slotEl === null) {
      if (currentEl.tagName.toLowerCase() === "button" && currentEl.classList.contains("slot")) {
        slotEl = currentEl;
        break;
      }

      currentEl = currentEl.parentElement;

      if (currentEl === null) {
        break;
      }
    }

    if (slotEl) {
      contextMenuTarget.value = slotEl; // Don't use event target
      contextMenuSlot.value = slot;
      isContextMenuShowing.value = !isContextMenuShowing.value; // Toggle show on right click
    }
  }
}

function editSlotAppointmentType() {
  if (contextMenuSlot.value) {
    emit("edit-slot-appointment-type", contextMenuSlot.value);
  }
}

function convertSlotToBreak() {
  if (contextMenuSlot.value) {
    emit("convert-slot-to-break", contextMenuSlot.value);
  }
}

function convertSlotToRotaActivityAssignment() {
  if (contextMenuSlot.value) {
    emit("convert-slot-to-rota-activity-assignment", contextMenuSlot.value);
  }
}

function removeBreak(breakId: string) {
  emit("remove-break", breakId);
}

function removeRotaActivityAssignment(rotaActivityAssignmentId: string) {
  emit("remove-rota-activity-assignment", rotaActivityAssignmentId);
}
</script>

<style lang="scss">
:root {
  --appointment-diary-bg: var(--grey-lightest);
  --appointment-diary-border-radius: 6px;
  --appointment-diary-padding: var(--gap-3);
  --slot-border-radius: 4px;
  --transition: all 140ms ease-out;
}

.appointment-diary {
  display: flex;
  flex-direction: column;
  background: var(--appointment-diary-bg);
  border-radius: var(--appointment-diary-border-radius);
  position: relative;
  padding-bottom: var(--appointment-diary-padding);
}

.diary-title {
  position: sticky;
  top: 0;
  background: var(--appointment-diary-bg);
  z-index: 100;
  font-size: 14px;
  padding-top: var(--appointment-diary-padding);
  padding-left: var(--appointment-diary-padding);
  padding-right: 40px;
  display: flex;
  align-items: flex-start;
  color: var(--text-color-light);
  border-top-left-radius: var(--appointment-diary-border-radius);
  border-top-right-radius: var(--appointment-diary-border-radius);
}

.diary-info {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0 6px;
  padding: 0 var(--appointment-diary-padding);
  margin-bottom: var(--gap-2);
  font-size: 14px;
  color: var(--grey-darkest);
  font-weight: 400;

  .diary-info-icon {
    grid-column-start: 1;
    display: flex;
    place-items: center;
  }

  .diary-info-value {
    grid-column-start: 2;
  }
}

.diary-actions {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 600;
  aspect-ratio: 1 / 1;
  width: auto;
}

.appointments-group {
  + .appointments-group {
    margin-top: var(--gap-3);
  }
}

.appointments-group-title {
  position: sticky;
  background: linear-gradient(to bottom, var(--appointment-diary-bg) 66%, transparent);
  z-index: 50;
  font-size: 16px;
  font-weight: 600;
  padding: 0 var(--appointment-diary-padding) var(--gap-1);
  // padding-bottom: 15px;
  line-height: 1.4;

  .diary-info {
    padding-left: 0;
    margin-bottom: 5px;
  }
}

.appointments {
  .slots {
    padding: 0 var(--appointment-diary-padding);
  }
  .slot {
    position: relative;
    transition: var(--transition);

    .slot-info {
      line-height: 1.2;

      .start-time {
        font-weight: 600;
        margin-bottom: 4px;
        transition: var(--transition);
      }

      .duration {
        color: var(--text-color-light);
        transition: all 140ms ease-out;
      }
    }

    + .slot {
      margin-top: var(--gap-2);
    }
  }

  .available-slot {
    background: var(--white-overylay);
    width: 100%;
    padding: var(--gap-1) var(--gap-2);
    border: 1.5px dashed var(--grey-darker);
    border-radius: var(--slot-border-radius);
  }

  .booked-item {
    width: 100%;
  }

  .overlay {
    display: block;
    text-decoration: underline;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-blue);
    color: #ffffff;
    font-weight: 600;
    border-radius: var(--slot-border-radius);
    transition: all 140ms ease-out;
  }

  .interactive {
    &:hover {
      border-style: solid;
      border-color: var(--theme-blue);
      cursor: pointer;

      box-shadow: var(--box-shadow);

      .start-time,
      .duration {
        opacity: 0;
      }

      .overlay {
        opacity: 1;
      }
    }
  }

  .slot-reservation {
    min-height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px dashed var(--border-colour);
    border-radius: 4px;
    cursor: not-allowed;
    color: var(--border-colour);
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }
  .unavailability-period {
    height: 100%;
    min-height: 60px;
    width: 100%;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }
}

.diary-notifications {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 var(--appointment-diary-padding);

  > :last-child {
    margin-bottom: 20px;
  }
}

.untimed-appointments-group {
  margin-top: 30px;

  .book-untimed-appointment {
    margin-top: 14px;
    padding: 0 var(--appointment-diary-padding);

    .book-untimed-appointment-button {
      width: fit-content;
      margin-top: 5px;
    }
  }

  .empty-text {
    border-radius: 6px;
    margin: 0 14px;
  }
}
</style>
