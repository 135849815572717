<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View Drug/Device Administration"
    @click="medicationAdministrationOverview()"
  >
    <MedicationAdministrationJournalElement
      :item="entry.data"
      :read-only="readOnly"
      :clickable="clickable"
    />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import MedicationAdministrationJournalElement from "../elements/MedicationAdministrationJournalElement.vue";
import { PatientJournalRecord } from "../types";
const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function medicationAdministrationOverview() {
  return props.readOnly
    ? openSlideover(`/clinical/medication-administration/overview/${props.entry.id}`, {
        close: true,
      })
    : undefined;
}
</script>
