<script lang="ts">
import { NOOP } from "@vue/shared";
import { QTab, QTabPanels, QTabs } from "quasar";
import { computed, defineComponent, h, inject, ref, watch, watchEffect } from "vue";
import { isArray, isString } from "vue-composable";
import { useRouter } from "vue-router";

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: [String, Object],
    panelClass: String,
    noFlex: Boolean,
    fit: Boolean,
    noPadding: Boolean,

    scroll: Boolean,

    routerSync: [Boolean, String],

    onBeforeRouteChange: Function,
    gutter: Boolean,
  },

  setup(props, { emit, slots, attrs }) {
    inject("prevent-overflow", NOOP)(true);
    inject("remove-padding", NOOP)(true);

    const value = ref(props.modelValue);

    const defaultValue = ref("");

    watch(
      () => props.modelValue,
      (v) => (value.value = v),
    );

    const router = useRouter();
    const queryName = computed(() =>
      props.routerSync ? (isString(props.routerSync) ? props.routerSync : "tab") : undefined,
    );

    watch([value, defaultValue], ([v, d]) => {
      if (queryName.value && v !== router.currentRoute.value.query[queryName.value]) {
        if (!d) return;
        let query: Record<string, string> = {
          [queryName.value]: v === defaultValue.value ? undefined : (v as any),
        };

        if (props.onBeforeRouteChange) {
          const r = props.onBeforeRouteChange(v, query);
          if (r) {
            query = r;
          }
        }

        // eslint-disable-next-line no-undef
        if (IS_APP) {
          router.push({
            query,
          });
        }
      }
    });

    watchEffect(() => {
      if (queryName.value) {
        const v = router.currentRoute.value.query[queryName.value];
        if (v) {
          value.value = v;
        } else if (defaultValue.value) {
          value.value = defaultValue.value;
        }
      }
    });

    return () => {
      const defaultSlot = slots.default?.();
      const children = defaultSlot?.map((x) => (x.key === "_default" ? x.children : x)).flat() as {
        children?: any[];
        props?: { name: string };
      }[];

      const tabs: any[] = [];

      if (children) {
        for (const child of children) {
          if (!child) continue;

          if (isArray(child.children)) {
            tabs.push(...child.children.filter((x) => x?.props?.name));
          } else if (child?.props?.name) {
            tabs.push(child);
          }
        }
      }

      if (!value.value && tabs.length) {
        //@ts-ignore
        value.value = tabs[0].props?.name;
        emit("update:modelValue", value.value);
      }
      if (tabs.length) {
        defaultValue.value = tabs[0].props?.name;
      }

      const panels = [
        h(
          QTabPanels,
          {
            modelValue: value.value,
            class: [
              {
                "flex-auto": !props.noFlex,
                // "medicus-grey-background": true,
              },
              props.panelClass,
            ],
            style: {
              backgroundColor: "inherit",
            },
            "onUpdate:modelValue"(e: any) {
              value.value = e;
            },
          },
          () => tabs,
          // slots.default
          // {
          //   default: ()=> tabs,
          // }
        ),
      ];

      const tabHeader = h(
        QTabs,
        {
          modelValue: value.value,
          // switchIndicator: true,
          indicatorColor: "primary",
          align: "left",
          noCaps: true,

          "onUpdate:modelValue"(e: any) {
            value.value = e;
            emit("update:modelValue", e);
          },
        },
        () => [
          ...tabs.map((t: any) =>
            h(
              QTab,
              // clear label to avoid showing it twice
              slots["header:" + t.props?.name]
                ? {
                    ...t.props,
                    label: undefined,
                  }
                : t.props,
              // @ts-ignore
              slots["header:" + t.props?.name]?.(),
            ),
          ),
        ],
      );

      const headNodes = slots.head ? slots.head() : [];

      return h(
        "div",
        {
          class: [
            {
              "tab-fit": props.fit,
              "no-padding": props.noPadding,
              "flex-col m-tabs flex-auto": true,
              "overflow-hidden": !props.scroll,
              gutter: props.gutter,
            },
            attrs.class,
          ],
          style: attrs.style,
        },
        [
          headNodes.length
            ? h(
                "div",
                {
                  style: {
                    display: "grid",
                    gap: "0.5rem",
                    gridTemplateColumns: `minmax(0, 1fr)  ${headNodes.map(() => "auto")} 0px`,
                    alignItems: "center",
                  },
                },
                // note adding extra div have an extra margin on the right
                [h("div", [tabHeader]), ...headNodes, h("div")],
              )
            : tabHeader,

          props.scroll
            ? h(
                "div",
                {
                  class: "scroll-area q-mb-md",

                  onScroll(e: any) {
                    emit("scroll", e);
                  },
                },
                panels,
              )
            : panels,
        ],
      );
    };
  },
});
</script>

<style lang="scss">
.m-tabs {
  display: flex;
  height: 100%;

  &.gutter {
    .q-tabs {
      .q-tabs__content {
        max-width: var(--page-content-max-width);
        margin-left: auto;
        margin-right: auto;
      }
    }
    .q-tab-panels {
      .q-tab-panel {
        max-width: var(--page-content-max-width);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .q-tabs__content {
    &::before {
      position: absolute;
      content: " ";
      width: 100%;

      bottom: 0px;
    }
  }

  &.tab-fit .q-tab-panel {
    padding: 5px;
  }

  &.no-padding .q-tab-panel {
    padding: 0;
  }

  .q-tab-panel > *:last-child {
    padding-bottom: 12px;
  }

  > .scroll-area {
    height: 100%;
  }

  // .q-tabs::after {
  //   content: " ";
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   height: 3px;
  //   border-bottom: 1px solid var(--border-colour);
  // }

  .q-tab {
    &:first-of-type {
      margin-left: 10px;
    }

    min-height: 40px;
    position: relative;

    box-sizing: border-box;

    .q-tab__label {
      color: var(--text-color-light);
      font-weight: bold;
      font-style: normal;
      font-size: 14px;
      line-height: 150%;
      font-family: Arial, sans-serif;
    }

    &.q-tab--active {
      border-bottom: none;
      .q-tab__label {
        color: var(--text-color);
      }
    }

    .q-tab__indicator {
      height: 3px;
      color: var(--status-blue) !important;
      position: absolute;
      z-index: 2;
    }
  }
}
</style>
