<script lang="ts" setup>
import SidebarMenu, { MenuItems } from "../../SidebarMenu";
import { usePromise } from "vue-composable";
import { load } from "../../../../api/medicus";

const title = "Quick Actions";

const { result: items } = usePromise(() =>
  load<MenuItems>("navigation/ui/quick-actions", {
    showError: false,
  }).then((x) => x.data),
);
</script>
<template>
  <SidebarMenu :title="title" :items="items || []" role="navigation" aria-label="Actions menu" />
</template>
