<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import MedicusBottomEffect from "../svg/MedicusBottomEffect.vue";
import { routesHistory } from "../router/history";
import MButton from "../components/medicus/MButton";

const props = defineProps<{ from?: string }>();

const router = useRouter();

const redirectTo = computed(() => {
  for (let i = 0; i < routesHistory.length; i++) {
    const h = routesHistory[i];
    const nextHistoryName = routesHistory[i + 1]?.name;
    if (
      (!h.name || h.name?.indexOf("403") === -1) &&
      (!nextHistoryName || nextHistoryName?.indexOf("403") === -1) &&
      h.fullPath !== props.from
    ) {
      return h;
    }
  }

  return "/";
});

const isHome = computed(() => redirectTo.value === "/");

function goBack() {
  return router.push(redirectTo.value);
}
</script>
<template>
  <div class="page-403 flex-auto items-center">
    <div class="flex flex-col justify-center items-center">
      <h1 class="title">Incorrect Permissions</h1>
      <p>Sorry, you don't have the permission to view this page.</p>
      <m-button v-if="redirectTo" @click="goBack">{{
        isHome ? "Go to homepage" : "Go back to previous page"
      }}</m-button>
    </div>
    <medicus-bottom-effect class="medicus-bottom-effect" />
  </div>
</template>

<style lang="scss">
.page-403 {
  display: flex;
  justify-content: center;

  p {
    color: var(--grey-darker);
  }
}
</style>
