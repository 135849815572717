<script setup lang="ts">
import { ref } from "vue";
import MForm from "../../components/medicus/MForm";
import MInput from "../../components/medicus/inputs/MInput";
import MButton from "../../components/medicus/MButton";
import { useAuth } from "../../store";
import MSeparator from "../../components/medicus/MSeparator";
import MLayoutStack from "../../components/medicus/MLayoutStack";
import { AxiosError } from "axios";
import { useRouter } from "vue-router";
const token = ref("");
const errors = ref<string[]>([]);

const auth = useAuth();

async function doSubmit() {
  try {
    errors.value = [];
    await auth.validateMFA(token.value);
  } catch (e: unknown) {
    const err = e as AxiosError<{ errors: { command: string[] } }>;
    errors.value = err.response?.data?.errors?.command ??
      (err.message ? [err.message] : undefined) ?? ["Unknown error happened"];
  }
}

const router = useRouter();

defineExpose({
  token,
  doSubmit,
});
</script>
<template>
  <div class="flex flex-col gap-3">
    <m-form hide-required-message :enter-submit="true" @submit="doSubmit">
      <p>Enter the 6 digit from your authenticator app.</p>
      <m-input
        v-model="token"
        label="Token"
        required
        :autofocus="true"
        mask="999999"
        inputmode="numeric"
        :display-errors="errors"
        data-testid="token-input"
      />

      <template #footer>
        <m-layout-stack full>
          <m-button type="submit" class="flex-auto" label="Verify" />
          <m-separator text="or" />
          <m-button
            type="button"
            class="flex-auto"
            label="Enter recovery code"
            color="secondary"
            :permission="true"
            :to="{
              name: 'recovery-code',
            }"
            data-testid="recovery-code-button"
          />
          <m-button
            color="secondary"
            @click="
              router.push({
                name: 'login',
              })
            "
            >Back to login page</m-button
          >
        </m-layout-stack>
      </template>
    </m-form>
  </div>
</template>
