<script lang="ts" setup>
import { QIcon } from "quasar";
import { computed } from "vue";
import { useTimeout } from "vue-composable";
import { SnackbarType } from "./interfaces";
import type { SnackbarMessage } from "./interfaces";
import MAction from "../../components/medicus/MAction";
import { NOOP } from "@vue/shared";

const props = defineProps<{
  message: SnackbarMessage;
  dense?: boolean;
}>();

const emit = defineEmits({
  remove: () => true,
});

const { cancel: cancelTimeout } = props.message.persistant
  ? { cancel: NOOP }
  : useTimeout(remove, props.message?.timeout ?? 2000);

const icon = computed(() => {
  if (props.message.icon) return props.message.icon;
  switch (props.message.type) {
    case SnackbarType.Info: {
      return "fa-solid fa-circle-info";
    }
    case SnackbarType.Warning:
    case SnackbarType.Danger: {
      return "fa-solid fa-triangle-exclamation";
    }
    case SnackbarType.Success: {
      return "fa-solid fa-circle-check";
    }

    default: {
      return "";
    }
  }
});

function remove() {
  emit("remove");
}
function removeClick() {
  cancelTimeout();
  remove();
}
</script>
<template>
  <article :class="['snackbar', message.type || 'system']">
    <q-icon v-if="icon" class="snackbar-type-icon" :name="icon" />
    <div class="flex flex-col info">
      <div v-if="message.title" class="flex-auto snackbar-title">{{ message.title }}</div>
      <div class="flex-auto message" v-html="message.message" />
    </div>

    <div class="flex flex-end gap-1 items-center actions">
      <m-action v-if="message.actions" :model-value="message.actions" />
      <div v-if="message.buttons" class="buttons gap-3">
        <m-button v-for="button of message.buttons" :key="button.label" v-bind="button" />
      </div>
      <button v-if="!message.persistant" class="close-btn" @click="removeClick">
        <span class="sr-only">Close</span>
        <q-icon name="fa-light fa-xmark" />
      </button>
    </div>
  </article>
</template>
<style lang="scss">
.snackbar {
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;

  border-radius: 4px;

  background: white;
  padding: 1rem 0.75rem 1rem 1.25rem;

  color: var(--text-color);
  font-size: 14px;
  gap: 0.75rem;

  border: 1px solid var(--status-grey);

  position: relative;

  min-width: 200px;

  max-width: 100%;

  .info {
    flex: 1 1 auto;
  }

  &:not(.panic-alert) {
    max-width: 300px;
  }

  &.danger {
    max-width: 420px;
  }

  &::before {
    position: absolute;
    content: " ";
    width: 5px;
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    z-index: 0;

    border-radius: 4px 0 0 4px;
  }
  .snackbar-title {
    font-weight: 700;
    // font-size: 16px;
  }

  .snackbar-type-icon {
    font-size: 16px !important;
    flex: 0 0 auto;
    margin-top: 2px;
  }

  .message {
    white-space: pre-wrap;
    padding-right: var(--gap-3);

    p {
      margin-top: var(--gap-2);
    }
  }

  &.system {
    &::before {
      background: var(--status-grey);
    }
    .snackbar-type-icon {
      color: var(--status-grey);
    }
  }

  &.info {
    &::before {
      background: var(--status-blue);
    }
    .snackbar-type-icon {
      color: var(--status-blue);
    }
  }

  &.success {
    &::before {
      background: var(--status-green);
    }
    .snackbar-type-icon {
      color: var(--status-green);
    }
  }

  &.danger {
    &::before {
      background: var(--status-red);
    }
    .snackbar-type-icon {
      color: var(--status-red);
    }
  }

  &.warning {
    &::before {
      background: var(--status-yellow);
    }
    .snackbar-type-icon {
      color: var(--status-yellow);
    }
  }

  &.panic-alert {
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    border: 4px solid var(--status-red);
    border-radius: 4px !important;
    box-shadow: var(--box-shadow);

    .info {
      .snackbar-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 1.1;
        margin-bottom: 8px;
      }

      .message {
        font-size: 14px;
        p + p {
          margin-top: 6px;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  }

  &:first-child {
    // border-radius: 4px 4px 0 0;
    border-radius: 0 0 4px 4px;

    &::before {
      // border-radius: 4px 0 0 0;
      border-radius: 0 0 0 4px;
    }
  }

  > div > button.close-btn {
    //flex fill-white hover:(fill-gray-200 bg-gray-700)
    display: flex;
    color: var(--grey-darkest);
    margin-top: 2px;
    margin-right: 2px;
    font-size: 16px;

    background: none;
    border: none;

    cursor: pointer;

    &:hover {
      color: var(--grey-darkest);
      // background: var(--grey);
    }
  }
}
</style>
