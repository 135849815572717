<script setup lang="ts">
import { ref } from "vue";
import MSelect from "../MSelect";
import ColorRender from "./ColorRender.vue";

defineProps<{
  label?: string;
  modelValue: any;
  name?: string;
  instructions?: string;
}>();

const colours = [
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
];

const selectEl = ref<InstanceType<typeof MSelect>>();

defineExpose({
  open() {
    return selectEl.value?.open();
  },
});
</script>
<template>
  <m-select
    ref="selectEl"
    :options="colours"
    :label="label"
    :name="name"
    :instructions="instructions"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template v-if="modelValue" #prepend>
      <ColorRender :color="modelValue" />
    </template>
    <template #option-section="{ option }">
      <ColorRender class="q-mr-sm" :color="option.value" />
    </template>
  </m-select>
</template>
