<template>
  <m-page
    class="tabbed-page"
    :title="title"
    :description="description"
    :buttons="buttons"
    :bg-white="bgWhite"
    :fit="fit"
    :small-header="scrolling"
    no-overflow
    no-padding
  >
    <template #header="bindHeader">
      <slot name="header" v-bind="bindHeader" />
    </template>
    <template #title="bindTitle">
      <slot name="title" v-bind="bindTitle" />
    </template>
    <m-tabs
      :model-value="modelValue"
      scroll
      router-sync
      @update:model-value="onChange"
      @scroll="onScroll"
      @before-route-change="onBeforeRouteChange"
    >
      <slot />
    </m-tabs>
  </m-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import MPage from "../MPage";
import MTabs from "../MTabs";

export default defineComponent({
  components: {
    MPage,
    MTabs,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: null,
    },

    bgWhite: Boolean,

    fit: Boolean,

    modelValue: {
      type: Object,
      default: null,
    },
    onBeforeRouteChange: {
      type: Function,
      default: null,
    },
  },

  setup(_, { emit }) {
    const scrolling = ref(false);

    function onChange(e: any) {
      emit("update:modelValue", e);
      scrolling.value = false;
    }

    function onScroll(e: { verticalPosition: number; verticalPercentage: number }) {
      scrolling.value = e.verticalPercentage > 0.1;
    }

    return {
      onChange,
      onScroll,

      scrolling,
    };
  },
});
</script>
<style lang="scss">
.tabbed-page {
  > .header {
    border: none;
    padding-bottom: 0;
    .header-title {
      flex: 1 1 auto;
      padding-bottom: 0px;
    }
  }

  .page-content {
    background: white;
  }

  .m-tabs {
    margin-top: 0;
    > .scroll-area {
      background: var(--medicus-background);
    }
  }

  .q-tab-panels {
    background: var(--medicus-background);
    height: 100%;
  }

  .q-panel {
    display: flex;
  }

  .q-tab-panel {
    height: auto;
    flex: 1 1 auto;
    overflow: auto;
  }

  .q-tabs {
    box-shadow: inset 0px -1px 0px var(--border-colour);
  }

  .q-tab {
    box-shadow: inset 0px -1px 0px var(--border-colour);
  }
}
</style>
