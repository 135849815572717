<template>
  <m-calendar ref="calendar" :options="options" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MCalendar from "./../MCalendar";

// import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

const plugins = [resourceTimeGridPlugin /*, interactionPlugin*/];

export default defineComponent({
  components: { MCalendar },

  props: {
    initialView: {
      type: String,
      default() {
        return "resourceTimeGridDay";
      },
    },

    headerToolbar: {
      type: Object,
      default() {
        return { start: "", center: "", end: "" };
      },
    },
    height: {
      type: String,
      default() {
        return "100%";
      },
    },
    slotDuration: {
      type: [String, Object],
      default() {
        return { minutes: 30 };
      },
    },

    dateClick: Function,
    eventClick: Function,
    events: Array,
    eventSources: Array,

    resources: Array,
    initialDate: [Date, String],
  },

  computed: {
    options() {
      return Object.assign(
        {
          plugins,
        },
        this.$props,
      );
    },
  },
});
</script>

<style></style>
