<script lang="ts" setup>
import { computed } from "@vue/reactivity";
import { isObject } from "@vue/shared";
import { ref, watch } from "vue";
import { openModal, openPage, openSlideover } from "../../../../composables/dialog/drawer";
import MAction from "../../MAction";
import MBadge from "../../MBadge";
import MLink from "../../MLink/MLink.vue";
import { PropsOf } from "../../../../utils/types";

type DescriptionListItemValue = { text: string } & (TextValue | BadgeValue | LinkValue);

type TextValue = {
  type: "text";
};

type BadgeValue = {
  type: "badge";
} & PropsOf<typeof MBadge>;

type LinkValue = {
  type: "link";
} & PropsOf<typeof MLink>;

const props = defineProps<{
  label: string;
  value?: DescriptionListItemValue | string | null;

  darkLabel?: boolean;

  horizontal?: boolean;

  showVerticalLine?: boolean;

  emptyValueText?: string;

  badge?:
    | string
    | InstanceType<typeof MBadge>["$props"]
    | Array<string | InstanceType<typeof MBadge>["$props"]>;

  action?: {
    icon?: string;
    srLabel?: string;
    to?: string;
    toSlideover?: string;
    toModal?: string;
  };
}>();

function handleClick(e) {
  if (!props.action) return;

  e.stopPropagation();

  const { to, toSlideover, toModal } = props.action;
  if (to) {
    return openPage(to);
  }

  if (toModal) {
    const [url, options] = isObject(toModal) ? [toModal.url, toModal] : [toModal, {}];
    return openModal(url, options as any);
  }
  if (toSlideover) {
    const [url, options] = isObject(toSlideover)
      ? [toSlideover.url, toSlideover]
      : [toSlideover, {}];

    return openSlideover(url, options as any);
  }
}

// better align buttons otherwise it will align on the `dd` instead of description list item
const labelEl = ref<HTMLElement | null>(null);
const contentMarginTop = ref("23px");
watch(labelEl, (l) => {
  contentMarginTop.value = `-${l?.getBoundingClientRect()?.height ?? 23}px`;
});

const extraRows = computed(() => [props.badge, props.value].filter((x) => !!x).length);
</script>
<template>
  <div
    class="description-list-item"
    :class="{
      'has-click': !!action,
      'has-vertical-line': showVerticalLine,
      'is-horizontal': horizontal,
      'dark-label': props.darkLabel,
    }"
    @click.capture="handleClick"
  >
    <dt ref="labelEl" class="description-list-item--label" v-text="label" />
    <dd>
      <span
        class="description-list-item--value"
        :class="{ 'description-list-item--empty': value == null }"
      >
        <template v-if="value !== null && typeof value === 'object' && value.type !== 'text'">
          <m-badge v-if="value?.type === 'badge'" v-bind="value" />
          <m-link v-if="value?.type === 'link'" v-bind="value" />
        </template>
        <template v-else>
          {{ value == null ? emptyValueText || "None recorded" : value.text || value }}
        </template>
      </span>
      <div v-if="badge" class="description-list-item--badge">
        <template v-if="Array.isArray(badge)">
          <template v-for="p in badge" :key="`${p}`">
            <m-badge v-if="typeof p === 'string'" :text="p" />
            <m-badge v-else v-bind="p" />
          </template>
        </template>
        <m-badge v-else-if="typeof badge === 'string'" :text="badge" />
        <m-badge v-else v-bind="badge" />
      </div>
      <div v-else></div>
      <m-action v-if="action" class="description-list-item--action" :model-value="action" />
      <div v-else></div>
    </dd>
  </div>
</template>
<style lang="scss" scoped>
.description-list-item {
  display: grid;
  gap: 0.125rem;
  padding: 0.25rem 15px;

  &.dark-label {
    .description-list-item--label {
      color: var(--text-color);
    }

    .description-list-item--value {
      color: var(--grey-darkest);
    }
  }

  &.has-click {
    cursor: pointer;

    &:hover {
      background-color: #ebedef;

      .action {
        background: none;
        color: #003078;
      }
    }
  }

  &.has-vertical-line {
    border-left: 1px solid #e5e5e5;

    position: relative;
    padding-left: 25px !important;

    &::before {
      content: " ";
      position: absolute;
      top: 5px;
      left: 15px;
      height: calc(100% - 10px);
      width: 3px;
      background-color: var(--status-blue);
    }
  }
  &.is-horizontal {
    grid-template-columns: 160px 1fr;

    padding: 3px 0;
    gap: 1.25rem;

    > dt {
      text-align: right;
    }

    > dd {
      grid-template-areas: "value badge action";
      grid-template-rows: auto;

      padding-right: 5px;

      .description-list-item--action {
        margin-top: 0.25rem;
      }
    }
  }
  > dt {
    color: var(--grey-darkest);
  }
  > dd {
    display: grid;
    grid-template-columns: 1fr repeat(v-bind(extraRows), auto);
    grid-template-rows: auto auto;

    grid-template-areas:
      "value action"
      "badge action";

    .description-list-item--value {
      grid-area: value;
      word-break: break-word;
      hyphens: auto;
      white-space: pre-wrap;
    }

    .description-list-item--badge {
      grid-area: badge;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .description-list-item--action {
      grid-area: action;
      margin-left: 0.5rem;

      margin-top: calc(v-bind(contentMarginTop) + 0.5rem);
    }

    .description-list-item--empty {
      color: var(--grey-darkest);
    }
  }
}
</style>
