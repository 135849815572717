export type CursorValues = { leftOfSelection: string | null; rightOfSelection: string | null };

export type TextAreaKeyboardEvent = KeyboardEvent & { target: HTMLTextAreaElement };

export type SnomedClinicalCode = {
  label: string;
  value: {
    description: string;
    conceptId: string;
    descriptionId: string;
    parentConceptIds: string[];
  };
};

export enum Direction {
  UP = 1,
  DOWN = -1,
}
