<script lang="ts" setup>
import MButton from "../../components/medicus/MButton";
import NHSButton from "../../components/medicus/MCis2Login/NHSButton.vue";
import MLayoutStack from "../../components/medicus/MLayoutStack/MLayoutStack.vue";
import MSeparator from "../../components/medicus/MSeparator";
import MInput from "../../components/medicus/inputs/MInput";

import { Loading, QSpinnerBall } from "quasar";
import { computed, markRaw, ref } from "vue";
import { useRoute } from "vue-router";
import { logout } from "../../api/iam";
import MBanner from "../../components/medicus/MBanner";
import MLink from "../../components/medicus/MLink/MLink.vue";
import { useApp } from "../../store";
import { useAuth } from "../../store/auth";
import { useNHS } from "../../store/nhs";
import { showDialog } from "../../utils/dialog";
import AcceptableUse from "./AcceptableUse.vue";
import { clearQuery } from "../../plugins/global";

const props = defineProps<{
  // just to be passed on storybook or tests
  isNHS?: boolean;
}>();

const basicAuthErrorMessage = "Invalid username or password.";
const cis2AuthErrorMessage =
  "CIS2 account not paired with a Medicus user. Please login with username and password and pair to enable this login option.";
const cis2ServerErrorMessage =
  "Unable to login using NHS CIS2. Please try again and if this issue persists, contact Medicus Support.";

const route = useRoute();
const authStore = useAuth();
useApp().updateTitle("Login");
authStore.reset();

const userData = ref({
  username: "",
  password: "",
});
const isPwd = ref(true);
const showSession = ref(route.query.expired === "true");
const error = ref<Array<string>>([]);
const passwordChanged = route.params.passwordChanged;

const autocomplete = computed(() => {
  const allowed = ["https://uat.england.medicus.health"];
  // eslint-disable-next-line no-undef
  return (IS_APP && !import.meta.env.PROD) || ~allowed.indexOf(location.origin);
});

const isNHSEngland = computed(() => {
  if (props.isNHS) return true;
  const allowedDomains = [
    "dev1.medicus.health",
    "uat.england.medicus.health",
    "pre-prod.england.medicus.health",
    "england.medicus.health",
    "training.england.medicus.health",
    "staging.england.medicus.health",
    "dr.england.medicus.health",
  ];
  return ~allowedDomains.indexOf(location.hostname);
});

/**
 * TODO remove this
 * the IAM if sent an expired SESSION on login
 * it will request to remove the SESSION cookie
 * instead of sending a new cookie
 *
 * To prevent that we will always logout the
 * user
 */
if (window.sessionStorage.getItem("PHPSESSION")) {
  logout();
}

const store = useNHS();

async function doLoginNHS() {
  const res = await store.openNhsLogin();

  if ((res?.data && !("error" in res.data)) || !res?.data?.error) {
    return;
  }
  const msg = res?.shouldPair
    ? cis2AuthErrorMessage
    : res?.data?.error?.indexOf("Account locked") !== -1
    ? res.data.error
    : cis2ServerErrorMessage;

  error.value = [msg];
}
async function doLogin() {
  try {
    Loading.show({
      spinner: QSpinnerBall,
      delay: 500,
      customClass: "medicus-loading-ball",
    });
    const { username, password } = userData.value;
    if (!username || !password) {
      throw Error();
    }
    showSession.value = false;
    await authStore.login(userData.value);
  } catch (e) {
    error.value = e?.response?.data?.errors?.command || [basicAuthErrorMessage];
  } finally {
    clearQuery();
    Loading.hide();
  }
}

function openAcceptableUse(e?: Event) {
  e?.preventDefault();
  showDialog({
    type: "small",
    title: "Acceptable Use of the Medicus System",
    noCancel: true,
    okLabel: "Dismiss",
    okColor: "secondary",
    component: markRaw(AcceptableUse),
  });
}
</script>
<template>
  <div>
    <form class="flex flex-col" autocomplete="off" @submit="doLogin">
      <m-layout-stack>
        <m-banner v-if="showSession"
          >You have been logged out due to inactivity. Please log in again.</m-banner
        >
        <m-banner v-if="passwordChanged" type="success"> Password successfully changed. </m-banner>

        <NHSButton
          label="Log in with Smartcard"
          data-testid="nhs-login-button"
          @click="doLoginNHS"
        />

        <MSeparator none text="or" />
        <!-- NOTE HACKY WAY TO DISABLE AUTOFILL -->
        <input
          v-if="!autocomplete"
          name="username"
          class="stealthy"
          tabindex="-1"
          aria-hidden="true"
        />

        <m-input
          v-model="userData.username"
          label="Email address"
          data-testid="username"
          placeholder=""
          @keypress.enter="doLogin"
        />
        <!-- NOTE HACKY WAY TO DISABLE AUTOFILL -->
        <input
          v-if="!autocomplete"
          name="username"
          class="stealthy"
          tabindex="-1"
          aria-hidden="true"
        />

        <m-input
          v-model="userData.password"
          class="login-password"
          :class="{ 'as-password': isPwd }"
          :type="autocomplete && isPwd ? 'password' : 'text'"
          label="Password"
          color="primary"
          data-testid="password"
          placeholder=""
          aria-label="Password"
          @keypress.enter="doLogin"
        >
          <template #append>
            <m-button
              :icon="isPwd ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'"
              color="secondary"
              ghost
              icon-only
              :label="isPwd ? 'Show password' : 'Hide password'"
              @click="isPwd = !isPwd"
            />
          </template>
        </m-input>

        <span
          v-if="error.length > 0"
          :class="{
            'sr-only': !error,
          }"
          type="error"
          class="error"
          role="status"
        >
          <p v-for="err in error" :key="err">
            {{ err }}
          </p>
        </span>

        <router-link
          :to="{ name: 'forgot-password', query: { username: userData.username } }"
          class="forgot-password"
        >
          Forgot password?
        </router-link>

        <m-button
          class="float-right login-button w-full"
          data-testid="login-button"
          color="secondary"
          label="Log in"
          @click="doLogin"
        />
        <div id="login-footer">
          <m-layout-stack horizontal>
            <span>&copy; Medicus Health Limited {{ new Date().getFullYear() }}</span>
            <span v-if="isNHSEngland">|</span>
            <m-link
              v-if="isNHSEngland"
              text="Acceptable use"
              data-testid="acceptable-use-link"
              @click="openAcceptableUse"
            />
            <span>|</span>
            <m-link
              text="Help centre"
              href="https://medicus-help.zendesk.com/hc/en-gb/"
              target="_blank"
            />
          </m-layout-stack>
        </div>
      </m-layout-stack>
    </form>
  </div>
</template>
<style lang="scss" scoped>
.stealthy {
  left: 0;
  margin: 0;
  max-height: 1px;
  max-width: 1px;
  opacity: 0;
  outline: none;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1;
}

.forgot-password {
  text-decoration-line: underline;
  color: var(--grey-darkest);
  cursor: pointer;
  margin-bottom: 5px;
}
.medicus-button {
  color: var(--grey-darkest);
}
</style>

<style lang="scss">
#login-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-password {
  label .q-field__control .q-field__append {
    position: absolute;
    right: 0;
    margin: auto;
    height: 100% !important;
  }
}
.error {
  color: var(--status-red);
}
.as-password input {
  -webkit-text-security: disc !important;
}
</style>
