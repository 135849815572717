<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View Medication Prescribed Elsewhere"
    @click="medicationStatementOverview()"
  >
    <MedicationStatementPrescribedElsewhereJournalElement
      :item="entry.data"
      :read-only="readOnly"
      :clickable="clickable"
    />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import MedicationStatementPrescribedElsewhereJournalElement from "../elements/MedicationStatementPrescribedElsewhereJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function medicationStatementOverview() {
  return props.readOnly
    ? openSlideover(
        `/clinical/medication-statement/overview-${props.entry.data.entryType}/${props.entry.id}`,
        { close: true },
      )
    : undefined;
}
</script>
