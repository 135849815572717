import { openModal } from "../../../composables/dialog/drawer";
import { EntryType } from "./types";

export const conceptsToOpenModal = [
  {
    conceptId: "163020007",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "162913005",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "309646008",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "162986007",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "33747003",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "302789003",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "162763007",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "162755006",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "363787002",
    type: EntryType.OBSERVATION,
  },
  {
    conceptId: "420134006",
    type: EntryType.ALLERGY,
  },
  {
    conceptId: "281647001",
    type: EntryType.ALLERGY,
  },
];

export function createObservation(
  patientId: string,
  contextId: string,
  contextType: string,
  typeConceptId: string,
  additionalCreatePayloadData: {},
) {
  openModal(`/clinical/observation/create/${patientId}`, {
    params: {
      ...{
        contextId,
        contextType,
        typeConceptId,
      },
    },
    additionalCreatePayloadData,
  });
}

export function createAllergy(
  patientId: string,
  contextId: string,
  contextType: string,
  allergyConceptId: string,
  additionalCreatePayloadData: {},
) {
  openModal(`/clinical/allergy/create-allergy/${patientId}`, {
    params: {
      ...{
        contextId,
        contextType,
        allergyConceptId,
      },
    },
    additionalCreatePayloadData,
  });
}
