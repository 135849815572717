<template>
  <m-card
    :action="{
      label: 'View report',
      click: openInvestigation,
    }"
    :class="{ 'marked-incorrect': investigationReport.isMarkedIncorrect }"
  >
    <m-layout-stack gap="1">
      <m-layout-stack flex horizontal>
        <div class="flex-auto">
          <m-layout-stack horizontal>
            <div class="flex-auto">
              <b>Investigation results</b> • {{ investigationReport.requestedBy }}
            </div>
            <i v-if="investigationReport.isRetrospectivelyAmended" style="white-space: nowrap">{{
              formatRetrospectivelyAmendedLabel
            }}</i>
          </m-layout-stack>
        </div>
      </m-layout-stack>
      <m-layout-stack v-if="showBadgesRow" horizontal>
        <m-badge
          v-if="investigationReport.filingStatus === 'Pending'"
          class="amber status-badge"
          text="Awaiting Filing"
          data-testid="investigation-presenter-badge"
        />
        <m-badge
          v-if="investigationReport.hiddenFromPatientFacingServices"
          text="Redacted from patient online access"
          gray
          data-testid="topic-hidden-from-patient-facing-services-badge"
        />
        <m-badge
          v-if="investigationReport.confidentialFromThirdParties"
          text="Confidential from Third Parties"
          gray
          data-testid="topic-confidential-from-third-parties-badge"
        />
        <m-badge
          v-for="(linkedProblem, index) in investigationReport.linkedProblems"
          :key="`investigation-report-linked-problem-${index}`"
          :text="linkedProblem.problemCodeDescription"
          gray
          :data-testid="`investigation-report-linked-problem-badge-${index}`"
        />
      </m-layout-stack>
    </m-layout-stack>
    <m-separator class="q-mt-sm" />

    <!--      GROUPS-->
    <m-layout-stack gap="0">
      <div
        v-for="group in investigationReport.investigationGroups"
        :key="`group-result-card-${group.id}`"
        class="q-mt-sm q-ml-sm"
      >
        <p>
          {{ group.label }} •
          {{ group.groupCollectionDateDisplayText }}
        </p>

        <!--      GRID-->
        <m-grid
          v-if="group.results.length"
          :custom-grid-options="{
            suppressContextMenu: true,
            suppressRowClickSelection: true,
            suppressRowTransform: true,
            suppressRowHoverHighlight: true,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            suppressCellFocus: true,
            domLayout: 'autoHeight',
            autoSizeStrategy: {
              type: 'fitCellContents',
            },
            autoSizePadding: 0,
            defaultColDef: {
              sortable: false,
              suppressMenu: true,
              headerHeight: 0,
              columnGroupShow: 'closed',
              autoHeight: true,
            },
          }"
          :column-defs="getCardColumnDefs()"
          :row-data="getRowOriginalData(group.results)"
          suppress-size-columns-to-fit
          class="journal-investigation-group-grid"
        />

        <m-layout-stack
          v-if="group.multilineResults.length"
          data-testid="text-result-test"
          :class="{ 'marked-incorrect text-grey': investigationReport.isMarkedIncorrect }"
          class="q-mt-sm"
        >
          <m-layout-stack
            v-for="result in group.multilineResults"
            :key="`multiline-result-${result.id}`"
          >
            <m-link :text="result.investigationResultLabel" :to-slideover="result.url" />
          </m-layout-stack>
        </m-layout-stack>
      </div>
    </m-layout-stack>

    <!--   UNGROUPED RESULTS-->
    <div
      v-for="result in investigationReport.ungroupedResults"
      :key="`ungrouped-result-card-${result.id}`"
      class="q-mt-sm q-ml-sm"
    >
      <!--      GRID-->
      <div
        v-if="
          result.resultType !== 'text-result' ||
          (result.resultText.length < 100 && !result.isMultilineResult)
        "
      >
        <p>
          {{ result.investigationResultLabel }} •
          {{ result.collectionDateDisplayText }}
        </p>
        <m-grid
          :column-defs="getCardColumnDefs()"
          :row-data="getRowOriginalData([result])"
          suppress-size-columns-to-fit
          class="journal-investigation-ungrouped-grid"
          :custom-grid-options="{
            suppressContextMenu: true,
            suppressRowClickSelection: true,
            suppressRowTransform: true,
            suppressRowHoverHighlight: true,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            suppressCellFocus: true,
            domLayout: 'autoHeight',
            autoSizeStrategy: {
              type: 'fitCellContents',
            },
            autoSizePadding: 0,
            defaultColDef: {
              sortable: false,
              suppressMenu: true,
              headerHeight: 0,
              columnGroupShow: 'closed',
              autoHeight: true,
            },
          }"
        />
      </div>
      <!-- MULTILINE RESULTS -->
      <m-layout-stack v-else gap="0">
        <p>
          {{ result.investigationResultLabel }} •
          {{ result.collectionDateDisplayText }}
        </p>
        <m-button v-if="result.url" :to-slideover="result.url" ghost>{{
          result.investigationResultLabel
        }}</m-button>
      </m-layout-stack>
    </div>

    <!--    FILING COMMENTS -->
    <div class="q-my-sm">
      <p v-if="investigationReport.filingComments?.length">Filing Notes</p>
      <ul>
        <li
          v-for="(filingComment, index) in investigationReport.filingComments"
          :key="`investigation-filing-comment-${index}`"
        >
          {{
            filingComment.resultDescription
              ? `${filingComment.resultDescription} - ${filingComment.comment}`
              : filingComment.comment
          }}
          <i>({{ formattedFilingCommentAuthor(filingComment) }})</i>
        </li>
      </ul>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { openModal } from "../../../../composables/dialog/drawer";
import MCard from "../../MCard";
import MGrid from "../../MGrid";
import LinkRenderer from "../cell-renderers/LinkRenderer.vue";
import ValueResultRenderer from "../cell-renderers/ValueResultRenderer.vue";
import ReferenceRangeRenderer from "../cell-renderers/ReferenceRangeRenderer.vue";
import MSeparator from "../../MSeparator";
import MLayoutStack from "../../MLayoutStack";

export type InvestigationDetailsItem = {
  description: string;
};

export type FilingComment = {
  id: string;
  isRetrospectivelyAmended: boolean;
  isMarkedIncorrect: boolean;
  responsiblePractitioner: string;
  responsibleOrganisation: string;
  recordAuthorIsLocal: boolean;
  resultDescription: string;
  comment: string;
};

export type InvestigationJournalItem = {
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  id: string;
  isMarkedIncorrect: boolean;
  requestedBy: string;
  atLeastOneResultOutsideOfReferenceRange: boolean;
  filingComments: FilingComment[];
  ungroupedResults: [];
  filingStatus: string;
  isRetrospectivelyAmended: boolean;
  investigationDetails: InvestigationDetailsItem[];
};

const props = defineProps<{
  investigationReport: InvestigationJournalItem;
  readOnly: boolean;
  isPatientRecordView?: boolean;
}>();

defineEmits(["investigationClick"]);

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];

  if (props.investigationReport.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const showBadgesRow = computed(() => {
  return (
    props.investigationReport.filingStatus === "Pending" ||
    props.investigationReport.hiddenFromPatientFacingServices ||
    props.investigationReport.confidentialFromThirdParties ||
    props.investigationReport.linkedProblems?.length
  );
});

function getCardColumnDefs() {
  const cols = [
    {
      headerName: "Investigation",
      field: "investigation",
      cellRenderer: LinkRenderer,
      flex: 1,
      minWidth: 300,
      wrapText: true,
    },
    {
      headerName: "Result",
      field: "result",
      cellRenderer: ValueResultRenderer,
      flex: 2,
      minWidth: 80,
      wrapText: true,
    },
    {
      headerName: "Reference Range",
      field: "referenceRanges",
      cellRenderer: ReferenceRangeRenderer,
      flex: 2,
      wrapText: true,
    },
  ];
  return cols;
}

function getRowOriginalData(results) {
  let data = [];

  if (results && results instanceof Array && results.length > 0) {
    // Add results rows
    data = results.map((result) => {
      const row = {
        investigation: {
          text: result.investigationResultLabel,
          url: result.url,
        },
        result,
        referenceRanges: {
          referenceRanges: result.referenceRanges,
          resultUnit: result.resultUnit,
        },
      };

      return row;
    });
  }
  return data;
}

function formattedFilingCommentAuthor(filingComment: FilingComment) {
  let formattedAuthorLabel = [];
  let responsiblePractitioner = filingComment.responsiblePractitioner ?? "Unknown practitioner";

  formattedAuthorLabel.push(responsiblePractitioner);

  if (filingComment.isRetrospectivelyAmended) {
    formattedAuthorLabel.push("Edited");
  }

  return formattedAuthorLabel.join(" • ");
}

function openInvestigation() {
  openModal(`/clinical/investigation/investigation-overview/${props.investigationReport.id}`, {
    close: true,
  });
}
</script>
<style lang="scss">
.status-badge {
  align-self: start;
}
.high-priority {
  font-size: 1.2rem !important;

  flex: none;
  align-self: center;
  color: var(--yellow-dark-non-text);

  margin: 0 8px;
}
.grey-darkest {
  color: var(--grey-darkest);
}
.card-description {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #68778d;
}

.investigation-details {
  margin-left: var(--card-padding);
  right: var(--card-padding);
}

.investigation-warning {
  margin-bottom: 10px;
}

.journal-investigation-ungrouped-grid.ag-theme-material
  .ag-layout-auto-height
  .ag-center-cols-viewport,
.journal-investigation-ungrouped-grid.ag-theme-material
  .ag-layout-auto-height
  .ag-center-cols-container,
.journal-investigation-ungrouped-grid.ag-theme-material .ag-layout-print .ag-center-cols-viewport,
.journal-investigation-ungrouped-grid.ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: auto !important;
}

.journal-investigation-group-grid.ag-theme-material .ag-layout-auto-height .ag-center-cols-viewport,
.journal-investigation-group-grid.ag-theme-material
  .ag-layout-auto-height
  .ag-center-cols-container,
.journal-investigation-group-grid.ag-theme-material .ag-layout-print .ag-center-cols-viewport,
.journal-investigation-group-grid.ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: auto !important;
}

.journal-investigation-ungrouped-grid.ag-theme-material {
  --ag-header-height: 26px;
}

.journal-investigation-group-grid.ag-theme-material {
  --ag-header-height: 26px;
}
</style>
