<script lang="ts" setup>
import { QCard, QCardSection, QSpace, debounce } from "quasar";
import { computed, inject, provide, ref, watchEffect } from "vue";
import { NO_OP } from "vue-composable";
import { onBeforeRouteUpdate } from "vue-router";
import { useResizeObserver } from "../../../composables/resizeObserver";
import MButton from "../MButton";
import MLayoutStack from "../MLayoutStack";
import MPatientBanner from "../PatientBanner";
import MBadge from "./../MBadge";

defineProps({
  title: String,
  name: String,
  description: String,

  badges: Array,

  patientBannerPatientId: String as () => string | null | undefined,
});

// TODO maybe remove this
const closeSlideover = inject("closeSlideover", NO_OP);
const reloadData = inject("reloadData", NO_OP);

let executed = false;
provide("requestFocus", (_focus: Function) => {
  if (executed) {
    return;
  }
  executed = true;
  // _focus();
});

provide("success", () => {
  reloadData?.();
  closeSlideover?.();
});
provide("cancel", () => closeSlideover?.());
onBeforeRouteUpdate(() => closeSlideover?.());

const headerEl = ref<QCardSection | null>(null);

const { contentBoxSize } = useResizeObserver(computed(() => headerEl.value?.$el));
const headerSize = ref("50px");
const updateHeaderSize = debounce((_sz: number) => {
  headerSize.value = `${headerEl.value?.$el.offsetHeight}px`;
}, 10);

watchEffect(() => {
  const [item] = contentBoxSize.value;
  if (item) {
    updateHeaderSize(item.blockSize);
  }
});

const labelId = inject<string>("slideover-title");
</script>
<template>
  <!-- TODO add responsive layout -->
  <q-card class="slideover-card" style="width: 575px; max-width: min(575px, 100vw)">
    <!-- HEADER  -->
    <slot name="card-header" horizontal>
      <q-card-section
        ref="headerEl"
        class="header flex flex-col no-wrap"
        :class="[patientBannerPatientId && 'with-patient']"
      >
        <m-layout-stack class="header-content" horizontal full>
          <slot name="header" :title-id="labelId">
            <div class="flex-col">
              <div class="flex">
                <h1 :id="labelId" class="title q-mr-xs">
                  {{ title || name }}
                </h1>
                <m-badge v-for="(b, i) in badges" :key="i" v-bind="b" />
              </div>
              <div class="text-grey sub-title">
                {{ description }}
              </div>
            </div>
          </slot>

          <q-space />

          <m-button
            class="slideover-close-btn"
            icon="fa-light fa-xmark"
            color="secondary"
            title="Close Dialog"
            label="Close Dialog"
            icon-only
            borderless
            data-testid="modal-closeBtn"
            @click="closeSlideover"
          />
        </m-layout-stack>
        <div v-if="patientBannerPatientId" class="patient-info">
          <m-patient-banner :patient-id="patientBannerPatientId" blue-accent />
        </div>
      </q-card-section>
    </slot>

    <!-- BODY -->
    <q-card-section class="card-section">
      <slot />
    </q-card-section>

    <slot name="footer" />
  </q-card>
</template>

<style lang="scss">
.slideover-card {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  overflow: hidden !important;
  padding-top: v-bind(headerSize);

  &::-webkit-scrollbar {
    display: none;
  }

  .card-section {
    padding-bottom: 3em !important;
    padding: 10px 30px;
    overflow: auto;
    flex: 1 1 auto;
  }

  .header {
    padding: 10px 0;
    border-bottom: 1px solid var(--grey-light);
    position: fixed;
    top: 0;
    width: inherit;
    background: #fff;

    // must be heigher than the medicus-outline
    z-index: 9999999;

    max-width: 100%;

    &.with-patient {
      padding-bottom: 0;
    }

    > .header-content {
      padding: 0 30px;
    }

    > .patient-info > .encounter-patient {
      margin-top: 1em;
      padding: 10px 30px;
    }
    .slideover-close-btn {
      height: 30px;
    }
    .sub-title {
    }
  }

  .m-simple-table {
    border-left: none;
    border-right: none;
  }
}
</style>
