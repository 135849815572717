<script lang="ts" setup>
import { computed, ref, toRef, watch } from "vue";
import { useEvent, useFormat } from "vue-composable";
import { useRoute } from "vue-router";
import { ExtractComponentProps } from "../../../utils/types";
import MAction from "../MAction";
import MButton from "../MButton";
import MLayoutStack from "../MLayoutStack";
import MTaskPreview from "../MTaskPreview";
import MPage from "./../MPage";
import MSplitScreen from "./../MSplitScreen";
import MTaskListItem from "./../MTaskListItem";
import { Task } from "./types";

const props = defineProps({
  ...(MPage.props as ExtractComponentProps<typeof MPage>),

  items: Array as () => Task[],

  modelValue: Object,

  description: String,

  noPadding: Boolean,

  noScroll: Boolean,

  itemTitle: String,

  showFilter: Boolean,

  showingText: String,

  itemCountText: {
    type: String,
    default: "Showing {0} item(s)",
  },

  dateCustomText: String,

  createAction: Object,

  hideChangeAssignee: Boolean,

  hidePatientBanner: Boolean,

  hideComments: {
    type: Boolean,
    default: false,
  },

  viewHistoricalAndSnoozedRoute: {
    type: String,
    required: true,
  },
});

const emit = defineEmits({
  "update:modelValue": (_item: any) => true,
});

const route = useRoute();
// const reloadData = inject("reloadData", NOOP);

const setItem = (item: any) => emit("update:modelValue", item);

const viewHistoricalAndSnoozedFeRoute = computed(
  () =>
    `${props.viewHistoricalAndSnoozedRoute.replace("/data/", "/")}${
      Object.keys(route.query).length ? `?${new URLSearchParams(route.query)}` : ""
    }`,
);

const itemsShowingText = useFormat(
  toRef(props, "itemCountText"),
  computed(() => [props.items?.length || 0]),
);

const taskListPage = ref<InstanceType<typeof MPage> | null>();

const isFullscreen = ref(false);

function toggleFullscreen() {
  isFullscreen.value = !isFullscreen.value;
  if (isFullscreen.value) {
    (taskListPage.value?.$el as HTMLElement).requestFullscreen();
  } else {
    document.exitFullscreen();
  }
}

useEvent(document, "fullscreenchange", () => {
  isFullscreen.value = document.fullscreenElement === taskListPage.value?.$el;
});

const snoozedDate = ref(props.modelValue?.snoozeUntil);

watch(
  () => props.modelValue,
  (s) => (snoozedDate.value = s?.snoozeUntil),
);
</script>
<template>
  <m-page
    ref="taskListPage"
    class="m-task-list"
    :description="description"
    no-overflow
    no-padding
    bg-white
    no-gutter
    hide-header
  >
    <!-- <template #header>
      <div />
    </template> -->
    <m-split-screen
      class="task-list-split-screen"
      :model-value="modelValue"
      persistent
      no-padding
      no-content-scroll
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <slot name="items" v-bind="{ items }">
        <div class="flex flex-col gap-1 flex-nowrap h-full">
          <h1 v-if="title" class="title">
            {{ title }}
          </h1>

          <slot name="before:item" />

          <m-layout-stack class="justify-between items-center q-mr-md" gap="1" horizontal>
            <span class="task-list-items-showing" data-testid="task-list-items-showing">
              {{ itemsShowingText }}
            </span>
            <div>
              <m-action :model-value="createAction" />
            </div>
          </m-layout-stack>
          <div class="task-list-items scroll-area">
            <div
              v-for="(item, i) in items"
              :key="`${i}_${item.id}_${item.assignee?.id}_${item.snoozeUntil}`"
            >
              <slot
                name="item"
                v-bind="{
                  items,
                  item,
                  index: i,
                  selected: item === modelValue,
                  setItem: () => setItem(item),
                }"
              >
                <m-task-list-item
                  v-slot="bindings"
                  v-bind="item"
                  :selected="item.id === modelValue.id"
                  :date-text="dateCustomText"
                  :is-overdue="item.isOverdue"
                  @click="setItem(item)"
                >
                  <slot name="item:content" v-bind="bindings" />
                </m-task-list-item>
              </slot>
            </div>
            <div
              v-if="items?.length === 0"
              class="q-mx-md empty-text flex items-center justify-center h-full"
            >
              <slot name="empty:items">There are no tasks to display.</slot>
            </div>
          </div>
          <div class="view-historical-tasks">
            <m-button
              ghost
              :to="viewHistoricalAndSnoozedFeRoute"
              label="View historical and snoozed tasks"
            />
          </div>
        </div>
      </slot>

      <template #item="itemBind">
        <div class="h-full flex flex-col flex-nowrap" :class="{ 'padded-content': !noPadding }">
          <template v-if="itemBind.item">
            <m-task-preview
              :hide-patient-banner="hidePatientBanner"
              :item="itemBind.item"
              :hide-change-assignee="hideChangeAssignee"
              :task-type="itemBind.item.type"
              :change-task-assignee-route="itemBind.item.changeTaskAssigneeRoute"
              :no-scroll="noScroll"
              :no-padding="noPadding"
              :hide-comments="hideComments"
              :enable-full-screen="true"
              :is-full-screen="isFullscreen"
              :change-task-due-date-route="itemBind.item.changeTaskDueDateRoute"
              :disable-edit-due-date="itemBind.item.disableEditDueDate"
              @toggle-full-screen="toggleFullscreen"
            >
              <slot v-bind="itemBind" />
            </m-task-preview>
          </template>

          <template v-else>
            <div class="task-list-item-content flex items-center justify-center empty-text">
              No task has been selected.
            </div>
          </template>
        </div>
      </template>
    </m-split-screen>
  </m-page>
</template>
<style lang="scss">
.m-task-list {
  padding-top: 0;

  .content {
    max-width: 320px !important;

    .top-border > .m-task-list-item {
      border-top: 1px solid var(--border-colour);
    }
  }

  .padded-content {
    padding: 16px;
    padding-bottom: 0px;
  }

  .task-list-split-screen {
    height: 100%;

    .content .title {
      margin: 10px 16px 4px 16px;
    }
  }

  .persistent-item {
    border: 1px solid var(--border-colour);
    border-bottom: none;
    border-top: none;
  }

  .task-list-items-showing {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 19px */

    color: var(--grey-darkest);

    margin-left: 1rem;
    display: block;
  }

  .task-list-item-content {
    height: 100%;
    overflow: hidden;
    background: var(--grey-lightest);
  }

  .view-historical-tasks {
    align-items: center;
    border-top: 1px solid var(--border-colour);
    padding: 10px;
    height: 40px;
  }
}

.filter-title {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;

  color: var(--text-color);
}
</style>
