<template>
  <div
    class="m-appointment-card flex flex-col"
    :class="{
      'with-hover cursor-pointer medicus-outline': onClick,
      grey: appointment?.displayStatus?.isEnded,
    }"
    role="button"
    tabindex="0"
    @click="onClick"
    @click.right.prevent="
      () => {
        allowRightClick ? handleAvailableRightClick() : undefined;
      }
    "
  >
    <slot>
      <template v-if="appointment">
        <m-layout-stack no-gap>
          <div class="card-title">
            <div class="appointment-info">
              <span v-if="startTime" class="time">{{ startTime }} – </span>
              <span>{{ appointment?.patient?.name }}</span>
            </div>
            <m-badge
              v-if="appointment.displayStatus && !appointment.displayStatus.isBooked"
              :text="appointment.displayStatus.label"
              :amber="displayStatusColour"
            />
          </div>
          <div v-if="appointment?.patient?.preferredName" class="card-description">
            <i
              >Preferred name <b>{{ appointment?.patient?.preferredName }}</b></i
            >
            <m-separator />
          </div>
        </m-layout-stack>
        <p v-if="!hideReasonForAppointment" class="card-description">
          <span>{{ appointment.compiledReasonForAppointment }}</span>
        </p>
        <p class="card-description">
          <span>{{ `${appointment.duration} mins` }} • {{ appointment.deliveryMode.label }}</span>
        </p>
        <div class="card-footer">
          <m-badge v-if="appointment.isHighPriority" text="High Priority" amber />

          <m-badge
            v-if="appointment.overlapsWithOtherAppointments"
            text="Overlapping Appointment"
            amber
          />
        </div>
      </template>
    </slot>

    <MMenu v-model="isContextMenuShowing" no-auto-open>
      <slot name="context-menu-options"></slot>
    </MMenu>
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";
import { randomID } from "../../../../utils/helpers";
import MLayoutStack from "../../MLayoutStack/MLayoutStack.vue";
import MBadge from "../../MBadge";
import MSeparator from "../../MSeparator/MSeparator.vue";
import { Appointment as AppointmentType } from "./types";
import MMenu from "../../MMenu";
export default defineComponent({
  components: { MMenu, MLayoutStack, MSeparator, MBadge },

  props: {
    onClick: {
      type: Function as PropType<(e?: MouseEvent) => void>,
      required: true,
    },

    appointment: {
      required: false,
      type: Object as () => AppointmentType & {
        title?: string;
        description?: string;
        formattedDuration?: string;
      },
      default: null,
    },

    hideMoveAppointmentToSameDiaryOption: {
      required: false,
      type: Boolean,
      default: false,
    },

    hideReasonForAppointment: {
      required: false,
      type: Boolean,
      default: false,
    },

    allowRightClick: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const controlId = randomID("m-appointment-card");

    return {
      controlId,
    };
  },

  data() {
    return {
      isContextMenuShowing: false,
    };
  },

  computed: {
    startTime(): string | undefined {
      if (this.appointment?.startDateTime) {
        return this.$formatDateTime(this.appointment.startDateTime).split(", ")[1];
      }
      return undefined;
    },

    deliveryModeLabel() {
      if (!this.appointment?.deliveryMode) return undefined;

      if (this.appointment?.deliveryMode.isFaceToFace) {
        return "Face to Face";
      }

      if (this.appointment?.deliveryMode.isHomeVisit) {
        return "Home Visit";
      }

      if (this.appointment?.deliveryMode.isVideo) {
        return "Video";
      }
      return "Call";
    },

    deliveryModeIcon() {
      if (!this.appointment) return undefined;
      if (this.appointment.deliveryMode.isFaceToFace) {
        return "fa-solid fa-hospital";
      }
      if (this.appointment.deliveryMode.isHomeVisit) {
        return "fa-solid fa-house";
      }
      if (this.appointment.deliveryMode.isVideo) {
        return "fa-solid fa-video";
      }
      return "fa-solid fa-phone";
    },

    displayStatusColour(): boolean | undefined {
      return this.appointment?.displayStatus?.isDidNotAttend;
    },

    ariaLabel() {
      return this.appointment?.patient?.name;
    },
  },

  methods: {
    handleAvailableRightClick() {
      this.isContextMenuShowing = !this.isContextMenuShowing;
    },
  },
});
</script>

<style lang="scss">
// BUTTON needs some special handling
button.m-appointment-card {
  // width: calc(100% - 30px);
  width: 100%;
  overflow: hidden;

  text-align: left;
}

.m-appointment-card {
  border: none;
  padding: 2px 2px 2px var(--gap-2);
  background: #ffffff;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  flex-wrap: nowrap;
  user-select: none;
  border: 2px solid transparent;

  display: flex;
  flex: 0 0 auto;

  &.selected {
    background: var(--hover-background);
    border-radius: 8px;
  }
  &.ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  &.grey {
    opacity: 0.4;
  }
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .appointment-info {
    color: var(--theme-darkest-blue);
    transition: var(--transition);
    font-size: 14px;

    .time {
      font-weight: bold;
    }
  }

  .card-description {
    color: var(--theme-grey);
    word-break: break-word;
    hyphens: auto;
  }

  .card-footer {
    &:empty {
      display: none;
    }

    color: var(--theme-grey);
    margin-top: 0.2em;
    * {
      margin-right: 8px;
    }
  }
}
.with-hover:hover {
  border-color: var(--theme-blue);

  .appointment-info {
    color: var(--theme-blue);
  }
}
</style>
