// All Entry Types
export enum EntryType {
  CARE_RECORD_NOTE = "note",
  ALLERGY = "allergy",
  OBSERVATION = "observation",
  PRESCRIPTION = "prescription",
  MEDICATION_STATEMENT_PRESCRIBED_ELSEWHERE = "medication-statement-prescribed-elsewhere",
  MEDICATION_STATEMENT_OVER_THE_COUNTER = "medication-statement-over-the-counter",
  FOLLOW_UP_APPOINTMENT = "follow-up-appointment",
  RECALL = "recall",
  FIT_NOTE = "fit-note",
  IMMUNISATION = "immunisation",
  MEDICATION_ADMINISTRATION = "medication-administration",
  PROCEDURE = "procedure",
  COMMUNICATION = "communication",
  COMMUNICATION_THREAD = "communication-thread",
  INVESTIGATION = "investigation",
  REFERRAL_ATTACHMENT = "referral-attachment",
  INBOUND_DOCUMENT = "inbound-document",
  OUTBOUND_REFERRAL = "outbound-referral",
  FUTURE_ACTION = "future-action",
  TASK = "task",
  APPOINTMENT = "appointment",
  DOCUMENT = "document",
  INVESTIGATION_REQUEST = "investigation-request",
}

// Change-Notes API Object

export type Note = {
  id: string;
  entryType: EntryType;
  note?: string;
  code?: SnomedCode;
  hasUnsavedChanges: boolean;
  isMarkedAsIncorrect: boolean;
};

export type SnomedCode = {
  description: string;
  conceptId?: string;
  descriptionId?: string;
  parentConceptIds?: string[];
};

// Entries API Objects
export type BaseEntryProps = {
  id: string;
  createdDateTime?: string;
  recordedByOrganisation?: string;
  confidentialFromThirdParties?: boolean;
  hiddenFromPatientFacingServices?: boolean;
  linkedProblems?: [];
  sortOrder?: number | null;
  isMarkedAsIncorrect: boolean;
};

export type Entry =
  | NoteEntry
  | AllergyEntry
  | ObservationEntry
  | PrescriptionEntry
  | PrescribedElsewhereEntry
  | OverTheCounterEntry
  | FollowUpAppointmentEntry
  | RecallEntry
  | FitNoteEntry
  | ImmunisationEntry
  | MedicationAdministrationEntry
  | ProcedureEntry
  | CommunicationEntry
  | InvestigationEntry
  | ReferralEntry
  | InboundDocumentEntry
  | OutboundReferralEntry
  | FutureActionEntry
  | InvestigationRequestEntry;

export type NoteEntry = BaseEntryProps & {
  entryType: EntryType.CARE_RECORD_NOTE;
  recordDate?: string;
  recordedBy?: string;
  clinicalCodeDescription?: string | null;
  isExplicitlyIncludedInSCR?: boolean;
  isMarkedAsIncorrect?: boolean;
  riskAlert?: boolean;
  note?: string;
};

export type AllergyEntry = {
  entryType: EntryType.ALLERGY;
  substanceDescription?: string;
  allergyCodeDescription?: string;
  severity?: string;
  certainty?: string;
  isEnded: boolean;
  isMarkedAsIncorrect: boolean;
} & BaseEntryProps;

export type ObservationEntry = {
  entryType: EntryType.OBSERVATION;
  type?: string;
  value: string;
  approximateValue: boolean;
  isMarkedAsIncorrect: boolean;
  additionalInformation?: string;
} & BaseEntryProps;

export type PrescriptionEntry = {
  entryType: EntryType.PRESCRIPTION;
  productName: string;
  dosageText: string;
  prescriptionTypeLabel?: string | null;
  isAcutePrescription: boolean;
  numberOfIssues?: number | null;
  displayStatus: string;
  requiresAction: boolean;
  isDiscontinued: boolean;
  issueQuantity: string;
} & BaseEntryProps;

export type PrescribedElsewhereEntry = {
  entryType: EntryType.MEDICATION_STATEMENT_PRESCRIBED_ELSEWHERE;
  productName: string;
  dosageInstruction: string;
  prescriptionType: string;
} & BaseEntryProps;

export type OverTheCounterEntry = {
  entryType: EntryType.MEDICATION_STATEMENT_OVER_THE_COUNTER;
  productName: string;
  dosageInstruction: string;
} & BaseEntryProps;

export type FollowUpAppointmentEntry = {
  entryType: EntryType.FOLLOW_UP_APPOINTMENT;
  startDateTime: string;
  deliveryMode: string;
  serviceName: string;
} & BaseEntryProps;

export type RecallEntry = {
  entryType: EntryType.RECALL;
} & BaseEntryProps;

export type FitNoteEntry = {
  entryType: EntryType.FIT_NOTE;
  diagnosis?: string;
  decision?: string;
  validPeriod?: string;
} & BaseEntryProps;

export type ImmunisationEntry = {
  entryType: EntryType.IMMUNISATION;
  code?: string;
  administeringOrganisation?: string;
  isPointOfCare?: boolean;
  isVaccinationHistory?: boolean;
  reasonNotGiven?: boolean;
} & BaseEntryProps;

export type MedicationAdministrationEntry = {
  entryType: EntryType.MEDICATION_ADMINISTRATION;
  productName?: string;
  quantityAdministered?: string;
} & BaseEntryProps;

export type ProcedureEntry = {
  entryType: EntryType.PROCEDURE;
  body?: string;
  procedureCode?: string;
  isExplicitlyIncludedInSCR?: boolean;
} & BaseEntryProps;

export type CommunicationEntry = {
  entryType: EntryType.COMMUNICATION;
  sentBy: string;
  sentByOrg: string;
  snomedCTCode: string;
  summary: string;
  careRecordStatus?: {
    isDraft?: boolean;
    isDraftDeleted?: boolean;
    isEditable?: boolean;
    isFinalised?: boolean;
    isMarkedIncorrect?: boolean;
    isMarkedIncorrectAndHidden?: boolean;
    isVisible?: boolean;
    label?: string;
    showOnCareRecord?: boolean;
    value?: string;
  };
} & BaseEntryProps;

export type InvestigationEntry = {
  entryType: EntryType.INVESTIGATION;
} & BaseEntryProps;

export type ReferralEntry = {
  entryType: EntryType.REFERRAL_ATTACHMENT;
  isManual: boolean;
  incompleteStatusLabel?: string;
  cancelledStatusLabel?: string;
  referralCode: string;
  priority?: string;
  isMarkedAsIncorrect: boolean;
  referringClinician?: string;
  referralProvider?: string;
  uniqueBookingReferenceNumber: string;
  shortlistedServices: string;
  referralType: string;
  isAdviceAndGuidance?: boolean;
} & BaseEntryProps;

export type InboundDocumentEntry = {
  entryType: EntryType.INBOUND_DOCUMENT;
} & BaseEntryProps;

export type OutboundReferralEntry = {
  entryType: EntryType.OUTBOUND_REFERRAL;
  isManual?: boolean;
  incompleteStatusLabel?: string;
  cancelledStatusLabel?: string;
  referralCode?: string;
  priority?: string;
  referringClinician?: string;
  referralProvider?: string;
  uniqueBookingReferenceNumber?: string;
  shortlistedServices?: string;
  referralType?: string;
  isExplicitlyIncludedInSCR?: boolean;
  isAdviceAndGuidance?: boolean;
} & BaseEntryProps;

export type FutureActionEntry = {
  entryType: EntryType.FUTURE_ACTION;
  clinicalCode?: string;
  displayStatus?: string;
  plannedDateRange?: string;
} & BaseEntryProps;

export type InvestigationRequestEntry = {
  entryType: EntryType.INVESTIGATION_REQUEST;
  investigationRequestItems?: [];
  isMarkedAsIncorrect: boolean;
} & BaseEntryProps;

// Other
export type SaveNotePartial = {
  uuid: string;
  entryType: EntryType;
  note?: string;
  code?: object;
};

export type Action = {
  type: string;
  props: [];
  contextId: string;
  contextType: string;
  label: string;
  url: string;
};

export type SortOrder = { id: string; entryType: EntryType }[];
