<template>
  <div style="width: 100%">
    <h5>Outbound sms</h5>
    <q-field borderless label="To patient" stack-label>
      <template #control>
        <div class="self-center full-width no-outline" tabindex="0">
          {{ patient.label }}
        </div>
      </template>
    </q-field>
    <q-field borderless label="Message" stack-label>
      <template #control>
        <div class="self-center full-width no-outline" tabindex="0">
          {{ message }}
        </div>
      </template>
    </q-field>
    <q-field borderless label="Status" stack-label>
      <template #control>
        <div class="self-center full-width no-outline" tabindex="0">
          {{ status }}
        </div>
      </template>
    </q-field>
    <q-field borderless label="Created at" stack-label>
      <template #control>
        <div class="self-center full-width no-outline" tabindex="0">
          {{ createdAt }}
        </div>
      </template>
    </q-field>
  </div>
</template>

<script>
import { QField } from "quasar";
export default {
  components: { QField },
  props: {
    status: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    toNumber: {
      type: String,
      default: "",
    },
    patient: {
      type: Object,
      default: null,
    },
    errorCode: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    createdAt: {
      type: String,
      default: "",
    },
    sentAt: {
      type: String,
      default: "",
    },
  },
};
</script>
