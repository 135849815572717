<script lang="ts" setup>
import merge from "deepmerge";
import { Loading, QSpinnerBall } from "quasar";
import {
  ComponentOptions,
  ComponentPublicInstance,
  DefineComponent,
  computed,
  getCurrentInstance,
  h,
  markRaw,
  provide,
  ref,
  watch,
} from "vue";

import { isPlainObject } from "is-plain-object";
import { isFunction, isString } from "vue-composable";
import { useApp, useAuth, useKeyboardShortcutsStore } from "../../store";
import { dispatch } from "../../utils/bus";
import { loadComponent, loadComponentData } from "../../utils/component";
import { showDialog } from "../../utils/dialog";
import { randomID } from "../../utils/helpers";
import { parseRoute } from "../../utils/route";
import { expiresIn, updateExpires } from "../../utils/session";

const props = defineProps<{
  componentPath: string;
  absoluteURL?: string;
  tenant: string;

  query?: Record<string, any>;
}>();

const emit = defineEmits({
  loading: () => true,
  loaded: () => true,
});

const app = useApp();
const auth = useAuth();
useKeyboardShortcutsStore();

const appContext = getCurrentInstance().appContext;

const componentEl = ref<InstanceType<DefineComponent>>();
const subnavEl = ref<InstanceType<DefineComponent>>();

const comp = ref<ComponentOptions>();
const subnav = ref<ComponentOptions>();

const queryPath = computed(() => {
  return `${props.componentPath}${
    props.query ? location.search || `?${new URLSearchParams(props.query)}` : ""
  }`;
});

let loadId = randomID();
async function resolveComponent(componentPath: string, signal: AbortSignal, absoluteURL?: string) {
  Loading.show({
    spinner: QSpinnerBall,
    delay: 500,
    boxClass: "medicus-loading-ball",
  });
  let currentLoadId = (loadId = randomID());

  emit("loading");

  try {
    const c = await loadComponent(
      componentPath,
      undefined,
      undefined,
      absoluteURL,
      undefined,
      appContext.config.globalProperties,
    );

    if (c) {
      try {
        await loadSidenav(c, ("data" in c && (c as any).data(c as any)) ?? undefined, signal);
      } catch (e) {
        console.error("failed refreshing sidenav", e);
      }
      if (signal.aborted) return;

      comp.value = markRaw(c);
    } else {
      if (loadId === currentLoadId) {
        comp.value = markRaw({
          render() {
            return h("div", "Unknown Error was found while loading the page");
          },
        });
      }
    }
  } catch (e: any) {
    // just catch
  } finally {
    Loading.hide();
    emit("loaded");
  }
}

function patchSubnavData(
  sn: Awaited<ReturnType<typeof loadComponent>>,
  c: Awaited<ReturnType<typeof loadComponent>>,
) {
  if (sn && c?.subnavData) {
    const _beforeUnmount = sn.beforeUnmount;
    sn.beforeUnmount = function () {
      _beforeUnmount?.call(this);
      // clear watch
      remove();
    };

    const subnavData = computed(() => {
      const instance = componentEl.value!;
      const subnavData: (vm: ComponentPublicInstance) => Record<string, any> =
        // @ts-expect-error subnavData does not exist
        instance?.$?.type?.subnavData;
      return subnavData?.bind(instance)(instance);
    });

    const remove = watch(
      [subnavEl, subnavData],
      ([sub, data], [psub, pdata]) => {
        if (!sub || !data) return;
        if (psub && sub !== psub) return;

        Object.assign(sub.$data, data);
      },
      {
        // we do immediate to keep track on the previously
        // rendered component, this way we know we should
        // only assign if sub !== psub
        immediate: true,
      },
    );
  }
}
let lastSubnav = "";
async function loadSidenav(
  comp: Awaited<ReturnType<typeof loadComponent>>,
  context: any = undefined,
  signal: AbortSignal,
) {
  if (!comp) {
    subnav.value = undefined;
    return;
  }
  const subnavPath = isFunction(comp.subnav)
    ? comp.subnav.bind(context || appContext)(appContext)
    : comp.subnav;
  if (!subnavPath) {
    subnav.value = undefined;
    return;
  }

  const { componentPath } = parseRoute(props.componentPath);

  const absPath = subnavPath[0] === "/" ? subnavPath : componentPath + "/" + subnavPath;
  const absoluteUrl = new URL(
    absPath.endsWith(".vue") ? absPath : absPath + ".vue",
    location.origin,
  )
    .toString()
    .replace(location.origin, "");

  if (absoluteUrl === lastSubnav && subnav.value) return subnav.value;

  lastSubnav = absoluteUrl;

  const s = await loadComponent(absoluteUrl, true, undefined, absoluteUrl, undefined);
  if (signal.aborted) return;

  patchSubnavData(s, comp);
  subnav.value = s ? markRaw(s) : undefined;
}

watch([componentEl, comp], ([rendered, comp], _, onInvalidate) => {
  if (!rendered || !comp) return;
  const sn = comp.subnav;
  if (typeof sn !== "function") return;

  const w = watch(
    () => sn.bind(rendered)(appContext),
    () => {
      loadSidenav(comp, rendered, { aborted: false } as AbortSignal);
    },
    {},
  );

  onInvalidate(w);
});

watch([componentEl, comp], ([rendered, comp], _, onInvalidate) => {
  if (!rendered || !comp) return;
  const title = comp.title;
  if (isString(title)) {
    app.updateTitle(title);
  } else if (typeof title === "function") {
    const w = watch(
      () => title.bind(rendered)(appContext),
      (t) => {
        app.updateTitle(t);
      },
      {
        immediate: true,
      },
    );
    onInvalidate(w);
  } else {
    app.updateTitle("");
    warn("This page has no title. Please add a title property to a component.");
  }
});

async function refreshData() {
  if (componentEl.value) {
    const parsed = parseRoute(
      `${props.componentPath}${
        props.query ? location.search || `?${new URLSearchParams(props.query)}` : ""
      }`,
    );
    try {
      const data: any = await loadComponentData(parsed.fullPath, parsed.componentPath);
      Object.assign(
        componentEl.value.$data,
        merge(componentEl.value.$data, data, {
          isMergeableObject: isPlainObject,
        }),
      );
    } catch {
      // ignore
    }
  }
}

function reloadData() {
  if (comp.value?.reload) {
    const method = isString(comp.value.reload)
      ? componentEl.value[comp.value.reload]
      : comp.value.reload.bind(componentEl.value);
    method();
    return;
  }
  dispatch("reloadData", true);
  return refreshData();
}

provide("renderedComponent", componentEl);
provide("reloadData", reloadData);

function formatTime(ms: number) {
  const secs = ms / 1000;
  if (secs >= 60) {
    return `${Math.ceil(secs / 60)} minutes`;
  }

  return `${Math.floor(secs)} seconds`;
}

let showingDialog: ReturnType<typeof showDialog> | null = null;
watch(expiresIn, (e, o) => {
  if (e === null) {
    updateExpires();
    return;
  }
  // if the current value is greater than previous we should
  // close the modal
  if (e > o || e <= 0) {
    showingDialog?.close();
  }

  if (Math.ceil(e / 1000 / 60) > 2 || showingDialog) {
    return;
  }

  showingDialog = showDialog({
    title: "Automatic Logout",
    okLabel: "Stay logged in",
    okColor: "",
    cancelLabel: "Logout",

    component: markRaw(
      defineComponent({
        setup() {
          let timesup = false;

          return () => {
            if (expiresIn.value <= 5000 || timesup || !expiresIn.value) {
              timesup = true;
              return h("p", ["For your security, we will log you out ", h("b", "now"), "."]);
            }

            return h("p", [
              "For your security, we will log you out in ",
              h("b", formatTime(expiresIn.value)),
              ".",
            ]);
          };
        },
      }),
    ),
  })
    .onOk(async () => {
      await reloadData();
      showingDialog = null;
    })
    .onCancel(() => {
      showingDialog = null;
      auth.logout();
    });
});

defineExpose({
  reloadData,

  subnav,
});

try {
  await resolveComponent(queryPath.value, { aborted: false } as AbortSignal, props.absoluteURL);
} catch {
  // ignore
}
watch([() => props.componentPath, () => props.tenant], ([c, t], [pc, pt], onCleanup) => {
  if (auth.selectedTenant !== t) {
    auth.selectedTenant = t;
  }
  const abortController = new AbortController();
  // the query path has component + query
  // but for the watch we just want the componentPath to be changed
  resolveComponent(queryPath.value, abortController.signal, props.absoluteURL);

  onCleanup(() => abortController.abort());
});
</script>
<script lang="ts">
import { defineComponent } from "vue";
import { warn } from "vue";

export default defineComponent({
  inheritAttrs: false,
});
</script>
<template>
  <comp ref="componentEl" />
  <subnav v-if="subnav" :key="`subnav-${tenant}`" ref="subnavEl" />
</template>
