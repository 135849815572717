<template>
  <div class="m-date-picker">
    <m-button
      ghost
      icon="fa-light fa-chevron-left"
      icon-only
      :label="`Previous ${view}`"
      @click="prev"
    />
    <q-btn class="date-text-container" no-caps flat>
      <span class="date-text">
        <span class="sr-only">Change Date</span>
        {{ showText }}
      </span>

      <q-popup-proxy v-model="showForm" transition-show="scale" transition-hide="scale">
        <q-date
          ref="datePickerEl"
          v-model="dateText"
          minimal
          mask="DD/MM/YYYY"
          :navigation-max-year-month="maxDateNavigation"
          :navigation-min-year-month="minDateNavigation"
          :default-view="defaultView"
          :options="dateOptions"
          @navigation="onNavigation"
          @update:model-value="onModelValue"
        />
      </q-popup-proxy>
    </q-btn>

    <m-button
      ghost
      icon="fa-light fa-chevron-right"
      icon-only
      :label="`Next ${view}`"
      @click="next"
    />
  </div>
</template>
<script lang="ts">
export const enum IncrementStep {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
}

export default {};
</script>
<script setup lang="ts">
import { QDate, QPopupProxy } from "quasar";

import type { DateOptions } from "quasar";
import { date } from "quasar";
import { computed, ref } from "vue";
import { isDate, isNumber, isString, useVModel } from "vue-composable";
import { getMaxDate, getMinDate } from "../../../../utils/helpers";
import MButton from "../../MButton";

const props = defineProps({
  modelValue: [Date, String, Object, Number] as unknown as () =>
    | string
    | number
    | Date
    | { from: string; to: string },
  minDate: {
    type: [Date, String],
    default() {
      return new Date(1000, 1, 1);
    },
  },
  maxDate: {
    type: [Date, String],
    default() {
      return new Date(new Date().getFullYear() + 100, 1, 1);
    },
  },

  view: {
    type: String as () => IncrementStep,
    default() {
      return "day";
    },
  },
});

const showForm = ref(false);

function parseDate(e: any) {
  if (!e) return "";
  // check if does not have time, if not remove the current timezone
  if (typeof e === "string" && e.indexOf(":") === -1) {
    const d = new Date(e);
    return date.addToDate(d, { minutes: d.getTimezoneOffset() });
  }
  return e;
}

const dateValue = useVModel(props, "modelValue");
const dateText = computed<string | { from: string; to: string }>({
  get() {
    if (!dateValue.value) return "";
    if (isDate(dateValue.value) || isString(dateValue.value) || isNumber(dateValue.value)) {
      const d = parseDate(dateValue.value);

      return date.formatDate(d, "DD/MM/YYYY");
    }

    return {
      from: date.formatDate(dateValue.value.from, "DD/MM/YYYY"),
      to: date.formatDate(dateValue.value.to, "DD/MM/YYYY"),
    };
  },
  set(v: string | { from: string; to: string } | Date) {
    if (isDate(v) || isString(v) || isNumber(v)) {
      const d = isString(v) ? v.split("/").reverse().join("-") : v;
      dateValue.value = date.formatDate(new Date(d), "YYYY-MM-DD");
    } else if (v !== null) {
      const from = isString(v.from) ? v.from.split("/").reverse().join("-") : v.from;
      const to = isString(v.to) ? v.to.split("/").reverse().join("-") : v.to;

      dateValue.value = {
        from: date.formatDate(from, "YYYY-MM-DD"),
        to: date.formatDate(to, "YYYY-MM-DD"),
      };
    }
  },
});

const maxDateNavigation = computed(() => {
  const d = getMaxDate(props.maxDate, false);
  return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, "0")}`;
});
const minDateNavigation = computed(() => {
  const d = getMinDate(props.minDate, false);
  return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, "0")}`;
});

function dateOptions(dateValue: any) {
  const d = new Date(dateValue);
  const max = getMaxDate(props.maxDate, false);
  const min = getMinDate(props.minDate, false);
  return d >= min && d <= max;
}

function formatDate(d: string | number | Date, showYear = true) {
  return date.formatDate(d, `ddd DD MMM${showYear ? " YYYY" : ""}`);
}

function formatDisplayDate(d: string | number | Date, type: string) {
  switch (type) {
    case "month": {
      return date.formatDate(d, `MMM YYYY`);
    }
    case "week": {
      const n = new Date(date.formatDate(d, "YYYY-MM-DD"));

      return (
        "w/c " +
        date.formatDate(
          date.subtractFromDate(d, {
            days: n.getDay() - 1,
          }),
          `DD MMM YYYY`,
        )
      );
    }

    default:
    case "day": {
      return date.formatDate(d, `ddd DD MMM YYYY`);
    }
  }
}

const showText = computed(() => {
  if (!props.modelValue) return "";

  if (isDate(props.modelValue) || isString(props.modelValue) || isNumber(props.modelValue)) {
    return formatDisplayDate(props.modelValue, props.view);
  }
  return `${formatDate(props.modelValue.from, false)} - ${formatDate(props.modelValue.to, false)}`;
});

function doStep(forward: boolean) {
  const v = dateValue.value;
  if (!v) return;
  let step = 1;

  switch (props.view) {
    case IncrementStep.day: {
      step = 1;
      break;
    }
    case IncrementStep.week: {
      step = 7;
      break;
    }
    case IncrementStep.month: {
      step = 1;
      break;
    }
    case IncrementStep.year: {
      step = 1;
      break;
    }
  }
  if (!forward) {
    step = -step;
  }

  const options: DateOptions =
    props.view === IncrementStep.month
      ? {
          months: step,
        }
      : props.view === IncrementStep.year
      ? { years: step }
      : { days: step };

  if (isDate(v) || isString(v) || isNumber(v)) {
    // @ts-expect-error not valid overload
    dateText.value = date.addToDate(v, options);
  } else {
    dateText.value = {
      // @ts-expect-error not valid overload
      from: date.addToDate(v.from, options),
      // @ts-expect-error not valid overload
      to: date.addToDate(v.to, options),
    };
  }
}

function next() {
  doStep(true);
}

function prev() {
  doStep(false);
}

const defaultView = computed(() => (props.view === IncrementStep.month ? "Months" : undefined));

function onNavigation(view: { year: number; month: number }) {
  if (props.view !== IncrementStep.month) {
    return;
  }

  const from = new Date(view.year, view.month - 1, 1);
  const to = date.addToDate(from, { months: 1, days: -1 });

  // @ts-expect-error not valid overload
  dateText.value = { from, to };

  showForm.value = false;
}

function onModelValue(value: string) {
  showForm.value = false;
}
const datePickerEl = ref(null as QDate | null);
// enableFocusTrap(computed(() => datePickerEl.value?.$el));
</script>
<style lang="scss">
.m-date-picker {
  user-select: none;

  color: var(--text-color);
  display: flex;
  gap: 0.25rem;
  font-size: 14px;

  align-self: center;
  align-items: center;

  .date-text-container {
    width: 150px;
    padding: 0;
    display: flex;

    justify-content: center;
  }

  i {
    width: 40px;
  }

  span {
    display: flex;
    align-items: center;

    padding: 0 0.25rem;
  }

  i,
  span {
    height: 35px;
    cursor: pointer;

    &:hover {
      background: #e7eaee;
    }
  }
}
</style>
