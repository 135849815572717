<script lang="ts" setup>
import { ref, watch, onMounted, nextTick, Ref } from "vue";
import { CursorValues, TextAreaKeyboardEvent, Direction, SnomedClinicalCode } from "./types";
import { Action } from "../MConsultationTopicHeading/types";
import { Key } from "ts-key-enum";
import { QItem } from "quasar";
import { useDebouncedThrottledFunction } from "../../../../composables/throttleDebounce";
import axios from "../../../../utils/axios";

const props = defineProps<{
  actions: Action[];
  disabled?: boolean;
  labelledBy: string;
  id: string;
  value?: string;
}>();

const emit = defineEmits({
  "update:modelValue": (_id: string, _newValue: string, _immediate: boolean = false) => true,
  codeSelected: (_code: SnomedClinicalCode) => true,
  actionSelected: (_action: Action) => true,
  blur: (_inputValue: string) => true,
  enterKeydown: (_cursorValues: CursorValues) => true,
  backspaceAtStart: () => true,
});

const inputValue = ref<string>(props.value || "");
const inputEl = ref<InstanceType<typeof HTMLTextAreaElement> | null>(null);

// Actions menu
const isActionMenuShowing = ref<boolean>(false);
const filteredActions = ref<Action[]>(props.actions);
const refActionMenuItems = ref<QItem[]>([]);
const focusedActionIndex = ref(0);
const MENU_WIDTH = 300;
const MENU_MAX_HEIGHT = 300;
const ACTIONS_MENU_INIT_CHAR = "/";

// Clinical code menu
const isClinicalCodeMenuShowing = ref<boolean>(false);
const isCodeMenuSearching = ref<boolean>(false);
const refCodeMenuItems = ref(<QItem[]>[]);
const focusedCodeIndex = ref(0);
const codeSearchResults = ref<SnomedClinicalCode[]>([]);
const codeSearchAbortController = ref<AbortController>(new AbortController());
const {
  debouncedThrottled: searchCodesDebouncedThrottled,
  cancelDebouncedThrottled: cancelSearchCodesDebouncedThrottled,
} = useDebouncedThrottledFunction((searchText: string) => searchCodes(searchText), 1000, 1000);
const CODE_SEARCH_URL =
  "/clinical/gb/snomed/search/description/constrained?constrainingParentConcepts=71388002,404684003,243796009,48176007,272379006,363787002&outputParentConceptIds=1&excludeConstrainingConcepts=773051000000102,773031000000109,773011000000101,777441000000102,417753008,416308001,417370002,413909005";
const CODE_MENU_INIT_CHAR = ".";

defineExpose({ props, focus });

onMounted(() => {
  adjustHeight();
});

/**
 * Emits the update:modelValue event with the ID from props and the latest input value.
 * Helper functiion to reduce code repetition.
 * @param saveImmediately Whether the listener should cancel their debounce/throttle and instead save immediately
 */
function emitInputValue(saveImmediately: boolean = false) {
  emit("update:modelValue", props.id, inputValue.value, saveImmediately);
}

/**
 * Watches for input value change and debounces/throttles the emit so that the heading component
 * does not get overloaded with events when the user is typing.
 */
watch(inputValue, (newValue, prevValue) => {
  // Update menu visibility
  isActionMenuShowing.value = newValue.trim().startsWith(ACTIONS_MENU_INIT_CHAR);
  isClinicalCodeMenuShowing.value = newValue.trim().startsWith(CODE_MENU_INIT_CHAR);

  if (isActionMenuShowing.value === true) {
    // Filter actions list with the latest text value (minus the forward slash)
    const searchText = newValue.slice(1).toLowerCase();
    focusedActionIndex.value = 0;

    filteredActions.value = props.actions.filter((action) => {
      return action.label.trim().toLowerCase().includes(searchText);
    });
  } else if (isClinicalCodeMenuShowing.value === true) {
    // Search clinical codes
    focusedCodeIndex.value = 0;
    const searchText = newValue.slice(1).toLowerCase();

    if (searchText.length === 0) {
      if (prevValue.startsWith(CODE_MENU_INIT_CHAR)) {
        cancelSearchCodesDebouncedThrottled();
        isCodeMenuSearching.value = false;
        codeSearchResults.value = [];
      }
    } else {
      isCodeMenuSearching.value = true;
      searchCodesDebouncedThrottled(searchText);
    }
  } else {
    const comingFromMenu =
      prevValue.trim().startsWith(ACTIONS_MENU_INIT_CHAR) ||
      prevValue.trim().startsWith(CODE_MENU_INIT_CHAR);

    // Emit new value to parent
    if (comingFromMenu === false) emitInputValue();
  }

  // New value could overflow to next line
  adjustHeight();
});

async function searchCodes(searchText: string) {
  const newCodeSearchResults = await axios
    .get<{ results: SnomedClinicalCode[] }>(`${CODE_SEARCH_URL}&query=${searchText}`, {
      signal: codeSearchAbortController.value.signal,
    })
    .then((res) => {
      isCodeMenuSearching.value = false;
      return res.data.results;
    });

  codeSearchResults.value = newCodeSearchResults;
}

/**
 * If parent updates value, then update internal inputValue
 */
watch(
  () => props.value,
  (newValue) => {
    inputValue.value = newValue;
    adjustHeight();
  },
);

/**
 * Whenever the input loses focus, if the input value is different to the model value,
 * cancel any debounced/throttled callbacks
 * and emit the new value immediately with save immediately set to true
 */
function handleBlur(_event: FocusEvent & { target: HTMLTextAreaElement }) {
  emit("blur", inputValue.value);
}

function handleBackspace(event: TextAreaKeyboardEvent) {
  const selectionStart = event.target.selectionStart;
  const selectionEnd = event.target.selectionEnd;

  if (selectionStart === 0 && selectionEnd === 0) {
    event.preventDefault();
    emit("backspaceAtStart");
  }
}

function onEnterKeydown(event: TextAreaKeyboardEvent) {
  event.preventDefault();

  if (isActionMenuShowing.value === true) {
    selectAction(filteredActions.value[focusedActionIndex.value]);
    return;
  }

  if (isClinicalCodeMenuShowing.value === true) {
    selectCode(codeSearchResults.value[focusedCodeIndex.value]);
    return;
  }

  // Get values before and after the current cursor selection position
  const selectionStart = event.target.selectionStart;
  const selectionEnd = event.target.selectionEnd;

  let leftOfSelection = null;
  if (selectionStart !== 0) leftOfSelection = inputValue.value.slice(0, selectionStart);

  let rightOfSelection = null;
  if (selectionEnd !== event.target.value.length)
    rightOfSelection = inputValue.value.slice(selectionEnd);

  emit("enterKeydown", <CursorValues>{
    leftOfSelection,
    rightOfSelection,
  });
}

function moveMenuFocus(direction: Direction, focusIndex: Ref, refList: Ref<QItem[]>) {
  if (direction === Direction.UP) {
    if (focusIndex.value - 1 >= 0) {
      focusIndex.value = focusIndex.value - 1;
    } else {
      focusIndex.value = refList.value.length - 1;
    }
  }

  if (direction === Direction.DOWN) {
    if (focusIndex.value + 1 <= refList.value.length - 1) {
      focusIndex.value = focusIndex.value + 1;
    } else {
      focusIndex.value = 0;
    }
  }

  nextTick(() => {
    const refFocusedItem = refList.value[focusIndex.value];
    refFocusedItem.$el.scrollIntoView();
  });
}

function handleKeydown(event: TextAreaKeyboardEvent) {
  switch (event.key) {
    case Key.ArrowDown: {
      event.preventDefault();
      if (isActionMenuShowing.value) {
        moveMenuFocus(Direction.DOWN, focusedActionIndex, refActionMenuItems);
      }

      if (isClinicalCodeMenuShowing.value) {
        moveMenuFocus(Direction.DOWN, focusedCodeIndex, refCodeMenuItems);
      }

      break;
    }
    case Key.ArrowUp: {
      event.preventDefault();
      if (isActionMenuShowing.value) {
        moveMenuFocus(Direction.UP, focusedActionIndex, refActionMenuItems);
      }

      if (isClinicalCodeMenuShowing.value) {
        moveMenuFocus(Direction.UP, focusedCodeIndex, refCodeMenuItems);
      }

      break;
    }
    case Key.Escape: {
      event.preventDefault();
      cancelSearchCodesDebouncedThrottled();
      inputValue.value = "";
      break;
    }
    case Key.Enter: {
      break;
    }
    default: {
      focusedActionIndex.value = 0;
    }
  }
}

function adjustHeight() {
  requestAnimationFrame(() => {
    inputEl.value.style.height = "auto";
    inputEl.value.style.height = inputEl.value.scrollHeight + "px";
  });
}

function focus(caretPosition: number | null = null) {
  inputEl.value?.focus();

  if (caretPosition !== null) {
    inputEl.value.setSelectionRange(caretPosition, caretPosition);
  }
}

function selectAction(action: Action) {
  emit("actionSelected", action);
}

function selectCode(code: SnomedClinicalCode) {
  emit("codeSelected", code);
}
</script>

<template>
  <div class="consultation-entry-input">
    <textarea
      ref="inputEl"
      v-model="inputValue"
      :disabled="disabled"
      rows="1"
      :aria-labelledby="props.labelledBy"
      @blur="handleBlur"
      @keydown.enter="onEnterKeydown"
      @keydown.backspace="handleBackspace"
      @keydown="handleKeydown"
    />
    <m-menu
      v-if="isActionMenuShowing"
      ref="actions-menu"
      class="m-action-menu"
      :target="inputEl"
      no-refocus
      no-focus
      :model-value="true"
      :scroll-target="false"
      :max-height="MENU_MAX_HEIGHT"
      fit
      :style="{ width: MENU_WIDTH + 'px' }"
      role="listbox"
    >
      <q-list ref="list" class="scroll-area" dense tabindex="0">
        <q-item
          v-for="(action, i) in filteredActions"
          :id="`${action}-${i}`"
          :key="`${action}-${i}`"
          ref="refActionMenuItems"
          v-close-popup
          clickable
          :focused="i === focusedActionIndex"
          tabindex="-1"
          :aria-selected="i === focusedActionIndex"
          role="option"
          @click="() => selectAction(action)"
        >
          <q-item-section>
            <q-item-label>
              {{ action.label }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </m-menu>
    <m-menu
      v-if="isClinicalCodeMenuShowing"
      ref="codes-menu"
      class="m-code-menu"
      :target="inputEl"
      no-refocus
      no-focus
      :model-value="true"
      :scroll-target="false"
      :max-height="MENU_MAX_HEIGHT"
      fit
      role="listbox"
    >
      <q-list
        v-if="isCodeMenuSearching === false && codeSearchResults.length > 0"
        ref="list"
        class="code-menu-items scroll-area"
        dense
        tabindex="0"
      >
        <q-item
          v-for="(code, i) in codeSearchResults"
          :id="`${code}-${i}`"
          :key="`${code}-${i}`"
          ref="refCodeMenuItems"
          v-close-popup
          clickable
          :focused="i === focusedCodeIndex"
          tabindex="-1"
          :aria-selected="i === focusedCodeIndex"
          role="option"
          @click="
            () => {
              selectCode(code);
            }
          "
        >
          <q-item-section>
            <q-item-label>
              {{ code.label }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>

      <div v-if="inputValue.length < 2" class="label help-text">
        Start typing to search clinical codes
      </div>
      <div v-if="isCodeMenuSearching === true" class="label searching-text">Searching codes...</div>
      <div
        v-if="
          isCodeMenuSearching === false && codeSearchResults.length < 1 && inputValue.length > 1
        "
        class="label no-results-text"
      >
        No results
      </div>
    </m-menu>
  </div>
</template>

<style lang="scss" scoped>
.consultation-entry-input {
  display: flex;
  flex: 1 1 auto;
}
textarea {
  flex: 1 1 auto;
  border: 0;
  width: 100%;
  resize: none;
  min-height: 26px;
  padding: 2px 8px;
  background: none;
  overflow: hidden;

  &::placeholder {
    font-style: italic;
    color: #777;
  }

  &:focus {
    outline: none;
  }
}

.label {
  padding: 14px;
  font-style: italic;
  color: var(--text-color-lightest);
}
</style>
