<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, ref, watch } from "vue";
import { isString } from "vue-composable";
import { useFormValue } from "../../../../../composables/namedValue";
import { usePrescriptionStore } from "../../../../../store/transitient/prescription";
import { SearchPredefinedDosagesQueryResult } from "../../../../../store/transitient/prescription/api/types";
import MRadioGroup from "../../../../medicus/MRadioGroup";
import MSection from "../../../../medicus/MSection";
import MInput from "../../../../medicus/inputs/MInput";
import { Key } from "ts-key-enum";

const props = defineProps<{
  modelValue?: string;
  name?: string;
  label: string;
  counter?: boolean;
  items?: Array<{
    label: string;
    value: SearchPredefinedDosagesQueryResult; // TODO set the current type
  }>;

  manualDosage?: boolean; //TODO: Delete unused prop
  required?: boolean;
}>();

defineEmits({
  "update:modelValue": (_v: string) => true,
});

const value = useFormValue(props);
const input = ref<InstanceType<typeof MInput>>();

const store = usePrescriptionStore();

const counter = ref(props.counter);
const patientWeightWarning = ref<string | null>(null);
const required = ref(props.required);
const { proxy } = getCurrentInstance()!;

const freeTextRadioVal = "free-text";

const drugOrDevice = computed(() => store.drugOrDevice);
watch(drugOrDevice, (v) => {
  if (v) {
    store.selectedPredefinedDosage = null;
  }
});

const selectedPredefinedDosage = computed(() => store.selectedPredefinedDosage);
watch(selectedPredefinedDosage, (v) => {
  if (v) {
    if (v === freeTextRadioVal) {
      nextTick(() => {
        input.value?.clearValue();
        input.value?.focus();
      });
    } else {
      commit(v);
    }
  }
});

function expandedShorthandValue(input: string, eventType: string) {
  if (!input) {
    return null;
  }

  let expressions = {
    keyDown: [
      { regex: new RegExp(/\b(prn|p\.r\.n\.|p\.r\.n)\b/, "ig"), replaceString: "as needed" },
      { regex: new RegExp(/\b(od|o\.d\.|o\.d)([ .])/, "ig"), replaceString: "once daily$2" },
      { regex: new RegExp(/\b(om|o\.m\.|o\.m)([ .])/, "ig"), replaceString: "every morning$2" },
      { regex: new RegExp(/\b(on|o\.n)\./, "ig"), replaceString: "every night" },
      { regex: new RegExp(/\b(qpm|q\.p\.m\.|q\.p\.m)\b/, "ig"), replaceString: "every night" },
      { regex: new RegExp(/\b(bd|b\.d\.|b\.d)\b/, "ig"), replaceString: "twice daily" },
      {
        regex: new RegExp(/\b(bid|b\.i\.d\.|b\.i\.d)([ .])/, "ig"),
        replaceString: "twice daily$2",
      },
      { regex: new RegExp(/\b(tds|t\.d\.s\.|t\.d\.s)\b/, "ig"), replaceString: "3 times a day" },
      {
        regex: new RegExp(/\b(tid|t\.i\.d\.|t\.i\.d)([ .])/, "ig"),
        replaceString: "3 times a day$2",
      },
      { regex: new RegExp(/\b(tdd|t\.d\.d\.|t\.d\.d)\b/, "ig"), replaceString: "3 times a day" },
      { regex: new RegExp(/\b(qds|q\.d\.s\.|q\.d\.s)\b/, "ig"), replaceString: "4 times a day" },
      { regex: new RegExp(/[\s]{2,}/, "g"), replaceString: " " },
    ],
    blur: [
      { regex: new RegExp(/\b(prn|p\.r\.n\.|p\.r\.n)\b/, "ig"), replaceString: "as needed" },
      { regex: new RegExp(/\b(od|o\.d\.|o\.d)\b/, "ig"), replaceString: "once daily" },
      { regex: new RegExp(/\b(om|o\.m\.|o\.m)\b/, "ig"), replaceString: "every morning" },
      { regex: new RegExp(/\b(on|o\.n\.|o\.n)$/, "ig"), replaceString: "every night" },
      { regex: new RegExp(/\b(on|o\.n\.|o\.n)\./, "ig"), replaceString: "every night" },
      { regex: new RegExp(/\b(qpm|q\.p\.m\.|q\.p\.m)\b/, "ig"), replaceString: "every night" },
      { regex: new RegExp(/\b(bd|b\.d\.|b\.d)\b/, "ig"), replaceString: "twice daily" },
      { regex: new RegExp(/\b(bid|b\.i\.d\.|b\.i\.d)\b/, "ig"), replaceString: "twice daily" },
      { regex: new RegExp(/\b(tds|t\.d\.s\.|t\.d\.s)\b/, "ig"), replaceString: "3 times a day" },
      { regex: new RegExp(/\b(tid|t\.i\.d\.|t\.i\.d)\b/, "ig"), replaceString: "3 times a day" },
      { regex: new RegExp(/\b(tdd|t\.d\.d\.|t\.d\.d)\b/, "ig"), replaceString: "3 times a day" },
      { regex: new RegExp(/\b(qds|q\.d\.s\.|q\.d\.s)\b/, "ig"), replaceString: "4 times a day" },
      { regex: new RegExp(/[\s]{2,}/, "g"), replaceString: " " },
    ],
  };

  Object.values(expressions[eventType]).forEach((entry) => {
    input = input.replace(entry.regex, entry.replaceString);
  });

  return input;
}

function onBlur(event: FocusEvent) {
  if (
    event.type === "blur" ||
    !event.relatedTarget ||
    (event.relatedTarget as HTMLElement).classList.contains("m-menu") ||
    (event.relatedTarget as HTMLElement).classList.contains("q-item") ||
    (proxy?.$el as HTMLElement).contains(event.relatedTarget as HTMLElement)
  ) {
    value.value = expandedShorthandValue(value.value, "blur");

    // Capitalise first letter of dosage text
    value.value = value.value?.replace(/^./, value.value[0].toUpperCase());

    commit(value.value as string);
    return;
  }

  if (!value.value) {
    return;
  }

  commit(value.value as string);
}

async function commit(v: SearchPredefinedDosagesQueryResult | string) {
  if (isString(v)) {
    value.value = v;
    store.commitDosage(null);
  } else if (v) {
    await store.commitDosage(v);
    value.value = v.dosageTextToPersist;
    patientWeightWarning.value = v.patientWeightRestriction;

    await nextTick();
  } else {
    value.value = v;
  }
}

function onKeydown(ev: KeyboardEvent) {
  if ([Key.ArrowDown, Key.ArrowUp, Key.Escape].indexOf(ev.code) !== -1) {
    return;
  }

  ev.target.value = expandedShorthandValue(ev.target.value, "keyDown");
}

const dosageRadioItems = computed(() => {
  const predefinedDosages = store.dosageSuggestion?.unGroupedPredefinedDosages || [];

  return [
    ...predefinedDosages,
    {
      label: "Enter free text",
      value: freeTextRadioVal,
    },
  ];
});
</script>
<template>
  <m-section title="Dosage">
    <m-radio-group
      v-if="store.dosageSuggestion?.unGroupedPredefinedDosages?.length > 0"
      v-model="store.selectedPredefinedDosage"
      :items="dosageRadioItems"
      :required="props.required"
      label="Dosage instructions"
      instructions="Check specific product guidance for dosage information (incl. licensing)."
    />

    <m-input
      v-if="
        store.dosageSuggestion === null ||
        store.dosageSuggestion?.unGroupedPredefinedDosages?.length === 0 ||
        store.selectedPredefinedDosage === freeTextRadioVal
      "
      ref="input"
      v-model="value"
      :label="label"
      :loading="store.loadingChecks"
      role="combobox"
      :required="required"
      :counter="counter"
      maxlength="700"
      @blur="onBlur"
      @keyup="onKeydown"
    />
    <m-banner v-if="patientWeightWarning" type="warning">{{ patientWeightWarning }} </m-banner>
  </m-section>
</template>
<style lang="scss">
.dosage-instructions-title {
  color: var(--grey-darkest);
  font-weight: 400;
  margin-left: 1rem;
}

.dosage-instructions-footer {
  color: var(--grey-darkest);

  border-top: 1px solid var(--border-colour);
  padding: 10px;

  > span {
    margin-left: 10px;
  }
}
</style>
