<script lang="ts" setup>
import SidebarMenu from "../../SidebarMenu";
import { useApp } from "../../../../store";

const title = "Modules";
const app = useApp();

if (app.modulesMenuItems.length === 0) app.fetchModulesMenuItems();
</script>

<template>
  <SidebarMenu
    :title="title"
    :items="app.modulesMenuItems || []"
    role="navigation"
    aria-label="Main menu"
  />
</template>
