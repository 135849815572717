<template>
  <ag-grid-vue
    ref="gridRef"
    class="ag-theme-material default-table compact-cells"
    v-bind="$attrs"
    :overlay-loading-template="overlayLoadingTemplate"
    :overlay-no-rows-template="overlayNoRowsTemplate"
    suppress-context-menu
    :grid-options="gridOptions"
    @rowDataChanged="rowDataChanged"
    @gridSizeChanged="handleGridSizeChanged"
    @firstDataRendered="handleFirstDataRendered"
  />
</template>

<script setup lang="ts">
import { isDate, isString } from "vue-composable";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager, GridOptions, ColDef, RowClassRules } from "ag-grid-enterprise";

import "../../../css/ag-grid.scss";
import { inject, onBeforeUnmount, onMounted, ref, watch, withDefaults, defineProps } from "vue";
import { formatDate } from "../../../utils/date";
import { listen } from "../../../utils/bus";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-072703}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{MEDICUS_HEALTH_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Medicus_Health}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Medicus_Health}_need_to_be_licensed___{Medicus_Health}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_February_2026}____[v3]_[01]_MTc3MDUwODgwMDAwMA==48be409536e723585729e928e654a90a",
);

type CellStyle = { backgroundColor: string | null; color?: string | null };

const preventOverflow = inject("prevent-overflow", () => {});

const props = withDefaults(
  defineProps<{
    columnDefs: ColDef[];
    overlayLoadingTemplate?: string;
    overlayNoRowsTemplate?: string;
    rowClassRules?: RowClassRules;
    applicationIdField?: string;
    suppressSizeColumnsToFit?: boolean;
    customGridOptions?: GridOptions<any>;
  }>(),
  {
    overlayLoadingTemplate: "<span>Rows are loading...</span>",
    overlayNoRowsTemplate: "<span>No rows to show.</span>",
    suppressSizeColumnsToFit: false,
    customGridOptions: {},
  },
);

const defaultGridOptions: GridOptions = {
  groupDefaultExpanded: -1, // Expand groups by default
  groupDisplayType: "custom", // We must define our own group column
  suppressAggFuncInHeader: true, // Don't show "sum(xxx)" in the aggregate column header
  rowClassRules: props.rowClassRules,
  suppressFieldDotNotation: true, // show fields with dots in them
};

const gridOptions = ref({
  ...defaultGridOptions,
  ...props.customGridOptions,
});

const isLoading = ref(false);
const debounced = ref<null | ReturnType<typeof setTimeout>>(null);
const gridRef = ref<typeof AgGridVue>();

// If custom Id field specified, use that instead of auto generated
if (props.applicationIdField) {
  gridOptions.value = Object.assign(gridOptions.value, {
    getRowId: (params) => params.data[props.applicationIdField],
  });
}

watch(
  () => props.columnDefs,
  (v) => {
    if (!gridOptions.value.api) return;
    gridOptions.value.api.clearFocusedCell();
    processColumnDefs(v);
    gridOptions.value.api.setColumnDefs(v);
    autoSizeColumns();
  },
  { deep: true },
);

onMounted(() => {
  preventOverflow?.(true);

  gridOptions.value.api = gridRef.value?.gridOptions.api;
  gridOptions.value.columnApi = gridRef.value?.gridOptions.columnApi;

  listen("tableDataLoading", (tableDataLoading: boolean) => {
    isLoading.value = tableDataLoading;
  });

  window.addEventListener("resize", handleResize, { passive: true });
  processColumnDefs(props.columnDefs);
  gridOptions.value.api?.setColumnDefs(props.columnDefs);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

function getDate(s) {
  if (!s) return new Date(0);
  return isString(s) ? new Date(s) : isDate(s) ? s : new Date(s);
}

function handleResize() {
  if (debounced.value) {
    clearTimeout(debounced.value);
  }

  debounced.value = setTimeout(() => {
    if (!gridOptions.value.api) return;
    autoSizeColumns();
  }, 400);
}

function handleGridSizeChanged() {
  autoSizeColumns();
}

function handleFirstDataRendered() {
  autoSizeColumns();
}

function autoSizeColumns() {
  !props.suppressSizeColumnsToFit && gridOptions.value?.columnApi?.autoSizeAllColumns();
}

function rowDataChanged(params) {
  // give time for transitions to finish
  setTimeout(() => {
    autoSizeColumns();
  }, 10);

  if (isLoading.value) {
    let dataLength = params.api.getModel().rowsToDisplay.length;
    dataLength < 1 ? params.api.showLoadingOverlay() : params.api.hideOverlay();
  }
}

function processColumnDefs(colDefs: (ColDef & { __processed?: boolean })[]) {
  try {
    for (const col of colDefs) {
      if (col.__processed) return;
      col.__processed = true;
      if (!col.menuTabs) {
        col.menuTabs = [
          "generalMenuTab",
          // 'columnsMenuTab'
        ];
      }

      switch (col.type) {
        case "date": {
          col.cellRenderer = ({ value }) => {
            return formatDate(value);
          };

          col.comparator = (valueA, valueB) => {
            const a = getDate(valueA);
            const b = getDate(valueB);

            return a - b;
          };
          delete col.type;

          break;
        }
        case "check": {
          col.cellStyle = () => ({ display: "flex", justifyContent: "center" });
          col.cellRenderer = ({ value }) => {
            const icon = value ? "fa-check" : "fa-xmark";
            const color = value ? "green" : "var(--status-red)";
            return `<div class="fa-regular ${icon} fa-lg" style="color: ${color};" />`;
          };
          delete col.type;

          break;
        }
      }

      col.cellStyle = (params) => {
        if (params.data) {
          const colId = params.column.colId;

          if (params.data.cellStyles?.[colId]) {
            const colCellStyles = params.data.cellStyles[colId];

            let style: CellStyle = {
              backgroundColor: null,
              color: null,
            };

            const theme = colCellStyles.theme;

            if (theme) {
              style = getThemeStyle(theme);
            }

            const definedBackgroundColur = colCellStyles.backgroundColor;
            if (definedBackgroundColur) style.backgroundColor = definedBackgroundColur;

            const definedTextColor = colCellStyles.color;
            if (definedTextColor) style.color = definedTextColor;

            return style;
          }
        }

        return null;
      };
    }
  } catch (e) {
    console.error(e);
  }
}

function getThemeStyle(theme: string): CellStyle {
  switch (theme) {
    case "amber":
      return {
        backgroundColor: "var(--status-yellow)",
        color: "var(--status-yellow-text)",
      };
    case "green":
      return {
        backgroundColor: "var(--status-green)",
        color: "white",
      };
    case "red":
      return {
        backgroundColor: "var(--status-red)",
        color: "white",
      };
    case "blue":
      return {
        backgroundColor: "var(--status-light-blue)",
        color: "var(--status-blue)",
      };
    case "grey":
      return {
        backgroundColor: "var(--grey-lightest)",
      };
    default:
      return {
        backgroundColor: "var(--grey-lightest)",
      };
  }
}
</script>

<style>
/* Ref: https://ag-grid.com/vue-data-grid/global-style-customisation-compactness/ */
.ag-theme-material {
  --ag-font-size: 14px;
  --ag-checkbox-checked-color: var(--theme-grey);
  --ag-cell-horizontal-padding: var(--gap-4);
}

.compact-cells {
  --ag-row-height: 32px;
}

.ag-cell-wrap-text {
  white-space: normal;
  word-break: break-word;
  line-height: 150%;
  padding-top: 5px; /* space top */
  padding-bottom: 5px; /* space bottom */
}

.ag-center-cols-container {
  min-width: 100%;
}
</style>
