<template>
  <m-layout-stack
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
    gap="1"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">Medication prescribed elsewhere: {{ getMainLine }}</div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <m-layout-stack
      v-if="
        item.isEnded ||
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.linkedProblems.length
      "
      horizontal
      class="q-mt-sm"
    >
      <m-badge v-if="item.isEnded" class="secondary status-badge">Ended</m-badge>
      <m-badge
        v-if="
          item.hiddenFromPatientFacingServices &&
          item.hiddenFromPatientFacingServices !== contextHiddenFromPatientFacingServices
        "
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`medication-prescribed-elsewhere-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`medication-prescribed-elsewhere-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </m-layout-stack>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type MedicationStatementPrescribedElsewhereJournalItem = {
  isEnded: boolean;
  prescriptionType: any;
  dosageInstructions: any;
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  isMarkedIncorrect: boolean;
  productName: string;
  isRetrospectivelyAmended: boolean;
};

const props = defineProps<{
  item: MedicationStatementPrescribedElsewhereJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const getMainLine = computed(() => {
  const textAsArray = [
    props.item.productName,
    props.item.dosageInstructions,
    props.item.prescriptionType,
  ];
  const text = textAsArray.filter((x) => x).join(" • ");
  return text === "" ? false : text;
});
</script>
