<script lang="ts" setup>
import { NOOP } from "@vue/shared";
import { computed, inject, onMounted } from "vue";
import { usePrescriptionStore } from "../../../../store/transitient/prescription";
import MCard from "../../../medicus/MCard";
import MDescriptionList, { MDescriptionListItem } from "../../../medicus/MDescriptionList";
import MLayoutStack from "../../../medicus/MLayoutStack/MLayoutStack.vue";
import MSplitScreen from "../../../medicus/MSplitScreen/MSplitScreen.vue";
import DrugAlerts from "../DrugAlerts";

defineProps<{
  title?: string;
}>();

// init
const store = usePrescriptionStore();
const over18 = store.state.patientAgeInDays && store.state.patientAgeInDays >= 18 * 365;
// / init

const removePadding = inject<(v: boolean) => void>("removePadding", NOOP);
const setModalType = inject<(v: string) => void>("setModalType", NOOP);
onMounted(() => {
  setModalType("large");
  removePadding(true);
});

const computedHasValidPackSize = computed(() => {
  let hasValidPackSize = false;
  if (store.metaInformation?.packInfo) {
    store.metaInformation.packInfo.forEach((packInfo) => {
      if (null !== packInfo.quantity) {
        hasValidPackSize = true;
      }
    });
  }

  return hasValidPackSize;
});
</script>
<template>
  <MSplitScreen
    half
    persistent
    no-padding
    :model-value="true"
    class="medicus-grey-background flex-auto"
  >
    <div>
      <slot />
    </div>
    <template #item>
      <m-layout-stack class="q-pa-md">
        <DrugAlerts v-if="store.drugOrDevice" data-testid="drug-alerts" />
        <m-card
          v-if="store.drugOrDevice && store.metaInformation?.bnfMedicationName"
          title="Guidance"
        >
          <m-description-list>
            <m-description-list-item
              v-if="store.metaInformation?.bnfMedicationName"
              :label="over18 ? 'BNF monograph' : 'BNFC monograph'"
              :value="{
                text: store.metaInformation.bnfMedicationName,
                type: 'link',
                href: over18 ? store.metaInformation.bnfUrl : store.metaInformation.bnfcUrl,
              }"
              horizontal
              data-testid="bnf-medication-name"
            />
          </m-description-list>
        </m-card>

        <m-card v-if="store.drugOrDevice && computedHasValidPackSize" title="Pack Information">
          <m-table
            flat
            :columns="[
              {
                name: 'availableSize',
                title: 'Available Size',
                path: (item) => `${item.quantity} ${item.unit.description}`,
              },
              {
                name: 'price',
                title: 'Cheapest Price',
                path: (item) => {
                  if (!item.packPrice) {
                    return 'Unknown';
                  }
                  return `£${new Number(item.packPrice).toFixed(2)}`;
                },
              },
            ]"
            :items="store.metaInformation.packInfo"
          />
        </m-card>
      </m-layout-stack>
    </template>
  </MSplitScreen>
</template>
