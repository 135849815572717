<script setup lang="ts">
import { ref } from "vue";
import { isMobile } from "../../../utils/resize";
const el = ref<HTMLDivElement>(null);
defineExpose({ el });
</script>
<template>
  <div ref="el" class="m-responsive-content">
    <slot v-if="isMobile" name="mobile" />
    <slot v-else name="desktop" />
    <slot v-bind="{ isMobile: isMobile }" />
  </div>
</template>

<style lang="scss">
.m-responsive-content {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}
</style>
