<template>
  <q-list v-if="selectedTask !== null" separator>
    <q-select
      v-if="displayFilterOption && statusOptions"
      v-model="selectedTaskStatus"
      borderless
      multiple
      :options="statusOptions"
      use-chips
      stack-label
      label="Task Status Filter"
      class="select"
      @input="
        (val) => {
          onStatusChange(val);
        }
      "
    />
    <q-item
      v-for="(taskItem, index) in selectedTaskItems"
      :key="index"
      v-ripple
      clickable
      :active="taskItem.id === selectedTaskItemId"
      @click="$emit('task-item-selected-id', taskItem.id)"
    >
      <q-item-section>
        <q-item-label>{{ fetchLabel(taskItem) }}</q-item-label>
        <q-item-label caption :inner-html.prop="fetchCaption(taskItem)" />
      </q-item-section>
    </q-item>
    <div v-if="selectedTaskItems.length === 0" class="center-container">
      <span v-if="selectedTaskStatus.length > 0"
        >You don't have any task with a status of
        <span v-for="(status, index) in selectedTaskStatus" :key="index"
          >{{ status.label }}
        </span></span
      >
      <span v-else>You need to select at least one status</span>
    </div>
  </q-list>
</template>

<script>
import { QItem, QItemLabel, QItemSection, QList, QSelect } from "quasar";
export default {
  components: { QSelect, QList, QItem, QItemSection, QItemLabel },
  props: {
    selectedTask: {
      type: Object,
      default: null,
    },
    selectedTaskItemId: {
      type: String,
      default: null,
    },
    selectedTaskItems: {
      type: Array,
      default: null,
    },
    displayFilterOption: {
      type: Boolean,
      default: true,
    },
    statusOptions: {
      type: Array,
      default: null,
    },
  },

  data: () => ({
    selectedTaskStatus: [],
    initSetUpDone: false,
    initSelectSetUpDone: false,
  }),

  watch: {
    statusOptions() {
      if (!this.initSetUpDone) {
        this.selectedTaskStatus = this.statusOptions;
        this.$emit("task-status-selected", this.statusOptions);
        this.initSetUpDone = true;
      }
    },
    selectedTaskItems(list) {
      if (!this.initSelectSetUpDone && list.length > 0) {
        this.$emit("task-item-selected-id", list[0].id);
        this.initSelectSetUpDone = true;
      }
    },
  },

  methods: {
    fetchLabel(taskItem) {
      switch (this.selectedTask.taskName) {
        case "contact-patient":
          return taskItem.title;
        default:
          return "Unsupported task type: " + this.selectedTask.taskName;
      }
    },
    fetchCaption(taskItem) {
      switch (this.selectedTask.taskName) {
        case "contact-patient":
          return taskItem.patient.label + "<br>Created at " + taskItem.createdAt;
        default:
          return "Unsupported task type: " + this.selectedTask.taskName;
      }
    },
    onStatusChange(status) {
      this.$emit("task-status-selected", status);
    },
  },
};
</script>
<style scoped>
.select {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid var(--border-colour);
}
.center-container {
  width: 100%;
  padding: 10px;
  margin-top: 200px;
  text-align: center;
  color: grey;
}
</style>
