<template>
  <MConsultationTemplateFormElement
    v-for="(item, index) in props.results"
    :key="'consultationTemplateFormElement' + index"
    :element="item"
    :form-values="formValues"
    @formValueUpdate="handleFormValueUpdate"
  />
</template>

<script setup lang="ts">
import MConsultationTemplateFormElement from "../MConsultationTemplateFormElement/MConsultationTemplateFormElement.vue";
import { useDebouncedThrottledFunction } from "../../../../composables/throttleDebounce";
const props = defineProps({
  results: Array,
  formValues: Object,
});

const emit = defineEmits({
  formUpdatedSave: () => true,
});

async function saveForm() {
  emit("formUpdatedSave");
}

function handleFormValueUpdate() {
  debouncedThrottledSaveEntries();
}

const { debouncedThrottled: debouncedThrottledSaveEntries } = useDebouncedThrottledFunction(
  () => saveForm(),
  500,
  800,
);
</script>
<style>
.consultation-template-footer {
  color: var(--text-color-light);
  background: var(--medicus-background);
}
</style>
