<script lang="ts" setup>
import { NOOP } from "@vue/shared";
import { getActivePinia } from "pinia";
import { inject, onUnmounted } from "vue";
import { usePrescriptionStore } from "../../../../store/transitient/prescription";
import { parseRoute } from "../../../../utils/route";
import CreatePrescriptionForm from "../CreatePrescriptionForm";
import PrescriptionScreen from "../PrescriptionScreen";

const parsed = parseRoute(inject("drawer-url"));
const patientId = parsed.id!;

// end

const props = defineProps<{
  openIssue?: boolean;
  contextId?: string;
  contextType?: string;
  taskId?: string;
  taskType?: string;
  defaultTopicHeadingId?: string;
  success?: Function;
  showIssueLaterButton?: boolean;
  issueLaterLabel?: string;
  issueLaterColor?: string;
  hideIssueNow?: boolean;
  hideAddAnother?: boolean;
  restrictRecipientsToRequester?: boolean;
  createdInPatientRequestTask?: boolean;
}>();

const emit = defineEmits({
  success: (_data) => true,
});
const setTitle = inject<(v: string) => void>("setTitle", NOOP);
setTitle("New Prescription");
const store = usePrescriptionStore();
await store.initByPatient(
  patientId,
  props.contextId,
  props.contextType,
  props.taskId,
  props.taskType,
);

if (props.contextId && props.defaultTopicHeadingId && props.contextType === "consultation-topic") {
  store.form.createTopicHeadingIntent = {
    consultationTopicId: props.contextId,
    defaultTopicHeadingId: props.defaultTopicHeadingId,
  };
}
if (props.taskId && props.taskType) {
  store.form.taskId = props.taskId;
  store.form.taskType = props.taskType;
}

onUnmounted(() => {
  // clean store
  store.$dispose();

  // clear store state
  const pinia = getActivePinia();
  delete pinia!.state.value[store.$id];
});

function handleSuccess(data) {
  emit("success", data);
}
// / init
</script>
<template>
  <PrescriptionScreen>
    <CreatePrescriptionForm
      form-url="/clinical/prescription/create/create-prescription/authorise"
      :hide-add-another="hideAddAnother"
      :hide-issue-now="hideIssueNow"
      :show-issue-later-button="showIssueLaterButton"
      :issue-later-label="issueLaterLabel"
      :issue-later-color="issueLaterColor"
      :restrict-recipients-to-requester="restrictRecipientsToRequester"
      :created-in-patient-request-task="createdInPatientRequestTask"
      @success="(data) => (success ? success(data) : handleSuccess(data))"
    />
  </PrescriptionScreen>
</template>
