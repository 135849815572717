<script lang="ts" setup>
import { Ref, inject, useSlots, watchEffect } from "vue";
import { NO_OP } from "vue-composable";
import MLayoutStack from "../MLayoutStack";
import { Patient } from "../PatientBanner";

const setTitle = inject<(s: string) => void>("setTitle", NO_OP);
const setPatient = inject<(s: Patient) => void>("setPatient", NO_OP);
const setModalType = inject<(s: string | undefined) => void>("setModalType", NO_OP);
const setBgGrey = inject<(s: boolean | undefined) => void>("setBgGrey", NO_OP);
const setPatientId = inject<(s: string | undefined) => void>("setPatientId", NO_OP);
const setPreventBackgroundClose = inject<(s: boolean | undefined) => void>(
  "setPreventBackgroundClose",
  NO_OP,
);
const removePadding = inject<(s: boolean | undefined) => void>("removePadding", NO_OP);

const patientBanner = inject<Ref<Patient> | undefined>("patientBanner", undefined);

const props = defineProps<{
  title: string;
  type?: string;
  patient?: Patient;
  patientBannerPatientId?: string;

  noPadding?: boolean;
  preventBackgroundClose?: boolean;
  bgGrey?: boolean;

  darkBanner?: boolean;
}>();

const slots = useSlots();

watchEffect(() => {
  if (props.title) {
    setTitle?.(props.title);
  }
  if (props.patient) {
    setPatient?.(props.patient);
  }
  if (patientBanner?.value) {
    setPatient?.(patientBanner.value);
  }

  setModalType(props.type);

  setPatientId?.(props.patientBannerPatientId);

  if (slots.footer) {
    removePadding(true);
  } else {
    removePadding(props.noPadding);
  }
  setBgGrey(props.bgGrey);

  if (props.preventBackgroundClose) {
    setPreventBackgroundClose(props.preventBackgroundClose);
  }

  // TODO add darkBanner
});
</script>

<!-- Different implementation because changes are treated as platform changes -->
<template>
  <m-layout-stack v-if="$slots.footer" class="full-h flex-auto overflow-hidden">
    <m-layout-stack
      class="flex-auto overflow-auto"
      :class="{
        'q-pa-md': !props.noPadding,
      }"
    >
      <slot />
    </m-layout-stack>

    <div
      :class="{
        'q-pa-md': !props.noPadding,
      }"
    >
      <slot name="footer" />
    </div>
  </m-layout-stack>
  <m-layout-stack v-else full>
    <slot />
  </m-layout-stack>
</template>
