<script lang="ts" setup>
import { MedicusAttachment } from "../../../../composables/fileUploader";
import MButton from "../../MButton";
import MLayoutStack from "../../MLayoutStack";
MButton;

const props = defineProps<{
  item: MedicusAttachment;
  maxSize: string;
}>();
</script>
<template>
  <m-layout-stack class="items-center" justify-between horizontal>
    <m-layout-stack no-gap>
      <span>{{ item.fileName }}</span>
      <div
        v-if="props.item.__uploading && props.item.__uploading.error"
        class="error-text flex justify-between"
      >
        <span v-if="props.item.__uploading.error.type"></span>
        <span v-else-if="props.item.__uploading.error.size"
          >Maximum file size {{ props.maxSize }}</span
        >
        <span v-else-if="props.item.__uploading.error.extension">Unsupported file type</span>
        <span v-else>Error uploading the file</span>
      </div>
      <!-- <template></template> -->
    </m-layout-stack>

    <m-button
      class="top-0 right-0"
      icon="fa-solid fa-trash-can"
      icon-only
      ghost
      label="Delete file"
      @click="$emit('delete', item)"
    />
  </m-layout-stack>
</template>
