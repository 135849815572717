<script setup lang="ts">
import { computed, isVNode, ref, useSlots, watch } from "vue";
import { randomID } from "../../../utils/helpers";
import MAction, { type ActionModelValue } from "../MAction";
import MButton from "../MButton";
import MExpander from "../MExpander";
import MLayoutStack from "../MLayoutStack";

const props = defineProps<{
  title?: string;

  emptyText?: string;

  action?: ActionModelValue;

  border?: boolean;

  expanded?: boolean;
}>();

const emit = defineEmits({
  expand: (_state: boolean) => true,
  "update:expanded": (_state: boolean) => true,
});

const enum ShapeFlags {
  ELEMENT = 1,
  FUNCTIONAL_COMPONENT = 1 << 1,
  STATEFUL_COMPONENT = 1 << 2,
  TEXT_CHILDREN = 1 << 3,
  ARRAY_CHILDREN = 1 << 4,
  SLOTS_CHILDREN = 1 << 5,
  TELEPORT = 1 << 6,
  SUSPENSE = 1 << 7,
  COMPONENT_SHOULD_KEEP_ALIVE = 1 << 8,
  COMPONENT_KEPT_ALIVE = 1 << 9,
  COMPONENT = ShapeFlags.STATEFUL_COMPONENT | ShapeFlags.FUNCTIONAL_COMPONENT,
}

const slots = useSlots();

const hasSlot = computed(
  () =>
    slots.expanded ||
    (slots.default?.() || []).filter((v) => {
      return (
        isVNode(v) &&
        (v.shapeFlag & ShapeFlags.STATEFUL_COMPONENT ||
          v.shapeFlag & ShapeFlags.SUSPENSE ||
          (Array.isArray(v.children) && v.children.length))
      );
    }).length > 0,
);

const isExpanded = ref(false);

watch(
  () => props.expanded,
  (v) => (isExpanded.value = v),
  {
    immediate: true,
  },
);

const labelId = randomID("property-list-");
const expanderId = randomID("property-list-expander-");

function toggle() {
  isExpanded.value = !isExpanded.value;
  emit("expand", isExpanded.value);
  emit("update:expanded", isExpanded.value);
}

const ariaButton = computed(() =>
  slots.expanded
    ? {
        "aria-expanded": isExpanded.value,
        "aria-controls": expanderId,
      }
    : undefined,
);
</script>
<template>
  <div
    class="property-list"
    role="group"
    :class="{
      'no-shadow': border,
      border: border,
    }"
  >
    <m-layout-stack horizontal justify-between>
      <slot :id="labelId" name="title">
        <h3 v-if="title" :id="labelId" class="property-list--title">
          {{ title }}
        </h3>
      </slot>
      <slot v-if="(action || hasSlot) && !$slots.expanded" name="action">
        <m-action :model-value="action" />
      </slot>

      <template v-if="$slots.expanded">
        <m-button
          :icon="isExpanded ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"
          ghost
          v-bind="ariaButton"
          :sr-label="isExpanded ? 'close' : 'open'"
          @click="toggle"
        />
      </template>
    </m-layout-stack>

    <div v-if="hasSlot" class="property-list-content">
      <slot />

      <m-expander
        v-if="$slots.expanded"
        :id="expanderId"
        :expanded="isExpanded"
        hide-header
        borderless
      >
        <template #head></template>
        <!-- Transition only works with a single element, so we wrap the slot in a div -->
        <div>
          <slot name="expanded" />
        </div>
      </m-expander>
    </div>

    <m-layout-stack v-else class="empty-list items-center">
      <span class="empty-text"> {{ emptyText ?? "Nothing to display." }}</span>

      <slot name="action">
        <m-action v-if="action" :model-value="action" :ghost="false" color="secondary" />
      </slot>
    </m-layout-stack>
  </div>
</template>
<style lang="scss">
.property-list {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  padding: 10px 0;

  list-style-type: none;
  margin: 0;
  &.no-shadow {
    box-shadow: none;
  }
  &.border {
    border: 1px solid var(--grey);
  }
  > *:not(.property-list-content) {
    padding: 0 15px;
  }

  .property-list--title {
    line-height: 150%;
    color: var(--text-color);
    display: flex;
    align-items: center;

    margin-bottom: 5px;
  }

  .empty-list {
    padding-bottom: 10px;
  }

  .empty-list {
    padding-bottom: 10px;
  }

  .property-list-content {
    display: flex;
    flex-direction: column;

    > *,
    > .m-expander > div > div > * {
      padding: 0 15px;
    }

    > .m-expander,
    > .m-expander > div {
      padding: 0;
    }
  }

  .property-row {
    gap: 1em;

    > * {
      padding: 7px 0;
    }

    > .property-row-description {
      display: flex;

      flex: 1 1 auto;
      gap: 1em;

      > .property-row--label {
        width: 160px;
        flex: 0 0 auto;

        text-align: right;

        color: var(--text-color-light);
      }

      > .property-row--value {
        flex: 1 1 auto;

        white-space: break-spaces;
        word-break: break-word;
      }

      > .property-row--badge {
        flex: 0 0 auto;

        display: flex;
        gap: 0.25rem;

        align-self: flex-start;
      }
      > .property-row--empty-value {
        flex: 1 1 auto;
        color: var(--grey-darkest);
      }
    }

    > .property-row--action {
      margin-top: 4px;
    }
  }
}

// make it "0,0,0" specificity, because we won't to be allowed to change it
:where(.property-row--action) {
  color: #1d70b8;
}
</style>
