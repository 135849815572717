<script setup lang="ts">
import { computed, nextTick, provide, ref, watch } from "vue";

import { Key } from "ts-key-enum";

import { isObject, isString } from "vue-composable";
import { useInputRules } from "../../../../composables/horizontalLabel";
import { CancelToken } from "../../../../utils/axios";
import { checkLabel, randomID } from "../../../../utils/helpers";
import { validAttribute } from "../../../../utils/misc";
import MButton from "../../MButton";
import MLayoutStack from "./../../MLayoutStack";
import MInput from "./../MInput";
import MSelect, { GroupOption, ItemOption } from "./../MSelect";
import MValidationComponent from "./../MValidationComponent";

const props = defineProps<{
  modelValue?: any[];
  name?: string;

  label?: string;
  placeholder?: string;

  for?: string;

  options?: Array<GroupOption | ItemOption>;

  hasPhoto?: boolean;

  clearable?: boolean;

  useInput?: boolean;

  debounce?: string | number;

  helper?: string;
  instructions?: string;

  search?: (
    v: string,
    update: Function,
    cancelToken: InstanceType<typeof CancelToken>,
  ) => Promise<any>;
  searchFilter?: (item: string) => boolean;
  searchUrl?: string;
  // searchMinLen?: number;
  itemMap?: (v: any) => any;

  disabled?: string | boolean;

  itemKey?: string | ((item: any) => any);
  renderText?: (a: any, item: any, prevLabel?: string) => string;
  markIncorrect?: (a: any) => boolean;
}>();

const selectEl = ref<InstanceType<typeof MSelect> | null>(null);
const tempItem = ref();

const emit = defineEmits({
  addedItem: (val?: string) => val,
  removedItem: (val?: string) => val,
});

const { value, displayErrors } = useInputRules(props, () => {}, [], true);

provide("validationController", []);

async function onAddValue(a: any) {
  if (a === null) return;
  value.value.push(a);
  await nextTick();
  emit("addedItem", a);
  selectEl.value?.clear();
  tempItem.value = null;
}

function onKeyDown(e: KeyboardEvent) {
  if (e.key === Key.Enter) {
    onAddValue(tempItem.value);
  }
}

function onRemoveItem(a: any) {
  emit("removedItem", a);
  value.value.splice(value.value.indexOf(a), 1);
}

function renderText(a: any) {
  const label = getItemLabel(a);
  if (props.renderText) {
    return props.renderText(a, getEntryByValue(a), label);
  }
  return label;
}

function getItemLabel(a: any) {
  if (!a) return "";
  return isString(a)
    ? a
    : (labelMap.has(a) && labelMap.get(a)) ||
        a[LabelKey] ||
        a.label ||
        a.value ||
        a.name ||
        a.description ||
        a;
}

function getEntryByValue(a: any) {
  if (!props.options) return undefined;
  const options = [...props.options];
  const hasGroup = !!options[0]?.title;
  if (hasGroup) {
    for (const it of options) {
      const item = it.items.find((x) => x.value === a);
      if (item) return item;
    }
  }

  return options.find((x) => x.value === a);
}

const searchFilter = computed(() => {
  const itemKey = props.itemKey;
  // doesn't matter
  if (!itemKey) return undefined;

  const p = isString(itemKey) ? (s: any) => s[itemKey] : itemKey;
  const itemIds = new Set(value.value.map((x) => p(x)));

  return (item: any) => !itemIds.has(p(item.value));
});

function filterItems(items: any[], selectedItems: any[]) {
  const filter = searchFilter.value ?? ((x: any) => selectedItems.indexOf(x.value) === -1);
  return items.filter(filter);
}
const filteredOptions = computed(() => {
  if (!props.options) return undefined;
  if (!value.value || value.value.length === 0) return props.options;

  const options = [...props.options];
  // @ts-expect-error please narrow this type
  const hasGroup = !!options[0]?.title;

  if (hasGroup) {
    const groups: any[] = [];
    for (let i = 0; i < options.length; i++) {
      const element = options[i];

      // @ts-expect-error please narrow this type
      const items = filterItems(element.items, value.value);

      if (items.length > 0) {
        groups.push({
          ...element,
          items,
        });
      }
    }

    return groups;
  } else {
    return filterItems(options, value.value);
  }
});

const LabelKey = Symbol();
const labelMap = new Map();
// if the value only has value and label, set the value to label and no options provided
watch(
  value,
  (v) => {
    if (!v) return;
    if (!Array.isArray(v)) return;

    for (let i = 0; i < v.length; i++) {
      const item = v[i];
      if (!isObject(item)) continue;
      const keys = Object.keys(item);
      if (keys.length !== 2) continue;
      if (
        (keys[0] !== "value" && keys[0] !== "label") ||
        (keys[1] !== "value" && keys[1] !== "label")
      ) {
        continue;
      }

      const label = item.label;
      // in case
      if (isObject(item.value)) {
        item.value[LabelKey] = label;
      } else {
        labelMap.set(item.value, label);
      }
      v[i] = item.value;
    }
  },
  { immediate: true },
);

const id = computed(() => props.for ?? randomID("select-list"));

// eslint-disable-next-line no-undef
if (IS_APP && (import.meta.env.DEV || location.host.startsWith("uat."))) {
  checkLabel(props, "MSelectList", "label");
}
</script>
<template>
  <m-validation-component :class="[$attrs.class, 'm-select-list']" :errors="displayErrors">
    <m-layout-stack full gap="1" role="group">
      <m-select
        v-if="options || searchUrl || search"
        ref="selectEl"
        v-bind="$props"
        v-model="tempItem"
        :label="label"
        :required="validAttribute($attrs.required)"
        :helper="helper"
        :instructions="instructions"
        :placeholder="validAttribute($attrs.required) ? '' : placeholder"
        :options="filteredOptions"
        :name="undefined"
        :multiple="false"
        :use-chips="false"
        no-validation
        :search-filter="searchFilter"
        @update:modelValue="onAddValue"
      >
        <template #options-end="optionsBind">
          <slot name="options-end" v-bind="optionsBind"></slot>
        </template>
      </m-select>

      <m-input
        v-else
        v-bind="$props"
        v-model="tempItem"
        :for="id"
        :placeholder="validAttribute($attrs.required) ? '' : placeholder"
        :label="label ?? ''"
        :name="undefined"
        :helper="undefined"
        no-validation
        @change="onAddValue"
        @keydown="onKeyDown"
      />

      <template v-if="value">
        <m-layout-stack role="status">
          <m-layout-stack v-for="(item, i) in value" :key="i" justify-between horizontal>
            <div
              :class="{
                'marked-incorrect': markIncorrect?.(item),
              }"
            >
              <slot :item="item" :index="i" :label="getItemLabel(item)">{{
                renderText(item)
              }}</slot>
            </div>
            <m-button
              v-if="!disabled"
              icon="fa-regular fa-trash-alt"
              :label="`Delete ${renderText(item)}`"
              ghost
              color="danger"
              icon-only
              @click.capture.stop="onRemoveItem(item)"
            />
          </m-layout-stack>
        </m-layout-stack>
      </template>
    </m-layout-stack>
  </m-validation-component>
</template>
