<template>
  <div class="flex flex-col flex-nowrap gap-2">
    <h3 v-if="title">{{ title }}</h3>

    <div v-if="dragging" class="appointment-dropzone" @dragover="onDragover" @drop="onDrop">
      <span>Drop patient here.</span>
    </div>

    <m-layout-stack v-else class="scroll-area">
      <template v-for="item in schedule" :key="item.id">
        <m-appointment-diary
          v-if="item.scheduleType === 'diary'"
          :diary="item"
          :heading="item.summary.service.name"
          :show-diary-controls="showDiaryControls"
          :allow-appointment-context-menu="allowAppointmentContextMenu"
          :slot-label="slotLabel"
          :hide-reasons-for-appointments="hideReasonsForAppointments"
          @slot-clicked="$emit('slot-clicked', $event)"
          @appointment-clicked="$emit('appointment-clicked', $event)"
          @edit-diary="$emit('edit-diary', $event)"
          @print-diary="$emit('print-diary', $event)"
          @cancel-diary="$emit('cancel-diary', $event)"
          @squeeze-patient-in="$emit('squeeze-patient-in', $event)"
          @book-untimed-appointment="$emit('book-untimed-appointment', $event)"
          @edit-slot-appointment-type="$emit('edit-slot-appointment-type', $event)"
          @convert-slot-to-break="$emit('convert-slot-to-break', $event)"
          @remove-break="$emit('remove-break', $event)"
          @rota-activity-assignment-clicked="$emit('rota-activity-assignment-clicked', $event)"
          @convert-slot-to-rota-activity-assignment="
            $emit('convert-slot-to-rota-activity-assignment', $event)
          "
          @remove-rota-activity-assignment="$emit('remove-rota-activity-assignment', $event)"
        />

        <m-appointment-card
          v-else-if="
            item.scheduleType === 'unavailability-period' && item.eventType.isRotaActivityAssignment
          "
          class="slot unavailability-period"
          allow-right-click
          @click="$emit('rota-activity-assignment-clicked', item)"
        >
          <m-layout-stack gap="1">
            <p>
              <strong>{{ convertDateTimeToTime(item.startDateTime) }}</strong>
              {{ item.title }}
            </p>

            <p style="color: var(--theme-grey-darkest)">
              <span v-if="item.description">
                {{ item.description }} ({{ formatDuration(item.startDateTime, item.endDateTime) }})
              </span>
              <span v-else>{{ formatDuration(item.startDateTime, item.endDateTime) }}</span>
            </p>
          </m-layout-stack>

          <template #context-menu-options>
            <q-list ref="list" class="scroll-area" dense tabindex="0">
              <q-item
                v-close-popup
                clickable
                tabindex="-1"
                role="option"
                @click="$emit('remove-rota-activity-assignment', item.eventId)"
              >
                <q-item-section>
                  <q-item-label>Remove {{ item.title }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </template>
        </m-appointment-card>

        <div v-else class="unknown-schedule-type">
          <span class="empty-text">
            <span>{{ item.title }} • </span>
            <span v-if="item.isAllDay">All day</span>

            <span v-else>
              {{ formatTimeRange(item.startDateTime, item.endDateTime) }}
            </span>
          </span>
        </div>
      </template>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import MLayoutStack from "../../../medicus/MLayoutStack";
import MAppointmentDiary from "./../MAppointmentDiary";

import type { Schedule } from "./types";
import MAppointmentCard from "../MAppointmentCard";
import { formatDateTime } from "../../../../utils/date";

defineProps({
  title: String,
  schedule: Array as () => Array<Schedule>,
  onClick: Function,

  dragging: Boolean,

  showDiaryControls: {
    required: false,
    type: Boolean,
    default: false,
  },

  allowAppointmentContextMenu: {
    required: false,
    type: Boolean,
    default: false,
  },

  slotLabel: {
    required: false,
    type: String,
    default: "Book",
  },

  hideReasonsForAppointments: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits({
  drop: (_o: object) => true,
  "slot-clicked": (_o: object) => true,
  "appointment-clicked": (_o: object) => true,
  "cancel-diary": (_o: object) => true,
  "edit-diary": (_o: object) => true,
  "squeeze-patient-in": (_o: object) => true,
  "book-untimed-appointment": (_o: object) => true,
  "edit-slot-appointment-type": (_o: object) => true,
  "convert-slot-to-break": (_o: object) => true,
  "convert-slot-to-rota-activity-assignment": (_o: object) => true,
  "remove-break": (_o: object) => true,
  "remove-rota-activity-assignment": (_o: object) => true,
  "print-diary": (_o: object) => true,
  "rota-activity-assignment-clicked": (_o: object) => true,
});

function onDragover(e: DragEvent) {
  e.preventDefault();
  e.dataTransfer!.effectAllowed = "move";
}

function onDrop(e: DragEvent) {
  e.preventDefault();
  const obj = JSON.parse(e.dataTransfer!.getData("json"));
  emit("drop", obj);
}

function convertDateTimeToTime(dateTime: string) {
  return formatDateTime(dateTime).split(", ")[1];
}

function formatTimeRange(startDateTime: string, endDateTime: string) {
  return `${convertDateTimeToTime(startDateTime)} - ${convertDateTimeToTime(endDateTime)}`;
}

function formatDuration(startDateTime: string, endDateTime: string) {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);
  const mins = Math.round((end.getTime() - start.getTime()) / 60000);
  return `${mins} min${mins > 1 ? "s" : ""}`;
}
</script>

<style lang="scss">
.unknown-schedule-type {
  background: var(--grey-lightest);
  border-radius: 6px;
  padding: 16px;
  min-width: 300px;
}
.appointment-dropzone {
  width: 350px;
  background: var(--grey-light);
  color: var(--grey-darkest);
  border: 2px dashed var(--border-colour);
  box-sizing: border-box;
  border-radius: 4px;

  height: 100%;

  display: flex;

  // align-items: center;
  justify-content: center;
  padding-top: 150px;
}
</style>
