<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View future action/recall"
    @click="futureActionOverview()"
  >
    <FutureActionJournalElement :item="entry.data" :read-only="readOnly" :clickable="clickable" />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import FutureActionJournalElement from "../elements/FutureActionJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function futureActionOverview() {
  return props.readOnly
    ? openSlideover(`/future-action/patient-overview/${props.entry.id}`, { close: true })
    : undefined;
}
</script>
