<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span v-if="item.substanceDescription">Allergy: {{ item.substanceDescription }}</span>
        <span v-else-if="item.allergyCodeDescription">{{ item.allergyCodeDescription }}</span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel(item)
      }}</i>
    </m-layout-stack>
    <div v-if="getSeverityAndCertainty()">
      <span class="secondary-text">
        {{ getSeverityAndCertainty() }}
      </span>
    </div>
    <m-layout-stack
      v-if="
        item.isEnded ||
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.linkedProblems.length
      "
      horizontal
      class="q-mt-sm"
    >
      <m-badge v-if="item.isEnded" class="secondary">Ended</m-badge>
      <m-badge
        v-if="item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices"
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices"
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`allergy-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`allergy-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
export type AllergyJournalItem = {
  entryType: string;
  id: string;
  substanceDescription?: string;
  allergyCodeDescription: string;
  isEnded: boolean;
  severity?: string;
  certainty?: string;
  recordedBy: string;
  recordedByOrganisation: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  sortOrder?: unknown[];
  createdDateTime?: string;
};

const props = defineProps<{
  item: AllergyJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

function getSeverityAndCertainty() {
  const textAsArray = [props.item.severity, props.item.certainty];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
}

function formatRetrospectivelyAmendedLabel(item: AllergyJournalItem) {
  let label = [];
  if (item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
}
</script>
