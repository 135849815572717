<script lang="ts" setup>
import { DefineComponent, computed, provide, ref, watch, watchEffect } from "vue";

import { useLocalStorage, useMatchMedia } from "vue-composable";
import { RouteLocationNormalized, useRouter } from "vue-router";
import AudioContainer from "../../components/AudioContainer.vue";
import DialogContainer from "../../components/DialogContainer.vue";
import ErrorBoundary from "../../components/ErrorBoundary.vue";
import VideoCallContainer from "../../components/VideoCallContainer.vue";
import MedicusDrawer from "../../components/navigation/MedicusDrawer";
import MobileSplashScreen from "../../components/ui/MobileSplashScreen.vue";
import { pusher } from "../../plugins/thirdparties/pusher";
import { useApp, useAuth } from "../../store";
import { useMeeting } from "../../store/meeting";
import { isExpired } from "../../utils/session";
import MenuPresenter from "./MenuPresenter/MenuPresenter.vue";
import { MenuType } from "./SidebarMenu";
import { listen } from "../../utils/bus";
import { usePanicButton } from "../../store/panicButton";
import PanicButtonActiveAlertsModal from "../../components/misc/PanicButtonActiveAlertsModal";
import PanicButtonAlerts from "../../components/misc/PanicButtonAlerts";
import KeyboardShortcutsModal from "../../components/misc/KeyboardShortcutsModal";
import { useKeyboardShortcut } from "../../composables/keyboardShortcut";

const router = useRouter();
const meetingStore = useMeeting();
const store = useAuth();
const appStore = useApp();
const panicButtonStore = usePanicButton();

watch(
  () => store.user,
  (user) => {
    if (user?.username) {
      const channel = pusher.subscribe(`update-tenants-${user.username}`);

      channel.bind("update-tenants-available", async () => {
        const info = await store.refreshUserInfo();
        const tenants = info.tenants ?? {};
        const tenantIds = Object.keys(tenants);
        if (tenantIds.length) {
          return store.logout();
        }
        if (tenants[store.selectedTenant!]) return;

        if (tenantIds.length === 1) {
          // TODO probably do something here
          return;
        }
        return router.push({
          name: "tenants",
        });
      });
    }
  },
  {
    immediate: true,
  },
);

watch(isExpired, (e) => {
  if (e) {
    store.logout(location.pathname + location.search, { expired: "true" });
  }
});

const componentEl = ref<InstanceType<
  DefineComponent<any, { reloadData: () => Promise<void> }>
> | null>();

function reloadData() {
  return componentEl.value?.reloadData?.();
}
provide("reloadData", reloadData);

const showSidenav = computed(() => !!componentEl.value?.subnav);

defineExpose({
  reloadData,
});

const menuType = ref<MenuType | null>(null);
function openMenu(menu: MenuType) {
  if (menu === "user") {
    //To refresh Active Panic Alerts counter if 'other' locations updated
    panicButtonStore.fetchActiveAlerts();
  }

  menuType.value = menu;
}

provide("openMenu", openMenu);

const { storage: expandedUser } = useLocalStorage("sidebar-expanded", true);

const expanded = ref(expandedUser.value);
const isSendingPanicAlert = ref(false);

const { matches } = useMatchMedia("(orientation: portrait)");

useKeyboardShortcut("show-keyboard-shortcuts-modal", () => {
  appStore.setKeyboardShortcusModalVisible(true);
});

useKeyboardShortcut("collapse-main-menu", () => {
  expanded.value = false;
});

useKeyboardShortcut("expand-main-menu", () => {
  expanded.value = true;
});

useKeyboardShortcut("panic-button", () => {
  if (isSendingPanicAlert.value === false && panicButtonStore.isPanicButtonFlashing === false) {
    isSendingPanicAlert.value = true;
    panicButtonStore.sendPanicAlert().then(() => {
      isSendingPanicAlert.value = false;
    });
  }
});

/**
https://uat.england.medicus.health/a10001/clinical/encounter/preview?patientId=27a8db63-28d9-11eb-adc1-47c2069fbda5
https://uat.england.medicus.health/a10001/tasks/care-record-document/task-list
https://uat.england.medicus.health/a10001/tasks/gp-connect-document/task-list
https://uat.england.medicus.health/a10001/tasks/post-event-message-111/task-list
 */

function checkExpand(to: RouteLocationNormalized) {
  if (window.innerWidth < 700 || matches.value) {
    expanded.value = false;
    return;
  }
  // if is collapsed
  const shouldExpand = collapsedUrls.every((x) => to.fullPath.indexOf(x) === -1);
  // if the user wants it expanded
  if (expandedUser.value) {
    expanded.value = shouldExpand;
  }
}

// @todo should move to a function that can call to toggle the subnav instead
const collapsedUrls = [
  "tasks/care-record-document/task-list",
  "tasks/gp-connect-document/task-list",
  "tasks/post-event-message-111/task-list",
  "tasks/match-investigation-report/task-list",
  "tasks/review-investigation-report/task-list-old",
];

watchEffect(() => {
  checkExpand(router.currentRoute.value);
});

watch(expandedUser, (e) => (expanded.value = e));
function toggleExpanded() {
  expandedUser.value = expanded.value = !expanded.value;
}

listen("closeSubnav", (_) => {
  expandedUser.value = expanded.value = false;
});

const isSuspenseBusy = ref(true);
function onPending() {
  isSuspenseBusy.value = true;
}
function onResolve() {
  isSuspenseBusy.value = false;
}
</script>
<template>
  <div
    :class="[
      'page-container',
      expanded && 'sidebar-expanded',
      menuType && 'sidemenu-opened',
      !showSidenav && 'hide-sidenav',
    ]"
    :aria-busy="isSuspenseBusy"
  >
    <a href="#main" class="skip-to-content" data-testid="skip-to-content"> Skip to main content </a>
    <div class="sidebar">
      <div ref="miniButton" class="absolute sidebar--toggle-btn">
        <m-button
          :class="'drawer-sidenav-button'"
          :icon="expanded ? 'fa-solid fa-chevron-left' : 'fa-solid fa-chevron-right'"
          :sr-label="`${expanded ? 'Collapse' : 'Expand'} Sub-navigation`"
          :aria-expanded="expanded"
          :title="`${expanded ? 'Collapse' : 'Expand'} Sub-navigation`"
          icon-only
          borderless
          data-testid="subnav-mini-button"
          aria-controls="sidebar-content"
          @click="toggleExpanded"
        />
      </div>
      <div id="sidebar-content" class="sidebar--content">
        <div id="sidenav" class="sidenav">
          <!-- TO BE added by teleport -->
        </div>
      </div>
    </div>
    <div :class="['sidemenu', { opened: !!menuType }]">
      <MenuPresenter v-if="menuType" v-model="menuType" />
    </div>

    <main id="main" class="content" role="main" tabindex="-1">
      <suspense @pending="onPending" @resolve="onResolve">
        <router-view v-slot="{ Component }">
          <ErrorBoundary>
            <component :is="Component" ref="componentEl" />
          </ErrorBoundary>
        </router-view>
      </suspense>
    </main>

    <dialog-container />
    <audio-container />
    <video-call-container
      v-if="meetingStore.meetingId"
      :key="meetingStore.meetingId"
      :meeting-id="meetingStore.meetingId"
    />

    <MobileSplashScreen />
    <PanicButtonActiveAlertsModal v-model="panicButtonStore.isPanicAlertsModalShowing" />
    <KeyboardShortcutsModal v-model="appStore.isKeyboardShortcusModalVisible" />

    <!-- <MedicusSlideover />
    <MedicusModal /> -->

    <MedicusDrawer />
    <Transition name="fade">
      <PanicButtonAlerts v-if="panicButtonStore.alertsToDisplay.length > 0" />
    </Transition>
  </div>
</template>
<style lang="scss">
html,
body,
#q-app {
  // min-height: max(320px, 100vh) !important;
}
.skip-to-content {
  background: rgb(31, 111, 235);
  color: white;
  padding: 20px;
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;
  text-decoration: none;

  &:focus {
    top: 4px;
    left: 4px;
    z-index: 9001;
  }
}

.page-container {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 100%;
  gap: 0px 0px;
  grid-template-areas: "sidebar content";
  height: 100%;
  flex: 1 1 auto;

  min-width: 320px;

  &.hide-sidenav {
    grid-template-areas: "content content";
    > .sidebar {
      display: none;
    }
  }

  > .sidebar {
    position: relative;
    grid-area: sidebar;
    background: var(--theme-darkest-blue);
    color: var(--grey-lightest);

    // z-index: 9001;
    z-index: 1001;

    width: var(--sidebar-width-collapsed);

    transition: width 0.2s;
  }

  .drawer-sidenav-button {
    border-radius: 50%;
    height: 24px;
    max-height: 24px;
    width: 24px;
    max-width: 24px;

    > button.medicus-button {
      background: var(--grey-darkest);
      border-radius: 50%;
      height: 24px;
      max-height: 24px;
      width: 24px;
      max-width: 24px;
      transition-property: width, border-radius;
      transition-duration: 0.2s;
      transition-timing-function: ease-out;

      &:focus {
        &::before {
          display: none;
        }

        outline: 2px solid var(--focus-ring);
        outline-offset: 2px;
      }

      i {
        font-size: 12px !important;
      }
    }
  }

  &:not(.sidebar-expanded) {
    .sidebar--content {
      height: 100%;
      // display: none;
      .sidenav {
        height: 100%;
      }

      .sidenav-logo,
      .sidenav-title,
      .sidenav-user .user-info,
      .sidenav-content > *,
      .scroll-container {
        display: none;
      }

      .top-nav,
      .sidenav-support,
      .sidenav-user,
      .sidenav-panic-button {
        margin: 0;

        .q-btn {
          border-radius: 0;
          aspect-ratio: 1 / 1;

          .q-btn__content {
            > div {
              justify-content: center;
            }

            span {
              display: none;
            }
          }
        }
      }

      .top-nav {
        border-bottom: 0;
        margin-top: var(--page-header-height);
      }

      .bottom-nav {
        border-top: 0;
        margin: 0;

        .q-btn {
          aspect-ratio: 1 / 1;
        }
      }
    }

    .sidebar--toggle-btn {
      top: 0;
      right: 0;
      z-index: 1001;
      transition: right 0.2s;
    }

    .drawer-sidenav-button {
      height: var(--page-header-height) !important;
      max-height: var(--page-header-height) !important;
      width: var(--sidebar-width-collapsed) !important;
      max-width: var(--sidebar-width-collapsed) !important;
      padding: 0;

      button {
        border-radius: 0 !important;
        height: var(--page-header-height) !important;
        max-height: var(--page-header-height) !important;
        width: var(--sidebar-width-collapsed) !important;
        max-width: var(--sidebar-width-collapsed) !important;

        &:focus {
          outline-offset: -2px;
        }
      }
    }
  }

  &.sidebar-expanded {
    > .sidebar {
      width: 330px;
    }

    .sidebar--content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 100%;
      height: 100%;

      overflow: hidden;
    }

    .sidebar--toggle-btn {
      top: 12px;
      right: 12px;
    }
  }

  &.sidemenu-opened {
    .sidebar--toggle-btn {
      display: none;
    }
  }
  > .content {
    grid-area: content;
    overflow: hidden;
    background: var(--medicus-background);
  }

  > .sidemenu {
    position: absolute;
    // left: 50px;
    z-index: 1002;

    background-color: white;
    transition: width 0.2s;

    height: 100vh;
    width: 0;

    overflow: hidden;

    &.opened {
      // z-index: 9002;
      width: 330px;
      border-right: 1px solid var(--border-colour);

      > * {
        min-width: 330px;
      }
    }
  }

  @media (orientation: portrait) {
    grid-template-columns: var(--sidebar-width-collapsed) 1fr;
    grid-template-rows: 100%;
    grid-template-areas: "sidebar content";

    .sidebar--toggle-btn {
      right: -12px;
    }

    > .sidebar {
      position: absolute;

      // tab focus has index: 10 ; Journal has index: 999
      z-index: 100;
      height: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.alert-card .card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .alert-name {
    // font-weight: 600;
  }

  .alert-time {
    color: var(--text-color-lightest);
  }
}
</style>
