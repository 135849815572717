<template>
  <m-layout-stack v-if="!hideTopicDetailsSection" data-testid="encounter-details-section">
    <div :class="['flex flex-col gap-1']">
      <p
        :class="[
          {
            'marked-incorrect': topic.isMarkedIncorrect,
          },
        ]"
      >
        {{ topic?.topicCode?.topicCode?.label ?? topic.title }}
      </p>
      <m-layout-stack
        v-if="
          showTopicIsHiddenFromPatientFacingServicesBadge ||
          showTopicIsConfidentialFromThirdPartiesBadge ||
          topic.linkedProblems.length
        "
        horizontal
        class="q-mt-sm"
      >
        <m-badge
          v-if="showTopicIsHiddenFromPatientFacingServicesBadge"
          text="Redacted from patient online access"
          gray
          data-testid="topic-hidden-from-patient-facing-services-badge"
          class="q-mb-sm"
        />
        <m-badge
          v-if="showTopicIsConfidentialFromThirdPartiesBadge"
          text="Confidential from Third Parties"
          gray
          data-testid="topic-confidential-from-third-parties-badge"
          class="q-mb-sm"
        />
        <m-badge
          v-for="(linkedProblem, index) in topic.linkedProblems"
          :key="`${topic.topicCode}-topic-linked-problem-${index}`"
          :text="linkedProblem.problemCodeDescription"
          gray
          :data-testid="`${topic.id}-topic-linked-problem-badge-${index}`"
          class="q-mb-sm"
        />
      </m-layout-stack>
    </div>
  </m-layout-stack>

  <span v-if="!topic.headings.length" class="empty-text">
    No sections have been added to this consultation topic.
  </span>

  <template v-for="heading in topic.headings" :key="heading.id">
    <template v-if="hideEmptyHeadings ? heading.entries.length > 0 : true">
      <p
        :class="[
          {
            'marked-incorrect': heading.isMarkedIncorrect,
          },
        ]"
      >
        {{ heading.title }}
      </p>

      <m-layout-stack
        v-if="
          showHeadingIsHiddenFromPatientFacingServicesBadge(heading) ||
          showHeadingIsConfidentialFromThirdPartiesBadge(heading)
        "
        horizontal
        class="q-mt-sm"
      >
        <m-badge
          v-if="showHeadingIsHiddenFromPatientFacingServicesBadge(heading)"
          text="Redacted from patient online access"
          gray
          data-testid="topic-hidden-from-patient-facing-services-badge"
        />
        <m-badge
          v-if="showHeadingIsConfidentialFromThirdPartiesBadge(heading)"
          text="Confidential from Third Parties"
          gray
          data-testid="topic-confidential-from-third-parties-badge"
          class="q-mb-sm"
        />
      </m-layout-stack>

      <m-list v-if="heading.entries.length">
        <ConsultationCareRecordElements
          :data-testid="`section-${heading.title}`"
          :items="heading.entries"
          :read-only="readOnly"
          :context-hidden-fps="getContextIsHiddenFromPatientFacingServices(heading)"
          :context-confidential-ftp="getContextIsConfidentialFromThirdParties(heading)"
        />
      </m-list>
      <div v-else>
        <p class="empty-text">There are no entries recorded.</p>
      </div>
    </template>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ConsultationCareRecordElements from "./ConsultationCareRecordElements.vue";

export interface Topic {
  id: string;
  title: string;
  topicCode: TopicCode;
  linkedProblems: any[];
  headings: Heading[];
  isDraft: boolean;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  encounterHiddenFromPatientFacingServices: boolean;
  encounterConfidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
}

export interface TopicCode {
  topicCode?: {
    label: string;
  };
}

export interface Heading {
  id: string;
  title: string;
  entries: Entry[];
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  encounterHiddenFromPatientFacingServices: boolean;
  encounterConfidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
}

export interface Entry {
  entryType: string;
  id: string;
  note?: string;
  clinicalCodeDescription?: string;
  recordedBy: string;
  recordedByOrganisation?: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isExplicitlyIncludedInSCR: boolean;
  isRetrospectivelyAmended: boolean;
  patientBannerFlags: any[];
  linkedProblems: any[];
  createdDateTime?: string;
}

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  topic: Topic;
  topicTitle?: string;
  readOnly?: boolean;
  showTopicTitleInside?: boolean;
  patientId?: string;
  hideEmptyHeadings?: boolean;
  encounterHiddenFromPatientFacingServices?: boolean;
  encounterConfidentialFromThirdParties?: boolean;
  consultationTopicCount: number;
}>();

const hideTopicDetailsSection = computed(() => {
  return hideTopicCodeWhenEmpty.value;
});

const hideTopicCodeWhenEmpty = computed(() => {
  return !props.topic.topicCode.topicCode && !props.topic.title;
});

const showTopicIsHiddenFromPatientFacingServicesBadge = computed(() => {
  return (
    props.topic.hiddenFromPatientFacingServices || props.encounterHiddenFromPatientFacingServices
  );
});

const showTopicIsConfidentialFromThirdPartiesBadge = computed(() => {
  return props.topic.confidentialFromThirdParties || props.encounterConfidentialFromThirdParties;
});

function showHeadingIsHiddenFromPatientFacingServicesBadge(heading) {
  return (
    !showTopicIsHiddenFromPatientFacingServicesBadge.value &&
    heading.hiddenFromPatientFacingServices
  );
}

function showHeadingIsConfidentialFromThirdPartiesBadge(heading) {
  return (
    !showTopicIsConfidentialFromThirdPartiesBadge.value &&
    heading.encounterConfidentialFromThirdParties
  );
}

function getContextIsHiddenFromPatientFacingServices(heading) {
  return (
    props.topic.hiddenFromPatientFacingServices ||
    props.encounterHiddenFromPatientFacingServices ||
    heading.hiddenFromPatientFacingServices
  );
}

function getContextIsConfidentialFromThirdParties(heading) {
  return (
    props.topic.confidentialFromThirdParties ||
    props.encounterConfidentialFromThirdParties ||
    heading.encounterConfidentialFromThirdParties
  );
}
</script>
