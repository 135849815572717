<script setup lang="ts">
import { reactive, ref, nextTick } from "vue";
import { useSetupAccount } from "../../store/resetPassword";
import { computed } from "@vue/reactivity";
import MInput from "../../components/medicus/inputs/MInput";
import MButton from "../../components/medicus/MButton";
import MBanner from "../../components/medicus/MBanner";
import MForm from "../../components/medicus/MForm";
import MLayoutStack from "../../components/medicus/MLayoutStack";
import { useRouter } from "vue-router";
import { useAuth } from "../../store";

const props = defineProps<{ setupCode: string; username: string }>();

const router = useRouter();

const store = useSetupAccount();
const auth = useAuth();

const showPassword = reactive({
  pw: false,
  pw2: false,
});

const passwordMismatch = ref(false);

const otpCode = ref("");

const errors = computed<string[]>(() =>
  Object.values(
    (store.setupUser.error?.response?.data?.errors as Record<string, string>) ?? {},
  ).flat(),
);

const isLoading = computed(() => store.validateResetKey.loading || store.validatePassword.loading);

async function doAccountSetup() {
  if (!store.password || !store.passwordConfirm) {
    return;
  }

  if (store.password !== store.passwordConfirm) {
    passwordMismatch.value = true;
    return;
  }
  passwordMismatch.value = false;

  try {
    const r = await store.setupUser.exec({
      username: props.username,
      setupCode: props.setupCode,
      otpCode: otpCode.value,
      password: {
        password: store.password,
        passwordConfirm: store.passwordConfirm,
      },
      secret: store.mfaSecret.result?.data.secret,
    });

    if (r) {
      await auth.refreshUserInfo();

      nextTick(() => {
        return router.push({
          name: "tenants",
        });
      });
    }
  } catch (e) {
    console.error(e);
  }
}

defineExpose({
  otpCode,
  doAccountSetup,
});
</script>
<template>
  <m-layout-stack>
    <m-form hide-required-message :enter-submit="true" @submit="doAccountSetup">
      <MBanner
        v-if="passwordMismatch"
        type="danger"
        data-testid="password-mismatch-error-display"
        class="q-mb-lg"
      >
        Passwords do not match
      </MBanner>
      <m-input
        v-model="store.password"
        outlined
        label="Password"
        autocomplete="new-password"
        :type="showPassword.pw ? 'text' : 'password'"
        color="primary"
        data-testid="password-input"
        placeholder=""
        required
      >
        <template #append>
          <m-button
            :icon="showPassword.pw ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
            color="secondary"
            ghost
            icon-only
            :label="showPassword.pw ? 'Hide password' : 'Show password'"
            data-testid="password-toggle-button"
            @click="showPassword.pw = !showPassword.pw"
          />
        </template>
      </m-input>
      <m-input
        v-model="store.passwordConfirm"
        outlined
        label="Confirm password"
        :type="showPassword.pw2 ? 'text' : 'password'"
        color="primary"
        data-testid="confirm-password-input"
        autocomplete="new-password"
        placeholder=""
        required
      >
        <template #append>
          <m-button
            :icon="showPassword.pw2 ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
            color="secondary"
            ghost
            icon-only
            :label="showPassword.pw2 ? 'Hide password' : 'Show password'"
            data-testid="confirm-password-toggle-button"
            @click="showPassword.pw2 = !showPassword.pw2"
          />
        </template>
      </m-input>

      <m-banner type="info" class="q-mb-sm">
        <strong>Secure password tip: </strong> The National Cyber Security Centre advises creating a
        password using
        <a
          href="https://www.ncsc.gov.uk/blog-post/three-random-words-or-thinkrandom-0"
          target="_blank"
          >three random words</a
        >.
      </m-banner>

      <m-layout-stack v-if="store.mfaSecret.result?.data.qrCode">
        <p class="q-my-lg text-center">
          Scan the QR code with your authenticator app (e.g. Google Authenticator or Microsoft
          Authenticator) to pair a new authenticator app:
        </p>
        <div
          style="width: 50%"
          class="q-mx-auto"
          data-testid="qr-code-display"
          v-html="store.mfaSecret.result.data.qrCode"
        />
        <p class="q-my-lg text-center">
          Or enter manually: <br />{{ store.mfaSecret.result.data.secret }}
        </p>
        <m-input
          v-model="otpCode"
          label="6 digit token from authenticator app"
          required
          mask="999999"
          inputmode="numeric"
          data-testid="otp-code-input"
        />
      </m-layout-stack>

      <template #footer>
        <m-button
          type="submit"
          class="float-right login-button w-full"
          data-cy="changePasswordButton"
          :disable="isLoading"
          data-testid="change-password-button"
          @click="doAccountSetup"
        >
          Complete setup
        </m-button>

        <div v-if="errors" type="error" class="error-text q-mb-sm" data-test-id="error-container">
          <p v-for="errorText in errors" :key="errorText">{{ errorText }}</p>
        </div>
      </template>
    </m-form>
  </m-layout-stack>
</template>
