<script setup lang="ts">
import { Browser } from "detect-browser";
import { capitalize, computed } from "vue";
import { useRouter } from "vue-router";
import useBrowser from "../../store/browser";
import MedicusLogo from "../../svg/MedicusLogo.vue";
import { isMobile } from "../../utils/resize";

const store = useBrowser();

const cleanName = {
  chrome: "Google Chrome",
  "edge-chromium": "Edge",
  ios: "Iphone Safari",
} as Record<Browser, string>;

const name = computed(() => {
  return cleanName[store.name!] ?? capitalize(store.name!) ?? "Invalid browser";
});

if (store.supported.supported) {
  useRouter().push("/");
}
</script>
<template>
  <div class="unsupported-page" :class="{ mobile: store.isMobile || isMobile }">
    <medicus-logo class="medicus-logo" white />

    <h1 class="title">Unsupported Browser</h1>

    <svg
      v-if="store.isMobile || isMobile"
      width="167"
      height="246"
      viewBox="0 0 167 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="115.039"
        height="4.57309"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 36.1338 49)"
        fill="#46556A"
      />
      <rect
        width="79.9421"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 35.8184 58.1464)"
        fill="#46556A"
      />
      <rect
        width="109.189"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0347902 0.999395 35.6631 62.7135)"
        fill="#46556A"
      />
      <rect
        width="91.6409"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0347902 0.999395 35.499 67.2869)"
        fill="#46556A"
      />
      <ellipse
        rx="6.00503"
        ry="5.71424"
        transform="matrix(1 0 -0.0213391 0.999772 20.1272 54.7161)"
        fill="#6EBA90"
      />
      <rect
        width="115.039"
        height="4.57309"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 36.1338 91.7496)"
        fill="#848D9C"
      />
      <rect
        width="79.9421"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 35.8193 100.897)"
        fill="#848D9C"
      />
      <rect
        width="109.189"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0347902 0.999395 35.6621 105.463)"
        fill="#848D9C"
      />
      <rect
        width="91.6409"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0347902 0.999395 35.499 110.036)"
        fill="#848D9C"
      />
      <ellipse
        rx="6.00503"
        ry="5.71424"
        transform="matrix(1 0 -0.0213391 0.999772 20.1272 97.466)"
        fill="#4078B6"
      />
      <rect
        width="115.039"
        height="4.57309"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 36.1338 134.334)"
        fill="#46556A"
      />
      <rect
        width="79.9421"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 35.8135 143.474)"
        fill="#46556A"
      />
      <rect
        width="109.189"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0347902 0.999395 35.6592 148.047)"
        fill="#46556A"
      />
      <rect
        width="91.6409"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0347902 0.999395 35.499 152.621)"
        fill="#46556A"
      />
      <ellipse
        rx="6.00503"
        ry="5.71424"
        transform="matrix(1 0 -0.0213391 0.999772 20.1272 140.05)"
        fill="#E16E7C"
      />
      <rect
        width="115.039"
        height="4.57309"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 36.1963 176.716)"
        fill="#C1C6CD"
      />
      <rect
        width="79.9421"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 35.875 185.857)"
        fill="#C1C6CD"
      />
      <rect
        width="109.189"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0346307 0.9994 35.7188 190.425)"
        fill="#C1C6CD"
      />
      <rect
        width="91.6409"
        height="2.28655"
        rx="1"
        transform="matrix(1 0 -0.0347902 0.999395 35.5625 194.997)"
        fill="#C1C6CD"
      />
      <ellipse
        rx="6.00503"
        ry="5.71424"
        transform="matrix(1 0 -0.0213391 0.999772 20.1272 182.426)"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="166" height="245" rx="7.5" stroke="white" />
      <path
        d="M163.017 5.74108L163.017 225.641C163.017 226.47 162.346 227.141 161.517 227.141L5.20704 227.141C4.37861 227.141 3.70704 226.47 3.70704 225.641L3.70703 5.74108C3.70703 4.91266 4.3786 4.24109 5.20703 4.24109L61.2581 4.24109C62.0866 4.24109 62.7581 4.91266 62.7581 5.74109L62.7581 7.35195C62.7581 8.73267 63.8774 9.85195 65.2581 9.85195L107.732 9.85195C109.113 9.85195 110.232 8.73266 110.232 7.35195L110.232 5.74108C110.232 4.91266 110.904 4.24108 111.732 4.24108L161.517 4.24108C162.346 4.24108 163.017 4.91265 163.017 5.74108Z"
        stroke="white"
      />
      <rect x="68" y="231" width="31" height="10" rx="5" fill="#46556A" />
    </svg>

    <svg
      v-else
      width="451"
      height="312"
      viewBox="0 0 451 312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="311.5"
        width="311"
        height="450"
        rx="7.5"
        transform="rotate(-90 0.5 311.5)"
        stroke="white"
      />
      <rect
        width="132.604"
        height="8.55476"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.821 54)"
        fill="#46556A"
      />
      <rect
        width="92.1486"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.457 71.1162)"
        fill="#46556A"
      />
      <rect
        width="125.861"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.274 79.6636)"
        fill="#46556A"
      />
      <rect
        width="105.634"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.094 88.2217)"
        fill="#46556A"
      />
      <ellipse
        rx="6.30533"
        ry="6"
        transform="matrix(1 0 -0.0213391 0.999772 16.4761 15.9986)"
        fill="#6EBA90"
      />
      <rect
        width="132.604"
        height="8.55476"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.819 118.848)"
        fill="#848D9C"
      />
      <rect
        width="92.1486"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.458 135.964)"
        fill="#848D9C"
      />
      <rect
        width="125.861"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.273 144.511)"
        fill="#848D9C"
      />
      <rect
        width="105.634"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.094 153.069)"
        fill="#848D9C"
      />
      <ellipse
        rx="6.30533"
        ry="6"
        transform="matrix(1 0 -0.0213391 0.999772 34.4761 15.9986)"
        fill="#4078B6"
      />
      <rect
        width="132.604"
        height="8.55476"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.82 183.691)"
        fill="#46556A"
      />
      <rect
        width="92.1486"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.454 200.797)"
        fill="#46556A"
      />
      <rect
        width="125.861"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.271 209.355)"
        fill="#46556A"
      />
      <rect
        width="105.634"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.092 217.913)"
        fill="#46556A"
      />
      <ellipse
        rx="6.30533"
        ry="6"
        transform="matrix(1 0 -0.0213391 0.999772 52.4761 15.9986)"
        fill="#E16E7C"
      />
      <rect
        width="132.604"
        height="8.55476"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 160.003 248.552)"
        fill="#C1C6CD"
      />
      <rect
        width="92.1486"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.637 265.658)"
        fill="#C1C6CD"
      />
      <rect
        width="125.861"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.452 274.205)"
        fill="#C1C6CD"
      />
      <rect
        width="105.634"
        height="4.27738"
        rx="1"
        transform="matrix(1 0 -0.0213391 0.999772 159.271 282.763)"
        fill="#C1C6CD"
      />
      <line x1="1" y1="31.5" x2="450" y2="31.5" stroke="white" />
    </svg>

    <p v-if="store.supported.browser">
      You are using an unsupported version of
      {{ name }} (Version {{ store.version ?? "Invalid version" }}). Please update your
      {{ name }} version to use Medicus.
    </p>
    <p v-else>
      {{ name }} is not a supported browser. Please download Google Chrome, Microsoft Edge or Apple
      Safari to use Medicus.
    </p>
  </div>
</template>
<style lang="scss">
.unsupported-page {
  width: 100%;
  height: 100%;
  background-color: var(--theme-darkest-blue);
  color: white;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 3rem;

  .title {
    color: white;

    font-size: 22px;
  }

  .medicus-logo {
    position: absolute;

    top: 0;
    left: 0;

    height: 24px;

    margin-top: 18px;
    margin-left: 24px;

    .medicus-logo-text {
      fill: #ffffff;
    }
  }

  p {
    max-width: 550px;
    margin: 0 2rem;

    text-align: center;
  }

  &.mobile {
    .medicus-logo {
      height: 16px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
