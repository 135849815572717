<template>
  <m-layout-stack
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
    gap="1"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <p>
          {{ item.prescriptionTypeLabel }} Prescription: {{ item.productName }}
          <span v-if="item.dosageText">• {{ item.dosageText }}</span>
        </p>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <m-layout-stack horizontal>
      <m-badge :text="item.displayStatus" :colour="item.requiresAction ? 'amber' : 'grey'" />
      <m-badge
        v-if="
          item.hiddenFromPatientFacingServices &&
          item.hiddenFromPatientFacingServices !== contextHiddenFromPatientFacingServices
        "
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`prescription-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`prescription-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </m-layout-stack>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type PrescriptionJournalItem = {
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  requiresAction: boolean;
  displayStatus: string;
  productName: string;
  dosageText: string;
  prescriptionTypeLabel: string;
  isMarkedIncorrect: boolean;
  isRetrospectivelyAmended: boolean;
};

const props = defineProps<{
  item: PrescriptionJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
