import MButton from "../../components/medicus/MButton/MButton.vue";
import type { DrawerUrlOptions } from "../../store/drawers";
import { PropsOf } from "../../utils/types";

export const SnackbarType = {
  System: "system",
  Info: "info",
  Danger: "danger",
  Success: "success",
  Warning: "warning",
  PanicAlert: "panic-alert",
} as const;

type SnackbarV = (typeof SnackbarType)[keyof typeof SnackbarType];
interface Action {
  label: string;
  onClick?: () => any;
  click?: () => any;
  to?: string;
  toSlideover?: string | DrawerUrlOptions;
  toModal?: string | DrawerUrlOptions;

  actions?: any;
}
export interface SnackbarMessage {
  type?: SnackbarV;

  message: string;

  title?: string;
  actions?: Action[];
  buttons?: PropsOf<typeof MButton>[];

  icon?: string;

  timeout?: number;
  persistant?: boolean;
}
