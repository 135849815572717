<template>
  <m-layout-stack class="m-clinical-code-input">
    <div ref="input" class="note-container" @blur.capture="onBlur">
      <m-layout-stack ref="inputNote" class="note-outer relative" horizontal>
        <m-inline-input
          ref="inlineInput"
          v-model="value"
          class="m-note w-full"
          :class="{ 'with-shortcut': selectedShortcut }"
          :for="$attrs.for"
          role="combobox"
          :aria-owns="!!menu ? menuId : undefined"
          :aria-controls="!!menu ? menuId : undefined"
          :aria-expanded="!!menu"
          :aria-label="ariaLabelledby ? undefined : label"
          :aria-labelledby="ariaLabelledby"
          :aria-activedescendant="!!menu ? `${menuId}-${focusedItem}` : undefined"
          :placeholder="placeholder"
          @keydown.capture="onKeyDown"
          @update:modelValue="handleInputUpdate"
        >
          <template #prepend>
            <m-clinical-code-badge v-if="selectedShortcut" class="shortcut-note">
              {{ selectedShortcut.label }}
            </m-clinical-code-badge>
            <!-- <template v-if="!modelValue">&nbsp;</template> -->

            <q-spinner
              v-if="searchItems.loading.value"
              class="note-loading-spinner"
              color="primary"
              size="1em"
            />
          </template>
        </m-inline-input>
      </m-layout-stack>

      <m-menu
        v-if="!!shortcutItems && shortcutItems?.length && showItems"
        :id="menuId"
        ref="menu"
        class="m-note-menu"
        :target="inputNote"
        no-refocus
        no-focus
        :model-value="true"
        :scroll-target="false"
        :offset="[0, menuOffset]"
        :max-height="300"
        fit
        :style="{ width: menuWidth }"
        role="listbox"
      >
        <div class="m-note-menu-content">
          <q-list ref="list" class="scroll-area" dense tabindex="0">
            <template v-if="(shortcutItems?.length ?? 0) > 0">
              <q-item
                v-for="(shortcut, i) in shortcutItems"
                :id="`${menuId}-${i}`"
                :key="shortcut?.label || i"
                :ref="onRef"
                v-close-popup
                clickable
                :focused="i === focusedItem"
                tabindex="-1"
                :aria-selected="i === focusedItem"
                role="option"
                @click="selectShortcut(shortcut)"
              >
                <q-item-section>
                  <q-item-label v-if="shortcut.fragments">
                    <template v-for="(frag, i) in shortcut.fragments" :key="frag + i">
                      <b v-if="typeof frag === 'object'">{{ frag.search }}</b>
                      <template v-else>
                        {{ frag }}
                      </template>
                    </template>
                  </q-item-label>
                  <q-item-label v-else>
                    {{ shortcut.label }}
                  </q-item-label>
                  <q-item-label v-if="shortcut.description" caption>{{
                    shortcut.description
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
            <q-item v-else role="menuitem">
              <q-item-section>
                <q-item-label caption>
                  There are no matching results for "{{ value }}".
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </m-menu>

      <m-menu
        v-if="!!actionsMenuItems && actionsMenuItems?.length && value.startsWith('/')"
        :id="actionsMenuId"
        ref="actionsMenu"
        class="m-note-menu"
        :target="inputNote"
        no-refocus
        no-focus
        :model-value="true"
        :scroll-target="false"
        :offset="[0, menuOffset]"
        :max-height="300"
        fit
        :style="{ width: menuWidth }"
        role="listbox"
      >
        <div class="m-note-menu-content">
          <q-list ref="actionsList" class="scroll-area" dense tabindex="0">
            <q-item
              v-for="(action, i) in filteredActionMenuItems"
              :id="`${actionsMenuId}-${i}`"
              :key="action"
              :ref="onActionsRef"
              v-close-popup
              clickable
              :focused="i === focusedAction"
              tabindex="-1"
              :aria-selected="i === focusedAction"
              role="option"
              @click="selectAction(action)"
            >
              <q-item-section>
                <q-item-label>
                  {{ action.label }}
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              v-if="
                actionsMenuItems.filter((action) =>
                  action.label.toLowerCase().includes(value.substring(1).toLowerCase()),
                ).length < 1
              "
              role="menuitem"
            >
              <q-item-section>
                <q-item-label caption>
                  There are no actions for "{{ value.substring(1) }}".
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </m-menu>
    </div>
  </m-layout-stack>
</template>
<script setup lang="ts">
import { QItem, QItemLabel, QItemSection, QList, QSpinner, debounce } from "quasar";
import { Key } from "ts-key-enum";
import { Component, computed, nextTick, onUpdated, ref, useAttrs, watch } from "vue";
import { NO_OP, usePromiseLazy, useVModel } from "vue-composable";
import axios, { CancelToken } from "../../../../utils/axios";
import { randomID } from "../../../../utils/helpers";
import { openModal } from "../../../../composables/dialog/drawer";
import MClinicalCodeBadge from "../../MClinicalCodeBadge/MClinicalCodeBadge.vue";
import MLayoutStack from "../../MLayoutStack";
import MInlineInput from "../MInlineInput";
import MInput from "../MInput/MInput.vue";
import { Action, QuickActionsMenuItem, ResultShortcut, SearchLookup, Shortcut } from "./interfaces";
import MMenu from "../../MMenu";

const props = defineProps({
  modelValue: String,

  shortcuts: Object as () => Record<string, Array<Shortcut> | SearchLookup>,

  autofocus: Boolean,

  searchUrl: {
    type: String,
    required: true,
  },

  onClinicalCodeSelected: Function,

  actions: Object as () => Action[],

  pinnedActions: Object as () => Action[],

  label: { type: String, default: "Notes" },

  ariaLabelledby: String,

  placeholder: String,

  actionsMenuItems: Object as () => QuickActionsMenuItem[],
});

const emit = defineEmits({
  "update:modelValue": (_e: string) => true,

  enterKeyDownOnEmpty: () => true,

  save: (_item: {
    item?: { label: string; value: string };
    note?: string;
    focusAfterCreate?: boolean;
  }) => true,

  clinicalCodeSelected: (_item: { label: string; value: string }) => true,
  blur: (_e: any) => true,
  change: (_text: string) => true,
});

const focusedItem = ref(0);
const focusedAction = ref(0);
const menu = ref<InstanceType<typeof MMenu>>();
const actionsMenu = ref<InstanceType<typeof MMenu>>();
const list = ref<QList>();
const actionsList = ref<QList>();
const input = ref<HTMLElement>();
const inputNote = ref<InstanceType<typeof MInput>>();
const selectedShortcut = ref<Shortcut>();
// if shortcutItems is `false` it means it's debouncing
const shortcutItems = ref<ResultShortcut[] | false>();
const showItems = ref(true);
const inlineInput = ref<InstanceType<typeof MInlineInput> | null>(null);

defineExpose({
  menu,
  list,

  focus() {
    inlineInput.value?.focus();
  },
});

const menuWidth = computed(() =>
  input.value?.clientWidth ? `${input.value?.clientWidth}px` : "100%",
);

const filteredActionMenuItems = computed(() => {
  return props.actionsMenuItems.filter((action) =>
    action.label.toLowerCase().startsWith(value.value.substring(1).toLowerCase()),
  );
});

const menuHeight = ref(0);

const menuOffset = computed(() => {
  const inputHeight = 42; //input.value?.clientHeight ?? 0;

  const height = menuHeight.value + inputHeight;
  return height - 35;
});

const value = props.modelValue ? useVModel(props, "modelValue") : ref("");

async function selectShortcut(item: Shortcut) {
  cancelSearch();
  doSearch.cancel();
  if (shortcut.value) {
    if (item.hasInput || ("hasInput" in shortcut.value! && shortcut.value.hasInput)) {
      item.click = item.click ?? shortcut.value?.click;
      selectedShortcut.value = item;
    } else {
      const click =
        item.click ??
        (shortcut.value && "click" in shortcut.value ? shortcut.value.click : undefined);
      await click?.(item);
    }
  } else if (!selectedShortcut.value && item) {
    selectedShortcut.value = item;
    showItems.value = false;

    if (props.onClinicalCodeSelected) {
      try {
        const x = props.onClinicalCodeSelected(item);
        if (x) {
          selectedShortcut.value = undefined;
          menu.value?.hide();
        }
      } catch {
        // ignore
      }
    } else {
      emit("clinicalCodeSelected", item);
    }
  } else {
    saveNote();
  }
  value.value = "";

  shortcutItems.value = undefined;

  saveNote();

  nextTick(() => {
    // focus inlineInput
    inlineInput.value?.focus();
  });
}

function selectAction(action) {
  let props = {
    ...action.props,
    ...{ contextId: action.contextId, contextType: action.contextType },
  };

  value.value = "";

  return openModal(action.url, { props });
}

const attrs = useAttrs();

async function saveNote(focusAfterCreate = false) {
  if (searchItems.loading.value) return;
  cancelSearch();
  doSearch.cancel();
  const click =
    selectedShortcut.value && "click" in selectedShortcut.value
      ? selectedShortcut.value.click
      : shortcut.value && "click" in shortcut.value && shortcut.value.click;
  if (click) {
    try {
      searchItems.loading.value = true;
      click(selectedShortcut.value, value.value);
    } finally {
      searchItems.loading.value = false;
    }
    selectedShortcut.value = undefined;
    value.value = "";
  } else if (!selectedShortcut.value && value.value) {
    if (attrs.onSave) {
      // @ts-expect-error
      await attrs.onSave(value.value!);
    } else {
      emit("save", { note: value.value!, item: undefined, focusAfterCreate });
    }
    value.value = "";
  } else if (selectedShortcut.value) {
    emit("save", { item: selectedShortcut.value, note: value.value, focusAfterCreate });
    selectedShortcut.value = undefined;
    showItems.value = true;
    shortcutItems.value = undefined;
    value.value = "";
  }
}

const handleInputUpdate = debounce((value) => {
  if (value.length > 0 && !value.startsWith(".") && !value.startsWith("/")) {
    saveNote(true);
  }
}, 2000);

function moveFocusItem(direction: -1 | 1) {
  const len = shortcutItems.value?.length ?? -1;
  focusedItem.value += direction;
  if (focusedItem.value < 0) {
    focusedItem.value = len - 1;
  } else if (focusedItem.value >= len) {
    focusedItem.value = 0;
  }

  nextTick(() => {
    const r = refItems[focusedItem.value];

    if (r) {
      const el: HTMLElement = "$el" in r ? r.$el : r;
      el.scrollIntoView(true);
    }
  });
}

function moveActionMenuFocusItem(direction: -1 | 1) {
  const len = filteredActionMenuItems.value.length ?? -1;
  focusedAction.value += direction;
  if (focusedAction.value < 0) {
    focusedAction.value = len - 1;
  } else if (focusedAction.value >= len) {
    focusedAction.value = 0;
  }

  nextTick(() => {
    const r = refActionItems[focusedAction.value];
    const el: HTMLElement = "$el" in r ? r.$el : r;
    el.scrollIntoView(true);
  });
}

function onKeyDown(ev: KeyboardEvent) {
  if (menu.value && showItems.value && !searchItems.loading.value) {
    switch (ev.key) {
      case Key.ArrowDown: {
        ev.preventDefault();
        moveFocusItem(1);
        break;
      }
      case Key.ArrowUp: {
        ev.preventDefault();
        moveFocusItem(-1);
        break;
      }
      case Key.Escape: {
        ev.preventDefault();
        cancelSearch();
        showItems.value = false;
        focusedItem.value = -1;
        break;
      }
      case Key.Enter: {
        ev.preventDefault();
        if (shortcutItems.value) {
          selectShortcut(shortcutItems.value![focusedItem.value]);
        }
        break;
      }
      default: {
        focusedItem.value = 0;
      }
    }
  } else if (props.actionsMenuItems?.length && value.value.startsWith("/")) {
    switch (ev.key) {
      case Key.ArrowDown: {
        ev.preventDefault();
        moveActionMenuFocusItem(1);
        break;
      }
      case Key.ArrowUp: {
        ev.preventDefault();
        moveActionMenuFocusItem(-1);
        break;
      }
      case Key.Escape: {
        ev.preventDefault();
        this.modelValue = "";
        focusedAction.value = -1;
        break;
      }
      case Key.Enter: {
        ev.preventDefault();
        // if (shortcutItems.value) {
        //   selectShortcut(shortcutItems.value![focusedItem.value]);
        // }

        selectAction(filteredActionMenuItems.value[focusedAction.value]);

        break;
      }
      default: {
        focusedAction.value = 0;
      }
    }
  } else if (ev.key === Key.Enter) {
    ev.preventDefault();

    if (value.value.trim().length === 0) {
      emit("enterKeyDownOnEmpty");
    } else {
      saveNote();
    }
  } else if (ev.key === Key.Escape && searchItems.loading.value) {
    ev.preventDefault();
    cancelSearch();
    showItems.value = false;
    focusedItem.value = -1;
  } else {
    switch (ev.key) {
      case Key.Delete:
      case Key.Backspace: {
        if (!value.value) {
          ev.preventDefault();
          selectedShortcut.value = undefined;
        }
      }
    }
  }
}

let refItems: Array<Component | HTMLElement> = [];
let refActionItems: Array<Component | HTMLElement> = [];
onUpdated(() => {
  refItems = [];
  refActionItems = [];
});

function onRef(r: HTMLElement | Component) {
  refItems.push(r);
}

function onActionsRef(r: HTMLElement | Component) {
  refActionItems.push(r);
}

const shortcutDelimeter = computed(() => {
  if (!props.shortcuts) return undefined;
  const delimeter = Object.keys(props.shortcuts).find((x) => value.value?.startsWith(x));
  if (!delimeter || !value.value) {
    return undefined;
  }
  return delimeter;
});

let cancelSearch = NO_OP;
const searchItems = usePromiseLazy((url: string, query: any) => {
  cancelSearch();
  doSearch.cancel();

  const cancelSource = CancelToken.source();
  cancelSearch = () => {
    cancelSource.cancel();
  };

  url += `${~url.indexOf("?") ? "&" : "?"}query=${query}`;

  return axios.get(url, { cancelToken: cancelSource.token }).then((x) => {
    const d = x.data;
    return d?.results || d?.result || d;
  });
});

const shortcut = computed(() =>
  shortcutDelimeter.value && !selectedShortcut.value
    ? props.shortcuts?.[shortcutDelimeter.value]
    : undefined,
);

const doSearch = debounce((query: string) => {
  return searchItems.exec(props.searchUrl, query).then((data) => {
    if (data) {
      shortcutItems.value = data.map((x: any) => ({ label: x.label, value: x }));
      focusedItem.value = 0;
    }
  });
}, 500);

watch(value, (v) => {
  if (!v) {
    showItems.value = true;
    shortcutItems.value = undefined;
    return;
  }

  const search = v?.slice(shortcutDelimeter.value?.length)?.trim() ?? "";
  if (!shortcut.value || (search.length === 0 && "searchUrl" in shortcut.value)) {
    cancelSearch();
    if (
      search &&
      search.startsWith(".") &&
      !selectedShortcut.value &&
      showItems.value &&
      props.searchUrl
    ) {
      const formattedSearchText = search.substring(1);
      doSearch(formattedSearchText);
    }
    return;
  }
  focusedItem.value = 0;

  if ("searchUrl" in shortcut.value) {
    const s = shortcut.value;
    searchItems.exec(s.searchUrl, s.getRequestData?.(search) ?? { term: search }).then((x) => {
      if (x) {
        shortcutItems.value = s.processData?.(x) ?? x;
      }
    });
  } else {
    shortcutItems.value = filterList(search, shortcut.value);
  }
});

function filterList(search: string, items: Shortcut[]): ResultShortcut[] {
  const reg = new RegExp(escapeRegExp(search), "gi");
  return items
    .map((x) => {
      const matches = [...x.label.matchAll(reg)];

      if (matches.length === 0) {
        return undefined;
      }

      const fragments: Array<string | { search: string }> = [];
      let lastIndex = 0;

      for (const m of matches) {
        fragments.push(x.label.slice(lastIndex, m.index));
        lastIndex = m.index! + m[0].length;
        fragments.push({ search: m[0] });
      }

      if (lastIndex < x.label.length) {
        fragments.push(x.label.slice(lastIndex));
      }

      return {
        ...x,
        fragments,
      };
    })
    .filter(Boolean) as ResultShortcut[];
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

// const placeholderText = computed(() => undefined);

function onBlur() {
  // // @ts-expect-error
  // if ((e.currentTarget as HTMLElement)?.contains(e.relatedTarget)) return;
  // // for some reason the target is inline-input when you click outside, probably because of the capture
  // if ((e.target as HTMLSpanElement).classList?.contains("inline-input")) {
  //   emit("blur", e);
  // }
}

const menuId = randomID("clinical-menu-code-input");
const actionsMenuId = randomID("quick-actions-menu-input");
</script>
<style lang="scss">
.m-clinical-code-input {
  .note-container {
    // border: 1px solid var(--grey-lightest-non-text);
    // border-spacing: 2px;
    position: relative;

    // &:focus-within {
    //   &::before {
    //     position: absolute;
    //     content: " ";

    //     top: -2px;
    //     left: -2px;
    //     right: -2px;
    //     bottom: -2px;

    //     display: block;
    //     pointer-events: none;
    //     background: none;

    //     margin: 0;
    //     padding: 0;

    //     border-radius: var(--__adaptive-focus-ring-radius, 4px);
    //     border: 3px solid var(--focus-ring);
    //     // padding: ;

    //     // box-shadow: 0 0 0 3px var(--__adaptive-focus-ring-color, var(--focus-ring, #007acc));

    //     z-index: 999999;
    //     overflow: visible;
    //   }
    // }

    .note-outer {
    }

    .m-note {
      display: inline-block;

      font-size: 14px;
      line-height: 150%;

      .q-field__append {
        padding: 4px;

        .medicus-button-container {
          height: 36px;
          align-self: flex-end !important;
        }
      }

      .q-textarea {
        .q-field__control {
          min-height: 40px !important;
          overflow: hidden;

          &::before,
          &::after {
            border: none !important;
          }
        }

        .q-field__control-container {
          flex: 1 1 auto;
          display: flex;
          min-width: 50%;
        }

        .q-field__native {
          padding-left: 0;
          min-height: 40px !important;
        }
      }

      &.with-shortcut {
        .q-field__prepend {
          margin-right: 12px;
          padding-right: 0;
          overflow: hidden;
        }

        .shortcut-note {
          // padding: 4px 8px;

          flex: 1 1 auto;
          margin-right: 0.25rem;

          .medicus-button-container {
            margin: 0 !important;
            .medicus-button {
              padding: 0 !important;
            }
          }
        }
      }

      .note-loading-spinner {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .actions-stack {
      min-height: 26px;
      align-content: center;
    }

    .medicus-button-container {
      max-height: 26px;

      align-self: flex-start;
    }

    .q-btn--fab {
      min-height: 26px;
      min-width: 26px;
      padding: 0;
    }

    .action-fab-button {
      background: var(--status-light-blue);
      border: 1px solid var(--status-blue);
      color: var(--status-blue);

      & .q-fab--opened {
        background: #ffffff;
      }
    }
  }

  .action-buttons {
    .m-dropdown-button {
      border: none;
      height: auto;
      margin-top: 1px;
    }

    .q-btn {
      padding: 0;
      min-height: unset;
    }
  }
}

.m-note-menu {
  border: 1px solid var(--border-colour);

  > .m-menu--content {
    overflow: hidden;
  }

  .m-note-menu-content {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    height: 100%;
  }
  .m-note-menu--header {
    display: grid;

    grid-template-columns: 1fr auto;
    padding: 0.5rem;
    background-color: var(--grey-lightest);
    color: var(--grey-darkest);

    .header-commands {
      > div {
        display: flex;
        gap: 0.25rem;
        align-items: center;
      }
      .to-select {
        transform: rotate(-90deg) scaleX(-1);
      }
      .escape-key-header {
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
      }
    }
  }

  .q-list {
    .q-item {
      min-height: 32px !important;
      padding: 2px 8px;

      &:hover {
        background: #ebedef;
      }

      &:hover {
      }
    }
  }
}

.medicus-button-container.save-button {
  position: absolute;
  top: 50%;
  right: 10px;
  translate: 0 -50%;
}
</style>
