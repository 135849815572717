<template>
  <div style="width: 100%">
    <h5>Letter</h5>
    <q-field borderless label="Summary" stack-label>
      <template #control>
        <div class="self-center full-width no-outline" tabindex="0">
          {{ summary }}
        </div>
      </template>
    </q-field>
    <q-field borderless label="Sender" stack-label>
      <template #control>
        <div class="self-center full-width no-outline" tabindex="0">
          {{ sender }}
        </div>
      </template>
    </q-field>
    <q-field borderless label="Related to patient" stack-label>
      <template #control>
        <div class="self-center full-width no-outline" tabindex="0">
          {{ patient.label }}
        </div>
      </template>
    </q-field>

    <div class="inboundCommunications--inbound-letter--content">
      <div
        v-for="(page, pageIndex) in inboundLetterPages"
        :key="pageIndex"
        class="inboundCommunications--inbound-letter--content-page"
      >
        <q-expansion-item
          expand-separator
          :default-opened="true"
          :label="`Letter ${pageIndex + 1} content`"
        >
          <p v-html="page.content"></p>
        </q-expansion-item>
      </div>
    </div>

    <div class="q-gutter-md row items-start">
      <q-img
        v-for="(page, pageIndex) in inboundLetterPages"
        :key="pageIndex"
        :src="inboundLetterPageBaseUrl + page.id"
        style="width: 150px"
        :ratio="1"
        basic
        spinner-color="white"
        class="rounded-borders"
      />
    </div>
  </div>
</template>

<script>
import { QExpansionItem, QField, QImg } from "quasar";

export default {
  components: { QField, QImg, QExpansionItem },
  props: {
    summary: {
      type: String,
      default: "",
    },
    sender: {
      type: String,
      default: "",
    },
    patient: {
      type: Object,
      default: null,
    },
    inboundLetterPages: {
      type: Array,
      default: null,
    },
  },

  computed: {
    inboundLetterPageBaseUrl() {
      return this.$axios.defaults.baseURL + "patient/data/patient/view-inbound-letter/";
    },
  },
};
</script>
<style>
.inboundCommunications--inbound-letter--content {
  margin-top: 15px;
  margin-bottom: 30px;
}
.inboundCommunications--inbound-letter--content-page {
  margin-bottom: 15px;
}
.inboundCommunications--inbound-letter--content-page .q-item__section {
  margin-left: -15px !important;
}
</style>
