<script setup lang="ts">
import { ref } from "vue";
import MForm from "../../components/medicus/MForm";
import MInput from "../../components/medicus/inputs/MInput";
import MButton from "../../components/medicus/MButton";
import { useAuth } from "../../store";
import { AxiosError } from "axios";
import { useRouter } from "vue-router";
const recoveryCode = ref("");
const errors = ref<string[]>([]);

const auth = useAuth();

async function doSubmit() {
  try {
    errors.value = [];

    await auth.validateRecoveryCode(recoveryCode.value);
  } catch (e: unknown) {
    const err = e as AxiosError<{ errors: { command: string[] } }>;
    errors.value = err.response?.data?.errors?.command ??
      (err.message ? [err.message] : undefined) ?? ["Unknown error happened"];
  }
}

const router = useRouter();

defineExpose({
  recoveryCode,
  doSubmit,
});
</script>
<template>
  <m-form hide-required-message :enter-submit="true" @submit="doSubmit">
    <p>Enter the 6 digit recovery code provided by your local Medicus administrator.</p>
    <m-input
      v-model="recoveryCode"
      label="Recovery code"
      required
      mask="999999"
      inputmode="numeric"
      :display-errors="errors"
      data-testid="token-input"
    />

    <template #footer>
      <m-layout-stack full>
        <m-button type="submit" class="flex-auto" label="Confirm" />
        <m-separator text="or" />
        <m-button
          color="secondary"
          @click="
            router.push({
              name: 'login',
            })
          "
          >Back to login page</m-button
        >
      </m-layout-stack>
    </template>
  </m-form>
</template>
