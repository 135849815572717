<script setup lang="ts">
import { watch, ref, defineModel } from "vue";
import { usePanicButton } from "../../../store/panicButton";
import { PanicAlert } from "../../../store/panicButton.types";
import { showDialog } from "../../../utils/dialog";
import { useSnackbar } from "../../../composables/snackbar";
import { formatDateTime } from "../../../utils/date";

const model = defineModel<boolean>();
const panicButtonStore = usePanicButton();
const isFetching = ref(true);

watch(
  () => panicButtonStore.isPanicAlertsModalShowing,
  () => {
    if (panicButtonStore.isPanicAlertsModalShowing === true) {
      panicButtonStore.fetchActiveAlerts().then(() => {
        isFetching.value = false;
      });
    }
  },
);

function handleCancelAlertClicked(alert: PanicAlert) {
  showDialog({
    title: "Cancel Panic Alert?",
    text: `Are you sure that you want to cancel the panic alert triggered by ${alert.triggeringStaffName}?`,
  }).onOk(async () => {
    await panicButtonStore.cancelPanicAlert(alert.id);
    useSnackbar().add({ message: "Panic alert cancelled.", type: "success" });
  });
}

function getCancelButtonTooltip(alert: PanicAlert, isCurrentLocation: boolean) {
  if (!isCurrentLocation) {
    return "You cannot cancel alert from another location.";
  }
  if (!alert.userHasPermissionToCancelAlert) {
    return "You do not have the permission to cancel this alert.";
  }
  return undefined;
}
</script>

<template>
  <m-modal
    v-model="model"
    title="Active Panic Alerts"
    type="small"
    bg-grey
    transient
    close-on-background-click
  >
    <m-layout-stack gap="6">
      <!-- Alerts Grouped By Location -->
      <section
        v-for="otherLocationSection of panicButtonStore.alertsGroupedByLocation"
        :key="otherLocationSection.labelId"
      >
        <label
          :id="otherLocationSection.labelId"
          class="panic-alert-section-label secondary-text"
          >{{ otherLocationSection.label }}</label
        >
        <m-layout-stack gap="3">
          <m-card
            v-for="alert of otherLocationSection.alerts"
            :key="alert.id"
            :aria-labelledby="otherLocationSection.labelId"
            class="alert-card"
          >
            <div class="info">
              <div class="alert-name">
                {{ alert.triggeringStaffName }} in {{ alert.locationName }}
              </div>
              <div class="alert-time">{{ formatDateTime(alert.alertStartedDateTime) }}</div>
            </div>
            <m-button
              :on-click="() => handleCancelAlertClicked(alert)"
              color="danger"
              :disable="
                alert.userHasPermissionToCancelAlert === false ||
                otherLocationSection.isCurrentLocation === false
              "
              class="cancel-alert"
              :tooltip="getCancelButtonTooltip(alert, otherLocationSection.isCurrentLocation)"
              >Cancel alert</m-button
            >
          </m-card>
        </m-layout-stack>
      </section>

      <!-- No Alerts Banner -->
      <m-banner v-if="isFetching === false && panicButtonStore.allAlertsCount < 1" type="info"
        >There are no active panic alerts.</m-banner
      >
    </m-layout-stack>
  </m-modal>
</template>

<style lang="scss">
.panic-alert-section-label {
  display: block;
  color: var(--grey-darkest);
  line-height: 1.2;
  margin-bottom: var(--gap-2);
}
</style>
