<script setup lang="ts">
import { computed, defineModel } from "vue";
import { useKeyboardShortcutsStore } from "../../../store/keyboard-shortcuts";

type KeyboardShortcutModalEntry = { id: string; heading: string; keys: string[] };

const { keyboardShortcuts } = useKeyboardShortcutsStore();
const model = defineModel<boolean>();
const isMacOS = navigator.userAgent.includes("Macintosh");

const keyboardShortcutModalEntries = computed<KeyboardShortcutModalEntry[]>(() => {
  return keyboardShortcuts.map((shortcut) => {
    const entry: KeyboardShortcutModalEntry = {
      id: shortcut.id,
      heading: shortcut.name,
      keys: [],
    };

    if (shortcut.controlCommand) {
      if (isMacOS) {
        entry.keys.push("Cmd");
      } else {
        entry.keys.push("Ctrl");
      }
    }

    if (shortcut.altControl) {
      if (isMacOS) {
        entry.keys.push("Option");
      } else {
        entry.keys.push("Alt");
      }
    }

    if (shortcut.shift) {
      entry.keys.push("Shift");
    }

    entry.keys.push(shortcut.key);

    return entry;
  });
});
</script>
<template>
  <m-modal
    v-model="model"
    title="Keyboard Shortcuts"
    type="small"
    bg-grey
    transient
    close-on-background-click
  >
    <div class="shortcuts">
      <div v-for="shortcut of keyboardShortcutModalEntries" :key="shortcut.id" class="shortcut">
        <h3 class="shortcut-heading">{{ shortcut.heading }}</h3>
        <m-layout-stack horizontal class="keys">
          <template v-for="(key, index) of shortcut.keys" :key="`${shortcut.id}-${key}`">
            <div v-if="index !== 0" class="plus">+</div>
            <div class="key">{{ key }}</div>
          </template>
        </m-layout-stack>
      </div>
    </div>
  </m-modal>
</template>
<style lang="scss">
:root {
  --keyboard-shortcuts-modal-key-height: 36px;
}

.shortcuts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap-4);
}

.shortcut {
  background: rgb(0 0 0 / 3%);
  padding: var(--gap-4);
  border: 1px solid rgb(0 0 0 / 6%);
  border-radius: 6px;
}

.shortcut-heading {
  letter-spacing: -0.3px;
  color: var(--text-color-light);
}

.keys {
  margin-top: var(--gap-3);
}

.key {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--keyboard-shortcuts-modal-key-height);
  min-width: 40px;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 4px;
  background: linear-gradient(to bottom, #f4f4f4, #fcfcfc);
  box-shadow:
    var(--box-shadow),
    0px 10px 4px -8px rgb(0 0 0 / 10%);
  font-weight: bold;
  letter-spacing: -0.5px;
  border: 2px solid white;
  text-shadow: 0 2px 1px white;
}

.plus {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--keyboard-shortcuts-modal-key-height);
  font-size: 20px;
  color: var(--grey-darkest);
}
</style>
