<template>
  <m-validation-component class="m-datetime" :errors="displayErrors">
    <m-input-group
      horizontal
      :force-horizontal-label="horizontal"
      :label="label"
      :required="$attrs.required"
      :helper="helper"
      :instructions="instructions"
      :data-testid="$attrs['data-testid'] || name || undefined"
      :label-width="labelWidth"
      left
    >
      <template v-if="$slots.helper" #helper> <slot name="helper" /></template>

      <m-input
        v-model="dateShowText"
        class="date-input"
        :for="dateFor"
        label="Date"
        :input-format="dateMask"
        :placeholder="datePlaceholder"
        :horizontal="false"
        :disable="$attrs.disabled || $attrs.disable"
        :disabled="$attrs.disabled || $attrs.disable"
        :large="large"
        :name="name ? `${name}:date` : undefined"
        :invalid="!!errors?.length"
        @blur="onBlur"
        @focus="onFocus"
        @keydown.capture="onKeydown"
      >
        <template #append>
          <m-button
            icon="fa-light fa-calendar"
            icon-only
            ghost
            small
            color="secondary"
            @click="showForm = true"
          >
            <span class="sr-only">Change date {{ dateShowText }}</span>
            <q-popup-proxy v-model="showForm" transition-show="scale" transition-hide="scale">
              <q-date
                ref="datePickerEl"
                v-model="dateText"
                minimal
                mask="DD/MM/YYYY"
                :navigation-max-year-month="maxDateNavigationStr"
                :navigation-min-year-month="minDateNavigationStr"
                :options="dateOptions"
                @update:modelValue="showForm = false"
              />
            </q-popup-proxy>
          </m-button>
        </template>
      </m-input>

      <m-input
        :model-value="timeText"
        :for="timeFor"
        class="time-input"
        :placeholder="timePlaceholder"
        :horizontal="false"
        label="Time"
        mask="##:##"
        :name="name ? `${name}:time` : undefined"
        :disable="$attrs.disabled || $attrs.disable"
        :disabled="$attrs.disabled || $attrs.disable"
        :invalid="!!errors?.length"
        @blur="onComponentBlur"
      />
    </m-input-group>
  </m-validation-component>
</template>

<script lang="ts" setup>
import { QDate, QPopupProxy } from "quasar";

import MButton from "../../MButton";
import MInputGroup from "./../../MInputGroup";
import MInput from "./../MInput";
import MValidationComponent from "./../MValidationComponent";

import { date } from "quasar";
import { computed, nextTick, provide, ref, watch } from "vue";
import { useDateValue } from "../../../../composables/dateValue";
import { getMaxDate, getMinDate } from "../../../../utils/helpers";
// import MDate css
import { formatDateTimeMedicus } from "../../../../utils/date";
import "../MDate";

const props = defineProps({
  label: String,
  description: String,
  modelValue: {
    type: [Object, String, Number, Array],
    required: false,
  },
  name: String,
  rules: Array as () => Array<(v: any) => boolean | string>,
  showAllErrors: Boolean,
  // m-labelled text props
  boldText: {
    type: Boolean,
    default: false,
  },
  horizontal: {
    type: Boolean,
    default: undefined,
  },
  labelWidth: String,
  timePlaceholder: {
    type: String,
    default() {
      return "hh:mm";
    },
  },
  datePlaceholder: {
    type: String,
    default() {
      return "dd/mm/yyyy";
    },
  },
  minDate: {
    type: [Date, String],
    default() {
      return new Date(1000, 1, 1);
    },
  },
  maxDate: {
    type: [Date, String],
    default() {
      return new Date(new Date().getFullYear() + 100, 1, 1);
    },
  },
  minDateText: {
    type: String,
  },
  maxDateText: {
    type: String,
  },
  requiredText: String,
  seconds: {
    type: String,
    default: "00",
  },
  large: Boolean,
  helper: String,
  instructions: String,
  masked: Boolean,

  for: String,
});

const dateFor = computed(() => (props.for ? `${props.for}.date` : undefined));
const timeFor = computed(() => (props.for ? `${props.for}.time` : undefined));

const showForm = ref(false);

provide("input-dont-use-name", true);

const { dateText, timeText, errors, displayErrors, displayDate, touched } = useDateValue(
  props,
  true,
  undefined,
  undefined,
  () => props.requiredText || `Enter a date and time for '${props.label}'`,
);

const maxDateNavigation = computed(() => {
  return getMaxDate(props.maxDate, true);
});
const maxDateNavigationStr = computed(() => {
  const d = maxDateNavigation.value;
  return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, "0")}`;
});

const minDateNavigation = computed(() => {
  return getMinDate(props.minDate, true);
});
const minDateNavigationStr = computed(() => {
  const d = minDateNavigation.value;
  return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, "0")}`;
});

function dateOptions(dateValue: any) {
  const d = new Date(dateValue);
  return date.isBetweenDates(d, minDateNavigation.value, maxDateNavigation.value, {
    inclusiveFrom: true,
    inclusiveTo: true,
    onlyDate: true,
  });
}

const tokenRegex = /-?(\d+)([d|w|m|y])/g;

function parseDate(d: string, splitter: string) {
  let [day, month, year, ...rest] = d.split(splitter);

  if (!day || !month || !year || rest?.length) {
    return undefined;
  }

  if (year && +year < 100) {
    year = "20" + year.padStart(2, "0");
  }

  return [day, month, year].join("/");
}

function parseToken(t: string) {
  const matches = t.match(tokenRegex);
  if (!matches) {
    return;
  }

  let d = new Date();

  for (const t of matches) {
    const v = +t.slice(0, -1);

    switch (t[t.length - 1]) {
      case "d": {
        d = date.addToDate(d, { days: v });
        break;
      }
      case "w": {
        d = date.addToDate(d, { days: v * 7 });
        break;
      }
      case "m": {
        d = date.addToDate(d, { months: v });
        break;
      }
      case "y": {
        d = date.addToDate(d, { years: v });
        break;
      }
    }
  }
  return formatDateTimeMedicus(d);
}

const shouldMask = ref(false);
const dateMask = computed(() => (shouldMask.value ? "dd/mm/yyyy" : undefined));

function onKeydown(e: KeyboardEvent) {
  if (props.masked) return;
  switch (e.key.toLowerCase()) {
    case "-":
    case "d":
    case "w":
    case "m":
    case "y":
      shouldMask.value = false;
      e.stopImmediatePropagation();
      break;
    case "/":
      shouldMask.value = true;
      break;
    default: {
      if (e.code.startsWith("Key") && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        e.preventDefault();
      }
    }
  }
}

function onFocus() {
  dateShowText.value = dateText.value;
  nextTick(() => {
    shouldMask.value = !!dateText.value;
  });
}

const dateShowText = ref(displayDate.value);

async function onBlur() {
  try {
    shouldMask.value = false;
    if (props.masked) {
      return;
    }

    let text = dateShowText.value ?? "";

    if (!Number.isNaN(text) && text.length === 8) {
      // if the text is a number and has 8 digits
      text = `${text.slice(0, 2)}/${text.slice(2, 4)}/${text.slice(4)}`;
    }

    let actualDate = parseDate(text, "/");
    if (!actualDate) {
      actualDate = parseDate(text, ".");
      if (!actualDate) {
        actualDate = parseToken(text.toLowerCase());
        if (!actualDate) {
          // invalid date
          return;
        }
      }
    }

    dateText.value = actualDate.split(" ")[0].split("-").reverse().join("/");
  } finally {
    await nextTick();
    dateShowText.value = displayDate.value;
  }
}

watch(displayDate, (t, p) => {
  if (dateShowText.value === p) {
    dateShowText.value = t;
  }
});

watch(dateShowText, (t) => {
  // slash cannot be the first character
  shouldMask.value = t?.indexOf("/") > 0;
});

function onComponentBlur(v: FocusEvent) {
  touched.value = true;
  if (!v?.target?.value) return;
  const padded: string = (v?.target?.value ?? "").padEnd(5, "0");

  const time = `${padded.substring(0, 2)}:${padded.substring(3, 5)}`;
  timeText.value = time;
}

const datePickerEl = ref(null as QDate | null);
// enableFocusTrap(computed(() => datePickerEl.value?.$el));
</script>
<style lang="scss">
.m-datetime {
  &.large {
    .date-input {
      flex: 1 1 auto;
    }
  }

  // margin-bottom: 12px;
  .labeled-text > .text {
    padding: 0;
  }

  .labeled-text {
    // margin-bottom: 0;
    padding-bottom: 0;
  }

  .m-input {
    margin: 0;
  }

  > .validation-component > .m-input-group.horizontal {
    .date-wrapper {
      .date-input {
        margin-right: 0;
      }
    }
    > .label {
      margin-top: 10px !important;
    }
  }

  .date-wrapper {
    display: flex;
    &.datetime {
      .date-input {
        // margin-right: 1em;
      }
    }

    &.time:not(.date) {
      > div {
        flex: 1 1 auto;
      }
    }
  }

  .time-input {
    width: 5rem;
  }
}
</style>
