<script lang="ts" setup>
import { QItem, QItemLabel, QItemSection, QList } from "quasar";
import { inject, ref } from "vue";
import { NO_OP } from "vue-composable";
import MButton from "../MButton";
import { Action } from "../MCreateForm";
import MDropdownButton from "../MDropdownButton";
import MForm from "../MForm";
import MLayoutStack from "../MLayoutStack";
import { useSnackbar } from "../../../composables/snackbar";

const props = defineProps({
  hideActions: { type: Boolean },
  hideCancel: { type: Boolean },
  showDelete: { type: Boolean },
  deleteLabel: { type: String, default: "Delete" },

  disableDelete: Boolean,
  disableSubmit: Boolean,

  cancelLabel: {
    type: String,
    default: "Cancel",
  },
  submitLabel: {
    type: String,
    default: "Save changes",
  },

  enterSubmit: Boolean,

  actions: Array as () => Action[],
  beforeSubmit: Function,

  onSuccess: Function,
  onCancel: Function,

  successMessage: String,

  prefix: String,

  noClose: Boolean,
});

const emit = defineEmits({
  success: (_e: Event) => true,
  cancel: (_e: Event) => true,
  delete: (_setSubmit: () => any) => true,
});

const cancelForm = inject<(shouldClose: boolean) => void>("cancel", NO_OP);
const additionalCreatePayloadData = inject<Record<string, any>>("additionalCreatePayloadData");
const form = ref<InstanceType<typeof MForm>>();

function handleSuccess(event: Event) {
  if (props.successMessage !== "" && props.successMessage !== null) {
    useSnackbar().add({
      message: props.successMessage ?? "Success.",
      type: "success",
    });
  }
  emit("success", event);
}
function handleCancel(event: Event) {
  emit("cancel", event);
  cancelForm?.(!props.noClose);
}
async function submitAction(a: Action, setSubmit: (e: boolean) => void) {
  try {
    setSubmit(true);

    if (a.beforeSubmit) {
      if ((await a.beforeSubmit()) === false) {
        return;
      }
    }

    if (a.submit) {
      if ((await a.submit()) === false) {
        return;
      }
    }
    form.value?.submit(true);

    a.afterSubmit && a.afterSubmit();
  } finally {
    setSubmit(false);
  }
}
</script>
<template>
  <m-form
    ref="form"
    v-bind="$attrs"
    :prefix="prefix"
    :enter-submit="enterSubmit"
    :before-submit="beforeSubmit"
    :no-close="noClose"
    :additional-create-payload-data="additionalCreatePayloadData"
    @success="handleSuccess"
    @cancel="handleCancel"
  >
    <slot />

    <template #footer="{ submitting, setSubmit, hasAccess }">
      <slot name="footer" v-bind="{ deleteLabel, cancelLabel, submitLabel }">
        <!-- REVERSE the row to fix the tabindex, it was focusing wrong items when using tabindex -->
        <m-layout-stack horizontal justify-between full>
          <m-button
            v-if="showDelete"
            :label="deleteLabel"
            color="danger"
            :loading="submitting"
            :disabled="disableDelete"
            @click.prevent="$emit('delete', setSubmit)"
          />
          <m-layout-stack class="flex-auto" horizontal end full>
            <m-button
              v-if="!hideCancel"
              :label="cancelLabel"
              color="secondary"
              :loading="submitting"
              @click.prevent="handleCancel"
            />
            <slot name="additionalButtons"></slot>
            <template v-if="!hideActions">
              <m-dropdown-button
                v-if="actions"
                split
                :label="submitLabel"
                type="submit"
                :loading="submitting"
                :disabled="disableSubmit || !hasAccess"
              >
                <q-list>
                  <q-item
                    v-for="(action, i) in actions"
                    :key="i"
                    v-close-popup
                    clickable
                    :loading="submitting"
                    :disabled="disableSubmit || action.disabled || !hasAccess ? true : undefined"
                    :data-testid="action.dataTestid"
                    @click="submitAction(action, setSubmit)"
                  >
                    <q-item-section>
                      <q-item-label>{{ action.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </m-dropdown-button>
              <m-button
                v-else
                :label="submitLabel"
                :loading="submitting"
                :disabled="disableSubmit || !hasAccess"
                type="submit"
                color="primary"
              />
            </template>
          </m-layout-stack>
        </m-layout-stack>
      </slot>
    </template>
  </m-form>
</template>
