import { router } from ".";
export const routesHistory: any[] = [];

router.beforeEach((to, _, next) => {
  routesHistory.unshift(to);
  if (routesHistory.length > 30) {
    routesHistory.splice(30);
  }
  next();
});
