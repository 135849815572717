<template>
  <video ref="videoRef" class="barcode-scanner" />
</template>
<script setup lang="ts">
import {
  BarcodeFormat,
  BrowserMultiFormatReader,
  DecodeHintType,
  NotFoundException,
} from "@zxing/library";
import { GS1Reader } from "gs1js";
import { getCurrentInstance, onUnmounted, ref, watch } from "vue";

const emit = defineEmits({
  input: (_x: any) => true,
});

const videoRef = ref<HTMLVideoElement>();

const hints = new Map();
const formats = [/*BarcodeFormat.QR_CODE,*/ BarcodeFormat.DATA_MATRIX /*, ...*/];

hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
const reader = new BrowserMultiFormatReader(hints);

const internalInstance = getCurrentInstance()!;

let media: MediaStream | undefined = undefined;

function closeMedia() {
  if (media) {
    media.getTracks().forEach((x) => x.stop());
  }
}

onUnmounted(closeMedia);

watch(videoRef, async (v) => {
  if (!v) return;
  try {
    media = await navigator.mediaDevices.getUserMedia({
      video: true,
      facingMode: "environment",
    });

    reader.decodeFromStream(media, v, (res, error) => {
      if (error && !(error instanceof NotFoundException)) {
        return console.error("error decoding", error);
      }
      if (!res) return;

      if (res.getBarcodeFormat() === BarcodeFormat.DATA_MATRIX) {
        // var myReader = new GS1Reader(res.getText());
        // console.log("decoded", res, myReader.getApplicationIdentifiers());
        processGS1(res.getText());
      }
    });
  } catch (e) {
    // ignore
  } finally {
    if (internalInstance.isUnmounted) {
      closeMedia();
    }
  }
});

function processGS1(code: string) {
  const myReader = new GS1Reader(code);

  const ids: Array<{
    identifier: string;
    value: string;
    length: number;
  }> = myReader.getApplicationIdentifiers();

  const value = ids.reduce(
    (p, c) => {
      const k = AiMap.get(c.identifier);
      if (k) {
        p[k] = c.value;
      }
      p.ais = p.ais ?? [];
      p.ais.push(c);
      return p;
    },
    {} as Record<string, string> & {
      ais: {
        identifier: string;
        value: string;
        length: number;
      }[];
    },
  );

  emit("input", value);
}

// https://www.gs1.org/standards/barcodes/application-identifiers
const Ais = [
  // SSCC (Serial Shipping Container Code)
  { ai: "00", title: "SSCC", code: "sscc" },
  // Global Trade Item Number (GTIN)
  { ai: "01", title: "GTIN", code: "gtin" },
  // GTIN of Contained Trade Items
  { ai: "02", title: "CONTENT", code: "content" },
  { ai: "10", title: "BATCH/LOT", code: "batch" },
  { ai: "11", title: "PROD DATE", code: "prod_date" },
  { ai: "12", title: "DUE DATE", code: "due_date" },
  { ai: "13", title: "PACK DATE", code: "pack_date" },
  { ai: "15", title: "BEST BEFORE or BEST BY", code: "best_by" },
  { ai: "16", title: "SELL BY", code: "sell_by" },
  { ai: "17", title: "USE BY OR EXPIRY", code: "use_by" },
  { ai: "20", title: "VARIANT", code: "varient" },
  { ai: "21", title: "SERIAL", code: "serial" },
  { ai: "22", title: "CPV", code: "cpv" },
  { ai: "235", title: "TPX", code: "tpx" },
  { ai: "240", title: "ADDITIONAL ID", code: "additional_id" },
  { ai: "241", title: "CUST. PART NO.", code: "customer_part_number" },
  { ai: "242", title: "MTO VARIANT", code: "mto_variant" },
  { ai: "243", title: "PCN", code: "pcn" },
  { ai: "250", title: "SECONDARYSERIAL", code: "secondary_serial" },
  { ai: "251", title: "REF. TO SOURCE", code: "reference_source" },
  { ai: "253", title: "GDTI", code: "gdti" },
  { ai: "254", title: "GLN EXTENSION COMPONENT", code: "gln" },
  { ai: "255", title: "GCN", code: "gcn" },
];

const AiMap = new Map(Ais.map((x) => [x.ai, x.code]));
</script>
<style lang="scss">
.barcode-scanner {
  max-width: 100%;
  flex: 1 1 auto;
}
</style>
