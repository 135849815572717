<script lang="ts" setup>
import axios from "axios";
import { computed, reactive, ref, watch } from "vue";
import { usePromiseLazy } from "vue-composable";
import { useRouter } from "vue-router";
import { resetPassword, resetPasswordQuery } from "../../api/iam";
import MBanner from "../../components/medicus/MBanner";
import MButton from "../../components/medicus/MButton";
import MLayoutStack from "../../components/medicus/MLayoutStack";
import MInput from "../../components/medicus/inputs/MInput";

const router = useRouter();
const props = defineProps<{
  resetKey?: string;
}>();

const resetKey = ref(props.resetKey?.toString());

const password = ref("");
const passwordConfirm = ref("");
const showPassword = reactive({
  pw: false,
  pw2: false,
});

const passwordMismatch = ref(false);

const { loading: loadingQuery, exec: resetPwQuery } = usePromiseLazy((key: string) =>
  resetPasswordQuery(key)
    .then(({ data, status }) => {
      if (status === 200) {
        resetKey.value = data.resetKey;
      } else {
        resetKey.value = undefined;
      }
    })
    .catch(() => {
      resetKey.value = undefined;
    }),
);
const { loading, exec: resetPw, error } = usePromiseLazy(resetPassword);

const errors = ref<string[] | null>(null);

watch(error, (e) => {
  if (axios.isAxiosError(e)) {
    errors.value = Object.values(
      (e.response?.data as { errors: Record<string, string> }).errors,
    ).flat();
  }
});

const isLoading = computed(() => loading.value || loadingQuery.value);

async function doCreatePassword() {
  passwordMismatch.value = false;
  const pw = password.value;
  const pw2 = passwordConfirm.value;

  if (pw !== pw2 || pw.length === 0) {
    passwordMismatch.value = true;
    return;
  }

  try {
    const response = await resetPw({
      password: {
        password: pw,
        passwordConfirm: pw2,
      },
      resetKey: resetKey.value!,
    });
    if (response?.status === 200) {
      router.push({
        name: "login",
        params: {
          passwordChanged: "true",
        },
      });
    }
  } catch (e) {
    // clear reset key
    resetKey.value = undefined;
  }
}

defineExpose({
  resetKey,
});

if (resetKey.value) {
  resetPwQuery(resetKey.value);
}
</script>
<template>
  <div v-if="!resetKey">
    <p class="error-text q-mb-lg" data-cy="noResetKeyError">
      Your reset password link has expired.
    </p>

    <m-button :to="{ name: 'forgot-password' }" :permission="true"> Request new link </m-button>
  </div>
  <m-layout-stack v-else>
    <m-input
      v-model="password"
      outlined
      label="Password"
      autocomplete="new-password"
      :type="showPassword.pw ? 'text' : 'password'"
      color="primary"
      placeholder=""
      data-cy="passwordField"
    >
      <template #append>
        <m-button
          :icon="showPassword.pw ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
          color="secondary"
          ghost
          icon-only
          :label="showPassword.pw ? 'Hide password' : 'Show password'"
          @click="showPassword.pw = !showPassword.pw"
        />
      </template>
    </m-input>
    <m-input
      v-model="passwordConfirm"
      outlined
      label="Confirm password"
      :type="showPassword.pw2 ? 'text' : 'password'"
      color="primary"
      placeholder=""
      autocomplete="new-password"
      data-cy="passwordField"
    >
      <template #append>
        <m-button
          :icon="showPassword.pw2 ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
          color="secondary"
          ghost
          icon-only
          :label="showPassword.pw2 ? 'Hide password' : 'Show password'"
          @click="showPassword.pw2 = !showPassword.pw2"
        />
      </template>
    </m-input>

    <span v-if="passwordMismatch" type="error" class="error-text">
      The passwords entered do not match.
    </span>

    <span v-else-if="errors" type="error" class="error-text">
      <p v-for="errorTxt in errors" :key="errorTxt">{{ errorTxt }}</p>
    </span>

    <m-banner type="info">
      <strong>Secure password tip: </strong> The National Cyber Security Centre advises creating a
      password using
      <a
        href="https://www.ncsc.gov.uk/blog-post/three-random-words-or-thinkrandom-0"
        target="_blank"
        >three random words</a
      >.
    </m-banner>

    <m-button
      class="float-right login-button w-full"
      data-cy="createPasswordButton"
      :disable="isLoading"
      @click="doCreatePassword"
    >
      Create password
    </m-button>
  </m-layout-stack>
</template>

<style lang="scss" scoped>
.login-link {
  display: block;

  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 150% */

  text-align: right;
  letter-spacing: 0.2px;
  text-decoration-line: underline;

  color: var(--text-color);
}
</style>
