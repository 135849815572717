<template>
  <div
    v-if="!isPopup"
    ref="containerEl"
    :class="{ 'video-call-container': true, 'cursor-move': !!mouseOffset, minimised: isMinimised }"
  >
    <div v-if="isMinimised" class="minimised-video">
      <!-- <video
        v-if="validAttendees[0]?.tileState"
        :ref="validAttendees[0].bindVideo"
        key="patient-video"
        class="patient-video"
        disablePictureInPicture
        disableControls
      /> -->
      <div v-if="video.lastSpeaker.value" class="attendee-video">
        <video
          v-if="video.lastSpeaker.value.tileState"
          :ref="(e) => video.lastSpeaker.value && video.lastSpeaker.value.bindVideo(e)"
          :key="`patient-video-${video.lastSpeaker.value.attendeeId}`"
          class="patient-video"
          disablePictureInPicture
          disableControls
        />

        <volume-level
          class="volume-level-comp"
          :muted="video.lastSpeaker.value.muted"
          :speaking="video.lastSpeaker.value.speaking"
        />
      </div>
      <div v-else-if="validAttendees.length" class="no-video">
        <q-icon name="fa-light fa-headphones-alt" />
      </div>
      <!-- <div v-else class="text-white no-video"> -->
      <!-- <span v-if="video.connecting.value || loading">Connecting...</span>
        <span v-else-if="!video.connected.value">Connecting...</span>
        <span v-else>Waiting for other participants...</span> -->
      <div v-else class="self-video alone">
        <div v-if="video.noVideo.value" class="no-video">
          <q-icon name="fa-light fa-headphones-alt" />
        </div>
        <video v-else ref="localVideoElement" disablePictureInPicture muted :controls="false" />
      </div>
      <!-- </div> -->

      <q-btn
        round
        title="Expand"
        color="primary"
        icon="fa-solid fa-expand-alt"
        @click="toogleMinimise"
      />
    </div>

    <m-layout-stack v-else-if="!isPopup" class="call-container overflow-hidden" full>
      <div class="video-container">
        <!-- <video
          v-if="validAttendees[0]?.tileState"
          :ref="validAttendees[0].bindVideo"
          key="patient-video"
          class="patient-video"
          disablePictureInPicture
          disableControls
        /> -->
        <div v-if="video.lastSpeaker.value" class="attendee-video">
          <video
            v-if="video.lastSpeaker.value.tileState"
            :ref="(e) => video.lastSpeaker.value && video.lastSpeaker.value.bindVideo(e)"
            :key="`patient-video-${video.lastSpeaker.value.attendeeId}`"
            class="patient-video"
            disablePictureInPicture
            disableControls
          />

          <volume-level
            class="volume-level-comp"
            :muted="video.lastSpeaker.value.muted"
            :speaking="video.lastSpeaker.value.speaking"
          />
        </div>
        <div v-else-if="validAttendees.length" class="no-video">
          <q-icon name="fa-solid fa-headphones-alt" />

          <!-- <span>{{ validAttendees.length ? "No video" : "Waiting for patient to join" }}</span> -->
        </div>
        <!-- <div v-else class="no-video">
          <span v-if="video.connecting.value || loading">Connecting...</span>
          <span v-else-if="!video.connected.value">Connecting...</span>
          <span v-else>Waiting for other participants...</span>
        </div> -->
        <div
          class="self-video"
          :class="{
            alone: validAttendees.length === 0,
          }"
        >
          <div class="self-video-container">
            <div v-if="video.noVideo.value" class="no-video">
              <q-icon name="fa-light fa-headphones-alt" />
            </div>
            <span v-else-if="video.videoStarting.value"> Camera is starting </span>
            <video v-else ref="localVideoElement" disablePictureInPicture muted :controls="false" />
          </div>

          <volume-level
            class="volume-level-comp"
            :muted="video.isMuted.value"
            :speaking="video.localAudioPercentage.value > 0"
            :stay="true"
          />
        </div>
      </div>
      <m-layout-stack class="call-buttons" horizontal no-gap>
        <m-layout-stack v-if="isPopup === false" horizontal>
          <q-btn
            ref="btnPopoutEl"
            round
            icon="fa-solid fa-expand"
            color="primary"
            :disable="!video.connected.value"
            @click="popoutPlayer"
          />
          <q-btn
            ref="btnMinimiseEl"
            round
            icon="fa-solid fa-compress-alt"
            color="primary"
            @click="toogleMinimise"
          />
        </m-layout-stack>
        <!-- <q-btn round icon="fa-solid fa-compress-alt" color="primary" @click="toogleMinimise" /> -->

        <div class="call-controls">
          <q-btn
            round
            :title="isMuted ? 'Turn on microphone' : 'Turn off microphone'"
            :color="isMuted ? 'red' : 'primary'"
            :icon="isMuted ? 'fa-solid fa-microphone-slash' : 'fa-solid fa-microphone'"
            @click="toggleAudio"
          />

          <q-btn
            round
            :title="isCameraHidden ? 'Turn on camera' : 'Turn off camera'"
            :color="isCameraHidden ? 'red' : 'primary'"
            :icon="isCameraHidden ? 'fa-solid fa-video-slash' : 'fa-solid fa-video'"
            @click="toggleCamera"
          />
          <q-btn round color="red" icon="fa-solid fa-phone-hangup" @click="endCall" />

          <q-btn
            round
            title="Show settings"
            color="primary"
            icon="fa-solid fa-gear"
            @click="showSettings = true"
          />
        </div>
      </m-layout-stack>
    </m-layout-stack>

    <audio ref="audioElement" />

    <m-modal v-model="showSettings" small title="Video Settings">
      <m-layout-stack>
        <m-select
          :model-value="video.videoInput.value?.deviceId || video.videoInput.value"
          :options="videoInputs"
          no-filter
          label="Video input"
          @update:model-value="video.videoInput.value = $event"
        />

        <m-select
          :model-value="video.audioInput.value?.deviceId || video.audioInput.value"
          :options="audioInputs"
          no-filter
          label="Audio input"
          @update:model-value="video.audioInput.value = $event"
        />
        <m-select
          :model-value="video.audioOutput.value?.deviceId || video.audioOutput.value"
          :options="audioOutputs"
          no-filter
          label="Audio output"
          @update:model-value="video.audioOutput.value = $event"
        />
      </m-layout-stack>
    </m-modal>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, ref, watch, watchEffect } from "vue";

import { AttendeeConfig, MeetingConfig, VolumeLevel, useAmazonChime } from "@medicus/meeting";
import "@medicus/meeting/style.css";
import { QBtn, QIcon } from "quasar";
import { useEvent, useInterval, useOnMouseMove, usePromise } from "vue-composable";
import MLayoutStack from "./medicus/MLayoutStack";
import MSelect from "./medicus/inputs/MSelect";

// outside of a Vue file
import { useMeeting } from "../store/meeting";
import axios from "../utils/axios";
import { showSnackbar } from "../utils/snackbar";

// let overrideConfig = {
//   meetingId: "84159794-84c7-11ec-b05b-06bf9b784824",
//   chimeMeetingDetails: {
//     MeetingId: "69db21d1-885f-469f-8c88-0aca6a740706",
//     ExternalMeetingId: "84159794-84c7-11ec-b05b-06bf9b784824",
//     MediaRegion: "eu-west-2",
//     MediaPlacement: {
//       AudioFallbackUrl:
//         "wss:\/\/haxrp.m2.ew2.app.chime.aws:443\/calls\/69db21d1-885f-469f-8c88-0aca6a740706",
//       AudioHostUrl: "09a3adb9c300f5274e4133ebdcf78532.k.m2.ew2.app.chime.aws:3478",
//       ScreenDataUrl:
//         "wss:\/\/bitpw.m2.ew2.app.chime.aws:443\/v2\/screen\/69db21d1-885f-469f-8c88-0aca6a740706",
//       ScreenSharingUrl:
//         "wss:\/\/bitpw.m2.ew2.app.chime.aws:443\/v2\/screen\/69db21d1-885f-469f-8c88-0aca6a740706",
//       ScreenViewingUrl:
//         "wss:\/\/bitpw.m2.ew2.app.chime.aws:443\/ws\/connect?passcode=null\u0026viewer_uuid=null\u0026X-BitHub-Call-Id=69db21d1-885f-469f-8c88-0aca6a740706",
//       SignalingUrl:
//         "wss:\/\/signal.m2.ew2.app.chime.aws\/control\/69db21d1-885f-469f-8c88-0aca6a740706",
//       TurnControlUrl: "https:\/\/ccp.cp.ue1.app.chime.aws\/v2\/turn_sessions",
//     },
//   },
//   chimeAttendeeDetails: {
//     AttendeeId: "e7309f46-56fd-4847-c3f5-6dbd4fb85eda",
//     ExternalUserId: "1332aa9c-28d6-11eb-adc1-0242ac120002",
//     JoinToken:
//       "ZTczMDlmNDYtNTZmZC00ODQ3LWMzZjUtNmRiZDRmYjg1ZWRhOjM4Y2MyMjU1LTMxMjgtNDk0Yy1iNDc5LTczNTI4YjhlYTRmYw",
//   },
// };
let overrideConfig = {};

const teleportTo = ref<HTMLDivElement>();

const containerEl = ref<HTMLDivElement>();

watch(containerEl, (e) => {
  if (e) {
    teleportTo.value = e;
  }
});

const showSettings = ref(false);

const isMinimised = ref(false);

const isPopup = ref(false);

const props = defineProps<{ meetingId: string }>();

const video = useAmazonChime({
  onAttendeeJoin(attendee) {
    if (!video.connected.value) return;
    if (video.localTile.value?.attendeeId !== attendee.attendeeId) {
      showSnackbar({
        message: "Patient joined",
        type: "info",
      });
    }
  },
  onAttendeeDrop() {
    showSnackbar({
      message: "Patient left",
      type: "info",
    });
  },
});

const audioElement = video.audioElement;
const localVideoElement = video.localVideoElement;

const videoInputs = computed(
  () => video.videoInputDevices.value?.map((x) => ({ label: x.label, value: x.deviceId })) || [],
);

const audioInputs = computed(
  () => video.audioInputDevices.value?.map((x) => ({ label: x.label, value: x.deviceId })) || [],
);

const audioOutputs = computed(
  () => video.audioOutputDevices.value?.map((x) => ({ label: x.label, value: x.deviceId })) || [],
);

const isMuted = video.isMuted;
const isCameraHidden = video.noVideo;

const { result, exec } = usePromise(() =>
  axios
    .get<{
      chimeAttendeeDetails: AttendeeConfig;
      chimeMeetingDetails: MeetingConfig;
      meetingId: string;
    }>(`/virtual-meeting/data/join-meeting/${props.meetingId}`)
    .then((x) => x.data),
);
watch(() => props.meetingId, exec);

watch(result, (r, prev) => {
  if (!r || video.connected.value) return;
  if (r?.meetingRoomId === prev?.meetingRoomId) return;
  video.connect(r.chimeMeetingDetails, r.chimeAttendeeDetails);
});

useInterval(exec, 5 * 60 * 1 * 100); // call exec every 5min

function reconnect() {
  const r = result.value || overrideConfig;
  if (r) {
    return video.connect(r.chimeMeetingDetails, r.chimeAttendeeDetails);
  }
  return Promise.resolve();
}

onMounted(() => {
  const el = containerEl.value;
  if (el) {
    Promise.resolve().then(() => {
      requestAnimationFrame(() => {
        el.style.setProperty("left", `${window.innerWidth - el.clientWidth}px`);

        el.style.setProperty("top", `${getCorrectTopPosition()}px`);
      });
    });
  }
});

function getCorrectTopPosition() {
  const patientEl = document.body.querySelector(
    ".encounter-page > .encounter-patient",
  )! as HTMLDivElement;

  if (patientEl) {
    return patientEl.offsetTop + patientEl.clientHeight;
  } else {
    return 20;
  }
}

const mouseOffset = ref<{ y: number; x: number }>();

useEvent(containerEl, "mousedown", (e) => {
  if (isPopup.value) return;

  mouseOffset.value = {
    x: containerEl.value!.offsetLeft - e.clientX,
    y: containerEl.value!.offsetTop - e.clientY,
  };
});
useEvent(window, "mouseup", () => (mouseOffset.value = undefined));
const { mouseX, mouseY } = useOnMouseMove(containerEl);
watchEffect(() => {
  if (!mouseOffset.value) return;

  const el = containerEl.value!;

  const left = mouseX.value + mouseOffset.value.x;
  const top = mouseY.value + mouseOffset.value.y;

  requestAnimationFrame(() => {
    el.style.left = `${left >= 0 ? left : 0}px`;
    el.style.top = `${top >= 0 ? top : 0}px`;

    fixMaxPosition(el);
  });
});

useEvent(window, "resize", () => {
  if (!containerEl.value) return;
  requestAnimationFrame(() => fixMaxPosition(containerEl.value!));
});

function fixMaxPosition(el: HTMLDivElement) {
  const right = el.offsetLeft + el.clientWidth + 20;
  const bottom = el.offsetTop + el.clientHeight + 20;

  if (right > window.innerWidth) {
    el.style.left = `${el.offsetLeft - (right - window.innerWidth)}px`;
  }
  if (bottom > window.innerHeight) {
    el.style.top = `${el.offsetTop - (bottom - window.innerHeight)}px`;
  }
}

function toggleCamera() {
  isCameraHidden.value = !isCameraHidden.value;
}

function toggleAudio() {
  isMuted.value = !isMuted.value;
}

const store = useMeeting();

let callEnded = false;
async function endCall() {
  await video.disconnect();
  store.disconnect();
  callEnded = true;
}

function toogleMinimise() {
  isMinimised.value = !isMinimised.value;

  const el = containerEl.value!;
  if (!el) return;

  if (isMinimised.value === false) {
    // On exapnd, move container back on screen if it goes outside
    Promise.resolve().then(() => {
      if (containerEl.value) {
        const OFFSET = 20;
        const elLeft = containerEl.value.offsetLeft;
        const elWidth = containerEl.value.offsetWidth;

        if (elLeft + elWidth > window.innerWidth) {
          const newElLeft = window.innerWidth - elWidth - OFFSET;

          requestAnimationFrame(() => {
            el.style.setProperty("left", `${newElLeft}px`);
          });
        }

        const elTop = containerEl.value.offsetTop;
        const elHeight = containerEl.value.offsetHeight;

        if (elTop + elHeight > window.innerHeight) {
          const newElTop = window.innerHeight - elHeight - OFFSET;
          requestAnimationFrame(() => {
            el.style.setProperty("top", `${newElTop}px`);
          });
        }
      }
    });
  }
}

const validAttendees = computed(() =>
  video.attendeeList.value.filter(
    (x) =>
      (x.tileState === undefined || !x.tileState.localTile) && !x.dropped && x.present !== false,
  ),
);

let newWindow: Window = null as any;

onUnmounted(() => {
  if (newWindow) {
    newWindow.close();
  }
});

const origin =
  location.origin.indexOf("local") >= 0
    ? "http://localhost:3000"
    : import.meta.env.VITE_MEETING_APP_URL;

let left = "",
  top = "";

useEvent(window, "message", (e) => {
  if (e.origin !== origin) {
    return;
  }
  // console.log("received messag", e.data);

  switch (e.data) {
    case "connected": {
      const el = containerEl.value;
      if (el) {
        left = el.style.left;
        top = el.style.top;
      }
      video.disconnect();
      isPopup.value = true;
      break;
    }
    case "terminated": {
      endCall();
    }
  }
});

function popoutPlayer() {
  if (isPopup.value) {
    newWindow.close();
    return;
  }
  const params = "fullscreen";

  const c = {
    ...(result.value || overrideConfig),

    videoInput: video.videoInput.value?.label,
    audioInput: video.audioInput.value?.label,
    audioOutput: video.audioOutput.value?.label,
  };

  const config = JSON.stringify(c);

  newWindow = window.open(
    `${origin}/#config=${encodeURIComponent(JSON.stringify(config))}`,
    "medicus-consultation-video",
    params,
  )!;

  if (newWindow.outerWidth < screen.availWidth || newWindow.outerHeight < screen.availHeight) {
    newWindow.moveTo(0, 0);
    newWindow.resizeTo(screen.availWidth, screen.availHeight);
  }

  if (!newWindow) {
    // couldn't open
    return;
  }

  function checkClosed(force = false) {
    // console.log("is closed", newWindow.closed || force);
    if (newWindow.closed || force) {
      if (callEnded) return;
      reconnect().then(() => {
        isPopup.value = false;
        nextTick(() => {
          const el = containerEl.value;
          if (el) {
            requestAnimationFrame(() => {
              el.style.left = left;
              el.style.top = top;
            });
          }
        });
        // try to play audio
        const tryToPlay = setInterval(async () => {
          try {
            const context = video.getAudioContext();
            console.log("user context state", context.state);
            if (context.state !== "running") {
              await context.resume();
              clearInterval(tryToPlay);
              console.log("user context after playing ", context.state);
            } else {
              clearInterval(tryToPlay);
            }
          } catch (e) {
            console.error("user failed audio context", e);
          }
        }, 100);
      });
      // nextTick().then(reconnect);
    } else {
      setTimeout(checkClosed, 10);
    }
  }

  checkClosed();
}

// @ts-expect-error
window.connect = (x) => {
  overrideConfig = x;
  result.value = x;
};
</script>
<style lang="scss">
.video-call-container {
  --q-primary: var(--theme-grey);

  position: absolute;

  // background-color: var(--theme-darkest-blue);
  border-radius: 6px;

  z-index: 5000;
  aspect-ratio: 16/9;

  width: 50%;

  display: flex;

  // max-width: 50%;

  // TODO change this
  top: 110px;
  left: 0;

  transition-property: top, left, width;

  * {
    user-select: none;
  }

  .q-btn--round {
    min-width: 2.5em;
    min-height: 2.5em;
  }

  video {
    border-radius: 4px;
    height: 100%;
    width: 100%;
    object-fit: cover;

    &::-webkit-media-controls {
      display: none;
    }
  }

  .volume-level-comp {
    right: 1rem;
    top: 1rem;
    position: absolute;

    .bg-black {
      background: rgba($color: black, $alpha: 0.2) !important;
    }
  }

  .no-video .q-icon {
    font-size: 10em !important;
    color: white;
  }

  .no-video {
    user-select: none;
    // max-height: 200px;
    // max-width: 500px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgb(33, 33, 33);

    span {
      color: white;
      text-align: center;
    }
  }

  .call-container {
    position: relative;

    border-radius: 8px;

    .call-buttons {
      position: absolute;
      bottom: 1rem;
      // left: 1rem;
      padding-left: 1rem;
      width: 100%;
    }
  }

  .video-container {
    background-color: rgb(33, 33, 33);
    position: relative;
    overflow: hidden;

    // max-width: 500px;
    // max-height: 300px;

    display: flex;
    flex: 1 1 auto;

    .patient-video {
      margin: auto;
    }

    .attendee-video {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .self-video {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    height: 100px;
    width: 180px;
    display: flex;

    .no-video {
      height: 100%;
      border-radius: 8px;
      border: 1px solid var(--theme-grey);
    }

    .self-video-container {
      position: relative;
      height: 100%;
      width: 100%;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      // > * {
      //   height: 100%;
      //   width: 100%;
      // }
    }

    span {
      color: white;
      margin: auto;
      font-size: 1.5rem; /* 24px */
      line-height: 2rem; /* 32px */
    }

    &.alone {
      width: 100%;
      height: 100%;

      bottom: 0;
      left: 0;
      right: 0;
    }

    .q-icon {
      font-size: 3rem !important;
    }
  }

  .q-icon {
    font-size: 1.25em !important;

    // center the chevron
    &.fa-chevron-right,
    &.fa-chevron-left {
      margin-left: 3px;
    }
  }

  .call-controls {
    display: flex;
    flex: 1 1 auto;

    gap: 1rem;
    align-items: center;
    justify-content: center;

    // add right button padding to push the items to be center
    padding-right: 82px;
  }

  .minimised-controls {
    flex-direction: column;
    padding-left: 0;
    gap: 0.75rem;

    .q-icon {
      font-size: 1.25em !important;
    }
    // center the chevron
    .fa-chevron-right,
    .fa-chevron-left {
      margin-left: 0px !important;
      margin-right: 1px !important;
    }
  }

  &.minimised {
    width: auto;
    height: 150px;

    flex: 1 1 auto;
    display: flex;
    // position: relative;

    aspect-ratio: 1.2;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;

    top: 0;
    right: 0;
    left: inherit;

    // max-height: 100px;
    .minimised-video {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;

      .q-btn {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
      }
    }

    .fa-chevron-left {
      margin-left: 0;
    }
  }
}

.cursor-move {
  cursor: move;
}

.gap-0\.50 {
  grid-gap: 0.125rem;
  gap: 0.125rem;
}
.w-6 {
  width: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-6 {
  height: 1.5rem;
}

.w-1 {
  width: 0.25rem;
}
.h-1 {
  height: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.z-10 {
  z-index: 10;
}

.fill-white {
  fill: white;
}
.text-white {
  color: white;
}
</style>
