<script lang="ts" setup>
import { computed } from "vue";
import { usePrescriptionStore } from "../../../../store/transitient/prescription";
import MBanner from "../../../medicus/MBanner";
import MLayoutStack from "../../../medicus/MLayoutStack";
import MSection from "../../../medicus/MSection/MSection.vue";
import CodeDegrades from "./CodeDegrades/CodeDegrades.vue";
import DrugAlertDisplay from "./DrugAlertDisplay";
import DrugAlertError from "./DrugAlertError";

defineProps<{
  codeDegradesType?: string;

  codeDegradesExpanded?: boolean;
  codeDegradesExpand?: boolean;

  codeDegradesLabel?: string;
  codeDegradesIcon?: string;
}>();

const store = usePrescriptionStore();

const hasSensitivityAlerts = computed(() => {
  return (
    "alerts" in store.safetyChecks.sensitivity && store.safetyChecks.sensitivity.alerts?.length
  );
});

const codeDegrades = computed(() => {
  if (!store.safetyChecks) return [];
  const { sensitivity, patientCondition, pharmacological, highRisk, interaction } =
    store.safetyChecks;
  return [
    sensitivity?.errors?.length ? false : sensitivity?.codeDegrades,
    patientCondition?.errors?.length ? false : patientCondition?.codeDegrades,
    pharmacological?.errors?.length ? false : pharmacological?.codeDegrades,
    highRisk?.errors?.length ? false : highRisk?.codeDegrades,
    interaction?.errors?.length ? false : interaction?.codeDegrades,
  ]
    .filter(Boolean)
    .flat() as { message: string; codes: string[] | null }[];
});

const drugWarningsLength = computed(() => {
  const condition = store.safetyChecks?.patientCondition;
  if (!condition || (condition && ("errors" in condition || !condition.drugWarnings))) {
    return 0;
  }

  return condition.drugWarnings.reduce((p, c) => c.alerts.length + p, 0);
});

const hasAlerts = computed(() => {
  if (!store.safetyChecks) return [];
  const { sensitivity, patientCondition, pharmacological, highRisk, interaction, safetyAlerts } =
    store.safetyChecks;

  return (
    [
      sensitivity?.errors?.length ? 0 : sensitivity?.alerts?.length,
      patientCondition?.errors?.length
        ? 0
        : patientCondition?.contraindications?.lowAlerts?.length ||
          patientCondition?.contraindications?.highAlerts?.length ||
          patientCondition?.precautions?.length ||
          patientCondition?.drugWarnings?.length,
      pharmacological?.errors?.length
        ? 0
        : pharmacological?.drugEquivalentAlerts?.length ||
          pharmacological?.ingredientEquivalenceAlerts?.length ||
          pharmacological?.therapyEquivalenceAlerts?.length,
      highRisk?.errors?.length ? 0 : highRisk?.alerts?.length,
      interaction?.errors?.length
        ? 0
        : (interaction?.highRiskInteractionAlerts?.length ?? 0) +
          (interaction?.significantRiskInteractionAlerts?.length ?? 0) +
          (interaction?.moderateRiskInteractionAlerts?.length ?? 0) +
          (interaction?.lowRiskInteractionAlerts?.length ?? 0),
      safetyAlerts?.safetyAlerts.length,
    ].filter(Boolean).length > 0
  );
});
</script>
<template>
  <m-card title="Safety Alerts">
    <p v-if="store.loadingChecks" class="empty-text">Checking for safety alerts...</p>
    <m-layout-stack v-else-if="store.safetyChecks" gap="2">
      <template v-if="store?.safetyChecks?.sensitivity?.errors?.length">
        <DrugAlertError :errors="store.safetyChecks.sensitivity.errors" />
      </template>
      <template v-if="store.safetyChecks?.interaction?.errors?.length">
        <DrugAlertError :errors="store.safetyChecks.interaction.errors" />
      </template>
      <template v-if="store.safetyChecks?.patientCondition?.errors?.length">
        <DrugAlertError :errors="store.safetyChecks.patientCondition.errors" />
      </template>
      <template v-if="store.safetyChecks?.pharmacological?.errors?.length">
        <DrugAlertError :errors="store.safetyChecks.pharmacological.errors" />
      </template>
      <template v-if="store.safetyChecks?.highRisk?.errors?.length">
        <DrugAlertError :errors="store.safetyChecks.highRisk.errors" />
      </template>

      <CodeDegrades v-if="codeDegrades.length" :code-degrades="codeDegrades" />

      <template v-if="hasAlerts">
        <DrugAlertDisplay
          v-for="(alert, index) in store.safetyChecks.safetyAlerts?.safetyAlerts ?? []"
          :key="`medicus-safety-alerts-${index}`"
          :label="alert.label"
          data-testid="`safety-alert-${index}`"
          :expanded="true"
          :expand="true"
          :type="alert.type"
          :alerts="[alert.value.description]"
          :render-alert-with-html="true"
        />

        <DrugAlertDisplay
          v-if="hasSensitivityAlerts"
          :label="`Sensitivities (${store.safetyChecks.sensitivity.alerts.length})`"
          data-testid="sensitivity-alerts"
          :expanded="true"
          :expand="true"
          type="danger"
          :alerts="store.safetyChecks.sensitivity.alerts"
        />

        <DrugAlertDisplay
          v-if="
            'highRiskInteractionAlerts' in store.safetyChecks.interaction &&
            store.safetyChecks.interaction.highRiskInteractionAlerts?.length
          "
          :label="`High Risk Interactions (${store.safetyChecks.interaction.highRiskInteractionAlerts?.length})`"
          data-testid="interactions-high-risk-interaction-alerts"
          :expanded="true"
          :expand="true"
          type="danger"
          :alerts="store.safetyChecks.interaction.highRiskInteractionAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'contraindications' in store.safetyChecks.patientCondition &&
            store.safetyChecks.patientCondition.contraindications.highAlerts &&
            store.safetyChecks.patientCondition.contraindications.highAlerts.length
          "
          :label="`Patient Specific Contraindications (${store.safetyChecks.patientCondition.contraindications.highAlerts.length})`"
          data-testid="contraindications-high-alerts"
          :expanded="true"
          :expand="true"
          type="danger"
          :alerts="store.safetyChecks.patientCondition.contraindications.highAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'alerts' in store.safetyChecks.highRisk && store.safetyChecks.highRisk.alerts?.length
          "
          :label="`High Risk Messages (${store.safetyChecks.highRisk.alerts?.length})`"
          data-testid="high-risk-messages"
          :expanded="true"
          :expand="true"
          type="danger"
          :alerts="store.safetyChecks.highRisk.alerts"
        />

        <DrugAlertDisplay
          v-if="
            'drugEquivalentAlerts' in store.safetyChecks.pharmacological &&
            store.safetyChecks.pharmacological.drugEquivalentAlerts.length
          "
          :label="`Drug Equivalents (${store.safetyChecks.pharmacological.drugEquivalentAlerts.length})`"
          data-testid="drug_equivalents"
          :expanded="true"
          :expand="true"
          type="danger"
          :alerts="store.safetyChecks.pharmacological.drugEquivalentAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'ingredientEquivalenceAlerts' in store.safetyChecks.pharmacological &&
            store.safetyChecks.pharmacological.ingredientEquivalenceAlerts.length
          "
          :label="`Duplicate Ingredients (${store.safetyChecks.pharmacological.ingredientEquivalenceAlerts.length})`"
          data-testid="duplicate_ingredients"
          :expanded="true"
          :expand="true"
          type="danger"
          :alerts="store.safetyChecks.pharmacological.ingredientEquivalenceAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'therapyEquivalenceAlerts' in store.safetyChecks.pharmacological &&
            store.safetyChecks.pharmacological.therapyEquivalenceAlerts.length
          "
          :label="`Duplicate Therapy (${store.safetyChecks.pharmacological.therapyEquivalenceAlerts.length})`"
          data-testid="duplicate_therapy"
          :expand="true"
          :expanded="true"
          type="danger"
          :alerts="store.safetyChecks.pharmacological.therapyEquivalenceAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'significantRiskInteractionAlerts' in store.safetyChecks.interaction &&
            store.safetyChecks.interaction.significantRiskInteractionAlerts?.length
          "
          :label="`Significant Risk Interactions (${store.safetyChecks.interaction.significantRiskInteractionAlerts?.length})`"
          data-testid="interactions-significant-risk-interaction-alerts"
          :expanded="true"
          :expand="true"
          type="warning"
          :alerts="store.safetyChecks.interaction.significantRiskInteractionAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'contraindications' in store.safetyChecks.patientCondition &&
            store.safetyChecks.patientCondition.contraindications.lowAlerts.length
          "
          :label="`General Contraindications (${store.safetyChecks.patientCondition.contraindications.lowAlerts.length})`"
          data-testid="contraindications-low-alerts"
          :expand="true"
          type="info"
          :alerts="store.safetyChecks.patientCondition.contraindications.lowAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'precautions' in store.safetyChecks.patientCondition &&
            store.safetyChecks.patientCondition.precautions.length
          "
          :label="`Precautions (${store.safetyChecks.patientCondition.precautions.length})`"
          data-testid="precautions"
          :expand="true"
          type="info"
          :alerts="store.safetyChecks.patientCondition.precautions"
        />

        <DrugAlertDisplay
          v-if="
            'moderateRiskInteractionAlerts' in store.safetyChecks.interaction &&
            store.safetyChecks.interaction.moderateRiskInteractionAlerts?.length
          "
          :label="`Moderate Risk Interactions (${store.safetyChecks.interaction.moderateRiskInteractionAlerts?.length})`"
          data-testid="interactions-moderate-risk-interaction-alerts"
          :expanded="false"
          :expand="true"
          type="info"
          :alerts="store.safetyChecks.interaction.moderateRiskInteractionAlerts"
        />

        <DrugAlertDisplay
          v-if="
            'lowRiskInteractionAlerts' in store.safetyChecks.interaction &&
            store.safetyChecks.interaction.lowRiskInteractionAlerts?.length
          "
          :label="`Low Risk Interactions (${store.safetyChecks.interaction.lowRiskInteractionAlerts?.length})`"
          data-testid="interactions-low-risk-interaction-alerts"
          :expanded="false"
          :expand="true"
          type="info"
          :alerts="store.safetyChecks.interaction.lowRiskInteractionAlerts"
        />

        <m-banner
          v-if="
            'drugWarnings' in store.safetyChecks.patientCondition &&
            store.safetyChecks.patientCondition.drugWarnings.length
          "
          gap="1"
          style="padding-top: 0"
          class="flex"
          data-testid="drug_warnings"
          :expanded="false"
          :expand="true"
          :label="`Prescribing Advice (${drugWarningsLength})`"
          type="info"
          :count="store.safetyChecks.patientCondition.drugWarnings.length"
        >
          <div
            v-for="(groupedDrugWarnings, index) in store.safetyChecks.patientCondition.drugWarnings"
            :key="`grouped_drug_warnings${index}`"
            class="prescribing-advice"
            :data-testid="`drug_warnings-${groupedDrugWarnings.heading}`"
          >
            <span class="grey-darkest">{{ groupedDrugWarnings.heading }}</span>
            <ul>
              <li v-for="alert in groupedDrugWarnings.alerts" :key="alert">
                {{ alert }}
              </li>
            </ul>
          </div>
        </m-banner>
      </template>
      <m-section v-else-if="!codeDegrades.length" title="Safety Alerts">
        <p class="white-box q-pa-md empty-text">No known safety alerts</p>
      </m-section>
    </m-layout-stack>
  </m-card>
</template>

<style scoped lang="scss">
div.prescribing-advice {
  padding-inline-start: 52px;
  padding-bottom: 0.5rem;

  .grey-darkest {
    color: var(--grey-darkest);
  }

  ul {
    padding-inline-start: 22px;
  }
}
</style>
