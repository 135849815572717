<template>
  <m-input
    class="time-input"
    v-bind="$props"
    :required-text="requiredText || `Enter a time for '${label}'`"
    mask="##:##"
    :rules="[...rules, !noValidation ? 'time' : undefined]"
    :model-value="fixedValue"
    @update:model-value="handleValueUpdate"
    @blur="onInput(textInput || value)"
  >
    <template v-if="$slots.helper" #helper> <slot name="helper" /></template>
  </m-input>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";

import { useFormValue } from "../../../../composables/namedValue";
import MInput from "./../MInput";

const props = defineProps({
  ...MInput.props,
  rules: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: String as () => string | null,
    required: false,
  },

  placeholder: {
    type: String,
    default() {
      return "hh:mm";
    },
  },
  noValidation: Boolean,

  seconds: {
    type: String,
    default: "00",
  },
  name: String,

  instructions: String,

  large: Boolean,

  removeSeconds: Boolean,

  actions: Array,
  actionsLeft: Boolean,
});

const value = useFormValue<{ modelValue: string | null }, string | null>(props as any);
const fixedValue = computed(() => value.value && value.value.split(":").slice(0, 2).join(":"));

let textInput = "";
function onInput(v: string | null) {
  let n = v;

  if (n && /^\d{2}:\d{2}$/.test(n)) {
    n = `${n}${props.seconds !== "" && !props.removeSeconds ? `:${props.seconds}` : ""}`;
  }

  value.value = n ?? null;
}

onMounted(() => {
  onInput(value.value);
});

function handleValueUpdate(newValue) {
  textInput = newValue;
  onInput(textInput);
}
</script>

<style lang="scss">
.time-input {
  .m-input--input-box {
    max-width: 5rem;
  }
  .label {
    margin-left: 0 !important;
  }
}
</style>
