<script setup lang="ts">
import { provide, ref } from "vue";
import { isString } from "vue-composable";
import DrilldownComponent from "./DrilldownComponent.vue";

function openPage(url: { url: string } & Record<string, any>): void;
// eslint-disable-next-line no-redeclare
function openPage(url: string, options: Record<string, any>): void;
// eslint-disable-next-line no-redeclare
function openPage(
  url: ({ url: string } & Record<string, any>) | string,
  options?: Record<string, any>,
): void {
  componentOpts.value = isString(url) ? { url, ...options } : url;
}

const componentOpts = ref<{ url: string } & Record<string, any>>();
provide("openPage", openPage);
provide("inDrilldownPage", ref(true));
</script>

<template>
  <div class="relative">
    <slot />
    <drilldown-component v-model="componentOpts" />
  </div>
</template>
