<script setup lang="ts">
import { computed, ref } from "vue";
import { formatDate } from "../../../../utils/date";

export type TimelineBarValue = {
  id: string;
  startDate: ChartDate;
  endDate?: ChartDate;
  tooltip?: string;
};

export type ChartDate = {
  day: number;
  month: number;
  year: number;
};

const props = defineProps<{
  data: TimelineBarValue[];
  range?: { start: Date; end: Date }; // Defaults to 12 months from today if not set
}>();

const dateToday = new Date();
const dateLastYear = new Date(
  dateToday.getFullYear(),
  dateToday.getMonth() - 12,
  dateToday.getDate(),
);

const dateRangeStart = ref<ChartDate>(
  props.range
    ? {
        day: props.range.start.getDate(),
        month: props.range.start.getMonth() + 1,
        year: props.range.start.getFullYear(),
      }
    : {
        day: dateLastYear.getDate(),
        month: dateLastYear.getMonth() + 1,
        year: dateLastYear.getFullYear(),
      },
);
const dateRangeStartDate = computed<Date>(() => getDateFromValue(dateRangeStart.value));

const dateRangeEnd = ref<ChartDate>(
  props.range
    ? {
        day: props.range.end.getDate(),
        month: props.range.end.getMonth() + 1,
        year: props.range.end.getFullYear(),
      }
    : {
        day: dateToday.getDate(),
        month: dateToday.getMonth() + 1,
        year: dateToday.getFullYear(),
      },
);
const dateRangeEndDate = computed<Date>(() => getDateFromValue(dateRangeEnd.value));

const itemEls = ref<HTMLLIElement[]>([]);

const numberOfWeeks = computed(() => {
  return Math.floor(timeBetweenDates(dateRangeStart.value, dateRangeEnd.value, "weeks"));
});

const percentPerTimeSegment = computed(() => 100 / numberOfWeeks.value);

function timeBetweenDates(
  date: ChartDate,
  date2: ChartDate,
  type: "ms" | "days" | "weeks" = "weeks",
): number {
  const startDate: Date = getDateFromValue(date);
  const endDate: Date = getDateFromValue(date2);

  const diffInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());
  if (type === "ms") return diffInMilliseconds;

  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  if (type === "days") return diffInDays;

  const diffInWeeks = diffInDays / 7;
  return diffInWeeks;
}

function getStartWeek(chartDate: ChartDate) {
  const date: Date = getDateFromValue(chartDate);

  if (date.getTime() < dateRangeStartDate.value.getTime()) {
    return 1;
  }

  const weeks = timeBetweenDates(dateRangeStart.value, chartDate, "weeks");
  return weeks;
}

function isStartDateInRange(value: TimelineBarValue): boolean {
  const startDate: Date = new Date(
    value.startDate.year,
    value.startDate.month - 1,
    value.startDate.day,
  );

  return (
    startDate.getTime() >= dateRangeStartDate.value.getTime() &&
    startDate.getTime() <= dateRangeEndDate.value.getTime()
  );
}

function isEndDateInRange(value: TimelineBarValue): boolean {
  if (!value.endDate) return false;

  const endDate: Date = new Date(value.endDate.year, value.endDate.month - 1, value.endDate.day);

  return (
    endDate.getTime() > dateRangeStartDate.value.getTime() &&
    endDate.getTime() < dateRangeEndDate.value.getTime()
  );
}

/**
 * Returns whether the start date or end date falls within the date range
 */
function isValueInRange(value: TimelineBarValue): boolean {
  return isStartDateInRange(value) || isEndDateInRange(value);
}

function getDateFromValue(chartDate: ChartDate) {
  return new Date(chartDate.year, chartDate.month - 1, chartDate.day);
}

function getPercentWidthForValue(value: TimelineBarValue): string {
  if (!value.endDate) {
    return "3px";
  }

  const timeSegments = timeBetweenDates(
    isStartDateInRange(value) ? value.startDate : dateRangeStart.value,
    isEndDateInRange(value) ? value.endDate : dateRangeEnd.value,
  );

  return timeSegments * percentPerTimeSegment.value + "%";
}

function getLeftPositionForValue(value: TimelineBarValue): string {
  const startWeek = getStartWeek(value.startDate);

  if (startWeek <= 1) return "0%";

  return startWeek * percentPerTimeSegment.value + "%";
}

function getTooltipTextForValue(value: TimelineBarValue): string {
  let tooltipText = "";

  tooltipText += `From: ${value.startDate.day}-${value.startDate.month}-${value.startDate.year}`;
  if (value.endDate) {
    tooltipText += `\n`;
    tooltipText += `To: ${value.endDate.day}-${value.endDate.month}-${value.endDate.year}`;
  }

  return tooltipText;
}
</script>
<template>
  <div class="m-timeline-bar-chart" role="figure">
    <div class="timeline">
      <div class="weeks">
        <div v-for="(_, index) of numberOfWeeks" :key="index" class="week" />
      </div>
      <ol class="data-list">
        <li
          v-for="(value, index) of data.filter(isValueInRange)"
          ref="itemEls"
          :key="value.id"
          :style="{
            left: getLeftPositionForValue(value),
            width: getPercentWidthForValue(value),
          }"
          tabindex="0"
          :class="{
            'list-item': true,
            'start-before-timeline': isStartDateInRange(value) === false,
            'has-end-date': value.endDate !== undefined,
          }"
        >
          <div class="start-indicator" />
          <m-tooltip
            :text="value.tooltip ?? getTooltipTextForValue(value)"
            :scroll-target="itemEls[index]"
            :delay="0"
            no-transition
          />
        </li>
      </ol>
    </div>
    <div class="labels">
      <div class="label-range-start">
        {{ formatDate(getDateFromValue(dateRangeStart)) }}
      </div>
      <div class="label-range-end">
        {{ formatDate(getDateFromValue(dateRangeEnd)) }}
      </div>
    </div>
  </div>
</template>
<style lang="scss">
:root {
  --chart-height: 16px;
  --chart-border-width: 1px;
}

.m-timeline-bar-chart {
  .timeline {
    height: var(--chart-height);
    border: var(--chart-border-width) solid var(--grey-lightest-non-text);
    overflow: hidden;
    position: relative;
  }

  .weeks {
    height: calc(var(--chart-height) - (var(--chart-border-width) * 2));
    display: flex;
    //gap: 1px;

    .week {
      background: white;
      flex: 1 1 auto;
      transition: var(--transition);

      &:hover {
        background: var(--grey-light);
      }
    }
  }

  .data-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(var(--chart-height) - (var(--chart-border-width) * 2));
    margin: 0;
    list-style: none;
    pointer-events: none;

    .list-item {
      position: absolute;
      height: inherit;
      pointer-events: all;
      transition: var(--transition);

      &.has-end-date {
        background: hsl(212deg 47% 47% / 20%);
      }

      &:hover,
      &:focus {
        background: var(--theme-blue);
      }

      &.start-before-timeline {
        .start-indicator {
          display: none;
        }
      }

      .start-indicator {
        width: 3px;
        height: inherit;
        background: var(--theme-darkest-blue);
      }
    }
  }

  .labels {
    display: flex;
    justify-content: space-between;
    margin-top: var(--gap-1);
    color: var(--text-color-lightest);
    font-size: 12px;
  }
}
</style>
