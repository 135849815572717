<template>
  <div class="flex flex-row">
    <!-- NOTE `@update:modelValue="$forceUpdate"` is an hack to fix the input,
    it seems the component does not refresh -->
    <m-input
      v-bind="validProps"
      v-model="formValue.value"
      :name="`${name}.value`"
      type="number"
      class="m-quantity"
      :helper="helper"
      :instructions="instructions"
      @update:modelValue="$forceUpdate"
    >
      <template v-if="$slots.helper" #helper> <slot name="helper" /></template>

      <template #append>
        <q-select
          v-model="formValue.unit"
          :name="`${name}.unit`"
          :options="units"
          map-options
          map-values
          emit-value
          dense
          rounded
          outlined
        />
      </template>
    </m-input>
  </div>
</template>

<script lang="ts">
import { QSelect } from "quasar";
import { PropType, WritableComputedRef, computed, defineComponent, provide, watch } from "vue";
import { useFormValue } from "../../../../composables/namedValue";
import MInput from "./../MInput";

export default defineComponent({
  components: {
    MInput,
    QSelect,
  },
  inheritAttrs: false,
  props: {
    ...((MInput as any).props as {
      label: PropType<string>;
      name: PropType<string>;
      modelValue: {
        type: () => { value: number; unit: string };
        required: true;
        default: () => { value: undefined; unit: undefined };
      };
    }),
    modelValue: {
      type: Object as () => { value: number; unit: string },
      required: false,
      default: () => ({
        value: undefined,
        unit: undefined,
      }),
    },

    units: Array as () => Array<{ label: string; value: string }>,
    helper: String,
    instructions: String,
  },

  setup(props, { attrs }) {
    provide("input-dont-use-name", true);

    const formValue = useFormValue(props) as WritableComputedRef<{ value: number; unit: string }>;

    // eslint-disable-next-line no-undef
    if (IS_APP && import.meta.env.NODE_ENV !== "production") {
      watch(formValue, (v) => {
        if (!v) {
          console.error("Please set a value to: ", props.name);
        }
      });
    }

    const validProps = computed(() => {
      const p = {
        ...attrs,
        ...props,
        // do not pass the name/value to the input
        name: undefined,
        modelValue: undefined,
        units: undefined,
        "onUpdate:modelValue": undefined,
      };

      delete p.name;
      delete p.modelValue;
      delete p["onUpdate:modelValue"];
      delete p.units;

      return p;
    });

    return {
      formValue,
      validProps,
    };
  },
});
</script>

<style lang="scss">
.m-quantity {
  .m-input--input-box--append {
    label {
      height: 30px;

      .q-field__control {
        min-height: 30px !important;
        height: 30px !important;
      }
    }
    .q-field--outlined {
      .q-field__control {
        &:before,
        &:after {
          border: none !important;
        }
      }
    }
  }
}
</style>
