<script setup lang="ts">
import { globalPlayer } from "../composables/playAudio";
import { QDialog } from "quasar";
import { ref, watchEffect } from "vue";
import MButton from "./medicus/MButton";

const show = ref(true);

function close() {
  globalPlayer.value?.pause();
  show.value = false;
  globalPlayer.value = undefined;
}

watchEffect(() => {
  show.value = !!globalPlayer.value;
});
</script>
<template>
  <q-dialog v-if="globalPlayer" v-model="show" seamless position="bottom" no-focus>
    <div class="audio-container">
      <m-button
        class="close-button"
        icon="fa-light fa-xmark"
        label="Close"
        icon-only
        color="secondary"
        @click="close"
      />

      <m-audio-player model-value :player="globalPlayer" />
    </div>
  </q-dialog>
</template>
<style lang="scss">
.audio-container {
  background: white;
  width: 350px;
  position: relative;

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    width: auto;

    border: none;
    background: #fff;
    color: var(--grey-darker);
    &:hover:not([disabled]) {
      background: #e7eaee;
      cursor: pointer;
    }
  }
}
</style>
