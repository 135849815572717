<script lang="ts" setup>
import { Ref, inject, watchEffect } from "vue";
import { NO_OP } from "vue-composable";
import MLayoutStack from "../MLayoutStack";
import { Patient } from "../PatientBanner";

const setTitle = inject<(s: string) => void>("setTitle", NO_OP);
const setPatient = inject<(s: Patient) => void>("setPatient", NO_OP);
const setPatientId = inject<(s?: string) => void>("setPatientId", NO_OP);

const patientBanner = inject<Ref<Patient> | undefined>("patientBanner", undefined);

const props = defineProps<{
  title: string;
  patient?: Patient;
  patientBannerPatientId?: string;
}>();

watchEffect(() => {
  if (props.title) {
    setTitle?.(props.title);
  }
  if (props.patient) {
    setPatient?.(props.patient);
  }
  if (patientBanner?.value) {
    setPatient?.(patientBanner.value);
  }

  if (props.patientBannerPatientId !== undefined) {
    setPatientId?.(props.patientBannerPatientId);
  }
});
</script>
<template>
  <m-layout-stack>
    <slot />
  </m-layout-stack>
</template>
