<template>
  <div class="list-item-row">
    <div class="item-label" :style="[labelWidth && { width: labelWidth }]" v-text="label" />
    <div class="item-text">
      <slot>
        {{ modelValue }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
export default defineComponent({
  props: {
    label: String,
    modelValue: String,
  },
  setup() {
    const labelWidth = inject("itemLabelWidth", undefined);

    return {
      labelWidth,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-item-row {
  display: flex;

  height: 2em;

  .item-label {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;

    color: var(--text-color-light);
  }

  .item-text {
    flex: 1 1 auto;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;

    color: var(--text-color);
  }
}
</style>
