<template>
  <div class="row">
    <div v-if="taskItem.status.value === 'done'" class="patient-contacted">
      Patient contacted
      <q-btn
        label="Return to To do"
        type="button"
        color="primary"
        class="float-right"
        @click="markAsTodo()"
      />
    </div>
    <div class="col-12 q-pa-xl title">
      <h4 class="q-mb-sm q-mt-none">
        {{ taskItem.title }}
        <q-badge align="middle" :color="taskItem.status.value === 'todo' ? 'grey' : 'secondary'">{{
          taskItem.status.label
        }}</q-badge>
      </h4>
    </div>
    <div class="col-12 q-pa-xl">
      <div class="row form-row">
        <div class="col-2 label">Assignee</div>
        <div class="col-10">
          <span v-if="taskItem.status.value === 'done'">{{ taskItem.assignee.label }}</span>
          <q-select
            v-else
            v-model="taskItem.assignee"
            outlined
            :options="staffOptions"
            use-input
            input-debounce="0"
            stack-label
            filter
            @filter="staffFilterFn"
            @focus="editAssignee = true"
            @blur="editAssignee = false"
          />
        </div>
      </div>
      <div class="row form-row">
        <div class="col-2 label">Priority</div>
        <div class="col-10">{{ taskItem.priority.label }}</div>
      </div>
      <div class="row form-row">
        <div class="col-2 label">Created</div>
        <div class="col-10">{{ taskItem.createdAt }}</div>
      </div>
      <div class="row form-row">
        <div class="col-2 label">Created by</div>
        <div class="col-10">{{ taskItem.createdBy.label }}</div>
      </div>
      <div class="row form-row">
        <div class="col-2 label">Patient</div>
        <div class="col-10">
          <q-btn
            class="bt-link"
            flat
            color="primary"
            :label="taskItem.patient.label"
            :to="`/patient/patient/care-record/${taskItem.patient.value}`"
            size="12px"
          />
        </div>
      </div>
      <div class="row form-row">
        <div class="col-2 label">Description</div>
        <div class="col-10">{{ taskItem.description }}</div>
      </div>
      <div v-if="taskItem.status.value === 'todo'" class="row" style="margin-top: 30px">
        <q-space />
        <q-btn label="Mark as done" type="button" color="primary" @click="markAsDone()" />
      </div>
    </div>
  </div>
</template>

<script>
import { QBadge, QBtn, QSelect, QSpace } from "quasar";
export default {
  name: "MContactPatientTask",
  components: {
    QBtn,
    QSpace,
    QSelect,
    QBadge,
  },

  props: {
    taskItem: {
      type: Object,
      default: null,
    },
    staff: {
      type: Array,
      default: null,
    },
  },

  data: () => ({
    staffOptions: [],
    editAssignee: false,
  }),

  computed: {
    assigneeId() {
      return this.taskItem.assignee.value;
    },
  },

  watch: {
    assigneeId(assigneeId) {
      if (this.editAssignee) {
        this.$axios
          .post("/communication/command/tasks/contact-patient-edit-assignee", {
            assignee: assigneeId,
            contactPatientTaskId: this.taskItem.id,
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
  },

  methods: {
    staffFilterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.staffOptions = this.staff.filter((v) => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },

    markAsDone() {
      this.taskItem.status = {
        value: "done",
        label: "Done",
      };
      this.updateStatus("done");
    },

    markAsTodo() {
      this.taskItem.status = {
        value: "todo",
        label: "To do",
      };
      this.updateStatus("todo");
    },

    updateStatus(status) {
      this.$axios
        .post("/communication/command/tasks/contact-patient-edit-status", {
          status: status,
          contactPatientTaskId: this.taskItem.id,
        })
        .then(function () {
          self.$dispatch("practiceSubNavReady", true);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.title {
  border-bottom: 1px solid var(--border-colour);
}
.label {
  color: grey;
}
.form-row {
  margin-bottom: 15px;
}
.bt-link {
  margin-left: -15px;
}
.patient-contacted {
  background-color: #cccccc;
  color: black;
  padding: 20px;
  width: 100%;
}
.float-right {
  float: right;
}
</style>
