<script lang="ts" setup>
import { useVModel } from "vue-composable";
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import { MenuType } from "./../SidebarMenu";
import * as Menu from "./Menu";

const props = defineProps<{
  modelValue: MenuType | null;
}>();

defineEmits({
  "update:modelValue": (_value: MenuType | null) => true,
});

const value = useVModel(props, "modelValue");

const router = useRouter();

function close() {
  value.value = null;
}
router.beforeEach(close);
onBeforeRouteUpdate(close);

const menus: Record<MenuType, any> = {
  menu: Menu.AppMenu,
  actions: Menu.ActionsMenu,
  user: Menu.UserMenu,
};
</script>
<template>
  <suspense v-if="modelValue">
    <component :is="menus[modelValue]" @close="close" />
  </suspense>
</template>
