import _axios from "axios";
import { withDefaultMode } from "bytes-iec";
import { Ref, markRaw, reactive, ref } from "vue";
import { NO_OP, useEvent } from "vue-composable";
// @ts-expect-error otherwise vitest complains
import accept from "attr-accept/src/index";
import { debounce } from "quasar";
import { useAuth } from "../store";
import axios from "../utils/axios";

const bytes = withDefaultMode("jedec");

export interface MedicusAttachment {
  // type: 'string'; // comment | attachment
  id: string;
  // comment: string;
  fileName: string;
  fileType: string;
  createdDateTime: Date;
  createdBy: string;
  createdAt: Date;

  createdByUserId: string;
}

export function useFileUploader(
  value: Ref<any | any[]>,
  maxSize: Ref<string>,
  beforeUpload: Ref<AnyFunction | undefined>,
  deleteItem: Ref<Function | undefined>,
  multiple: Ref<boolean>,
  uploadUrl: Ref<string | undefined>,
  { emit }: { emit: Function },
  accepts: Ref<string>,
) {
  const auth = useAuth();

  const input = ref<HTMLInputElement>();
  const dragging = ref<boolean>();
  const dragover = ref<boolean>();
  const stopDragging = debounce(() => {
    dragging.value = false;
  }, 100);

  useEvent(window.document, "dragover", (e) => {
    if (!e.dataTransfer?.types.length) return;
    dragging.value = true;
    stopDragging.cancel();
  });
  useEvent(window.document, "dragleave", stopDragging);

  useEvent(window.document, "dragover", (e) => {
    e.preventDefault();
    dragover.value = true;

    // @ts-expect-error
    const isInput = e.target === input.value || input.value?.contains(e.target!);
    if (e.dataTransfer && !isInput) e.dataTransfer.dropEffect = "none";
  });
  useEvent(input, "dragleave", () => (dragover.value = false));

  async function uploadFile(e: File) {
    const beforeEach = beforeUpload.value || NO_OP;
    const createdBy = auth.config?.staff.name;

    let canceller: undefined | (() => void) = undefined;
    const cancel = () => {
      canceller?.();
      // @ts-expect-error
      file.__uploading = undefined;
      // @ts-expect-error
      onDeleteItem(file);
    };

    const file = reactive({
      id: undefined,
      fileName: e.name,
      fileType: e.type || e.name.split(".").slice(-1)[0],
      createdBy,
      createdDateTime: undefined,
      type: "attachment",

      __file: markRaw(e),

      __key: Date.now(),
      __uploading: {
        error: undefined,
        local: true,
        progress: 0,
        cancel,
      },
    });

    if (maxSize.value) {
      //@ts-expect-error
      const maxSizeBytes = bytes(maxSize.value) as number;
      if (e.size >= maxSizeBytes) {
        //@ts-expect-error
        file.__uploading.error = { size: true };
      }
    }

    if (accepts.value && !accept(e, accepts.value)) {
      //@ts-expect-error
      file.__uploading.error = {
        extension: true,
      };
    }

    if (multiple.value) {
      if (!value.value) {
        value.value = [];
      }
      value.value.push(file);
      emit("input", value.value);
      emit("update:modelValue", value.value);
    } else {
      value.value = file;
      emit("input", file);
      emit("update:modelValue", file);
    }

    if (file.__uploading.error) {
      return;
    }
    if (uploadUrl.value) {
      file.__uploading.local = false;

      const cancelToken = new _axios.CancelToken((c) => {
        canceller = c;
      });

      const formData = new FormData();
      formData.append("fileName", file.fileName);
      formData.append("fileType", file.fileType);
      formData.append("data", e);

      await beforeEach(formData);

      await axios
        .post(uploadUrl.value!, formData, {
          cancelToken,
          onUploadProgress({ loaded, total }) {
            file.__uploading.progress = (loaded * 100) / total;
          },
        })
        .then((x: any) => {
          //@ts-expect-error
          file.createdDateTime = new Date();
          file.id = x.data.id || x.data.eReferralLetterAttachmentId || Object.entries(x.data)[0][1];

          emit("uploaded", {
            item: file,
            data: x.data,
          });

          //@ts-expect-error
          file.__uploading = undefined;
        })
        .catch((error: any) => {
          file.__uploading.error = error;
          file.__uploading.progress = -1;

          emit("error", {
            item: file,
            error,
          });
        });
    } else {
      canceller = () => {
        if (multiple.value) {
          value.value.splice(value.value.indexOf(file), 1);
        } else {
          value.value = undefined;
        }
      };
    }
  }

  const dropped = ref(false);

  function onInputFile(files: FileList) {
    dragging.value = false;
    const len = files.length;
    if (!len) return;
    for (let i = 0; i < len; ++i) {
      const file = files.item ? files.item(i) : files[i];
      if (!file) continue;
      uploadFile(file);
    }
  }

  useEvent(input, "drop", (e) => {
    e.preventDefault();
    dropped.value = true;
    try {
      dragging.value = false;
      dragover.value = false;
      const len = e.dataTransfer?.files.length;
      if (!len) return;

      onInputFile(e.dataTransfer!.files);
    } finally {
      setTimeout(() => {
        dropped.value = false;
      }, 10);
    }
  });

  async function onDeleteItem(
    file: MedicusAttachment & {
      __uploading?: { error: any; progress: number; cancel: () => void };
    },
  ) {
    if (file.__uploading?.cancel) {
      file.__uploading.cancel();
    } else if (deleteItem.value) {
      if ((await deleteItem.value(file)) === false) {
        return;
      }
    }

    if (multiple.value) {
      const v: Array<any> = value.value;
      const i = v.indexOf(file);
      if (~i) {
        v.splice(i, 1);
      }
      emit("input", v);
      emit("update:modelValue", v);
    } else {
      emit("input", null);
      emit("update:modelValue", null);
      value.value = null;
    }
  }

  return {
    input,

    dragging,
    dragover,
    dropped,

    onInputFile,

    onDeleteItem,
  };
}
