<script setup lang="ts">
import { computed } from "vue";
import { QIcon } from "quasar";
import MBadge from "./../MBadge";
import MAvatar from "./../MAvatar";
import MLayoutStack from "../MLayoutStack/MLayoutStack.vue";
import { formatDate, formatDateTime } from "../../../utils/date";

const props = defineProps<{
  name?: string;
  description?: string;
  footer?: {
    text?: string;
    date?: string;
    dateTime?: string;
  };
  assignee?: {
    name: string;
    initials: string;
    photoUrl: string;
    type: string;
  };
  photoUrl?: string;
  badge?: string | { text: string };
  highPriority?: boolean;
  selected?: boolean;
  isOverdue: boolean;
}>();

const footerText = computed(() => {
  const { footer } = props;
  if (!footer) return undefined;

  const date = footer.dateTime
    ? formatDateTime(footer.dateTime)
    : footer.date
    ? formatDate(footer.date)
    : undefined;

  return [footer.text, date].filter(Boolean).join(" ");
});
</script>
<template>
  <div class="m-task-list-item" :class="{ selected }" role="button" @click="$emit('click')">
    <m-layout-stack class="overflow-hidden" full gap="1">
      <div class="flex justify-between">
        <span class="name">{{ name }}</span>
        <m-badge v-if="badge && badge.text" v-bind="badge" />
        <m-badge v-else-if="badge" :text="badge" />
      </div>
      <div v-if="description" class="description" v-text="description" />
      <slot v-bind="$props" />

      <span v-if="footerText">{{ footerText }}</span>
    </m-layout-stack>

    <m-layout-stack>
      <m-avatar
        v-if="assignee !== undefined"
        :name="assignee?.name"
        :initials="assignee?.initials"
        :photo-url="assignee?.photoUrl || photoUrl"
        :workspace="assignee?.type === 'team'"
        data-testid="assignee-avatar"
        size="30px"
      />
      <m-layout-stack horizontal class="badges-container" gap="0">
        <div
          v-if="highPriority"
          class="high-priority"
          aria-label="High Priority Task"
          aria-hidden="false"
        >
          <q-icon name="fa-solid fa-triangle-exclamation" data-testid="high-priority" />
        </div>
        <div v-if="isOverdue" class="overdue" aria-label="Overdue Task" aria-hidden="false">
          <q-icon name="fa-regular fa-alarm-exclamation" data-testid="overdue" />
        </div>
      </m-layout-stack>
    </m-layout-stack>
  </div>
</template>
<style lang="scss">
.m-task-list-item {
  padding: 10px 8px 8px 12px;
  border: 1px solid var(--border-colour);
  border-right: none;
  border-top: none;
  border-left: none;
  font-size: 14px;
  line-height: 150%;
  color: var(--grey-darkest);

  display: flex;

  .medicus-avatar {
    flex: 0 0 auto;

    align-self: flex-end;
  }
  &:hover {
    cursor: pointer;
    background: #ebedef;
  }
  &.selected {
    background: var(--grey);
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    // margin-bottom: 3px;
    color: var(--text-color);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    margin-bottom: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;

    color: var(--grey-darkest);
  }
  .badges-container {
    align-items: end;

    .overdue,
    .high-priority {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 16px;
      color: var(--grey-darkest);
    }
  }
}
</style>
