<script lang="ts" setup>
import { computed } from "@vue/reactivity";
import { reactive, ref } from "vue";
import MButton from "../components/medicus/MButton";
import MInput from "../components/medicus/inputs/MInput";
import { router } from "../router";
import { useSetupAccount } from "../store/resetPassword";
import MBanner from "../components/medicus/MBanner";

const store = useSetupAccount();

const showPassword = reactive({
  pw: false,
  pw2: false,
});

const passwordMismatch = ref(false);

const errors = computed<string[]>(() =>
  Object.values(
    (store.validatePassword.error?.response?.data?.errors as Record<string, string>) ?? {},
  ).flat(),
);

const isLoading = computed(() => store.validateResetKey.loading || store.validatePassword.loading);

async function doResetPassword() {
  passwordMismatch.value = false;
  const pw = store.password;
  const pw2 = store.passwordConfirm;

  if (pw !== pw2 || pw.length === 0) {
    passwordMismatch.value = true;
    return;
  }

  try {
    const { status } = await store.validatePassword.exec();
    if (status === 200) {
      router.push({
        name: "reset-password-otp",
        params: {
          resetKey: store.resetKey,
        },
      });
    }
  } catch {
    //empty
  }
}

if (store.resetKey) {
  store.validateResetKey.exec();
}
</script>
<template>
  <m-banner v-if="!store.resetKey" type="danger" data-cy="noResetKeyError" class="q-mb-lg">
    The change password token is not recognised. Please
    <router-link class="q-mb-lg" :to="{ name: 'forgot-password' }">request a new one</router-link>.
  </m-banner>
  <div v-else title="Change Password">
    <m-banner v-if="passwordMismatch" type="danger" data-cy="passwordMismatchError" class="q-mb-lg">
      Passwords do not match
    </m-banner>
    <m-input
      v-model="store.password"
      outlined
      label="Password"
      autocomplete="new-password"
      :type="showPassword.pw ? 'text' : 'password'"
      class="q-pb-sm"
      color="primary"
      data-cy="passwordField"
      placeholder=""
    >
      <template #append>
        <m-button
          :icon="showPassword.pw ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
          color="secondary"
          ghost
          icon-only
          :label="showPassword.pw ? 'Hide password' : 'Show password'"
          @click="showPassword.pw = !showPassword.pw"
        />
      </template>
    </m-input>
    <m-input
      v-model="store.passwordConfirm"
      outlined
      label="Confirm password"
      :type="showPassword.pw2 ? 'text' : 'password'"
      class="q-pb-sm"
      color="primary"
      data-cy="passwordField"
      autocomplete="new-password"
      placeholder=""
    >
      <template #append>
        <m-button
          :icon="showPassword.pw2 ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
          color="secondary"
          ghost
          icon-only
          :label="showPassword.pw2 ? 'Hide password' : 'Show password'"
          @click="showPassword.pw2 = !showPassword.pw2"
        />
      </template>
    </m-input>

    <div v-if="errors" type="error" class="error-text q-mb-sm">
      <p v-for="errorText in errors" :key="errorText">{{ errorText }}</p>
    </div>

    <m-banner type="info" class="q-mb-sm">
      <strong>Secure password tip: </strong> The National Cyber Security Centre advises creating a
      password using
      <a
        href="https://www.ncsc.gov.uk/blog-post/three-random-words-or-thinkrandom-0"
        target="_blank"
        >three random words</a
      >.
    </m-banner>

    <m-button
      class="float-right login-button w-full"
      data-cy="changePasswordButton"
      :disable="isLoading.value"
      @click="doResetPassword"
    >
      Continue
    </m-button>
  </div>
</template>

<style lang="scss" scoped>
.login-link {
  display: block;

  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 150% */

  text-align: right;
  letter-spacing: 0.2px;
  text-decoration-line: underline;

  color: var(--text-color);
}
</style>
