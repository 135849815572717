<script setup lang="ts">
import { computed, warn, watchEffect } from "vue";
import { RouteLocationRaw } from "vue-router";
import { DrawerUrlOptions } from "../../../../store/drawers";
import MButton from "../../MButton";
import MBadge from "../../MBadge";
import PropertyRowRender from "./PropertyRowRender";
import { usePermission } from "../../../../composables/permission";

const props = defineProps<{
  label?: string;
  value?: any;
  emptyValueText?: string;
  to?: RouteLocationRaw;
  toModal?: string | DrawerUrlOptions | object | false;
  toSlideover?: string | DrawerUrlOptions | object | false;
  srLabel?: string;

  icon?: string;
  iconClass?: any;

  onClick?: Function;

  showVerticalLine?: boolean;

  badge?:
    | string
    | InstanceType<typeof MBadge>["$props"]
    | Array<string | InstanceType<typeof MBadge>["$props"]>;
}>();

const sanatisedBadges = computed(() =>
  Array.isArray(props.badge) ? props.badge.filter(Boolean) : props.badge,
);

const { hasLink, disabled, onClick } = usePermission(props, true);

// eslint-disable-next-line no-undef
if (IS_APP && (import.meta.env.DEV || location.host.startsWith("uat."))) {
  watchEffect(() => {
    if (props.srLabel) return;
    if (hasLink.value) {
      warn('This Property Row is not accessible, please pass "sr-label" property');
    }
  });
}
</script>
<template>
  <div
    class="property-row"
    :class="{
      'property-row--hover': hasLink,
      'vertical-line': showVerticalLine,
    }"
    :disable="disabled"
    @click="onClick"
  >
    <property-row-render :to="undefined">
      <div v-if="label || $slots.label" class="property-row--label">
        <slot name="label">
          <span v-if="label">{{ label }}</span>
        </slot>
      </div>
      <dd
        v-if="(value === undefined || value === null) && (!$slots.default || value === null)"
        class="property-row--empty-value"
      >
        {{ emptyValueText || "None recorded" }}
      </dd>
      <dd v-else class="property-row--value">
        <slot>
          {{ value }}
        </slot>
      </dd>

      <dd
        v-if="
          sanatisedBadges &&
          (typeof sanatisedBadges === 'string' ||
            (Array.isArray(sanatisedBadges) && sanatisedBadges.length > 0) ||
            (!Array.isArray(sanatisedBadges) && typeof sanatisedBadges === 'object'))
        "
        class="property-row--badge"
        gap="1"
        horizontal
      >
        <slot name="badge">
          <template v-if="Array.isArray(sanatisedBadges)">
            <m-badge
              v-for="(b, index) in sanatisedBadges"
              :key="`badge-${index}`"
              v-bind="typeof b === 'string' ? { text: b } : b"
            />
          </template>
          <m-badge
            v-else
            v-bind="
              typeof sanatisedBadges === 'string' ? { text: sanatisedBadges } : sanatisedBadges
            "
          />
        </slot>
      </dd>
    </property-row-render>

    <slot name="action">
      <div v-if="to || onClick" class="property-row--action">
        <m-button
          class="property-row--action"
          :icon="icon || 'fa-light fa-chevron-right'"
          :label="srLabel || label || value"
          icon-only
          ghost
          @click="onClick"
        />
      </div>
    </slot>
  </div>
</template>
<style lang="scss">
.property-row {
  display: flex;
  flex-direction: row;

  &.property-row--hover {
    cursor: pointer;
    text-decoration: none;
    color: var(--text-color);

    > .property-row-description > a {
      text-decoration: none;
      color: var(--text-color);

      flex: 1 1 auto;

      // fix padding
      padding: 7px 0;
      margin: -7px 0;
    }

    &:hover {
      background-color: #ebedef;

      .action {
        background: none;
        color: #003078;
      }
    }
  }

  &.vertical-line {
    position: relative;
    padding-left: 25px !important;

    .property-row-description {
      gap: 0;
      .property-row--label {
        width: auto;
        text-align: left;
      }

      flex-direction: column;
    }

    &::before {
      content: " ";
      position: absolute;
      top: 5px;
      left: 15px;
      height: calc(100% - 10px);
      width: 3px;
      background-color: var(--status-blue);
    }
  }
}
</style>
