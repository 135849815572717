<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View prescription"
    @click="prescriptionOverview"
  >
    <PrescriptionJournalElement
      :item="entry.data"
      :read-only="readOnly"
      :clickable="clickable"
      :can-open-prescription-overview="true"
    />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import PrescriptionJournalElement from "../elements/PrescriptionJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function prescriptionOverview() {
  return props.readOnly
    ? openSlideover("/clinical/prescription/overview/" + props.entry.id, { close: true })
    : undefined;
}
</script>
