<script setup lang="ts">
import MAppointmentDiary from "./MAppointmentDiary.vue";
import { AppointmentDiary } from "./types.js";

const diary: AppointmentDiary = {
  scheduleType: "diary",
  id: "01924c8d-e5bb-70eb-89a6-39ef046893c3",
  startDateTime: "2024-10-07 12:00:00",
  endDateTime: "2024-10-07 14:00:00",
  summary: {
    site: {
      id: "01924c8d-e55b-7383-9d18-a88be9403226",
      name: "Andover Site",
    },
    room: null,
    service: {
      id: "01924c8d-e55d-7377-9a8f-67dbe5295c02",
      name: "GP Home Visits",
    },
    status: {
      value: "active",
      label: "Active",
      isActive: true,
      isCancelled: false,
    },
    startDateTime: "2024-10-07 12:00:00",
    endDateTime: "2024-10-07 14:00:00",
    defaultDeliveryMode: {
      value: "home-visit",
      label: "Home visit",
      isFaceToFace: false,
      isHomeVisit: true,
      isPhone: false,
      isVideo: false,
    },
    usualAppointmentDuration: 30,
    assignees: [],
    booked: {
      count: 1,
      duration: "30 mins",
    },
    available: {
      count: 0,
      duration: "1 hr 30 mins",
    },
    additionalInformation: [],
    defaultAppointmentType: {
      id: "01924c8d-e5bb-70eb-89a6-39ef0419786a",
      appointmentService: {
        id: "01924c8d-e55d-7377-9a8f-67dbe5295c02",
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Home Visits",
        patientFacingName: null,
        appointmentTypes: [],
      },
      status: {
        value: "active",
        label: "Active",
        isActive: true,
        isArchived: false,
      },
      name: "GP Home Visits Default",
      patientFacingName: "Appointment",
      patientFacingOrInternalName: "Appointment",
      defaultDeliveryMode: {
        value: "home-visit",
        label: "Home visit",
        isFaceToFace: false,
        isHomeVisit: true,
        isPhone: false,
        isVideo: false,
      },
      defaultUsualAppointmentDuration: 30,
      defaultNHSNationalSlotTypeCategory: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      internalBookingOpensHoursBeforeStartTime: null,
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      patientFacingServicesBookingOpensHoursBeforeStartTime: null,
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      gpConnectBookingOpensHoursBeforeStartTime: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
      availableProcedures: [],
    },
    nhsNationalSlotTypeCategoryDefault: {
      value: "30101",
      label: "General Consultation Acute",
      isActive: true,
      isCareRelatedEncounter: true,
    },
    internalBookingOpensDateTime: null,
    patientFacingServicesAvailability: null,
    patientFacingServicesBookingOpensDateTime: null,
    canBeCancelled: false,
    supportsTimedAppointments: true,
    supportsUntimedAppointments: true,
    canBookUntimedAppointments: true,
    isAtCapacity: false,
    hasHighPriorityUntimedAppointments: false,
  },
  entries: [
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5bb-70eb-89a6-39ef046893c3",
      defaultDeliveryMode: {
        value: "home-visit",
        label: "Home visit",
        isFaceToFace: false,
        isHomeVisit: true,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 12:00:00",
      endDateTime: "2024-10-07 12:30:00",
      diaryStartDateTime: "2024-10-07 12:00:00",
      diaryEndDateTime: "2024-10-07 14:00:00",
      duration: 30,
      appointmentType: {
        id: "01924c8d-e5bb-70eb-89a6-39ef0419786a",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe5295c02",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Home Visits",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Home Visits Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "home-visit",
          label: "Home visit",
          isFaceToFace: false,
          isHomeVisit: true,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 30,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 30,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Home Visits",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5bb-70eb-89a6-39ef046893c3",
      defaultDeliveryMode: {
        value: "home-visit",
        label: "Home visit",
        isFaceToFace: false,
        isHomeVisit: true,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 12:30:00",
      endDateTime: "2024-10-07 13:00:00",
      diaryStartDateTime: "2024-10-07 12:00:00",
      diaryEndDateTime: "2024-10-07 14:00:00",
      duration: 30,
      appointmentType: {
        id: "01924c8d-e5bb-70eb-89a6-39ef0419786a",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe5295c02",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Home Visits",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Home Visits Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "home-visit",
          label: "Home visit",
          isFaceToFace: false,
          isHomeVisit: true,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 30,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 30,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Home Visits",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5bb-70eb-89a6-39ef046893c3",
      defaultDeliveryMode: {
        value: "home-visit",
        label: "Home visit",
        isFaceToFace: false,
        isHomeVisit: true,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 13:00:00",
      endDateTime: "2024-10-07 13:30:00",
      diaryStartDateTime: "2024-10-07 12:00:00",
      diaryEndDateTime: "2024-10-07 14:00:00",
      duration: 30,
      appointmentType: {
        id: "01924c8d-e5bb-70eb-89a6-39ef0419786a",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe5295c02",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Home Visits",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Home Visits Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "home-visit",
          label: "Home visit",
          isFaceToFace: false,
          isHomeVisit: true,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 30,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 30,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Home Visits",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e5bb-70eb-89a6-39ef046893c3",
      defaultDeliveryMode: {
        value: "home-visit",
        label: "Home visit",
        isFaceToFace: false,
        isHomeVisit: true,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 13:30:00",
      endDateTime: "2024-10-07 14:00:00",
      diaryStartDateTime: "2024-10-07 12:00:00",
      diaryEndDateTime: "2024-10-07 14:00:00",
      duration: 30,
      appointmentType: {
        id: "01924c8d-e5bb-70eb-89a6-39ef0419786a",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe5295c02",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Home Visits",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Home Visits Default",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "home-visit",
          label: "Home visit",
          isFaceToFace: false,
          isHomeVisit: true,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 30,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 30,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Home Visits",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
  ],
  untimedAppointments: [
    {
      isLocalAppointment: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      id: "0192665e-c35b-7052-93f8-bfbbb1328509",
      versionId: "4fca7ffc319207fd3d2fa82fe0aeeb67",
      overlapsWithOtherAppointments: false,
      patient: {
        id: "01924c8e-0ccb-729e-9083-f0378170a47d",
        name: "Miss Sienna Shaw",
        firstNames: "Miss Sienna",
        familyName: "Shaw",
        officialDisplayName: "Miss Sienna Shaw",
        nameParts: {
          prefix: "Miss",
          givenName: "Sienna",
          middleNames: "Keeley",
          familyName: "Shaw",
          suffix: "V",
        },
        preferredName: null,
        nhsNumber: "9449303789",
        dateOfBirth: "1973-03-20",
        homePhoneNumber: null,
        mobilePhoneNumber: "+441455659547",
        homeAddress: "85 Johnson Port, Johnson Crescent, Lisaburgh, Clackmannanshire, G83 0SH, GBR",
        emailAddress: "palmer.megan@hotmail.com",
        deceased: false,
        risks: [],
      },
      type: {
        value: "untimed",
        label: "Untimed",
        isTimed: false,
        isUntimed: true,
      },
      slotType: {
        value: "appointment",
        label: "Appointment",
        isAppointment: true,
        isSlot: false,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      isHighPriority: false,
      isHiddenFromPatientFacingServices: false,
      serviceName: "GP Home Visits",
      intendedOrContainerStartDateTime: "2024-10-07 12:00:00",
      intendedOrContainerEndDateTime: "2024-10-07 14:00:00",
      startDateTime: null,
      endDateTime: null,
      duration: 30,
      queueStartDateTime: null,
      queueEndDateTime: null,
      diaryStartDateTime: "2024-10-07 12:00:00",
      diaryEndDateTime: "2024-10-07 14:00:00",
      assignees: null,
      location: "Andover Site",
      reasonForAppointment: null,
      additionalInformation: null,
      patientSuppliedInformationForClinician: null,
      compiledReasonForAppointment: "No reason given.",
      displayStatus: {
        value: "booked",
        label: "Booked",
        isLate: false,
        isArrived: false,
        isCalledIn: false,
        isBooked: true,
        isDidNotAttend: false,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
      },
      createdDateTime: "2024-10-07 10:45:39",
      deliveryMode: {
        value: "home-visit",
        label: "Home visit",
        isFaceToFace: false,
        isHomeVisit: true,
        isPhone: false,
        isVideo: false,
      },
      appointmentStatus: {
        value: "pending",
        label: "Pending",
        isPending: true,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
        isDidNotAttend: false,
      },
      arrivalStatus: null,
      virtualMeetingRoomId: null,
      encounterId: null,
      encounterIsDraft: null,
      isPending: true,
      formattedTimesAndDuration: "30 mins",
      formattedDateTimeRange: "Mon 07 Oct 2024, 12:00 - 14:00",
      cancelledDateTime: null,
      rescheduledDateTime: null,
      cancellationReason: null,
      diaryId: "01924c8d-e5bb-70eb-89a6-39ef046893c3",
      queueId: null,
    },
  ],
};
</script>

<template>
  <MAppointmentDiary
    :diary="diary"
    heading="Minor Illness Clinic"
    slot-label="book"
    show-diary-controls
  />
</template>
