<template>
  <button
    :sr-label="renderSrLabel(entry)"
    :onClick="!eventsMap[entry.entryType] ? undefined : () => onClick(entry)"
    class="structured-entry"
    tabindex="0"
    :class="{
      'has-info': entry.hasOwnProperty('createdDateTime'),
      'marked-incorrect': entry.isMarkedAsIncorrect,
    }"
  >
    <p class="heading">
      <span>{{ structuredEntry.heading }}{{ structuredEntry.subheading ? ": " : null }}</span>
      <span v-if="structuredEntry.subheading?.length > 0" class="subheading">
        <template v-if="Array.isArray(structuredEntry.subheading)">
          <span
            v-for="subheadingItem in structuredEntry.subheading
              .filter((subheadingItem) => subheadingItem)
              .join(' • ')"
            :key="subheadingItem"
            class="subheading-item"
            >{{ subheadingItem }}</span
          >
        </template>
        <template v-else>{{ structuredEntry.subheading }}</template>
      </span>
      <template v-if="structuredEntry.metadata?.length > 0">
        <span class="metadata">
          <span v-for="metadataItem in structuredEntry.metadata" :key="metadataItem">{{
            metadataItem
          }}</span>
        </span>
      </template>
    </p>
  </button>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import {
  AllergyEntry,
  Entry,
  EntryType,
  ObservationEntry,
  PrescribedElsewhereEntry,
  PrescriptionEntry,
  OverTheCounterEntry,
  FutureActionEntry,
  FitNoteEntry,
  ImmunisationEntry,
  ProcedureEntry,
  CommunicationEntry,
  OutboundReferralEntry,
  SortOrder,
  InvestigationRequestEntry,
} from "../MConsultationTopicHeading/types";
import { openModal, openSlideover } from "../../../../composables/dialog/drawer";
import { DrawerOptions } from "../../../../store/drawers";
import { MedicationAdministrationEntry } from "../MConsultationTopicHeading/types";

const props = defineProps<{
  entry: Entry;
  draft: boolean;
  contextHiddenFps: boolean;
  contextConfidentialFtp: boolean;
  sortOrderHash: string;
  sortOrder: SortOrder;
  disabled: boolean;
}>();

const emit = defineEmits({
  updated: (_sortOrderHash?: string) => true,
});

export type StructuredEntry = {
  heading: string;
  subheading?: string | string[];
  metadata?: string[];
};

const structuredEntry = ref<StructuredEntry | null>(null);
const LABEL_HIDDEN_FROM_PFS: string = "Redacted from patient online access";
const LABEL_CONFIDENTIAL_FROM_THIRD_PARTIES: string = "Confidential from Third Parties";
const LABEL_EXPLICITLY_INCLUDED_IN_SCR: string = "Explicitly included in SCR";
const BASE_STRUCTURED_ENTRY: StructuredEntry = {
  heading: "",
  metadata: [],
};

onBeforeMount(() => {
  structuredEntry.value = getStructuredEntry(props.entry);
});

function getStructuredEntry(entry: Entry): StructuredEntry {
  let newStructuredEntry: StructuredEntry | null = null;

  switch (entry.entryType) {
    case EntryType.ALLERGY:
      newStructuredEntry = getAllergyEntry(entry);
      break;
    case EntryType.OBSERVATION:
      newStructuredEntry = getObservationEntry(entry);
      break;
    case EntryType.PRESCRIPTION:
      newStructuredEntry = getPrescriptionEntry(entry);
      break;
    case EntryType.MEDICATION_STATEMENT_PRESCRIBED_ELSEWHERE:
      newStructuredEntry = getMedicationPrescribedElsewhereEntry(entry);
      break;
    case EntryType.MEDICATION_STATEMENT_OVER_THE_COUNTER:
      newStructuredEntry = getMedicationOverTheCounterEntry(entry);
      break;
    case EntryType.FUTURE_ACTION:
      newStructuredEntry = getFutureActionEntry(entry);
      break;
    case EntryType.FIT_NOTE:
      newStructuredEntry = getFitNoteEntry(entry);
      break;
    case EntryType.IMMUNISATION:
      newStructuredEntry = getImmunisationEntry(entry);
      break;
    case EntryType.MEDICATION_ADMINISTRATION:
      newStructuredEntry = getDrugDeviceAdministeredEntry(entry);
      break;
    case EntryType.PROCEDURE:
      newStructuredEntry = getProcedureEntry(entry);
      break;
    case EntryType.COMMUNICATION:
      newStructuredEntry = getCommunicationEntry(entry);
      break;
    case EntryType.OUTBOUND_REFERRAL:
      newStructuredEntry = getOutboundReferralEntry(entry);
      break;
    case EntryType.INVESTIGATION_REQUEST:
      newStructuredEntry = getInvestigationRequestEntry(entry);
      break;
    default:
      newStructuredEntry = { ...BASE_STRUCTURED_ENTRY, heading: "Unhandled entry type" };
  }

  const genericMetadata = getGenericMetataForEntry(newStructuredEntry, entry);
  if (genericMetadata?.length > 0) newStructuredEntry.metadata.push(...genericMetadata);

  return newStructuredEntry;
}

function getAllergyEntry(entry: AllergyEntry): StructuredEntry {
  const newStructuredEntry: StructuredEntry = { ...BASE_STRUCTURED_ENTRY };

  if (entry.substanceDescription) {
    newStructuredEntry.heading = `Allergy: ${entry.substanceDescription}`;
  } else {
    newStructuredEntry.heading = entry.allergyCodeDescription;
  }

  if (entry.severity) newStructuredEntry.metadata.push(entry.severity);
  if (entry.certainty) newStructuredEntry.metadata.push(entry.certainty);

  return newStructuredEntry;
}
function getInvestigationRequestEntry(entry: InvestigationRequestEntry): StructuredEntry {
  const newStructuredEntry: StructuredEntry = { ...BASE_STRUCTURED_ENTRY };

  if (entry.investigationRequestItems) {
    newStructuredEntry.heading = `Investigation request: ${entry.investigationRequestItems.join(
      " • ",
    )}`;
  } else {
    newStructuredEntry.heading = `Undefined`;
  }

  return newStructuredEntry;
}

function getObservationEntry(entry: ObservationEntry): StructuredEntry {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: entry.type,
    subheading: entry.value + (entry.approximateValue ? " (approximate)" : ""),
  };

  return newStructuredEntry;
}

function getPrescriptionEntry(entry: PrescriptionEntry) {
  const newStructuredEntry: StructuredEntry = { ...BASE_STRUCTURED_ENTRY };

  newStructuredEntry.heading = `${entry.prescriptionTypeLabel} Prescription`;

  if (entry.dosageText) {
    newStructuredEntry.subheading = `${entry.productName} • ${entry.dosageText}`;
  } else {
    newStructuredEntry.subheading = entry.productName;
  }

  if (entry.displayStatus) newStructuredEntry.metadata.push("Not Issued");

  return newStructuredEntry;
}

function getMedicationPrescribedElsewhereEntry(entry: PrescribedElsewhereEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: "Medication prescribed elsewhere",
    subheading: [entry.productName, entry.dosageInstruction, entry.prescriptionType],
  };

  return newStructuredEntry;
}

function getMedicationOverTheCounterEntry(entry: OverTheCounterEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: "Over-the-counter medication",
    subheading: [entry.productName, entry.dosageInstruction],
  };

  return newStructuredEntry;
}

function getFutureActionEntry(entry: FutureActionEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: "Future action/recall",
    subheading: [entry.clinicalCode, entry.plannedDateRange],
  };

  if (entry.displayStatus) newStructuredEntry.metadata.push(entry.displayStatus);

  return newStructuredEntry;
}

function getFitNoteEntry(entry: FitNoteEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: "Fit Note",
    subheading: entry.decision,
  };

  if (entry.validPeriod) newStructuredEntry.metadata.push(entry.validPeriod);
  if (entry.diagnosis) newStructuredEntry.metadata.push(entry.diagnosis);

  return newStructuredEntry;
}

function getImmunisationEntry(entry: ImmunisationEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: entry.code,
  };

  if (entry.administeringOrganisation)
    newStructuredEntry.metadata.push(
      `Administering organisation: ${entry.administeringOrganisation}`,
    );

  return newStructuredEntry;
}

function getDrugDeviceAdministeredEntry(entry: MedicationAdministrationEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: "Drug/device administered",
    subheading: [entry.productName, entry.quantityAdministered],
  };

  return newStructuredEntry;
}

function getProcedureEntry(entry: ProcedureEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: "Procedure",
    subheading: [entry.procedureCode, entry.body],
  };

  return newStructuredEntry;
}

function getCommunicationEntry(entry: CommunicationEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: entry.snomedCTCode,
    subheading: [entry.summary],
  };

  return newStructuredEntry;
}

function getOutboundReferralEntry(entry: OutboundReferralEntry) {
  const newStructuredEntry: StructuredEntry = {
    ...BASE_STRUCTURED_ENTRY,
    heading: `${entry.referralProvider}`,
  };
  if (entry.priority) {
    newStructuredEntry.heading += ` (${entry.priority})`;
  }
  if (entry.referralProvider) {
    newStructuredEntry.heading += ` • ${entry.referralProvider}`;
  }

  if (entry.referringClinician) {
    newStructuredEntry.metadata.push(`Made by ${entry.referringClinician}`);
  }

  if (entry.cancelledStatusLabel) {
    newStructuredEntry.metadata.push(entry.cancelledStatusLabel);
  }

  if (entry.incompleteStatusLabel) {
    newStructuredEntry.metadata.push(entry.incompleteStatusLabel);
  }

  if (entry.isExplicitlyIncludedInSCR) {
    newStructuredEntry.metadata.push(LABEL_EXPLICITLY_INCLUDED_IN_SCR);
  }

  return newStructuredEntry;
}

function getGenericMetataForEntry(structuredEntry: StructuredEntry, entry: Entry): string[] {
  const genericMetadata = [];

  if (entry.hiddenFromPatientFacingServices) {
    genericMetadata.push(LABEL_HIDDEN_FROM_PFS);
  }

  if (entry.confidentialFromThirdParties) {
    genericMetadata.push(LABEL_CONFIDENTIAL_FROM_THIRD_PARTIES);
  }

  return genericMetadata;
}

const drawerOptions = computed(() => {
  return <DrawerOptions>{
    additionalDeletePayloadData: {
      sortOrderHash: props.sortOrderHash,
      sortOrder: props.sortOrder.filter((sortOrderItem) => sortOrderItem.id !== props.entry.id),
    },
    additionalCreatePayloadData: {
      uuid: props.entry.id,
      sortOrder: props.sortOrder,
      sortOrderHash: props.sortOrderHash,
    },
    onSuccess: (responseData) => {
      if (responseData && "sortOrderHash" in responseData) {
        emit("updated", responseData.sortOrderHash);
      } else {
        emit("updated");
      }
    },
  };
});

const eventsMap = computed(() => {
  return {
    note: noteOverviewEdit,
    allergy: allergyOverviewEdit,
    "medication-statement-over-the-counter": medicationOTCOverviewEdit,
    "medication-statement-prescribed-elsewhere": medicationPEOverviewEdit,
    observation: observationOverviewEdit,
    prescription: prescriptionOverviewEdit,
    "outbound-referral": outboundReferralOverview,
    "future-action": futureActionOverviewEdit,
    "fit-note": fitNoteOverviewEdit,
    "medication-administration": medicationAdministrationOverviewEdit,
    immunisation: immunisationOverviewEdit,
    procedure: procedureOverviewEdit,
    communication: outboundCommunicationOverview,
    "investigation-request": investigationRequestOverviewEdit,
  };
});

const supplementaryLineText = (item) => {
  const txt = [];
  if (item.dosageInstructions) {
    txt.push(item.dosageInstructions);
  }
  if (item.prescriptionType) {
    txt.push(item.prescriptionType);
  }
  return txt.join(" • ");
};

function renderSrLabel(entry) {
  let label = `${props.draft ? "Edit " : "View "} `;

  switch (entry.entryType) {
    case EntryType.CARE_RECORD_NOTE:
      return `${label} Care record note: ${entry.clinicalCodeDescription ?? ""} ${
        entry.note ?? ""
      }`;
    case "allergy":
      return `${label} Allergy: ${
        entry.substanceDescription ? entry.substanceDescription : entry.allergyCodeDescription
      }`;
    case "observation":
      return `${label} Observation${
        entry.entryType || entry.value || entry.additionalInformation ? ":" : ""
      } ${entry.type ?? ""} ${entry.value ?? ""} ${entry.additionalInformation ?? ""}`;
    case "prescription":
      return `${label} Prescription: ${entry.productName} ${
        entry.numberOfIssues ? `${entry.numberOfIssues} issues ${entry.dosageText}` : ""
      }`;
    case "medication-statement-prescribed-elsewhere":
      return `${label} Medication prescribed elsewhere: ${entry.productName} ${
        entry.dosageInstruction ? supplementaryLineText(entry) : ""
      }`;
    case "medication-statement-over-the-counter":
      return `${label} Over-the-counter medication: ${entry.productName}`;
    case "follow-up-appointment":
      return `${label} Appointment: ${entry.startDateTime ?? ""} (${
        entry.deliveryMode ? `(${entry.deliveryMode})` : ""
      }) ${entry.serviceName}`;
    case "future-action":
      return `${label} Future action/recall: ${entry.clinicalCode ?? ""} ${entry.plannedDateRange}`;
    case "fit-note":
      return `${label} MED3 Fit Note: ${entry.decision}`;
    case "immunisation":
      return `${label} Immunisation: ${entry.immunisation} ${entry.quantityAdministered ?? ""} ${
        entry.productName ?? ""
      }`;
    case "medication-administration":
      return `${label} Drug/device administered: ${entry.quantityAdministered ?? ""} ${
        entry.productName ?? ""
      }`;
    case "procedure":
      return `${label} Procedure: ${entry.procedureCode}`;
    case "communication":
      return `${label} ${entry.snomedCTCode}: ${entry.summary}`;
    case "investigation":
      return `${label} Investigation: ${entry.descriptionDetails
        .map((d) => d.description)
        .join(" ")} ${entry.requesterText}`;
    case "referral-attachment":
      return; // TODO - Is this in use?
    case "outbound-referral":
      return `${label} Referral: ${entry.referralCode} ${
        entry.referralType === "nhs-e-referral"
          ? `(UBRN: ${entry.uniqueBookingReferenceNumber}) ${entry.priority} ${entry.shortlistedServices}`
          : `${entry.priority} ${entry.referralProvider}`
      }`;
    case "general-task":
      return `Miscellaneous task`;
    case "appointment-request-task":
      return `Appointment request task`;
    case "required-intervention":
      return `Required intervention`;
    case "investigation-request":
      return `Investigation request: ${entry.investigationRequestItems.join(" • ")}`;
    default:
      throw new Error(`Unknown entry type: ${entry.type}`);
  }
}

function onClick(entry) {
  // handle the click event
  const handler = entry.entryType ? eventsMap.value[entry.entryType] : eventsMap.value["note"];
  handler(entry.id, entry);
}

// care-record-note
function noteOverviewEdit(id) {
  if (props.draft) {
    openSlideover(`/clinical/note/overview/${id}`, drawerOptions.value);
  } else {
    openModal(`/clinical/note/edit-note/${id}`, drawerOptions.value);
  }
}

// allergy
function allergyOverviewEdit(id) {
  if (props.draft) {
    openModal("/clinical/allergy/edit-allergy/" + id, drawerOptions.value);
  } else {
    openSlideover("/clinical/allergy/overview-allergy/" + id, drawerOptions.value);
  }
}
function investigationRequestOverviewEdit(id) {
  if (props.draft) {
    openModal(`/clinical/investigation-request/edit/${id}`, drawerOptions.value);
  } else {
    openSlideover(`/clinical/investigation-request/overview/${id}`, drawerOptions.value);
  }
}
// medication-statement-over-the-counter
function medicationOTCOverviewEdit(id) {
  if (props.draft) {
    openModal(
      `/clinical/medication-statement/edit-over-the-counter-medication/${id}`,
      drawerOptions.value,
    );
  } else {
    openSlideover(
      `/clinical/medication-statement/overview-medication-statement-over-the-counter/${id}`,
      drawerOptions.value,
    );
  }
}

// medication-statement-prescribed-elsewhere
function medicationPEOverviewEdit(id) {
  if (props.draft) {
    openModal(
      `/clinical/medication-statement/edit-prescribed-elsewhere-medication/${id}`,
      drawerOptions.value,
    );
  } else {
    openSlideover(
      `/clinical/medication-statement/overview-medication-statement-prescribed-elsewhere/${id}`,
      drawerOptions.value,
    );
  }
}

// observation
function observationOverviewEdit(id) {
  if (props.draft) {
    openModal("/clinical/observation/edit/" + id, drawerOptions.value);
  } else {
    openSlideover("/clinical/observation/overview/" + id, drawerOptions.value);
  }
}

function prescriptionOverviewEdit(id) {
  if (props.draft) {
    openModal(`/clinical/prescription/edit/${id}`, drawerOptions.value);
  } else {
    openSlideover("/clinical/prescription/overview/" + id, drawerOptions.value);
  }
}

function outboundReferralOverview(id, entry) {
  if (entry.referralType === "manual") {
    openSlideover(`/clinical/referral/manual-referral/overview/${id}`, drawerOptions.value);
  } else if (entry.isAdviceAndGuidance === true) {
    openModal(
      `/clinical/referral/nhs-e-referral/advice-and-guidance/overview/${id}`,
      drawerOptions.value,
    );
  } else {
    openSlideover(`/clinical/referral/nhs-e-referral/overview/${id}`, drawerOptions.value);
  }
  return;
}

// Future Action
function futureActionOverviewEdit(id) {
  if (props.draft) {
    openModal(`/future-action/edit/${id}`, drawerOptions.value);
  } else {
    openSlideover(`/future-action/patient-overview/${id}`, drawerOptions.value);
  }
}

// fit-note
function fitNoteOverviewEdit(id) {
  if (props.draft) {
    openModal(`/clinical/gb/fit-note/edit/${id}`, drawerOptions.value);
  } else {
    openSlideover(`/clinical/gb/fit-note/modals/preview-fit-note/${id}`, drawerOptions.value);
  }
}

// medication-administration
function medicationAdministrationOverviewEdit(id) {
  if (props.draft) {
    openModal(`/clinical/medication-administration/edit/${id}`, drawerOptions.value);
  } else {
    openSlideover(`/clinical/medication-administration/overview/${id}`, drawerOptions.value);
  }
}

// immunisation
function immunisationOverviewEdit(id, entry) {
  if (props.draft) {
    if (entry.isPointOfCare) {
      openModal(`/clinical/immunisation/edit-immunisation/${id}`, drawerOptions.value);
    } else if (entry?.isVaccinationHistory) {
      openModal(`/clinical/immunisation/edit-historical-immunisation/${id}`, drawerOptions.value);
    }
  } else {
    openSlideover(`/clinical/immunisation/immunisation-overview/${id}`, drawerOptions.value);
  }
}

// procedure
function procedureOverviewEdit(id) {
  if (props.draft) {
    openModal("/clinical/procedure/edit-procedure/" + id, drawerOptions.value);
  } else {
    openSlideover("/clinical/procedure/overview/" + id, drawerOptions.value);
  }
}

function outboundCommunicationOverview(id) {
  openSlideover(
    `/clinical/communication/care-record-communication-overview/${id}`,
    drawerOptions.value,
  );
}
</script>
<style>
.structured-entry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  min-height: 28px;
  background: var(--grey-lightest);
  padding: 4px 6px;
  margin-left: 4px;
  border-radius: 4px;

  &:focus,
  &:hover {
    border-radius: 4px;
    background: var(--grey-light);
  }

  &:hover {
    cursor: pointer;
  }

  &:not(.has-info) {
    visibility: hidden;
  }

  .metadata {
    > span::before {
      content: "•";
      margin: 0 3px;
    }
  }
}
</style>
