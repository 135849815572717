<script setup lang="ts">
import { onMounted } from "vue";

const props = defineProps<{
  params: Record<string, any>;
}>();
onMounted(() => {});

function outOfRangeResultIcon(item) {
  let icon;
  if (item.resultLowerThanReferenceRange) {
    icon = "fa-solid fa-arrow-down";
  } else if (item.resultHigherThanReferenceRange) {
    icon = "fa-solid fa-arrow-up";
  }
  return icon;
}
function formatResultLabel(result) {
  let resultLabel = "";
  if (result.resultValue) {
    if (result.approximateResult) {
      resultLabel += "~";
    }
    if (result.resultComparator) {
      resultLabel += result.resultComparator + " ";
    }
    resultLabel += result.resultValue;
    if (result.resultUnit === "%" || result.resultUnit === "°C") {
      resultLabel += result.resultUnit;
    } else if (result.resultUnit) {
      resultLabel += " " + result.resultUnit;
    }
  } else if (result.resultCodedDescription) {
    resultLabel += (resultLabel !== "" ? "\n" : "") + result.resultCodedDescription;
  } else if (result.resultText && !result.isMultilineResult) {
    resultLabel += (resultLabel !== "" ? "\n" : "") + result.resultText;
  } else {
    resultLabel = "-";
  }
  return resultLabel;
}
</script>

<template>
  <span v-if="typeof props.params.value === 'string'" class="text-cursor">{{
    props.params.value
  }}</span>
  <m-badge v-else-if="props.params.value?.requiresUrgentReview" amber>
    <q-icon
      v-if="outOfRangeResultIcon(props.params.value)"
      :name="outOfRangeResultIcon(props.params.value)"
    />
    {{ formatResultLabel(props.params.value) }}
  </m-badge>
  <span v-else class="text-cursor">{{ formatResultLabel(props.params.value) }}</span>
</template>
