<script setup lang="ts">
import { isObject } from "@vue/shared";
import { computed, ref } from "vue";
import { useVModel } from "vue-composable";
import TransitionExpand from "../../../components/transitions/TransitionExpand.vue";
import { openModal, openSlideover } from "../../../composables/dialog/drawer";
import MButton from "../MButton";
import MCheckbox from "../inputs/MCheckbox";
import MRadio from "../inputs/MRadio";
import MAction, { ActionModelValue } from "./../MAction";
import TableCell from "./TableCell.vue";
import { Column, Item } from "./interfaces";

const props = defineProps<{
  title?: string;
  columns: Column[];
  items: Item[];
  modelValue?: Item[] | Item | Record<string, string>;
  action?: ActionModelValue;

  showSelect?: boolean;
  singleSelect?: boolean;

  showExpand?: boolean;
  singleExpand?: boolean;
  expanded?: Item[] | Item | Record<string, string>;

  flat?: boolean;

  canSelect?: (item?: Item) => boolean;
}>();

const emit = defineEmits({
  "update:modelValue": (_e: Item[] | Item | null) => true,
  "update:expanded": (_e: Item[] | Item | null) => true,
});

const gridTemplateColumns = computed(() => {
  return `${props.showSelect ? "50px" : ""} ${props.columns
    ?.map((x) => (x.width ? x.width : "minmax(150px, 1fr)"))
    .join(" ")} ${props.showExpand ? "50px" : ""}`;
});

const allSelected = computed(() => {
  if (props.modelValue?.length > 0) {
    const items = props.canSelect ? props.items.filter(props.canSelect) : props.items;
    return props.modelValue?.length === items.length ? true : undefined;
  }

  return false;
});

function isSelected(item: Item) {
  if (props.singleSelect) {
    return props.modelValue === item;
  }

  if (!Array.isArray(props.modelValue)) return false;
  return props.modelValue.indexOf(item) >= 0;
}

function onSelect(selected: boolean, item: Item) {
  const value = props.singleSelect
    ? props.modelValue
    : props.modelValue && Array.isArray(props.modelValue)
    ? props.modelValue
    : [];

  if (Array.isArray(value)) {
    const arr = [...value];
    const index = arr.indexOf(item);

    if (index >= 0) {
      arr.splice(index, 1);
    }

    if (selected) {
      arr.push(item);
    }
    emit("update:modelValue", arr);

    return;
  }
  emit("update:modelValue", selected ? item : null);
}

function onSelectAll(selectAll: boolean) {
  if (selectAll) {
    emit(
      "update:modelValue",
      props.canSelect ? props.items.filter(props.canSelect) : [...props.items],
    );
  } else {
    emit("update:modelValue", []);
  }
}

const expanded = props.expanded === undefined ? ref() : useVModel(props, "expanded");
function isExpanded(item: Item) {
  if (props.singleExpand) {
    return expanded.value === item;
  }

  if (!Array.isArray(expanded.value)) return false;
  return expanded.value.indexOf(item) >= 0;
}

function onExpand(isExpanded: boolean, item: Item) {
  const value = props.singleExpand
    ? expanded.value
    : expanded.value && Array.isArray(expanded.value)
    ? expanded.value
    : [];

  if (Array.isArray(value)) {
    const arr = [...value];
    const index = arr.indexOf(item);

    if (index >= 0) {
      arr.splice(index, 1);
    }

    if (isExpanded) {
      arr.push(item);
    }
    expanded.value = arr;

    return;
  }
  expanded.value = isExpanded ? item : null;
}

function isClickable(item: Item) {
  return !!item.to || !!item.toModal || !!item.toSlideover || props.showExpand || props.showSelect;
}
function isRouteLink(item: Item) {
  if (props.showExpand || props.showSelect) return false;
  return !!item.to;
}

function onClick(item: Item, event: MouseEvent) {
  if (props.canSelect && !props.canSelect(item)) return;
  if (props.showSelect) {
    event.preventDefault();
    return onSelect(!isSelected(item), item);
  }
  if (props.showExpand) {
    event.preventDefault();
    return onExpand(!isExpanded(item), item);
  }

  if (item.toModal) {
    const [url, options] = isObject(item.toModal)
      ? [item.toModal.url, item.toModal]
      : [item.toModal, {}];

    return openModal(url, options);
  }
  if (item.toSlideover) {
    const [url, options] = isObject(item.toSlideover)
      ? [item.toSlideover.url, item.toSlideover]
      : [item.toSlideover, {}];

    return openSlideover(url, options);
  }
}
</script>
<template>
  <div class="m-table" :class="{ 'medicus-box-shadow': !flat }">
    <div v-if="action || title" class="section-head">
      <h3 v-if="title" class="table-title">{{ title }}</h3>
      <m-action v-if="action" :model-value="action" />
    </div>

    <div class="inner-table">
      <div class="table-head">
        <div v-if="showSelect" class="table-head--checkbox">
          <m-checkbox
            v-if="!singleSelect"
            :model-value="allSelected"
            :val="true"
            @update:modelValue="onSelectAll"
          />
        </div>

        <!-- title -->
        <div v-for="col in columns" :key="`table-header-${col.title}`" class="header-cell">
          {{ col.title }}
        </div>
        <div v-if="showExpand"></div>
      </div>
      <!-- content -->
      <template v-for="(item, i) in items" :key="`table-item-${i}`">
        <div
          class="table-row"
          :class="{
            clickable: isClickable(item),
            expanded: isExpanded(item),
          }"
          @click="onClick(item, $event)"
        >
          <div v-if="showSelect" class="table-row-select">
            <div v-if="canSelect && !canSelect(item)"></div>
            <m-radio
              v-else-if="singleSelect"
              :model-value="isSelected(item)"
              :val="true"
              @update:modelValue="onSelect($event, item)"
            />
            <m-checkbox
              v-else
              :model-value="isSelected(item)"
              :val="true"
              @update:modelValue="onSelect($event, item)"
            />
          </div>
          <component
            :is="isRouteLink(item) ? 'router-link' : 'div'"
            v-for="col in columns"
            :key="`table-header-${i}-${col.title}`"
            :to="isRouteLink(item) ? item.to : undefined"
            :class="[
              {
                'table-cell': true,
              },
              col.cellClass,
            ]"
          >
            <TableCell v-bind="col" :item="item">
              <slot :name="col.name || col.title" :item="item" :index="i" />
            </TableCell>
          </component>

          <div v-if="showExpand" class="table-expand-button">
            <m-button
              :icon="isExpanded(item) ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"
              ghost
              small
              icon-only
              :label="`${isExpanded(item) ? 'Collapse' : 'Expand'}`"
              @click="onExpand(!isExpanded(item), item)"
            >
            </m-button>
          </div>
        </div>
        <TransitionExpand v-if="isExpanded(item)" name="expand-medicus">
          <div class="table-expand">
            <slot
              name="expanded-item"
              :columns="columns"
              :item="item"
              :grid-template-columns="gridTemplateColumns"
            >
            </slot>
          </div>
        </TransitionExpand>
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.m-table {
  border-radius: 4px;

  .table-head,
  .table-row {
    display: grid;
    grid-template-columns: v-bind(gridTemplateColumns);

    > div,
    > a {
      padding: 7px 24px;

      color: var(--text-color);
      text-decoration: none;
    }
  }
  .table-row {
    > div,
    a {
      border-bottom: 1px solid var(--grey-light);
      word-break: break-word;
    }
  }

  .inner-table {
    overflow: auto;
  }

  .table-head {
    > .header-cell {
      color: var(--text-color);
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      margin-top: 0;
      vertical-align: baseline;
      text-align: left;
    }
  }

  .section-head {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;

    .table-title {
      // display: flex;
      align-items: center;
      text-transform: capitalize;
      padding: 10px 15px;
    }

    .medicus-button-container {
      margin-right: 10px;
      align-self: center;
    }
  }

  .table-row {
    &.clickable {
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #ddd;
      }
    }

    &:last-of-type .table-cell {
      border-bottom: none;
    }

    &.expanded {
      position: relative;
      > .table-cell,
      > div {
        border-bottom: none;
      }

      &::before {
        content: " ";
        position: absolute;
        background-color: var(--theme-blue);
        height: 100%;
        width: 2px;
        left: 0;
        top: 0;
      }
    }
  }

  .table-expand {
    border-bottom: 1px solid var(--grey-light);
    padding: 7px 24px;
    // background-color: var(--grey-lightest);
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      background-color: var(--theme-blue);
      height: 100%;
      width: 2px;
      left: 0;
      top: 0;
    }
  }

  .table-expand-button {
    color: var(--theme-blue) !important;
  }
}
</style>
