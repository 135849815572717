<script setup lang="ts">
import { provide } from "vue";
import { randomID } from "../../../../../utils/helpers";
import MButton from "../../../../medicus/MButton";
import PatientBanner from "../../../../medicus/PatientBanner";

defineProps<{
  title?: string;
  patientId?: string;

  bgGrey?: boolean;
}>();
const emit = defineEmits({
  close: () => true,
});
function onClose() {
  emit("close");
}

const labelId = randomID("slideover-label");
provide("slideover-title", labelId);
</script>

<template>
  <div class="slideover" :aria-labelledby="labelId" role="dialog">
    <div class="slideover--header">
      <h1 :id="labelId">
        {{ title }}
      </h1>
      <div class="slideover-buttons">
        <m-button
          class="slideover--header-close-btn"
          icon="fa-light fa-xmark"
          color="secondary"
          title="Close Dialog"
          label="Close Dialog"
          icon-only
          borderless
          data-testid="modal-closeBtn"
          @click="onClose"
        />
      </div>
    </div>

    <patient-banner v-if="patientId" :patient-id="patientId" secondary blue-accent no-border />

    <div class="slideover-content">
      <slot />
    </div>
  </div>
</template>
<style lang="scss">
.slideover {
  display: grid;
  grid-template-rows: 48px auto 1fr;
  grid-template-areas:
    "header"
    "banner"
    "content";
  background: white;

  width: 575px;

  max-width: 100vw;
  height: 100%;
  top: 0;
  position: absolute;

  right: -575px;

  float: right;

  animation: slideover-animation 0.5s forwards;
  box-shadow: var(--box-shadow);

  .slideover-content {
    grid-area: content;
    overflow: auto;
    padding-bottom: 3em !important;
    padding: 15px;
  }
  .slideover--header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 4px 0 16px;

    box-shadow: inset 0px -1px 0px var(--border-colour);

    .slideover--header-close-btn {
      height: 30px;
    }

    .q-icon {
      color: var(--grey-lightest-non-text);
      font-size: 22px !important;
    }
  }

  > .encounter-patient {
    grid-area: banner;
    padding: 3px 16px;
  }
}
@keyframes slideover-animation {
  100% {
    right: 0;
  }
}
</style>
