<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        Future action/recall: {{ item.clinicalCode }} • {{ item.plannedDateRange }}
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <m-layout-stack horizontal class="q-mt-sm">
      <m-badge
        v-if="!item.isMarkedIncorrect"
        :class="[
          item.displayStatus === 'Overdue' || item.displayStatus === 'Due' ? 'amber' : 'secondary',
          'status-badge',
        ]"
        :text="item.displayStatus"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`future-action-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`future-action-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type FutureActionJournalItem = {
  plannedDateRange?: string;
  clinicalCode?: string;
  displayStatus?: string;
  confidentialFromThirdParties?: boolean;
  isMarkedIncorrect?: boolean;
  isRetrospectivelyAmended?: boolean;
  linkedProblems?: { problemCodeDescription: string }[];
};

const props = defineProps<{
  item: FutureActionJournalItem;
  readOnly: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
<style>
.status-badge {
  align-self: start;
}
</style>
