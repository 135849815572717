<script setup lang="ts">
import { useVModel } from "vue-composable";
import MButton from "../MButton/MButton.vue";
import MLayoutStack from "../MLayoutStack/MLayoutStack.vue";
import MMenu from "../MMenu";
const props = defineProps<{
  modelValue?: boolean;
  title: string;

  showButtons?: boolean;
}>();

const value = useVModel(props, "modelValue");

const emit = defineEmits({
  "update:modelValue": () => true,
  cancel: () => true,
  save: () => true,
});

function onCancel() {
  emit("cancel");
  value.value = false;
}
function onSave() {
  emit("save");
}
</script>
<template>
  <m-menu v-model="value" no-auto-open>
    <MLayoutStack class="q-pa-sm menu-form">
      <m-layout-stack class="top items-center" horizontal justify-between>
        <span class="title">{{ title }}</span>

        <m-button
          icon="fa-regular fa-xmark"
          small
          ghost
          icon-only
          label="Close Menu"
          @click="onCancel"
        />
      </m-layout-stack>

      <slot />

      <m-layout-stack v-if="showButtons" horizontal justify-between no-gap>
        <div />
        <m-layout-stack horizontal>
          <MButton color="secondary" label="Cancel" @click="onCancel" />
          <MButton label="Save" @click="onSave" />
        </m-layout-stack>
      </m-layout-stack>
    </MLayoutStack>
  </m-menu>
</template>
<style lang="scss">
.menu-form {
  > .top {
    .title {
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 125%;
    }
    .q-icon {
      color: #9fa6b2;
      font-size: 16px !important;
    }
  }
}
</style>
