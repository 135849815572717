<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View referral"
    @click="referralOverview()"
  >
    <ReferralJournalElement :item="entry.data" :read-only="readOnly" :clickable="clickable" />
  </m-card>
</template>

<script setup lang="ts">
import { PatientJournalRecord } from "../types";
import { openModal, openSlideover } from "../../../../composables/dialog/drawer";
import ReferralJournalElement from "../elements/ReferralJournalElement.vue";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function referralOverview() {
  if (props.entry.data.isManual === true) {
    return props.readOnly
      ? openSlideover(`/clinical/referral/manual-referral/overview/${props.entry.id}`, {
          close: true,
        })
      : undefined;
  } else if (props.entry.data.isAdviceAndGuidance === true) {
    return props.readOnly
      ? openModal(
          `/clinical/referral/nhs-e-referral/advice-and-guidance/overview/${props.entry.id}`,
          {
            close: true,
          },
        )
      : "";
  } else {
    return props.readOnly
      ? openSlideover(`/clinical/referral/nhs-e-referral/overview/${props.entry.id}`, {
          close: true,
        })
      : "";
  }
}
</script>
