<script lang="ts" setup>
import { watch } from "vue";
import MModal from "./medicus/MModal";

import type { Router } from "vue-router";
import MLayoutStack from "./medicus/MLayoutStack";
import MButton from "./medicus/MButton";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },

  text: {
    type: String,
  },
  html: {
    type: String,
  },

  component: [String, Object],
  componentProps: null,

  cancelLabel: {
    type: String,
    default: "No",
  },

  okLabel: {
    type: String,
    default: "Yes",
  },
  okColor: {
    type: String,
    default: "danger",
  },

  noButtons: Boolean,
  noCancel: Boolean,

  modelValue: Boolean,

  router: { type: Object as () => Router, required: true },

  contentClass: null,

  isDialog: Boolean,
});

const emit = defineEmits({
  hide: () => true,
  ok: () => true,
});

function onDialogHide() {
  emit("hide");
}
function onOKClick() {
  emit("ok");
}

function onCancelClick() {
  onDialogHide();
}

// hide Dialog when the route changes
watch(() => props.router.currentRoute, onDialogHide);
</script>
<template>
  <m-modal
    ref="contentEl"
    :content-class="['m-dialog', !component && 'with-max-width', $attrs.class]"
    :model-value="modelValue"
    small
    :title="title"
    @close="onDialogHide"
  >
    <m-layout-stack :class="[{ 'no-buttons': noButtons }, contentClass, 'content']">
      <slot>
        <component :is="component" v-if="component" v-bind="componentProps" @success="onOKClick" />
        <div v-else-if="html" v-html="html" />
        <div v-else class="text">{{ text }}</div>
      </slot>
      <m-layout-stack v-if="!noButtons" class="justify-end" horizontal>
        <m-button
          v-if="!noCancel"
          :label="cancelLabel"
          border
          color="secondary"
          @click="onCancelClick"
        />
        <m-button :label="okLabel" :color="okColor" border @click="onOKClick" />
      </m-layout-stack>
    </m-layout-stack>
  </m-modal>
</template>

<style lang="scss">
.m-dialog {
  &.with-max-width {
    max-width: 500px;
  }

  .no-buttons > span {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .text {
    margin-bottom: var(--gap-3);
  }
}
</style>
