<script lang="ts" setup>
import { DrawerUrlOptions } from "../../../store/drawers";
import MBadge from "../MBadge";
import MCheckbox from "../inputs/MCheckbox";
import MRadio from "../inputs/MRadio";
import MAction from "./../MAction";
import MLayoutStack from "./../MLayoutStack";
import { usePermission } from "../../../composables/permission";
import { computed } from "vue";
const props = defineProps<{
  title?: string;
  action?: object;
  to?: string;
  toSlideover?: string | DrawerUrlOptions | false;
  toModal?: string | DrawerUrlOptions | false;
  dense?: boolean;
  onClick?: Function;
  disabled?: boolean;
  modelValue?: boolean;
  allowSelect?: boolean;
  multi?: boolean;
  badge?: object;
  borderless?: boolean;
}>();
defineEmits({
  click: (_e: any) => true,
});

const { disabled, hasLink, onClick } = usePermission(props, true);

const hasClick = computed(() => hasLink.value || props.onClick);

function handleClick(e: any) {
  if (!hasLink.value) return;
  onClick(e);
}
</script>
<template>
  <component
    :is="hasClick ? 'button' : 'div'"
    class="m-data-card"
    :class="{ 'with-hover medicus-outline cursor-pointer': hasClick, dense, borderless }"
    :aria-label="title || 'More information'"
    :disable="disabled"
    @click="handleClick"
  >
    <m-layout-stack horizontal>
      <m-checkbox
        v-if="allowSelect && multi"
        :model-value="modelValue"
        @update:modelValue="!disabled && $emit('update:modelValue', $event)"
      />
      <m-radio
        v-else-if="allowSelect"
        :model-value="modelValue"
        :val="true"
        @update:modelValue="!disabled && $emit('update:modelValue', $event)"
      />
      <m-layout-stack full gap="2">
        <m-layout-stack
          v-if="title || action || $slots.title || $slots.action"
          class="data-card-title"
          horizontal
          justify-between
        >
          <m-layout-stack horizontal>
            <slot name="title">
              <h3>{{ title }}</h3>
            </slot>

            <template v-if="badge">
              <template v-if="Array.isArray(badge)">
                <m-badge v-for="(p, i) in badge" :key="i" v-bind="p" />
              </template>
              <m-badge v-else-if="badge.text" v-bind="badge" />
              <m-badge v-else :text="badge" />
            </template>
          </m-layout-stack>

          <div v-if="action || $slots.action" class="card-button">
            <slot name="action">
              <m-action :model-value="action" />
            </slot>
          </div>
        </m-layout-stack>
        <div class="data-card-content">
          <slot />
        </div>
      </m-layout-stack>
    </m-layout-stack>

    <div v-if="$slots.footer" class="data-card-footer">
      <slot name="footer" />
    </div>
  </component>
</template>

<style lang="scss">
// BUTTON needs some special handling
button.m-data-card {
  // width: calc(100% - 30px);
  width: 100%;
  overflow: hidden;

  text-align: left;
}
.m-data-card {
  // NOTE SAME AS MCard
  background: #ffffff;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  min-height: 40px;
  flex: 0 0 auto;
  // padding: 22px 22px 14px 22px;
  padding: 0.5rem 15px;
  position: relative;
  .card-button {
    position: absolute;
    right: 0.5rem;
    display: flex;
    align-self: flex-start;
  }
  .data-card-content {
    flex: 1 1 auto;
    display: grid;
    row-gap: 0.5rem;
    column-gap: 1rem;
    // grid-template-columns: repeat(2, minmax(260px, 1fr));
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    // // min 225 or max 3 column total
    // grid-template-columns: repeat(
    //   auto-fit,
    //   minmax(225px, min(100%/3, max(225px, 100%/3 - 1rem * (3-1)/3)))
    // );
    .data-card-title {
      font-weight: bold;
      color: var(--text-color);
      grid-column: 1 / -1;
      font-size: 16px;
    }
  }
  &.with-hover:hover {
    background: var(--hover-background);
  }
  &.borderless {
    border-color: transparent;
  }
  .data-card-footer {
    border-top: 1px solid var(--grey-light);
    grid-column: 1 / -1;
    padding-top: 1rem;
  }
}
</style>
