<script lang="ts" setup>
import { isObject } from "@vue/shared";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { openModal, openSlideover } from "../../../../composables/dialog/drawer";
import { DrawerUrlOptions } from "../../../../store/drawers";
import MButton from "../../MButton";
import { ref } from "vue";

const props = withDefaults(
  defineProps<{
    showVerticalLine?: boolean;

    to?: string;

    toSlideover?: string | DrawerUrlOptions | object | false;
    toModal?: string | DrawerUrlOptions | object | false;

    onClick?: (() => void) | Array<() => void>;

    iconName?: string;

    srActionLabel?: string;
    srLabel?: string;
  }>(),
  {
    iconName: "fa-light fa-chevron-right",
  },
);

const emit = defineEmits({
  click: (_e: MouseEvent) => true,
});

const router = useRouter();

const refActionButton = ref();

const click = computed(() => {
  if (props.onClick) {
    return (e: MouseEvent) => {
      emit("click", e);

      if (typeof props.onClick === "function") {
        props.onClick();
      }

      refActionButton.value?.focus();
    };
  }
  if (props.toModal) {
    const [url, options] = isObject(props.toModal)
      ? [props.toModal.url, props.toModal]
      : [props.toModal, {}];
    return (_e: MouseEvent) => {
      refActionButton.value?.focus();
      openModal(url, options);
    };
  }
  if (props.toSlideover) {
    const [url, options] = isObject(props.toSlideover)
      ? [props.toSlideover.url, props.toSlideover]
      : [props.toSlideover, {}];
    return (_e: MouseEvent) => {
      refActionButton.value?.focus();
      openSlideover(url, options);
    };
  }

  if (props.to) {
    return (_e: MouseEvent) => {
      refActionButton.value?.focus();
      router.push(props.to);
    };
  }
  return undefined;
});
</script>

<template>
  <li
    class="m-list-item"
    :class="{
      clickable: !!click,

      'vertical-line': showVerticalLine,
    }"
    @click="click"
  >
    <div class="m-list-item--content">
      <slot />
    </div>
    <div v-if="click || $slots.action" class="m-list-item--action">
      <slot name="action">
        <m-button
          ref="refActionButton"
          class="m-list-item--action-button"
          :icon="iconName"
          :label="srActionLabel || srLabel"
          :on-click="click"
          ghost
          icon-only
        />
      </slot>
    </div>
  </li>
</template>
<style lang="scss">
.m-list-item {
  display: grid;
  grid-template-areas: "content" "action";
  grid-template-columns: 1fr 0;
  padding: 5px 10px;
  min-height: 35px;

  &.clickable {
    cursor: pointer;

    grid-template-columns: 1fr auto;

    &:hover,
    &.hover-component,
    &:focus-within {
      background-color: var(--grey-lightest);
    }
  }

  .m-list-item--content {
    grid-area: "content";
    word-break: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    white-space: pre-line;
  }

  .m-list-item--action {
    grid-area: "action";
  }

  .m-list-item--action-button {
    margin-top: 4px;
  }

  &.vertical-line {
    position: relative;
    padding-left: 25px !important;

    &::before {
      content: " ";
      position: absolute;
      top: 5px;
      left: 15px;
      height: calc(100% - 10px);
      width: 3px;
      background-color: var(--status-blue);
    }
  }

  .labeled-text {
    &.horizontal {
      // reset margin top
      > .text,
      > .label {
        margin-top: 0;
      }
    }
  }
}
</style>
