<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View procedure"
    @click="procedureOverview()"
  >
    <ProcedureJournalElement :item="entry.data" :read-only="readOnly" :clickable="clickable" />
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import ProcedureJournalElement from "../elements/ProcedureJournalElement.vue";
import { PatientJournalRecord } from "../types";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function procedureOverview() {
  props.readOnly
    ? openSlideover("/clinical/procedure/overview/" + props.entry.id, { close: true })
    : undefined;
}
</script>
