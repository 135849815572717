<template>
  <m-calendar ref="calendar" :options="options" />
</template>

<script>
import MCalendar from "./../MCalendar";

import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const plugins = [timeGridPlugin, interactionPlugin];

export default {
  components: { MCalendar },

  props: {
    initialView: {
      type: String,
      default() {
        return "timeGridDay";
      },
    },

    headerToolbar: [Boolean, Object],
    height: {
      type: String,
      default() {
        return "100%";
      },
    },
    slotDuration: {
      type: [String, Object],
      default() {
        // NOTE why is one minute?
        return "00:01:00";
      },
    },

    dateClick: Function,
    eventClick: Function,
    events: Array,
    eventSources: Array,

    initialDate: String,
  },

  computed: {
    options() {
      return Object.assign(
        {
          plugins,
        },
        this.$props,
      );
    },
  },
};
</script>

<style></style>
