<script setup lang="ts">
import MButton from "../MButton";
import MLayoutStack from "../MLayoutStack";
import MPage from "../MPage/MPage.vue";
import { openPage } from "../../../composables/dialog/drawer";
import MCard from "../MCard/MCard.vue";
import MDynamic from "../MDynamic/MDynamic.vue";
import { computed, inject, onBeforeUnmount, onMounted, PropType, ref } from "vue";
import { Task } from "./types";
import MDescriptionListItem from "../MDescriptionList/MDescriptionListItem/MDescriptionListItem.vue";
import MDescriptionList from "../MDescriptionList/MDescriptionList.vue";
import { useRouter } from "vue-router";
import MBanner from "../MBanner/MBanner.vue";
import MList from "../MList/MList.vue";
import MListItem from "../MList/MListItem/MListItem.vue";
import MLink from "../MLink/MLink.vue";
import MInput from "../inputs/MInput/MInput.vue";
import axios from "../../../utils/axios";
import { NO_OP } from "vue-composable";
import { showDialog } from "../../../utils/dialog";
import { dispatch, listen } from "../../../utils/bus";
import { useAuth } from "../../../store";
import { pusher } from "../../../plugins/thirdparties/pusher";
import { TaskComment } from "../MTaskList/types";

const props = defineProps({
  title: String,
  task: Object as PropType<Task>,
  patientId: String, // @todo when expanding component for tasks without patient, ensure the layout fits appropriately
  taskListEndpoint: String,
  taskList: String,
  noGutter: {
    type: Boolean,
    default: true,
  },
  viewContext: String,
  hideComments: Boolean,
  taskComments: Array as () => TaskComment[],
  hidePriority: {
    type: Boolean,
    default: false,
  },
  hideDueDate: {
    type: Boolean,
    default: false,
  },
  taskCommentTitle: {
    type: String,
    default: "Task Comments",
  },
  taskCancelledBannerText: {
    type: String,
    required: false,
  },
  taskCompletedBannerText: {
    type: String,
    required: false,
  },
  viewContextEndpoint: {
    type: String,
    required: true,
  },
  taskCardTitle: {
    type: String,
    default: "Task Overview",
  },
  taskDefaultStatuses: {
    type: Array,
    required: true,
  },
  editTaskDetailsAction: {
    type: Object,
    required: false,
  },
  hidePatientRecord: {
    type: Boolean,
    default: false,
  },
  hideTaskOverviewDetails: {
    type: Boolean,
    default: false,
  },
  hideAuditEvents: {
    type: Boolean,
    default: false,
  },
});

const commentText = ref<string>("");

const canSubmitComment = computed(() => !commentText.value);

const reloadData = inject("reloadData", NO_OP);

const broadcastChannels = [];

listen("task-completed", async () => await handleNextTask());

onMounted(() => {
  const store = useAuth();

  const channelName = store.config?.tenantId + "-task-" + props.task?.id;
  let channel = pusher.subscribe(channelName);

  channel.bind("updated", () => reloadData());

  broadcastChannels.push(channel);

  dispatch("closeSubnav", true);
});

onBeforeUnmount(() => {
  if (broadcastChannels.length === 0) {
    return;
  }

  broadcastChannels.forEach((x) => {
    x.unbind_all();
    x.unsubscribe();
  });
});

function backToList() {
  if (props.viewContext === "patient" || props.viewContext === "patient-list") {
    openPage(props.viewContextEndpoint);
  } else {
    openPage(props.taskListEndpoint);
  }
}

function editTaskAction() {
  return {
    iconOnly: true,
    label: "Edit task",
    icon: "fa-light fa-chevron-right",
    toModal: {
      url: `/tasks/edit-details/${props.task.id}`,
      params: {
        taskType: props.task.type,
      },
    },
  };
}

function editAssigneeAction() {
  return {
    iconOnly: true,
    label: "Edit assignee",
    icon: "fa-light fa-chevron-right",
    toModal: {
      url: `/tasks/change-assignee/${props.task.id}`,
      params: {
        taskType: props.task.type,
      },
    },
  };
}

function formatDueDate(dueDate) {
  if (dueDate) {
    const dd = new Date(dueDate);
    const now = new Date();
    if (dd < now) {
      return {
        type: "badge",
        text: dueDate,
        colour: "amber",
      };
    }
    return dueDate;
  }
  return "None";
}

const router = useRouter();

async function handleNextTask() {
  if (props.taskList) {
    await axios
      .get(`/task-list/${props.taskList}/next-task`, {
        params: { ...router.currentRoute.value.query },
      })
      .then(({ data }) => {
        if (data.foundNextTask) {
          router.push({ path: data.route, query: router.currentRoute.value.query });
        } else {
          showDialog({
            title: "No Tasks to Display",
            text: "There are no more tasks matching your filter criteria.",
            okLabel: "Dismiss",
            noCancel: true,
            okColor: "primary",
          }).onOk(() => {
            const assigneeId = router.currentRoute.value.query.masterAssignee;
            router.push({
              path: data.route,
              query: { "statuses[]": props.taskDefaultStatuses, masterAssignee: assigneeId },
            });
          });
        }
      });
  } else if (props.viewContext === "patient" || props.viewContext === "patient-list") {
    openPage(props.viewContextEndpoint);
  }
}

function commentingEnabled() {
  return props.task?.isCompleted || props.task?.isCancelled;
}

async function submitComment() {
  await axios
    .post(`/tasks/create-comment`, {
      comment: commentText.value,
      taskId: props.task.id,
      taskType: props.task.type,
    })
    .then(() => {
      commentText.value = "";
      reloadData?.();
    });
}
function openEditCommentModal(commentId) {
  showDialog({
    title: "Edit Comment",
    type: "dialog",
    component: `/tasks/edit-comment/${commentId}`,

    noButtons: true,
  }).onOk(async () => {
    reloadData?.();
  });
}

function cancelledBannerText() {
  if (props.taskCancelledBannerText) {
    return props.taskCancelledBannerText;
  }
  return `Task discarded on ${props.task.cancelledDate}.`;
}

function completedBannerText() {
  if (props.taskCompletedBannerText) {
    return props.taskCompletedBannerText;
  }
  return `Task completed on ${props.task.completedDate} by ${props.task.completedBy}.`;
}
</script>

<template>
  <m-page
    class="h-full"
    :title="title"
    :patient-banner-below="true"
    :patient-id="patientId"
    no-overflow
    :no-gutter="(patientId && !hidePatientRecord) || noGutter"
    @task-completed="handleNextTask"
  >
    <template #buttons>
      <m-button
        style="margin: 5px"
        ghost
        icon="fa-solid fa-xmark"
        label="Close"
        @click="backToList()"
      />
    </template>
    <m-layout-stack horizontal class="h-full">
      <m-layout-stack style="flex: 2" class="h-full overflow-auto">
        <m-banner v-if="task?.isSnoozed" type="info">{{ task.statusText }}.</m-banner>
        <m-banner v-if="task?.isCancelled" type="info">
          <p>{{ cancelledBannerText() }}</p>
        </m-banner>
        <m-banner v-if="task?.isCompleted" type="success">
          <p>{{ completedBannerText() }}</p>
        </m-banner>
        <slot name="banners"></slot>
        <m-card v-if="!hideTaskOverviewDetails" :title="taskCardTitle">
          <m-description-list>
            <m-description-list-item label="Status" :value="task.statusText" horizontal />
            <m-description-list-item
              v-if="!hidePriority"
              label="Priority"
              :value="
                task.priority === 'High'
                  ? {
                      type: 'badge',
                      text: task.priority,
                      colour: 'amber',
                    }
                  : task.priority
              "
              horizontal
              :action="editTaskDetailsAction ?? editTaskAction()"
            />
            <m-description-list-item
              v-if="!hideDueDate"
              label="Due date"
              :value="formatDueDate(task.dueDate)"
              horizontal
              :action="editTaskDetailsAction ?? editTaskAction()"
            />
            <m-description-list-item
              label="Assigned to"
              :value="task.assignedTo"
              horizontal
              :action="editAssigneeAction()"
            />
            <m-description-list-item label="Created" :value="task.createdBy" horizontal />
            <slot name="task-overview"></slot>
          </m-description-list>
        </m-card>

        <slot name="content"></slot>

        <m-card v-if="!hideComments" :title="`${taskCommentTitle} (${taskComments?.length ?? 0})`">
          <m-layout-stack>
            <m-banner v-if="commentingEnabled()" type="system">
              <p>Commenting is disabled for completed or cancelled tasks.</p>
            </m-banner>

            <m-layout-stack v-if="!taskComments?.length" center class="q-pa-sm">
              <p class="empty-text text-center">No comments to display.</p>
            </m-layout-stack>

            <m-list v-else>
              <m-list-item v-for="(comment, key) in taskComments" :key="key" show-vertical-line>
                <m-layout-stack>
                  <div class="heading">
                    <m-layout-stack horizontal>
                      <span class="empty-text"
                        >{{ comment.createdBy }} •
                        {{ $formatDateTime(comment.createdDateTime) }}</span
                      >
                    </m-layout-stack>
                  </div>
                  <span class="whitespace-pre-wrap">{{ comment.comment }}</span>
                  <m-link
                    v-if="comment.canEdit || comment.canDelete"
                    :on-click="() => openEditCommentModal(comment.id)"
                  >
                    Edit
                  </m-link>
                </m-layout-stack>
              </m-list-item>
            </m-list>
            <m-layout-stack horizontal class="w-full">
              <m-input
                v-model="commentText"
                class="w-full"
                style="flex: 3"
                type="text"
                sr-label="New task comment"
                :disabled="commentingEnabled()"
              />
              <m-button
                class="flex-0"
                label="Save comment"
                :click="submitComment"
                :disabled="canSubmitComment"
              />
            </m-layout-stack>
          </m-layout-stack>
        </m-card>

        <slot name="next-steps"></slot>

        <m-card v-if="!hideAuditEvents" title="Audit Events" expand>
          <m-dynamic :url="`/clinical/patient-audit/entity-audit-listing-slideover/${task.id}`" />
        </m-card>

        <slot name="buttons"></slot>
      </m-layout-stack>

      <div v-if="patientId && !hidePatientRecord" style="flex: 2" class="scroll-area">
        <m-dynamic
          :url="`/clinical/patient-record/overview/${patientId}`"
          :read-only="false"
          :hide-title="true"
        />
      </div>

      <slot v-else name="right-side"> </slot>
    </m-layout-stack>
  </m-page>
</template>

<style lang="scss"></style>
