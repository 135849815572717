<script lang="ts" setup>
import { computed } from "vue";
const props = defineProps({
  dark: Boolean,
});

const color = computed(() => (props.dark ? "#FFF" : "var(--theme-darkest-blue)"));
</script>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 63 64">
    <circle cx="39.85" cy="1.64" r="1.64" fill="#E16E7C" />
    <circle class="bottom-effect-change" cx="39.85" cy="21.33" r="1.64" :fill="color" />
    <circle class="bottom-effect-change" cx="59.54" cy="21.33" r="1.64" :fill="color" />
    <circle class="bottom-effect-change" cx="39.85" cy="41.02" r="1.64" :fill="color" />
    <circle class="bottom-effect-change" cx="20.15" cy="41.02" r="1.64" :fill="color" />
    <circle class="bottom-effect-change" cx="2.1" cy="41.02" r="1.64" :fill="color" />
    <circle cx="59.54" cy="41.02" r="3.28" fill="#4078B6" />
    <circle cx="20.15" cy="60.72" r="3.28" fill="#E16E7C" />
    <circle cx="20.15" cy="21.33" r="3.28" fill="#6EBA90" />
    <circle class="bottom-effect-change" cx="39.85" cy="60.72" r="1.64" :fill="color" />
    <circle cx="61.18" cy="60.72" r="1.64" fill="#6EBA90" />
  </svg>
</template>
