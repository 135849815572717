<script lang="ts">
import { QTabPanel } from "quasar";
import { defineComponent, h } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, { slots }) {
    return () =>
      h(
        QTabPanel,
        {
          name: props.name,
          label: props.label,
        },
        () => slots.default?.() ?? [],
      );
  },
});
</script>
