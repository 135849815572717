<script setup lang="ts">
import { NO_OP } from "vue-composable";
import MAppointmentDiary from "./MAppointmentDiary.vue";
import { AppointmentDiary } from "./types";
import { NOOP } from "@vue/shared";

const diary: AppointmentDiary = {
  scheduleType: "diary",
  id: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
  startDateTime: "2024-10-07 14:00:00",
  endDateTime: "2024-10-07 18:00:00",
  summary: {
    site: {
      id: "01924c8d-e55b-7383-9d18-a88be9403226",
      name: "Andover Site",
    },
    room: {
      id: "01924c8d-e566-720c-bff5-e9c7644e5328",
      name: "Consulting Room 2",
    },
    service: {
      id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
      name: "GP Surgery",
    },
    status: {
      value: "active",
      label: "Active",
      isActive: true,
      isCancelled: false,
    },
    startDateTime: "2024-10-07 14:00:00",
    endDateTime: "2024-10-07 18:00:00",
    defaultDeliveryMode: {
      value: "face-to-face",
      label: "Face-to-face",
      isFaceToFace: true,
      isHomeVisit: false,
      isPhone: false,
      isVideo: false,
    },
    usualAppointmentDuration: 10,
    assignees: [
      {
        id: "1332aa9c-28d6-11eb-adc1-0242ac120002",
        displayName: "Dr Lara Lincoln",
      },
    ],
    booked: {
      count: 1,
      duration: "10 mins",
    },
    available: {
      count: 0,
      duration: "3 hrs 50 mins",
    },
    additionalInformation: [],
    defaultAppointmentType: {
      id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
      appointmentService: {
        id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery",
        patientFacingName: null,
        appointmentTypes: [],
      },
      status: {
        value: "active",
        label: "Active",
        isActive: true,
        isArchived: false,
      },
      name: "GP Surgery PFS",
      patientFacingName: "Appointment",
      patientFacingOrInternalName: "Appointment",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      defaultUsualAppointmentDuration: 10,
      defaultNHSNationalSlotTypeCategory: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      internalBookingOpensHoursBeforeStartTime: null,
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      patientFacingServicesBookingOpensHoursBeforeStartTime: null,
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      gpConnectBookingOpensHoursBeforeStartTime: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
      availableProcedures: [],
    },
    nhsNationalSlotTypeCategoryDefault: {
      value: "30101",
      label: "General Consultation Acute",
      isActive: true,
      isCareRelatedEncounter: true,
    },
    internalBookingOpensDateTime: null,
    patientFacingServicesAvailability: null,
    patientFacingServicesBookingOpensDateTime: null,
    canBeCancelled: false,
    supportsTimedAppointments: true,
    supportsUntimedAppointments: true,
    canBookUntimedAppointments: true,
    isAtCapacity: false,
    hasHighPriorityUntimedAppointments: false,
  },
  entries: [
    {
      isLocalAppointment: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      id: "01924c8f-6063-72b1-8509-b3ecd9cee1e7",
      versionId: "e12e5f6e93836b4bfa9aa2f7e197476d",
      overlapsWithOtherAppointments: false,
      patient: {
        id: "01924c8d-c0f4-7092-91fa-3bf81deb271c",
        name: "MR Randal Quirk",
        firstNames: "MR Randal",
        familyName: "Quirk",
        officialDisplayName: "MR Randal Quirk",
        nameParts: {
          prefix: "MR",
          givenName: "Randal",
          middleNames: null,
          familyName: "Quirk",
          suffix: null,
        },
        preferredName: null,
        nhsNumber: "9730325391",
        dateOfBirth: "1968-10-02",
        homePhoneNumber: null,
        mobilePhoneNumber: null,
        homeAddress: "1 LANSFIELD AVENUE, LONDON, N18 2PT, GBR",
        emailAddress: null,
        deceased: false,
        risks: [],
      },
      type: {
        value: "timed",
        label: "Timed",
        isTimed: true,
        isUntimed: false,
      },
      slotType: {
        value: "appointment",
        label: "Appointment",
        isAppointment: true,
        isSlot: false,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      isHighPriority: false,
      isHiddenFromPatientFacingServices: false,
      serviceName: "GP Surgery",
      intendedOrContainerStartDateTime: "2024-10-07 14:00:00",
      intendedOrContainerEndDateTime: "2024-10-07 14:10:00",
      startDateTime: "2024-10-07 14:00:00",
      endDateTime: "2024-10-07 14:10:00",
      duration: 10,
      queueStartDateTime: null,
      queueEndDateTime: null,
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      assignees: "Dr Lara Lincoln",
      location: "Andover Site, Consulting Room 2",
      reasonForAppointment: "Change dressing on wound.",
      additionalInformation: null,
      patientSuppliedInformationForClinician: null,
      compiledReasonForAppointment: "Change dressing on wound.",
      displayStatus: {
        value: "booked",
        label: "Booked",
        isLate: false,
        isArrived: false,
        isCalledIn: false,
        isBooked: true,
        isDidNotAttend: false,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
      },
      createdDateTime: "2024-10-02 10:28:37",
      deliveryMode: {
        value: "video",
        label: "Video",
        isFaceToFace: false,
        isHomeVisit: false,
        isPhone: false,
        isVideo: true,
      },
      appointmentStatus: {
        value: "pending",
        label: "Pending",
        isPending: true,
        isStarted: false,
        isSeen: false,
        isRescheduled: false,
        isCancelled: false,
        isDidNotAttend: false,
      },
      arrivalStatus: null,
      virtualMeetingRoomId: null,
      encounterId: null,
      encounterIsDraft: null,
      isPending: true,
      formattedTimesAndDuration: "14:00 - 14:10 (10 mins)",
      formattedDateTimeRange: "Mon 07 Oct 2024, 14:00 - 14:10",
      cancelledDateTime: null,
      rescheduledDateTime: null,
      cancellationReason: null,
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      queueId: null,
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 14:10:00",
      endDateTime: "2024-10-07 14:20:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 14:20:00",
      endDateTime: "2024-10-07 14:30:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 14:30:00",
      endDateTime: "2024-10-07 14:40:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 14:40:00",
      endDateTime: "2024-10-07 14:50:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 14:50:00",
      endDateTime: "2024-10-07 15:00:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 15:00:00",
      endDateTime: "2024-10-07 15:10:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 15:10:00",
      endDateTime: "2024-10-07 15:20:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 15:20:00",
      endDateTime: "2024-10-07 15:30:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 15:30:00",
      endDateTime: "2024-10-07 15:40:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 15:40:00",
      endDateTime: "2024-10-07 15:50:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 15:50:00",
      endDateTime: "2024-10-07 16:00:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 16:00:00",
      endDateTime: "2024-10-07 16:10:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 16:10:00",
      endDateTime: "2024-10-07 16:20:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 16:20:00",
      endDateTime: "2024-10-07 16:30:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 16:30:00",
      endDateTime: "2024-10-07 16:40:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 16:40:00",
      endDateTime: "2024-10-07 16:50:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 16:50:00",
      endDateTime: "2024-10-07 17:00:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 17:00:00",
      endDateTime: "2024-10-07 17:10:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 17:10:00",
      endDateTime: "2024-10-07 17:20:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 17:20:00",
      endDateTime: "2024-10-07 17:30:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 17:30:00",
      endDateTime: "2024-10-07 17:40:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 17:40:00",
      endDateTime: "2024-10-07 17:50:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
    {
      isLocalOrganisationSlot: true,
      diaryEntryType: {
        value: "slot",
        label: "Slot",
        isSlot: true,
        isRuleSet: false,
      },
      slotType: {
        value: "slot",
        label: "Slot",
        isAppointment: false,
        isSlot: true,
        isSlotReservation: false,
        isUnavailabilityPeriod: false,
        isSqueezeInSlot: false,
      },
      diaryId: "01924c8d-e57c-71c6-af7c-c4601fc65dee",
      defaultDeliveryMode: {
        value: "face-to-face",
        label: "Face-to-face",
        isFaceToFace: true,
        isHomeVisit: false,
        isPhone: false,
        isVideo: false,
      },
      nhsNationalSlotTypeCategoryDefault: {
        value: "30101",
        label: "General Consultation Acute",
        isActive: true,
        isCareRelatedEncounter: true,
      },
      patientFacingServicesAvailability: {
        value: "available",
        label: "Available",
        isAvailable: true,
        isNotAvailable: false,
      },
      gpConnectAvailability: {
        value: "not-available",
        label: "Not available",
        isNotAvailable: true,
        isNoRestrictions: false,
        isRestricted: false,
      },
      startDateTime: "2024-10-07 17:50:00",
      endDateTime: "2024-10-07 18:00:00",
      diaryStartDateTime: "2024-10-07 14:00:00",
      diaryEndDateTime: "2024-10-07 18:00:00",
      duration: 10,
      appointmentType: {
        id: "01924c8d-e57b-720d-837b-f789f25cb4d4",
        appointmentService: {
          id: "01924c8d-e55d-7377-9a8f-67dbe47c92f2",
          status: {
            value: "active",
            label: "Active",
            isActive: true,
            isArchived: false,
          },
          name: "GP Surgery",
          patientFacingName: null,
          appointmentTypes: [],
        },
        status: {
          value: "active",
          label: "Active",
          isActive: true,
          isArchived: false,
        },
        name: "GP Surgery PFS",
        patientFacingName: "Appointment",
        patientFacingOrInternalName: "Appointment",
        defaultDeliveryMode: {
          value: "face-to-face",
          label: "Face-to-face",
          isFaceToFace: true,
          isHomeVisit: false,
          isPhone: false,
          isVideo: false,
        },
        defaultUsualAppointmentDuration: 10,
        defaultNHSNationalSlotTypeCategory: {
          value: "30101",
          label: "General Consultation Acute",
          isActive: true,
          isCareRelatedEncounter: true,
        },
        internalBookingOpensHoursBeforeStartTime: null,
        patientFacingServicesAvailability: {
          value: "available",
          label: "Available",
          isAvailable: true,
          isNotAvailable: false,
        },
        patientFacingServicesBookingOpensHoursBeforeStartTime: null,
        gpConnectAvailability: {
          value: "not-available",
          label: "Not available",
          isNotAvailable: true,
          isNoRestrictions: false,
          isRestricted: false,
        },
        gpConnectBookingOpensHoursBeforeStartTime: null,
        authorisedGPConnectOrganisationTypes: [],
        authorisedGPConnectOrganisations: [],
        availableProcedures: [],
      },
      usualAppointmentDuration: 10,
      internalBookingOpensDateTime: null,
      patientFacingServicesBookingOpensDateTime: null,
      gpConnectBookingOpensDateTime: null,
      siteName: "Andover Site",
      serviceName: "GP Surgery",
      assigneeNames: null,
      authorisedGPConnectOrganisationTypes: [],
      authorisedGPConnectOrganisations: [],
    },
  ],
  untimedAppointments: [],
};
</script>

<template>
  <div style="width: 500px">
    <m-appointment-diary
      :title="diary.summary.service.name"
      :diary="diary"
      :show-diary-controls="true"
      heading="GP Surgery"
      @click="NO_OP"
      @cancel-diary="NOOP"
    />
  </div>
</template>
