<template>
  <m-input-group
    class="m-address"
    :label="label"
    :instructions="instructions"
    :helper="helper"
    grid="2"
    :force-horizontal-label="horizontalLabel"
  >
    <template v-if="$slots.helper" #helper>
      <slot name="helper" />
    </template>
    <m-country-select
      v-if="showCountry"
      v-model="value.country"
      class="col-span-2"
      :name="name ? `${name}.country` : undefined"
      :for="name ? `${name}.country` : 'country'"
      :label="currentLabel.country"
      :disable="disable"
      :disabled="disable"
      override-in-group
      :required="required"
    />

    <template v-if="currentLabel.hasSearch && !editFullAddress">
      <div class="col-span-2">
        <m-select
          v-model="selectedAddress"
          search-url="/integrations/gb/os-places/search-address"
          label="Address"
          :required="required"
          :required-text="`Enter Address`"
        />
        <m-form-actions :items="postCodeActions" left />
      </div>
    </template>

    <template v-else>
      <m-input
        v-for="i in inputs"
        :key="i.label"
        v-bind="i"
        :disable="disable"
        :disabled="disable"
        :uppercase="i.uppercase"
      />
    </template>
    <slot />
  </m-input-group>
</template>
<script lang="ts" setup>
import type { WritableComputedRef } from "vue";
import { computed, getCurrentInstance, inject, provide, ref, watch } from "vue";
import { isObject } from "vue-composable";

import MFormActions from "../../MFormActions";
import MInputGroup from "../../MInputGroup";
import MSelect from "../MSelect";
import MCountrySelect from "./../MCountrySelect";
import MInput from "./../MInput";

import { useInputDisable } from "../../../../composables/disable";
import type { ValidationItem } from "../../../../composables/horizontalLabel";
import { useHorizontalLabel } from "../../../../composables/horizontalLabel";
import { useNamedValue } from "../../../../composables/namedValue";
import { useAuth } from "../../../../store";
import { Address } from "./types";

provide("inInputGroup", true);

const emit = defineEmits({
  "address-changed": (_a: Address) => true,
  "update:address": (_a: Address | null) => true,
  "update:modelValue": (_a?: Address) => true,
});
const props = defineProps({
  label: {
    type: String,
    default: () => {
      return "Address";
    },
  },
  required: Boolean,
  name: String,
  horizontal: {
    type: Boolean,
    default: undefined,
  },

  modelValue: {
    type: Object as () => Address,
  },
  helper: String,
  instructions: String,
});

const auth = useAuth();

const disable = useInputDisable();
const value = useNamedValue(props) as WritableComputedRef<Address>;

function addressName(n: keyof Address) {
  if (!props.name) return undefined;
  return `${props.name}?.${n}`;
}

const countryValue = useNamedValue({
  name: props.name ? addressName("country") : "address.country",
});
const proxy = getCurrentInstance().proxy!;

watch(
  value,
  (v) => {
    if (v && isObject(v) && "country" in v) {
      if (!v.country) {
        v.country = auth.config!.country;
      }
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const horizontalLabel = useHorizontalLabel(props);

const showCountry = computed(
  () => value.value && isObject(value.value) && "country" in value.value,
);

const editFullAddress = ref(!!value.value?.postalCode);
const selectedAddress = ref<Address | null>(null);

watch(countryValue, () => {
  editFullAddress.value = false;
  selectedAddress.value = null;
});

watch(
  () => value.value?.postalCode,
  (v) => {
    if (selectedAddress.value) {
      if (v !== selectedAddress.value.postalCode) {
        delete value.value.pafAddressKey;
      } else {
        value.value.pafAddressKey = selectedAddress.value.pafAddressKey;
      }
    } else if (value.value) {
      delete value.value.pafAddressKey;
    }
  },
);

watch(value, (a) => emit("address-changed", a));

watch(selectedAddress, (a) => {
  if (a) {
    Object.keys(a).map((x) => {
      value.value[x] = a[x];
    });
    value.value.pafAddressKey = a.pafAddressKey;
    editFullAddress.value = true;
  } else {
    delete value.value.pafAddressKey;
  }
});

const postCodeActions = [
  {
    label: "Enter address manually",
    click: () => {
      editFullAddress.value = true;
    },
  },
];

const labels = {
  GBR: {
    country: "Country",
    hasSearch: true,
    line1: "Address line 1",
    line2: "Address line 2",
    line3: "Address line 3",
    locality: "Town/city",
    administrativeArea: "County",
    postalCode: "Postcode",
  },
  IRL: {
    country: "Country",
    line1: "Address line 1",
    line2: "Address line 2",
    line3: "Address line 3",
    locality: "Town/city",
    administrativeArea: "County",
    postalCode: "Eircode",
  },
  USA: {
    country: "Country",
    line1: "Address line 1",
    line2: "Address line 2",
    line3: "Address line 3",
    locality: "Town/city",
    administrativeArea: "State",
    postalCode: "ZIP",
  },
  other: {
    country: "Country",
    line1: "Address line 1",
    line2: "Address line 2",
    line3: "Address line 3",
    locality: "Town/city",
    administrativeArea: "County",
    postalCode: "Postcode",
  },
};

const locale = computed(() => value.value?.country || auth.config!.country);
const currentLabel = computed<typeof labels.GBR>(() =>
  locale.value in labels ? (labels as any)[locale.value] : labels.other,
);

const fields: Array<keyof Address> = [
  "line1",
  "line2",
  "line3",
  "locality",
  "administrativeArea",
  "postalCode",
];

const inputs = computed(() =>
  fields.map((x: keyof Address) => {
    return {
      name: addressName(x)?.replace(/\?/g, ""),
      label: currentLabel.value[x],
      modelValue: value.value[x],
      "onUpdate:modelValue": (v: any) => {
        value.value[x] = v;
      },

      for: addressName(x) ? addressName(x) + x : x,

      // line1 specific
      required: x === "line1" && props.required,
      requiredText: x === "line1" && props.required ? `Enter ${currentLabel.value[x]}.` : undefined,

      uppercase: x === "postalCode",
    };
  }),
);

const validationController = inject<ValidationItem[]>("validationController", []);

validationController.push({
  name: props.name,
  validation() {
    if (!props.required) {
      if (inputs.value.every((x) => !x.modelValue)) {
        value.value = null as any;
        return true;
      }
    }
    return true;
  },

  setErrors() {
    if (!value.value)
      value.value = {
        country: auth.config?.country,

        line1: "",
        line2: "",
        line3: "",
        locality: "",
        administrativeArea: "",
        postalCode: "",
      };
  },
  scrollIntoView() {
    proxy.$el.scrollIntoView();
  },
});
</script>
<style lang="scss">
.m-address {
  &.horizontal {
    > .text {
      flex: 1 1 auto;
    }
    > label {
      margin-left: 0 !important;
    }
  }
}
</style>
