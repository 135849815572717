import { App, watch, watchEffect } from "vue";
import * as Sentry from "@sentry/vue";
import { router } from "../../router";
import { Router } from "vue-router";
import axios from "axios";
import { setTag, setUser, BrowserTracing, Replay } from "@sentry/browser";
import { pinia, useAuth } from "../../store";

export default function () {
  return {
    install(app: App) {
      Sentry.init({
        dsn: import.meta.env.SENTRY_DSN,
        release: import.meta.env.RELEASE_NUMBER,
        integrations: [
          new BrowserTracing(),
          new Replay({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        app,

        environment: import.meta.env.APP_ENVIRONMENT,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        // See: https://docs.sentry.io/platforms/javascript/tracing/trace-propagation/
        // This is disabled (empty) as it adds additional headers (sentry-trace, baggage)
        // to outgoing requests which causes an issue with CORS preflight requests
        // to the NHS Credentials Manager
        tracePropagationTargets: [],

        // Sample sessions (scrubbing all PII) when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      });

      app.provide("SENTRY", Sentry);
      const auth = useAuth(pinia);

      window.captureException = Sentry.captureException;

      // sentry
      watchEffect(() => {
        if (auth.user) {
          setUser({
            id: auth.user.userId,
            username: auth.user.username,
          });

          setTag("username", auth.user.username);
        } else {
          setUser(null);
        }
      });
      watch(
        () => auth.selectedTenant,
        (t) => setTag("tenant", t),
        { immediate: true },
      );
      // /sentry

      app.config.errorHandler = (err, vm, info) => {
        if (err) {
          Sentry.setTag("info", info);
          Sentry.captureException(err);
          console.error(err);
        }

        // TODO show error
        return false;
      };

      app.config.warnHandler = (msg, vm, trace) => {
        Sentry.captureMessage(msg);
        console.warn(msg, trace);
      };

      window.addEventListener("error", (event) => {
        Sentry.captureException(event);
        console.error(event);
      });

      // wait to sentry to load and doing it's thing
      setTimeout(() => {
        const _oldonunhandledrejection = window.onunhandledrejection;
        window.onunhandledrejection = (event) => {
          const { reason } = event;
          if (axios.isCancel(reason)) return;

          _oldonunhandledrejection?.(event);
        };
      }, 10);

      window.addEventListener("unhandledrejection", (event) => {
        const { reason } = event;
        if (axios.isCancel(reason)) return;
        Sentry.captureException(reason);
        console.error(reason);
      });

      let firstLoad = true;
      /**
       * Creates routing instrumentation for Vue Router v2
       *
       * @param router The Vue Router instance that is used
       */
      function vueRouterInstrumentation(router: Router) {
        return (
          startTransaction: any,
          startTransactionOnPageLoad = true,
          startTransactionOnLocationChange = true,
        ) => {
          router.onError((error) => Sentry.captureException(error));

          const tags = {
            "routing.instrumentation": "vue-router",
          };

          router.beforeEach((to, _from, next) => {
            const data = {
              params: to.params,
              query: to.query,
            };

            if (startTransactionOnPageLoad && firstLoad) {
              startTransaction({
                name: to.name || to.path,
                op: "pageload",
                tags,
                data,
              });
            }

            if (startTransactionOnLocationChange && !firstLoad) {
              startTransaction({
                name: to.name || to.matched[0].path || to.path,
                op: "navigation",
                tags,
                data,
              });
            }

            firstLoad = false;
            next();
          });
        };
      }
      vueRouterInstrumentation(router)(Sentry.startTransaction, true, true);
    },
  };
}
