<script setup lang="ts">
import { nextTick, onMounted, onUpdated, Ref, ref, watch } from "vue";
import { CanvasImage, VariableOption } from "./types";
import { openModal } from "../../../composables/dialog/drawer";
import MMenu from "../MMenu";
import { searchArray } from "../../../utils/misc";
import { QItem } from "quasar";
import { CursorValues, Direction, TextAreaKeyboardEvent } from "../MInlineTextEntryInput/types";
import { Key } from "ts-key-enum";
import { listen } from "../../../utils/bus";

const props = defineProps<{
  width: number;
  height: number;
  /** The initial images to draw on the canvas */
  initialImages?: [];
  /** Will make the position absolute. Required for drawing/highlighting on PDFs */
  isAbsolute?: false;
  /** The ref of the element that the canvas is overlaying. Required for drawing/highlighting on PDFs */
  overlayRef?: String;
  variableOptions: [];
  actionModalUrl: string;
  disableDrawing?: false;
}>();

const emit = defineEmits({
  blur: (_inputValue: string) => true,
  enterKeydown: (_cursorValues: CursorValues) => true,
});

const drawingCanvas = ref<HTMLCanvasElement | null>(null);
const images = ref<CanvasImage[]>([]);
const context = ref<CanvasRenderingContext2D | null>(null);

const inputValue = ref<string>("");
const textAreaEl = ref<InstanceType<typeof HTMLTextAreaElement> | null>(null);

let drawing = false;
let startingPositionX: number = null;
let startingPositionY: number = null;
let stoppedDrawing = false;
let currentImageIndex = null;

const menuDisplayVariables = ref(false);
const filteredActions = ref<Array<VariableOption>>(props.variableOptions ?? []);
const refActionMenuItems = ref<QItem[]>([]);
const focusedActionIndex = ref(0);
const MENU_WIDTH = 300;
const MENU_MAX_HEIGHT = 300;

const textLeftPosition = ref<string>("-100px");
const canvasTextAreaDisplay = ref<string>("none");
const textTopPosition = ref<string>("-100px");

onMounted(() => {
  if (props.initialImages) {
    images.value = props.initialImages;
  }

  setContext();
  nextTick(() => {
    drawInitialImage();
  });
});

onUpdated(() => {
  // This creates a clear rectangle across the canvas to remove the old rectangles
  const rect: CanvasRenderingContext2D = drawingCanvas.value.getContext("2d");
  rect.clearRect(0, 0, drawingCanvas.value.width, drawingCanvas.value.height);

  // upon clearing update the images and draw on fresh canvas
  images.value = props.initialImages;
  redraw(false);
});

watch(
  inputValue,
  (newValue, _) => {
    // Update menu visibility
    menuDisplayVariables.value =
      props.variableOptions !== undefined && props.variableOptions?.length > 0;

    if (menuDisplayVariables.value === true && props.variableOptions) {
      stoppedDrawing = true;
      // Filter actions list with the latest text value (minus the forward slash)
      const searchText = newValue.toLowerCase();
      focusedActionIndex.value = 0;

      if (searchText.length === 0) {
        filteredActions.value = props.variableOptions;
      } else {
        filteredActions.value = searchArray(props.variableOptions, searchText, "label");
      }
    }
  },
  { immediate: true },
);

listen("redrawCanvas", () => {
  requestAnimationFrame(() => {
    images.value = props.initialImages;
    redraw(false);
  });
});

function setContext() {
  context.value = context.value ? context.value : drawingCanvas.value.getContext("2d");
}

function drawInitialImage() {
  redraw(false);
}

function startDraw(event: MouseEvent) {
  if (!drawing && !props.disableDrawing) {
    if (currentImageIndex) {
      tidyImageAndInput();
    }

    let isInsideImage = false;
    let selectedImage = null;
    images.value.forEach((image: CanvasImage) => {
      if (
        image.coordX <= event.offsetX &&
        image.coordX + image.width >= event.offsetX &&
        image.coordY <= event.offsetY &&
        image.coordY + image.height >= event.offsetY
      ) {
        isInsideImage = true;
        selectedImage = image;
      }
    });

    if (!isInsideImage) {
      startingPositionX = event.offsetX;
      startingPositionY = event.offsetY;
      drawing = true;
      stoppedDrawing = false;
    } else {
      openModal(props.actionModalUrl, {
        params: {
          id: selectedImage.id,
        },
      });
    }
  }
}

function draw(event: MouseEvent) {
  if (drawing && !props.disableDrawing) {
    if (!context.value) {
      this.setContext();
    }

    context.value.beginPath();
    context.value.rect(
      startingPositionX,
      startingPositionY,
      event.offsetX - startingPositionX,
      event.offsetY - startingPositionY,
    );
    context.value.closePath();
    context.value.fillRect(0, 0, window.innerWidth, window.innerHeight);
    context.value.clearRect(0, 0, window.innerWidth, window.innerHeight);
    context.value.fillStyle = "#f00";
    context.value.lineWidth = 3;
    context.value.fill();

    redraw(false);
  }
}

function stopDraw(event: MouseEvent) {
  if (drawing && !props.disableDrawing) {
    const newImage: CanvasImage = {
      coordX: startingPositionX,
      coordY: startingPositionY,
      width: event.offsetX - startingPositionX,
      height: event.offsetY - startingPositionY,
    };

    if (newImage.width > 0 && newImage.height > 0) {
      images.value.push(newImage);
      stoppedDrawing = true;
    }
    redraw(true);
    setContext();
    drawing = false;
    currentImageIndex = images.value.length - 1;
    stoppedDrawing = false;
  }
}

function redraw(performAction: boolean) {
  let latestImage: CanvasImage = null;
  images.value.forEach((image: CanvasImage) => {
    if (drawingCanvas.value) {
      const rect: CanvasRenderingContext2D = drawingCanvas.value.getContext("2d");
      rect.fillStyle = "#f00";
      rect.fillRect(image.coordX, image.coordY, image.width, image.height);

      if (image.text) {
        rect.fillStyle = "black";
        if (image.isCheckbox === false) {
          rect.font = "8pt monospace";
          rect.textBaseline = "alphabetic";
          rect.fillText(image.text, image.coordX, image.coordY + 10, image.width);
        } else if (image.isCheckbox === true) {
          rect.font = "16pt monospace";
          rect.textBaseline = "hanging";
          rect.fillText("✓", image.coordX, image.coordY, image.width);
        }
      }
      latestImage = image;
    }
  });

  if (performAction && stoppedDrawing) {
    focusMenu(latestImage);
  }
}

function onEnterKeydown(event: TextAreaKeyboardEvent) {
  event.preventDefault();

  if (stoppedDrawing) {
    if (filteredActions.value.length > 0) {
      selectVariable(filteredActions.value[focusedActionIndex.value]);
      return;
    } else {
      customVariable();
      return;
    }
  }

  // Get values before and after the current cursor selection position
  const selectionStart = event.target.selectionStart;
  const selectionEnd = event.target.selectionEnd;

  let leftOfSelection = null;
  if (selectionStart !== 0) leftOfSelection = inputValue.value.slice(0, selectionStart);

  let rightOfSelection = null;
  if (selectionEnd !== event.target.value.length)
    rightOfSelection = inputValue.value.slice(selectionEnd);

  emit("enterKeydown", <CursorValues>{
    leftOfSelection,
    rightOfSelection,
  });
}

function handleExit() {
  stoppedDrawing = false;
  hideMenu();

  if (currentImageIndex) {
    tidyImageAndInput();
  }
}

function handleKeydown(event: TextAreaKeyboardEvent) {
  switch (event.key) {
    case Key.ArrowDown: {
      event.preventDefault();
      if (menuDisplayVariables.value && refActionMenuItems.value.length > 0) {
        moveMenuFocus(Direction.DOWN, focusedActionIndex, refActionMenuItems);
      }
      break;
    }
    case Key.ArrowUp: {
      event.preventDefault();
      if (menuDisplayVariables.value && refActionMenuItems.value.length > 0) {
        moveMenuFocus(Direction.UP, focusedActionIndex, refActionMenuItems);
      }
      break;
    }
    case Key.Escape: {
      break;
    }
    case Key.Enter: {
      break;
    }
    default: {
      focusedActionIndex.value = 0;
    }
  }
}

function moveMenuFocus(direction: Direction, focusIndex: Ref, refList: Ref<QItem[]>) {
  if (direction === Direction.UP) {
    if (focusIndex.value - 1 >= 0) {
      focusIndex.value = focusIndex.value - 1;
    } else {
      focusIndex.value = refList.value.length - 1;
    }
  }

  if (direction === Direction.DOWN) {
    if (focusIndex.value + 1 <= refList.value.length - 1) {
      focusIndex.value = focusIndex.value + 1;
    } else {
      focusIndex.value = 0;
    }
  }

  nextTick(() => {
    const refFocusedItem = refList.value[focusIndex.value];
    refFocusedItem.$el.scrollIntoView();
  });
}

function tidyImageAndInput() {
  if (!images.value[currentImageIndex]?.id) {
    delete images.value[currentImageIndex];
    currentImageIndex = null;
    textAreaEl.value?.blur();
    inputValue.value = "";
    hideMenu();
    requestAnimationFrame(() => {
      redraw(false);
    });
  }
}

function hideMenu() {
  inputValue.value = "";
  canvasTextAreaDisplay.value = "none";
  textLeftPosition.value = `-100px`;
  textTopPosition.value = `-100px`;
}

function focusMenu(image: CanvasImage) {
  if (textAreaEl.value) {
    canvasTextAreaDisplay.value = "initial";
    textAreaEl.value?.focus();
    textLeftPosition.value = `${image.coordX}px`;
    textTopPosition.value = `${image.coordY + image.height}px`;
  }
}

function selectVariable(variable: VariableOption) {
  const image = images.value[currentImageIndex];
  if (image) {
    openModal(props.actionModalUrl, {
      params: {
        variable: variable.value,
        xPosition: image.coordX,
        yPosition: image.coordY,
        height: image.height,
        width: image.width,
      },
    });
    hideMenu();
  }
}

function customVariable() {
  const image = images.value[currentImageIndex];
  if (image) {
    openModal(props.actionModalUrl, {
      params: {
        variable: inputValue.value,
        xPosition: image.coordX,
        yPosition: image.coordY,
        height: image.height,
        width: image.width,
      },
    });
    hideMenu();
  }
}
</script>

<template>
  <div>
    <canvas
      id="drawing-canvas"
      ref="drawingCanvas"
      :height="height"
      :width="width"
      @mousedown="startDraw"
      @mousemove="draw"
      @mouseup="stopDraw"
      @mouseleave="stopDraw"
    />
    <div id="canvas-text-area">
      <textarea
        ref="textAreaEl"
        v-model="inputValue"
        placeholder="Template variables"
        rows="1"
        @keydown.enter="onEnterKeydown"
        @keydown.esc="handleExit"
        @keydown="handleKeydown"
      />
      <MMenu
        v-if="stoppedDrawing"
        no-refocus
        no-focus
        :target="textAreaEl"
        :model-value="true"
        :scroll-target="false"
        :max-height="MENU_MAX_HEIGHT"
        fit
        :style="{ width: MENU_WIDTH + 'px' }"
        role="listbox"
      >
        <q-list v-if="filteredActions.length > 0" ref="list" class="scroll-area" dense tabindex="0">
          <q-item
            v-for="(variable, i) in filteredActions"
            :id="`${variable}-${i}`"
            :key="`${variable}-${i}`"
            ref="refActionMenuItems"
            v-close-popup
            clickable
            :focused="i === focusedActionIndex"
            tabindex="-1"
            :aria-selected="i === focusedActionIndex"
            role="option"
            @click="() => selectVariable(variable)"
          >
            <q-item-section>
              <q-item-label>
                {{ variable.label }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <q-list v-else>
          <q-item
            ref="refActionMenuItems"
            class="label empty-text"
            clickable
            focused
            aria-selected
            @click="() => customVariable()"
          >
            <q-item-section>
              <q-item-label class="custom-variable-text">
                Custom variable: {{ inputValue }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </MMenu>
    </div>
  </div>
</template>

<style scoped lang="scss">
#drawing-canvas {
  background: rgb(20, 20, 20, 0.05);
}

#canvas-text-area {
  position: absolute;
  width: 200px;

  display: v-bind(canvasTextAreaDisplay);
  top: v-bind(textTopPosition);
  left: v-bind(textLeftPosition);
}

textarea {
  flex: 1 1 auto;
  border: 1px;
  width: 100%;
  resize: none;
  min-height: 26px;
  padding: 2px 8px;
  background: #ffffff;

  box-shadow:
    0px 4px 6px rgba(0, 0, 0, 0.06),
    0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;

  &::placeholder {
    font-style: italic;
    color: #777;
  }

  &:focus {
    outline: none;
  }
}

.custom-variable-text {
  font-style: italic;
}
</style>
