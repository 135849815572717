<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { downloadFile } from "../../../utils/download";
import MImage from "../MImage";
import MPdfViewer from "../MPdfViewer";
import MButton from "../MButton";
import { FileRequest, FileResponse } from "./_types";
import axios from "../../../utils/axios";

const props = withDefaults(
  defineProps<{
    fileType: string;
    fileURI?: string;
    fileName: string;
    convertedToPdf?: boolean;
    fileRequest?: FileRequest;
    conversionInProgress?: boolean;
    conversionFailed?: boolean;
  }>(),
  {
    fileName: "document",
    fileURI: null,
    fileRequest: null,
  },
);

let fileRequest = ref();

watch([() => props.fileRequest], async () => (fileRequest.value = await getFile()));

onMounted(async () => {
  if (props.fileRequest) {
    fileRequest.value = await getFile();
  }
});

async function getFile() {
  return await axios
    .get(props.fileRequest.url, {
      responseType: "blob",
      params: props.fileRequest.params,
    })
    .then((response) => {
      let isPDF = false;
      if (response.data.type === "application/pdf") {
        isPDF = true;
      }

      const fileURI =
        URL.createObjectURL(new Blob([response.data], { type: response.data.type })) +
        "#view=FitH&navpanes=0";

      return {
        fileURI: fileURI,
        fileType: isPDF ? "application/pdf" : response.data.type,
        error: null,
      };
    })
    .catch((error) => {
      let errorMsg = error.response?.statusText;
      switch (error.response?.status) {
        case 415:
          errorMsg = "This file is not available because it contains a virus";
          break;
      }
      return { error: errorMsg };
    });
}

const fileResponse = computed(() => {
  if (props.fileURI) {
    return { fileURI: props.fileURI, fileType: props.fileType, error: null };
  }
  return fileRequest.value !== null ? fileRequest.value : { error: "File unable to be retrieved" };
});

const isImage = computed(() => {
  return (
    (fileResponse.value.fileType === "image/png" ||
      fileResponse.value.fileType === "image/jpg" ||
      fileResponse.value.fileType === "image/jpeg") &&
    !props.convertedToPdf
  );
});

const isPDF = computed(() => {
  return fileResponse.value.fileType === "application/pdf" || props.convertedToPdf;
});

const simplifyFileType = computed(() => {
  switch (props.fileType) {
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return ".xlsx";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return ".docx";
  }
  return props.fileType;
});

function download(fileResponse: FileResponse) {
  downloadFile(fileResponse.fileURI, props.fileName);
}
</script>

<template>
  <div
    v-if="!fileResponse?.fileURI"
    class="flex items-center flex-auto flex-col h-full justify-center"
  >
    <span v-if="fileResponse?.error" data-testid="file-error" class="empty-text">{{
      fileResponse.error
    }}</span>
    <span v-else class="empty-text">Loading...</span>
  </div>
  <div v-else style="height: 100%">
    <m-image v-if="isImage" :src="fileResponse.fileURI" :alt="fileName" />
    <m-pdf-viewer v-else-if="isPDF" :url="fileResponse.fileURI" height="100%" />
    <div
      v-else
      class="flex items-center gap-3 flex-auto flex-col full-height justify-center medicus-grey-background"
    >
      <span v-if="props.conversionInProgress" class="empty-text text-center">
        Medicus is currently converting this {{ simplifyFileType }} file for viewing on screen.<br />
        Please download the original file if you need to view the contents sooner.
      </span>
      <span v-else-if="props.conversionFailed" class="empty-text text-center">
        Medicus was unable to convert this {{ simplifyFileType }} file for viewing on screen. <br />
        Please download the original file if you need to view the contents.
      </span>
      <span v-else class="empty-text">
        Unable to preview {{ simplifyFileType }} files in Medicus.
      </span>
      <m-button label="Download" color="secondary" @click="download(fileResponse)" />
    </div>
  </div>
</template>
