<script lang="ts" setup>
import { Note, Entry, EntryType, Action, SaveNotePartial, SortOrder } from "./types";
import MInlineEntryList, { Entry as NoteEntry } from "../../MInlineEntryList";
import { ref, inject, onBeforeMount, nextTick, Ref, toRef } from "vue";
import { SnomedClinicalCode } from "../MConsultationEntryInput/types";

export type ChangeNotesPayload = {
  notesToSave: SaveNotePartial[];
  sortOrder: SortOrder;
  sortOrderHash: string | null;
};

const props = defineProps<{
  id: string;
  patientId: string;
  topicId: string;
  name: string;
  initialEntries: Entry[];
  initialSortOrderHash: string;
  entrySavingDebounce?: number;
  entrySavingThrottle?: number;
  actions: Action[];
  hiddenFromPatientFacingServices: boolean;
  topicHiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  topicConfidentialFromThirdParties: boolean;
  isMarkedIncorrect: boolean;
  draft: boolean;
  instructionsText?: string;
}>();

export type EmitCodeEventData = {
  uuid: string;
  code: SnomedClinicalCode;
  sortOrder: {}[];
  sortOrderHash: string;
};

const emit = defineEmits({
  entriesUpdated: () => true,
  focusNextInput: () => true,
});

const entries = ref<(Note | Entry)[] | null>(null);
const sortOrderHash = ref("");
const refList = ref<typeof MInlineEntryList | null>(null);

inject("reloadData");
const isOnline = inject<Readonly<Ref<boolean>>>("isOnline", toRef(true));

defineExpose({
  id: props.id,
  handleDropdownActionSelected,
});

// Add default entry if heading has no entries
onBeforeMount(() => {
  sortOrderHash.value = props.initialSortOrderHash;

  if (props.initialEntries.length > 0) {
    const mappedEntries = mapEntriesToNotes(props.initialEntries);
    entries.value = mappedEntries;
  }
});

function mapEntriesToNotes(entries: Entry[]) {
  return entries.map((entry) => {
    if (entry.entryType === EntryType.CARE_RECORD_NOTE) {
      const note: NoteEntry = {
        id: entry.id,
        type: entry.entryType,
        hasUnsavedChanges: false,
        isMarkedAsIncorrect: entry.isMarkedAsIncorrect,
      };

      if (entry.clinicalCodeDescription !== null) {
        note.code = { description: entry.clinicalCodeDescription };
      }

      if (entry.note?.length > 0) {
        note.text = entry.note;
      }

      return note;
    } else {
      return entry;
    }
  });
}

function handleDropdownActionSelected(action: Action) {
  const entries = refList.value?.entries;

  const lastEntry = entries[entries.length - 1];

  if (
    !lastEntry ||
    lastEntry.type !== EntryType.CARE_RECORD_NOTE ||
    ("text" in lastEntry && lastEntry.text !== "")
  ) {
    refList.value?.addNewEntry();
  }

  nextTick(() => {
    const entry = entries[entries.length - 1];

    refList.value?.handleActionSelected(action, entry);
  });
}
</script>

<template>
  <div class="consultation-topic-heading">
    <m-inline-entry-list
      ref="refList"
      :get-url="`/clinical/encounter/consultation-topic/topic-heading-entries/${props.id}`"
      :post-url="`/clinical/consultation-topic-heading/${props.id}/change-notes`"
      :label="props.name"
      :instructions-text="props.instructionsText"
      :actions="actions"
      :patient-id="props.patientId"
      :context-id="props.id"
      :read-only="props.draft === false"
      :disable-input="props.isMarkedIncorrect === true"
      context-type="consultation-topic-heading"
      @entries-updated="() => emit('entriesUpdated')"
      @focus-next-input="() => emit('focusNextInput')"
    >
      <!-- TODO: add this prop to list :initial-data="entries?.length > 0 && sortOrderHash ? { entries, sortOrderHash } : undefined" -->
      <m-banner
        v-if="
          (props.topicConfidentialFromThirdParties == false &&
            props.confidentialFromThirdParties === true) ||
          (props.topicHiddenFromPatientFacingServices === false &&
            props.hiddenFromPatientFacingServices === true)
        "
        class="q-mb-md"
        type="info"
      >
        <p
          v-if="
            props.topicConfidentialFromThirdParties === false &&
            props.topicHiddenFromPatientFacingServices === false &&
            props.confidentialFromThirdParties === true &&
            props.hiddenFromPatientFacingServices === true
          "
        >
          This heading is confidential from third parties and will be redacted from patient online
          access.
        </p>
        <p
          v-else-if="
            props.topicConfidentialFromThirdParties === false &&
            props.confidentialFromThirdParties === true
          "
        >
          This heading is confidential from third parties.
        </p>
        <p
          v-else-if="
            props.topicHiddenFromPatientFacingServices === false &&
            props.hiddenFromPatientFacingServices === true
          "
        >
          This heading will be redacted from patient online access.
        </p>
      </m-banner>
    </m-inline-entry-list>
    <div
      v-if="!draft && !isMarkedIncorrect && isOnline"
      class="flex flex-nowrap gap-3 action-buttons"
    >
      <m-dropdown-button label="Actions" ghost>
        <q-list>
          <q-item
            v-for="(action, actionIndex) in actions"
            :key="`action-${actionIndex}`"
            clickable
            @click="handleDropdownActionSelected(action)"
            >{{ action.label }}
          </q-item>
        </q-list>
      </m-dropdown-button>
    </div>
  </div>
</template>

<style lang="scss">
.action-buttons {
  margin-top: var(--gap-1);
}
</style>
