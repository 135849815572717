<template>
  <embed :src="`${hideToolbarOnURL(url)}`" width="100%" type="application/pdf" />
</template>

<script setup lang="ts">
defineProps<{
  url: string;
}>();

function hideToolbarOnURL(url: string) {
  if (url.includes("#")) {
    return url + "&toolbar=0";
  }

  return url + "#toolbar=0";
}
</script>
