<template>
  <m-input
    v-model="textValue"
    class="date-input"
    :label="label"
    :sr-label="srLabel"
    :placeholder="placeholder"
    :rules="rules"
    :required-text="requiredText || `Enter a date for '${label || srLabel}'`"
    :horizontal="horizontal"
    :label-width="labelWidth"
    :show-all-errors="showAllErrors"
    :input-format="dateMask"
    :large="large"
    :name="name"
    :helper="helper"
    :instructions="instructions"
    :actions="actions"
    :actions-left="actionsLeft"
    @blur="onBlur"
    @focus="onFocus"
    @keydown.capture="onKeydown"
  >
    <template v-if="$slots.helper" #helper> <slot name="helper" /></template>

    <template #append>
      <m-button
        ref="calendarButton"
        icon="fa-light fa-calendar"
        icon-only
        ghost
        small
        color="secondary"
        @click="showForm = true"
      >
        <span class="sr-only">Change date {{ textValue }}</span>
        <q-popup-proxy v-model="showForm" transition-show="scale" transition-hide="scale">
          <q-date
            ref="datePickerEl"
            v-model="input"
            minimal
            mask="DD/MM/YYYY"
            :navigation-max-year-month="maxDateNavigation"
            :navigation-min-year-month="minDateNavigation"
            :options="dateOptions"
            @update:modelValue="showForm = false"
          />
        </q-popup-proxy>
      </m-button>
    </template>
  </m-input>
</template>

<script lang="ts" setup>
import { QDate, QPopupProxy } from "quasar";
import MButton from "../../MButton";
import MInput from "./../MInput";

import { date } from "quasar";
import { computed, nextTick, provide, ref, watch } from "vue";
import { useDateValue } from "../../../../composables/dateValue";
import { formatDateTimeMedicus } from "../../../../utils/date";
import { getMaxDate, getMinDate } from "../../../../utils/helpers";

const props = defineProps({
  modelValue: {
    type: [Object, String, Number, Array, Date],
    required: false,
  },
  name: String,
  label: String,
  srLabel: String,
  rules: Array as () => Array<(v: any) => boolean | string>,

  showAllErrors: Boolean,

  // m-labelled text props
  horizontal: {
    type: Boolean,
    default: undefined,
  },
  labelWidth: String,

  placeholder: {
    type: String,
    default: "dd/mm/yyyy",
  },

  minDate: {
    type: [Date, Number, String],
    default() {
      return new Date(1000, 1, 1);
    },
  },
  maxDate: {
    type: [Date, Number, String],
    default() {
      return new Date(new Date().getFullYear() + 100, 1, 1);
    },
  },

  requiredText: String,

  minDateText: {
    type: String,
  },
  maxDateText: {
    type: String,
  },

  large: Boolean,

  helper: String,
  instructions: String,

  masked: Boolean,

  actions: Array,
  actionsLeft: Boolean,
});
provide("input-dont-use-name", true);

const showForm = ref(false);

const { dateText, rules, input, displayDate } = useDateValue(
  props,
  false,
  undefined,
  props.rules,
  () => props.requiredText || `Enter a date for '${props.label}'`,
);

const textValue = ref(displayDate.value);
const shouldMask = ref(false);
const calendarButton = ref<typeof MButton>(null);

const dateMask = computed(() => (shouldMask.value ? "dd/mm/yyyy" : undefined));
const maxDateNavigation = computed(() => {
  const d = getMaxDate(props.maxDate, false);
  return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, "0")}`;
});
const minDateNavigation = computed(() => {
  const d = getMinDate(props.minDate, false);
  return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, "0")}`;
});

function dateOptions(dateValue: any) {
  const d = new Date(dateValue);
  const max = getMaxDate(props.maxDate, false);
  const min = getMinDate(props.minDate, false);

  return date.isBetweenDates(d, min, max, {
    inclusiveFrom: true,
    inclusiveTo: true,
    onlyDate: true,
  });
}

function parseDate(d: string, splitter: string) {
  let [day, month, year, ...rest] = d.split(splitter);

  if (!day || !month || !year || rest?.length) {
    return undefined;
  }

  if (year && +year < 100) {
    year = "20" + year.padStart(2, "0");
  }

  if (month && +month < 10) {
    month = month.padStart(2, "0");
  }

  if (day && +day < 10) {
    day = day.padStart(2, "0");
  }

  return [day, month, year].join("/");
}

const tokenRegex = /-?(\d+)([d|w|m|y])/g;

function parseToken(t: string) {
  const matches = t.match(tokenRegex);
  if (!matches) {
    return;
  }

  let d = new Date();

  for (const t of matches) {
    const v = +t.slice(0, -1);

    switch (t[t.length - 1]) {
      case "d": {
        d = date.addToDate(d, { days: v });
        break;
      }
      case "w": {
        d = date.addToDate(d, { days: v * 7 });
        break;
      }
      case "m": {
        d = date.addToDate(d, { months: v });
        break;
      }
      case "y": {
        d = date.addToDate(d, { years: v });
        break;
      }
    }
  }
  return formatDateTimeMedicus(d);
}

function onKeydown(e: KeyboardEvent) {
  if (props.masked) return;

  switch (e.key.toLowerCase()) {
    case "-":
    case "d":
    case "w":
    case "m":
    case "y":
      shouldMask.value = false;
      e.stopImmediatePropagation();
      break;
    case "/":
      shouldMask.value = true;
      break;
    default: {
      if (e.code.startsWith("Key") && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        e.preventDefault();
      }
    }
  }
}

function onFocus() {
  textValue.value = dateText.value;
  nextTick(() => {
    // shouldMask.value = true;
    shouldMask.value = !!textValue.value;
  });
}

async function onBlur(e?: Event) {
  try {
    if (e && (e.target as HTMLElement).classList.contains("m-input--input-box")) {
      e.preventFormTouch = true;
      return;
    }
    shouldMask.value = false;
    if (props.masked) {
      dateText.value = textValue.value;
      return;
    }

    let text = textValue.value;
    if (!text) {
      // if the text is empty
      input.value = "";
      return;
    }

    // if the text is a number and has 6 or 8 digits (14022024 or 140224)
    if (text.match(/^(\d{6}|\d{8})$/)) {
      text = `${text.slice(0, 2)}/${text.slice(2, 4)}/${text.slice(4)}`;
    }

    let actualDate = parseDate(text, "/");
    if (!actualDate) {
      actualDate = parseDate(text, ".");
      if (!actualDate) {
        actualDate = parseToken(text.toLowerCase());
        if (!actualDate) {
          // invalid date
          return;
        }
      }
    }
    input.value = actualDate.split(" ")[0].split("-").reverse().join("/");
  } finally {
    shouldMask.value = false;
    await nextTick();
    textValue.value = displayDate.value;
  }
}

watch(input, () => {
  nextTick(calendarButton.value.focus);
});

watch(displayDate, (t, p) => {
  if (textValue.value === p) {
    textValue.value = t;
  }
});

watch(textValue, (t) => {
  // slash cannot be the first character
  shouldMask.value = t?.indexOf("/") > 0;
});

const datePickerEl = ref(null as QDate | null);
// enableFocusTrap(computed(() => datePickerEl.value?.$el));
</script>
<style lang="scss">
.date-input {
  .m-input--input-box {
    max-width: 11em;
  }
}
</style>
