<template>
  <m-card>
    <m-layout-stack gap="1">
      <m-layout-stack
        flex
        horizontal
        :class="[
          {
            'marked-incorrect': entry.isMarkedIncorrect,
          },
        ]"
        data-testid="encounter-details-section"
      >
        <div class="flex-auto">
          <m-layout-stack horizontal>
            <div class="flex-auto">
              {{ getMainLine }}
            </div>
            <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
              formatRetrospectivelyAmendedLabel(entry.data)
            }}</i>
            <m-action
              v-if="!isPreview"
              :model-value="{ label: 'View more', click: openEncounter }"
            />
          </m-layout-stack>
          <div v-if="getSecondLine">
            <span class="secondary-text">
              {{ getSecondLine }}
            </span>
          </div>
          <div v-if="getThirdLine">
            <span class="secondary-text">
              {{ getThirdLine }}
            </span>
          </div>
        </div>
      </m-layout-stack>
      <m-layout-stack
        v-if="
          entry.isDraft ||
          entry.data.hiddenFromPatientFacingServices ||
          entry.data.confidentialFromThirdParties
        "
        horizontal
      >
        <m-badge v-if="entry.isDraft" text="Draft" amber />
        <m-badge
          v-if="entry.data.hiddenFromPatientFacingServices"
          text="Redacted from patient online access"
          gray
          data-testid="hidden-from-patient-facing-services-badge"
        />
        <m-badge
          v-if="entry.data.confidentialFromThirdParties"
          text="Confidential"
          gray
          data-testid="confidential-from-third-parties-badge"
        />
      </m-layout-stack>
    </m-layout-stack>

    <m-separator class="q-ma-sm" />

    <template v-if="encounterHasEntries()">
      <template v-for="(topic, index) in entry.data.consultationTopics" :key="topic.id">
        <m-separator v-if="index !== 0" class="q-ma-sm" />
        <ConsultationTopicJournalElement
          :topic="topic"
          hide-empty-headings
          :read-only="topic.isDraft !== undefined ? topic.isDraft : readOnly"
          :encounter-hidden-from-patient-facing-services="
            entry.data.hiddenFromPatientFacingServices
          "
          :encounter-confidential-from-third-parties="entry.data.confidentialFromThirdParties"
          :consultation-topic-count="entry.data.consultationTopics.length"
        />
      </template>
    </template>
  </m-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { openSlideover } from "../../../../composables/dialog/drawer";
import { PatientJournalRecord } from "../types";
import ConsultationTopicJournalElement from "../elements/consultation-topic/ConsultationTopicJournalElement.vue";

defineEmits(["setDataUrl"]);

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly?: boolean;
  isPreview?: boolean;
}>();

const getMainLine = computed(() => {
  const data = props.entry.data;
  let text = data.deliveryMode ? data.deliveryMode + " consultation" : "Consultation";
  if (data.seenInEstablishment) {
    text += " (" + data.seenInEstablishment + ")";
  }
  if (data.startTime) {
    text += " • " + data.startTime;
  }
  if (data.endTime) {
    text += " - " + data.endTime;
  }
  return text;
});

const getSecondLine = computed(() => {
  const data = props.entry.data;
  let text = "";
  if (data.responsiblePractitioner) {
    text += data.responsiblePractitioner;
  }
  if (data.responsibleOrganisation) {
    if (data.responsiblePractitioner) {
      text += " at";
    }
    text += " " + data.responsibleOrganisation;
  }
  if (data.additionalStaff) {
    text += " • Additional staff: " + data.additionalStaff;
  }

  return text === "" ? false : text;
});

const getThirdLine = computed(() => {
  return props.entry.data.otherParticipants
    ? "Also present: " + props.entry.data.otherParticipants
    : false;
});

function formatRetrospectivelyAmendedLabel(item) {
  let label: string[] = [];
  if (item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
}

function encounterHasEntries() {
  let encounterHasEntries = false;
  props.entry.data.consultationTopics.forEach((consultationTopic) => {
    consultationTopic.headings.forEach((heading) => {
      if (heading.entries.length) {
        encounterHasEntries = true;
      }
    });
  });
  return encounterHasEntries;
}

function openEncounter() {
  openSlideover(`/clinical/encounter/modal/overview/${props.entry.id}`, { close: true });
}
</script>

<style>
.card-description {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #68778d;
}

.status-badge {
  align-self: start;
}
</style>
