<template>
  <div class="flex gap-3">
    <p>Access to Medicus and any information it contains is limited to authorised users only.</p>
    <p>
      Legal action can be taken against unauthorised use of, or unauthorised access to, this
      computer/Solution and/or any information it contains, including pursuant to the Computer
      Misuse Act 1990.
    </p>
    <p>
      If you are an authorised user, by proceeding to access and use this computer/Solution and/or
      the information it contains, you are accepting any terms of use, notices and policies which
      are contained or referenced within it or which have otherwise been drawn to your attention as
      an authorised user.
    </p>
  </div>
</template>
