<script setup lang="ts">
import { onMounted } from "vue";

const props = defineProps<{
  params: Record<string, any>;
}>();
onMounted(() => {});
</script>

<template>
  <m-link v-if="props.params.value.url" :to-slideover="props.params.value.url">
    {{ props.params.value.text }}
  </m-link>
  <div v-else>{{ props.params.value.text }}</div>
</template>
