<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="Preview fit note"
    @click="previewFitNoteClick"
  >
    <FitNoteJournalElement :item="entry.data" :read-only="readOnly" :clickable="clickable" />
  </m-card>
</template>

<script setup lang="ts">
import { PatientJournalRecord } from "../types";
import FitNoteJournalElement from "../elements/FitNoteJournalElement.vue";
import { openModal } from "../../../../composables/dialog/drawer";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function previewFitNoteClick() {
  return props.readOnly
    ? openModal(`/clinical/gb/fit-note/modals/preview-fit-note/${props.entry.id}`)
    : undefined;
}
</script>
