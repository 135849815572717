<script lang="ts" setup>
import { computed } from "vue";
import { randomID } from "../../../utils/helpers";

const props = defineProps<{
  name?: string;
  title?: string;
}>();

const titleId = computed(() => {
  return props.title?.toLowerCase().replace(/\s/g, "_").concat(`_heading_${randomID()}`);
});
</script>

<template>
  <section
    class="m-section"
    :class="title === undefined ? 'no-heading' : null"
    role="group"
    :aria-labelledby="titleId"
  >
    <h2 v-if="title" :id="titleId" class="section-heading">{{ title }}</h2>
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.m-section {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: var(--gap-3);
  position: relative;
  padding: 0 16px;

  .section-heading {
    position: absolute;
    top: 0;
    left: 10px;
    translate: 0 -50%;
    background: white;
    line-height: 1.2;
    padding: 0 6px;
  }

  &:not(.no-heading) {
    padding: 20px 16px 16px;
    border: 1px solid var(--border-colour);
    border-radius: 3px;
    margin-top: 6px;
  }

  + .m-section {
    margin-top: var(--gap-3);
  }

  &.no-heading {
    + .no-heading {
      margin-top: 0;
    }
  }
}
</style>
