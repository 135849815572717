<script setup lang="ts">
import { ComponentOptions, inject, onMounted, onUnmounted, provide, ref, watch } from "vue";
import { dispatch } from "../../../utils/bus";
import { ENTRY_CREATED_EVENT_NAME, ENTRY_DELETED_EVENT_NAME } from "../MInlineEntryList/types";
import { NO_OP } from "vue-composable";
import { loadComponent } from "../../../utils/component";
import { useApp } from "../../../store";

const props = defineProps<{
  heading?: string;
  side?: "left" | "right";
  paddedContent?: boolean;

  componentOptions: {
    url: string;
    props?: Record<string, any>;
    hasData?: boolean;
    additionalCreatePayloadData?: Record<string, any>;
    additionalDeletePayloadData?: Record<string, any>;
  };

  lifeCycleHooks?: {
    onOpen?: Function;
    onLoaded?: Function;
    onCancel?: Function;
    onSuccess?: (data?: Record<string, any>) => void;
  };
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const reloadData = inject("reloadData", NO_OP);

const refModal = ref<HTMLDivElement>();
const refModalContent = ref<HTMLDivElement>();

const heading = ref(props.heading);
const component = ref<ComponentOptions>();
const mutationObserver = ref<MutationObserver>();

provide("additionalCreatePayloadData", props.componentOptions.additionalCreatePayloadData);
provide("additionalDeletePayloadData", props.componentOptions.additionalDeletePayloadData);
provide("setTitle", (title: string) => {
  heading.value = title;
  useApp().setDockedModalTitle(title);
});
provide("success", (shouldClose: boolean) => {
  if (shouldClose) {
    close();
  }

  props.lifeCycleHooks?.onSuccess?.();

  if (props.componentOptions.additionalCreatePayloadData) dispatch(ENTRY_CREATED_EVENT_NAME, {});
  if (props.componentOptions.additionalDeletePayloadData) dispatch(ENTRY_DELETED_EVENT_NAME, {});

  reloadData();
});
provide("close", close);

watch(
  () => props.componentOptions.url,
  () => {
    loadContentComponent();
  },
  { immediate: true },
);

const initMutationObserver = () => {
  mutationObserver.value = new MutationObserver(() => {
    adjustContentHeight();
  });

  mutationObserver.value.observe(document.body, {
    attributes: true,
    childList: true,
    subtree: true,
  });
};

onMounted(() => {
  adjustContentHeight();
  window.addEventListener("resize", adjustContentHeight);
  initMutationObserver();
});

onUnmounted(() => {
  window.removeEventListener("resize", adjustContentHeight);

  if (mutationObserver.value) {
    mutationObserver.value.disconnect();
    mutationObserver.value = undefined;
  }
});

async function loadContentComponent() {
  const loadedComponent = await loadComponent(
    props.componentOptions.url,
    props.componentOptions.hasData ? !props.componentOptions.hasData : false,
  );

  props.lifeCycleHooks?.onLoaded?.();

  if (loadedComponent) {
    component.value = loadedComponent;
    props.lifeCycleHooks?.onOpen?.();
  }
}

function close() {
  props.lifeCycleHooks?.onCancel?.();
  emit("close");
  useApp().setDockedModalTitle(undefined);
}

function adjustContentHeight() {
  if (refModalContent.value) {
    const newHeight = window.innerHeight - refModalContent.value.getBoundingClientRect().top;

    refModalContent.value.style.height = `${newHeight}px`;
  }
}
</script>
<template>
  <div class="docked-modal-container">
    <div class="shadow" @click="close" />
    <div ref="refModal" class="modal">
      <header class="modal-header">
        <h3 class="modal-heading">
          <Transition name="fade">
            <span v-if="heading">{{ heading }}</span>
          </Transition>
        </h3>
        <div class="actions">
          <!-- Minimize Button -->
          <!-- <m-button class="docked-modal-header-button" ghost>
            <i class="fa-solid fa-window-minimize" />
          </m-button> -->
          <m-button class="docked-modal-header-button" ghost @click="close">
            <i class="fa-solid fa-xmark" />
          </m-button>
        </div>
      </header>
      <div
        ref="refModalContent"
        class="modal-content"
        :class="{ 'padded-content': props.paddedContent }"
      >
        <Transition name="fade">
          <component :is="component" v-if="component" v-bind="props.componentOptions.props" />
        </Transition>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.docked-modal-container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 20px;
  z-index: 500;
  container: docked-modal / inline-size;
  transition: all 300ms cubic-bezier(0.31, 0.83, 0.14, 1);

  &.v-enter-from {
    .shadow {
      opacity: 0;
    }

    .modal {
      translate: 0 60px;
      opacity: 0;
    }
  }

  &.v-leave-to {
    .shadow {
      opacity: 0;
    }

    .modal {
      opacity: 0;
    }
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 50%);
    opacity: 1;
    transition: all 0.2s ease-out;

    &:hover {
      background: rgb(0 0 0 / 55%);
      cursor: pointer;
    }
  }

  .modal {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 14px 14px 0 0;
    box-shadow: 0 2px 20px rgb(0 0 0 / 14%);
    z-index: 1;
    transition: all 500ms cubic-bezier(0.31, 0.83, 0.14, 1);
    overflow: hidden;
  }

  header.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: var(--gap-4);
    flex: 0 0 auto;
    min-height: var(--page-header-height);
    z-index: 5;
    border-bottom: 1px solid var(--border-colour);
    background: white;

    .modal-heading {
      font-size: 22px;
      margin: 0;
      line-height: 1.4;
      font-family: Georgia, "Times New Roman", Times, serif;
      font-weight: normal;
      translate: 0 2px;
    }

    .actions {
      display: flex;
      align-items: stretch;
      align-self: stretch;
      justify-content: flex-end;
      padding: 3px;

      > :last-child ::v-deep .medicus-button {
        border-top-right-radius: 12px;
      }
    }

    .docked-modal-header-button {
      align-self: stretch;

      ::v-deep {
        .medicus-button {
          height: 100%;
          aspect-ratio: 1 / 1;
          background: white;
          font-size: 20px;
          line-height: 0;
          color: var(--text-color);
          transition: var(--transition);
          align-self: center;
          border-radius: 3px;

          &:hover {
            background: var(--theme-blue);
            color: white;
          }
        }
      }
    }
  }

  .modal-content {
    overflow: auto;
    background: white;

    &.padded-content {
      padding: var(--gap-4);
    }
  }
}
</style>
