export function findFirstScrollableChild(
  element: Element,
  maxDepth = 5,
  current = 0,
): Element | null {
  // Check if the element itself is scrollable
  const isScrollable = element.scrollHeight - element.clientHeight > 3;

  if (isScrollable) {
    if (element.classList.contains("sr-only")) {
      return null;
    }
    return element;
  }
  if (maxDepth && current >= maxDepth) {
    return null;
  }

  // Check the child elements recursively
  const childElements = element.children;
  for (let i = 0; i < childElements.length; i++) {
    const child = childElements[i];
    const scrollableChild = findFirstScrollableChild(child, maxDepth, current + 1);
    if (scrollableChild) {
      return scrollableChild;
    }
  }

  // No scrollable child found
  return null;
}

export function findFirstScrollableParent(
  element: Element,
  maxDepth = 5,
  current = 0,
): Element | null {
  // Check if the element itself is scrollable
  const isScrollable = element.scrollHeight - element.clientHeight > 3;
  if (isScrollable) {
    if (element.classList.contains("sr-only")) {
      return null;
    }
    return element;
  }

  if (maxDepth && current >= maxDepth) {
    return null;
  }

  // if is document.body, let's stop
  if (element.parentElement) {
    if (element.parentElement === document.body) {
      return null;
    }
    return findFirstScrollableParent(element.parentElement, maxDepth, current + 1);
  }

  // No scrollable child found
  return null;
}
