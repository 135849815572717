<template>
  <div class="form-repeat-group">
    <div v-if="onClose" class="form-close" @click.capture.stop="onClose">
      <m-button icon="fa-regular fa-trash-xmark" small ghost sr-label="Delete group" />
    </div>
    <m-layout-stack :gap="gap">
      <slot />
    </m-layout-stack>
  </div>
</template>

<script lang="ts" setup>
import { useInheritBackgroundColor } from "../../../composables/background";
import MButton from "../MButton";
import MLayoutStack from "./../MLayoutStack";

defineProps({
  onClose: Function,
  gap: {
    type: String,
    default: "3",
  },
});

const bgColour = useInheritBackgroundColor();
</script>

<style lang="scss">
.form-repeat-group {
  position: relative;

  padding: 15px 20px;

  // border: 1px dashed var(--grey-dark); // NOTE this was almost choose
  border: 2px dashed var(--grey-light);
  box-sizing: border-box;
  border-radius: 4px;

  .form-title {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;

    color: var(--text-color);

    text-transform: capitalize;

    margin-bottom: 1em;
  }
  .form-close {
    cursor: pointer;
    position: absolute;
    display: flex;
    z-index: 99999;

    background-color: v-bind(bgColour);
    border: 1px solid var(--grey-lightest-non-text);
    border-radius: 99999px;

    height: 22px;
    width: 22px;
    right: -11px;
    top: -11px;

    &:hover {
      background-color: var(--status-light-red);
      border-color: var(--status-red);
    }

    > div {
      margin: auto;
    }

    i {
      color: var(--status-red);
    }
  }
}
</style>
