<script lang="ts" setup>
import { Key } from "ts-key-enum";
import { nextTick, ref, watch } from "vue";
import { useEvent } from "vue-composable";
import { randomID } from "../../../../utils/helpers";

const props = defineProps<{
  modelValue?: string;
  readOnly?: boolean;
  label?: string;
  placeholder?: string;

  role?: string;
  ariaLabel?: string;
  ariaLabelledby?: string;
  ariaOwns?: string;
  ariaExpanded?: boolean;
  ariaActivedescendant?: string;
}>();

const editable = ref(true);

watch(
  () => props.readOnly,
  (readonly) => {
    editable.value = !readonly;
  },
);

const emit = defineEmits({
  "update:modelValue": (_e: string | null) => true,
  change: (_e: string | null) => true,
});

function onInput(e: Event) {
  let txt = (e.target as HTMLSpanElement).textContent || "";

  /**
   * to show `caret` on `span` el
   * we need to set `&nbsp;`, but we need to remove that
   * when we remove it, the cursor goes to the beginning
   * to prevent that we need to handle and change selection
   * we should update the selection after we receive the modal value
   */
  if (!props.modelValue && txt.charCodeAt(txt.length - 1) === 160) {
    txt = txt.slice(0, txt.length - 1);

    const r = watch(
      () => props.modelValue,
      (v) => {
        r();
        focusCaretEditor(v!.length);
      },
      {
        flush: "post",
      },
    );
  }
  emit("update:modelValue", txt);
}
function onBlur(_e: Event) {
  // const text = editor.value.textContent;
  // // if (props.modelValue === text) return;
  // if (e.target === e.currentTarget) return;
  // emit("change", text);
  // updateEditable();
}

const el = ref<HTMLDivElement>();
const editor = ref<HTMLSpanElement>();
let isFocused = false;
useEvent(
  el,
  "blur",
  (e) => {
    if (!isFocused || !el.value) return;

    if (e.relatedTarget && el.value.contains(e.relatedTarget)) return;

    isFocused = false;

    const text = (editor.value!.textContent?.trimStart() || "").trim();

    if (props.modelValue !== text) {
      emit("change", text);
    }

    updateEditable();
  },
  { capture: true },
);

function onFocus() {
  isFocused = true;
  if (props.modelValue === "") {
    focusCaretEditor();
  }
}

// Resets the component state, this will remove any text errors shwon on the browser
function updateEditable() {
  editable.value = false;
  nextTick().then(() => (editable.value = true));
}

function onKeyDown(ev: KeyboardEvent) {
  if (ev.key === Key.Enter) {
    if (!ev.shiftKey) {
      ev.preventDefault();
      onInput(ev);
      emit("change", (ev.target as HTMLSpanElement).textContent);
    }
  }
}

function onFocusParent() {
  if (props.modelValue === "") {
    focusCaretEditor();
  }
  editor.value?.focus();
}

function focusCaretEditor(last = false) {
  const selection = document.getSelection();
  const range = document.createRange();
  const contenteditable = editor.value!;

  if (last) {
    if (contenteditable.lastChild!.nodeType == 3) {
      range.setStart(
        contenteditable.lastChild!,
        // @ts-expect-error
        contenteditable.lastChild!.length - (props.modelValue?.length ? 0 : 1),
      );
    } else {
      range.setStart(contenteditable, contenteditable.childNodes.length);
    }
  } else {
    range.setStart(editor.value!, 0);
  }
  range.collapse(true);
  selection?.removeAllRanges();
  selection?.addRange(range);
}

defineExpose({
  focus() {
    editor.value?.focus();

    focusCaretEditor(true);
  },
});

const labelId = randomID("inline-input-label");
</script>
<template>
  <span ref="el" class="m-inline-input" :class="[label && 'stacked-label']" @click="onFocusParent">
    <span v-if="label" :id="labelId" class="inline-input-label">
      {{ label }}
    </span>
    <slot name="prepend" />
    <span
      ref="editor"
      class="inline-input"
      :contenteditable="editable"
      :height="el?.clientHeight + 'px'"
      role="combobox"
      :aria-label="label ? undefined : ariaLabel"
      :aria-labelledby="label ? labelId : ariaLabelledby"
      :aria-expanded="ariaExpanded"
      :aria-controls="ariaOwns"
      :aria-owns="ariaOwns"
      :aria-autocomplete="ariaOwns ? 'list' : undefined"
      :aria-activedescendant="ariaActivedescendant"
      @focus="onFocus"
      @input="onInput"
      @blur="onBlur"
      @keydown="onKeyDown"
      v-text="modelValue || '&nbsp;'"
    />
    <div v-if="placeholder && modelValue?.length === 0" class="placeholder">
      {{ props.placeholder }}
    </div>
  </span>
</template>
<style lang="scss">
.m-inline-input {
  position: relative;
  display: contents;
  max-width: 100%;
  word-break: break-word;
  min-height: 1rem;
  min-width: 1.5em;
  transition: all 140ms ease-out;
  padding-top: 10px;
  padding-left: 14px;
  min-height: 42px;
  border-radius: 4px;

  &:hover {
    cursor: text;
  }

  &:hover,
  &:focus-within {
    background: var(--grey-lightest);

    .placeholder {
      color: var(--grey-darkest);
    }
  }

  .inline-input {
    &[placeholder] {
      &:after {
        content: attr(placeholder);
        color: var(--grey-darker);
        margin-left: -4px;
      }
    }

    &[contenteditable="true"] {
      &:active,
      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .placeholder {
    font-style: italic;
    color: var(--grey-darker);
    position: absolute;
    top: 10px;
    left: 14px;
    pointer-events: none;
    transition: all 140ms ease-out;
  }

  &.stacked-label {
    min-height: 35px;

    .inline-input-label {
      display: block;
      // position: absolute;
      z-index: 1;

      top: -2px;
      left: 0px;

      color: var(--grey-darkest);
      font-size: 12px;
    }
  }
}
</style>
