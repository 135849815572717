<script setup lang="ts">
import { QIcon } from "quasar";
import { Key } from "ts-key-enum";
import type { PropType } from "vue";
import { computed, getCurrentInstance, ref, watch } from "vue";
import { isString } from "vue-composable";
import MButton from "../MButton";
import MInput from "../inputs/MInput";

import Mark from "mark.js";

const props = defineProps({
  modelValue: String,
  content: { type: [String, Object, null] as PropType<HTMLElement | string>, required: true },
  showIcon: Boolean,

  placeholder: String,
  borderless: Boolean,

  for: String,
  id: String,
});

const emit = defineEmits({
  "update:modelValue": (_d: string) => true,
});

const mark = ref<Mark>();
const localSearch = ref();

const search = computed<string>({
  get() {
    if (props.modelValue === undefined) {
      return localSearch.value;
    }
    return props.modelValue;
  },
  set(v) {
    localSearch.value = v;
    emit("update:modelValue", v);
  },
});

const results = ref<HTMLElement[]>([]);
const selectedResult = ref<HTMLElement>();

const content = ref<HTMLElement>();

const instance = getCurrentInstance()!;

let timeout: any = 0;
function resolveContent(c: string | HTMLElement) {
  if (!c) {
    // force update on the parent
    setTimeout(() => instance.parent?.proxy?.$forceUpdate(), 10);
    return (content.value = undefined);
  }
  let t: HTMLElement | undefined = undefined;
  if (isString(c)) {
    if (c.indexOf(".") === -1 && c.indexOf("#") === -1) {
      //should be ref
      t = instance.parent?.refs?.[c] as HTMLElement;
    } else {
      t = document.querySelector(c) as HTMLElement;
    }
  } else {
    t = c;
  }
  clearTimeout(timeout);
  if (t) {
    content.value = t;
  } else {
    timeout = setTimeout(() => resolveContent(c), 10);
  }
}
watch(() => props.content, resolveContent, {
  immediate: true,
  flush: "post",
});

watch([search, mark], ([search, mark]) => {
  if (!mark) {
    return;
  }
  mark.unmark({
    done() {
      search
        ? mark.mark(search, {
            done() {
              if (content.value) {
                results.value = Array.from(
                  content.value.querySelectorAll("[data-markjs='true']"),
                ) as HTMLElement[];
              }
            },
          })
        : mark.unmark({
            done() {
              results.value = [];
            },
          });
    },
  });
});

watch(
  content,
  (content, _, onInvalidate) => {
    if (content) {
      mark.value = new Mark(content);
    }
    onInvalidate(() => {
      mark.value?.unmark({
        done() {
          results.value = [];
        },
      });
    });
  },
  {
    immediate: true,
  },
);

watch(results, (r) => {
  selectedResult.value = r?.length ? r[0] : undefined;
});

watch(selectedResult, (r, _, onInvalidate) => {
  if (r) {
    r.classList.add("current");
    r.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });

    onInvalidate(() => {
      r.classList.remove("current");
    });
  }
});

function move(step: 1 | -1) {
  if (!results.value || !selectedResult.value) {
    return;
  }
  const curr = results.value.indexOf(selectedResult.value);
  const next = curr + step;

  const nextIndex = next < 0 ? results.value.length + next : next % results.value.length;
  selectedResult.value = results.value[nextIndex];
}

function prev() {
  move(-1);
}
function next() {
  move(1);
}

function onInputKeydown(ev: KeyboardEvent) {
  if (ev.key === Key.Enter) {
    next();
  }
}
</script>
<template>
  <div class="flex gap-2">
    <m-input
      :id="id"
      v-model="search"
      class="highlight-search-input"
      :borderless="borderless"
      :placeholder="placeholder || 'Search'"
      :for="$props.for"
      sr-label="Search"
      @keydown="onInputKeydown"
    >
      <template v-if="showIcon" #prepend>
        <q-icon name="fa-light fa-search" />
      </template>
      <template v-if="search" #append>
        <div class="mark-input--append flex flex-row justify-center items-center">
          <span class="mark-input--count">
            {{ results.indexOf(selectedResult) + 1 }}/{{ results.length }}
          </span>
          <div class="mark-input--separator">&nbsp;</div>
          <m-button
            icon="fa-light fa-arrow-up"
            ghost
            icon-only
            label="Previous"
            :disabled="results.length ? undefined : true"
            @click="prev"
          />
          <m-button
            icon="fa-light fa-arrow-down"
            ghost
            icon-only
            label="Next"
            :disabled="results.length ? undefined : true"
            @click="next"
          />
          <m-button
            icon="fa-light fa-xmark"
            ghost
            icon-only
            label="Clear input"
            @click="search = ''"
          />
        </div>
      </template>
    </m-input>
  </div>
</template>
<style lang="scss">
.mark-input--append {
  .mark-input--count {
    padding: 0 10px;
  }

  .mark-input--separator {
    border-left: 1px solid #ccc;
    width: 1px;
    height: 100%;
  }
  .q-icon {
    color: #aaa;
    &:hover {
      color: #666;
    }

    &[disabled="true"] {
      cursor: default !important;
      color: var(--status-red);
      color: #ccc;
    }
  }
}

.highlight-search-input {
}

mark {
  background: yellow;
}

mark.current {
  background: orange;
}
</style>
