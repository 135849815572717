<script lang="ts" setup>
import interactionPlugin from "@fullcalendar/interaction";
import deepmerge from "deepmerge";
import { QItem, QItemLabel, QItemSection, QList, date } from "quasar";
import { computed, inject, onMounted, ref, watch } from "vue";
import MDropdownButton from "../../MDropdownButton";
import MDatePicker from "../../inputs/MDatePicker";
import { IncrementStep } from "../../inputs/MDatePicker/MDatePicker.vue";
import { NOOP } from "@vue/shared";

import { Calendar, CalendarOptions } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";

const props = defineProps({
  options: {
    type: Object as () => CalendarOptions,
    required: true,
    default: () => ({
      plugins: ["dayGridPlugin"],
      initialView: "dayGridMonth",
    }),
  },
  views: {
    type: Array as () => Array<string>,
    default: (props: any) => {
      return props.options.headerToolbar?.right?.split(",") || [];
    },
  },
  noToolbar: Boolean,
});

const emit = defineEmits({
  changeView: (_e: string) => true,
  dateChanged: (_e: Date) => true,
  formattedDate: (_e: Date | string) => true,
  api: (_e: any) => true,
});

inject("prevent-overflow", NOOP as (v: boolean) => void)(true);

const selectedDate = ref<Date>(
  props.options.initialDate ? new Date(props.options.initialDate) : new Date(),
);
const selectedView = ref(props.options.initialView! || props.views[0]);
const calendar = ref<{
  getApi(): Calendar;
}>();

const mergedOptions = computed<CalendarOptions>(() => {
  const mergedProps = deepmerge.all([
    props.options,
    <CalendarOptions>{
      schedulerLicenseKey: "0242186123-fcs-1725408000",
      views: {
        timeGridWeek: {
          dayHeaderFormat(e) {
            return date.formatDate(e.date.marker, "ddd DD");
          },
        },
        timeGridDay: {
          slotMinWidth: 100,
        },
      },
    },
  ]);

  const options = {
    firstDay: 1,
    locale: "en-GB",
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      meridiem: false,
      hour12: false,
      timeZone: "en-GB",
    },
    slotLabelFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      meridiem: false,
    },
    listDayFormat: {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      omitCommas: true,
    },
    listDaySideFormat: false,
    ...mergedProps,
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
      resourceTimelinePlugin,
      resourceTimeGridPlugin,
      resourceDayGridPlugin,
    ],
    headerToolbar: false,
    dateClick: (e) => {
      selectedDate.value = e.date;
      props.options?.dateClick?.(e);
    },
  } as CalendarOptions;

  return options;
});

const formattedDate = computed(() => {
  const view = selectedView.value;
  switch (view) {
    case "resourceTimelineDay":
    case "timeGridDay": {
      return date.formatDate(selectedDate.value, "DD MMM");
    }
    case "timeGridMonth":
    case "dayGridMonth":
    case "listMonth": {
      return date.formatDate(selectedDate.value, "MMM YYYY");
    }
    case "customResourceTimelineWeek":
    case "timeGridWeek": {
      const d = new Date(selectedDate.value);
      const day = d.getDay();
      const monday = date.subtractFromDate(d, { days: day - (day == 0 ? -6 : 1) });
      const sunday = date.addToDate(monday, { days: 6 });
      return `${date.formatDate(monday, "DD MMM")} - ${date.formatDate(sunday, "DD MMM")}`;
    }
    case "listYear": {
      return date.formatDate(selectedDate.value, "YYYY");
    }
  }
  return selectedDate.value;
});
const api = computed(() => getApi());

watch(api, (e) => emit("api", e));
watch(selectedDate, (d) => {
  if (!d) {
    selectedDate.value = new Date();
    return;
  }
  api.value?.gotoDate(d);
  emit("dateChanged", d);
});
watch(selectedView, (v) => {
  console.log("changed vew", v);
  api.value?.changeView(v);
  emit("changeView", v);
});
watch(formattedDate, (f) => emit("formattedDate", f), { immediate: true });
function getApi() {
  return calendar.value?.getApi();
}
onMounted(() => {
  getApi()?.updateSize();
});
function getViewLabel(view: string) {
  const o: Record<string, string> = {
    timeGridDay: "Day view",
    timeGridWeek: "Week view",
    timeGridMonth: "Month view",
    listMonth: "List view",
    listYear: "List year",
    dayGridMonth: "Month view",
    resourceTimelineDay: "Day view",
    customResourceTimelineWeek: "Week view",
  };
  return o[view] || "Unknown list " + view;
}

const viewType = computed<IncrementStep>(() => {
  const view = selectedView.value;
  return view.indexOf("Month") >= 0
    ? IncrementStep.month
    : view.indexOf("Week") >= 0
    ? IncrementStep.week
    : view.indexOf("Year") >= 0
    ? IncrementStep.year
    : IncrementStep.day;
});

function changeView(s: string) {
  selectedView.value = s;
}
</script>

<template>
  <div class="m-calendar">
    <div v-if="!noToolbar" class="toolbar gap-2">
      <slot name="before-date" />
      <div class="flex gap-2 items-end">
        <m-date-picker v-model="selectedDate" :view="viewType" />
      </div>
      <slot name="after-date" />

      <slot name="buttons" />

      <!-- SPACER -->
      <div class="flex-auto" />

      <slot name="before-view" />
      <m-dropdown-button
        :label="getViewLabel(selectedView)"
        type="secondary"
        data-testid="view-dropdown"
      >
        <q-list>
          <q-item
            v-for="(view, i) in views"
            :key="i"
            v-close-popup
            clickable
            @click="changeView(view)"
          >
            <q-item-section>
              <q-item-label>{{ getViewLabel(view) }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </m-dropdown-button>
      <slot name="after-view" />
    </div>
    <full-calendar ref="calendar" class="overflow-hidden" :options="mergedOptions">
      <template v-for="slot in Object.keys($slots)" :key="`slot:${slot}`" #[slot]="slotProps">
        <slot :name="slot" v-bind="slotProps" />
      </template>
    </full-calendar>
  </div>
</template>

<style lang="scss">
.m-calendar {
  --fc-today-bg-color: #fff;
  --fc-event-bg-color: var(--grey-lightest);
  --fc-event-text-color: var(--text-color);
  --fc-event-border-color: var(--grey);
  --fc-event-border-size: 3px;
  --fc-small-font-size: 12px;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  flex-wrap: nowrap;

  overflow: hidden;

  box-shadow: var(--box-shadow);
  background-color: white;

  max-height: 100%;

  > .fc {
    height: 100%;
  }

  .toolbar {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  .date-prev,
  .date-next {
    padding: 0 !important;
    min-width: 30px !important;
    height: 33px;
    margin-top: 1px !important;

    i {
      margin-right: 0;
    }
  }

  .fc-event-time {
    font-weight: bold;
  }

  .fc-timegrid-slot {
    color: var(--text-color);
  }

  tbody tr:nth-child(even) {
    td {
      border-top-color: transparent;
    }
  }

  .fc-scrollgrid {
    border: none;
    border-radius: 6px;
    background: #fff;
  }

  .fc-col-header-cell {
    border-top: none;
    border-right: none;
    border-left: none;
  }

  .fc-timegrid-axis {
    border-right: none;
  }

  .fc-event {
    border-radius: 4px;
    margin: 2px 0;
  }

  .fc-event-main {
    overflow: hidden;
    padding: 0 3px;
  }

  .fc-day {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 129% */

    color: var(--text-color);

    .fc-list-day-text {
      font-weight: normal !important;
    }
  }

  th {
    font-weight: bold;
    border-right-width: 0;
  }

  th.fc-day-today {
    font-weight: bold !important;
  }

  th.fc-day-today {
    position: relative;
    div::before {
      content: " ";
      border-top: 3px solid var(--theme-accent-blue) !important;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .fc-timeGridDay-view thead th.fc-day-today {
    div::before {
      bottom: 0;
      border-top: none !important;
      border-bottom: 3px solid var(--theme-accent-blue) !important;
    }
  }

  .fc-listYear-view,
  .fc-listMonth-view {
    .fc-list-day.fc-day-today .fc-list-day-text {
      font-weight: bold !important;
    }
  }

  .fc-list-event {
    color: var(--fc-event-text-color);
  }

  .fc-dayGridMonth-view .fc-day-today .fc-daygrid-day-number {
    position: relative;
    color: var(--fc-event-bg-color, #fff);
    &::before {
      content: " ";
      position: absolute;
      background: #0099ff;

      border-radius: 99999px;
      z-index: -1;

      top: 0px;
      left: -5px;
      padding: 12px;
    }
  }

  td.fc-timegrid-slot {
    font-size: 12px;

    height: 4em;
  }

  .fc-daygrid-dot-event {
    white-space: normal;
  }

  .fc-timeline-slot-major {
    border-right: none;
  }

  td.fc-timeline-slot-minor {
    border-left: none;
    border-style: none;
  }

  .fc-datagrid-cell,
  .fc-timegrid-axis,
  .fc-timeline-slot {
    color: var(--fc-event-text-color);
  }

  .date-prev,
  .date-next {
    height: 40px;

    button {
      padding: 0 !important;
    }
    i {
      margin-right: 0;
    }
  }
}
</style>
