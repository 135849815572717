<template>
  <div style="width: 100%">
    <div class="row" style="border-bottom: 1px solid var(--border-colour)">
      <div class="col-12 q-pa-xl">
        <h4 class="q-mb-sm q-mt-none">
          <span v-if="statusSelectedLabel != null">{{ statusSelectedLabel }} </span>Inbound
          communications
          <q-btn
            v-show="!byAssignedStaff"
            color="primary"
            label="Scan inbound letter"
            to="/communication-centre/scan-letter"
            class="float-right"
          />
        </h4>
      </div>
    </div>
    <div class="row" style="height: calc(100% - 145px)">
      <div
        class="col-xs-12 col-md-3"
        style="border-right: 1px solid var(--border-colour); height: 100%"
      >
        <MInboundCommunicationsSideNav
          :threads-filtered="threadsFiltered"
          :thread-selected-id="threadSelectedId"
          @thread-selected-id="selectThread"
        />
      </div>
      <div class="col-xs-12 col-md-9">
        <div v-if="selectedThread !== null" class="row q-pa-xl">
          <div v-if="selectedThread.staff !== null" class="col-xs-12 col-md-5">
            <q-select
              v-model="staffId"
              outlined
              :options="staffOptions"
              use-input
              input-debounce="0"
              stack-label
              filter
              label="Assignee staff"
              @filter="staffFilterFn"
              @focus="threadsEditAssignee = true"
              @blur="threadsEditAssignee = false"
            />
          </div>
          <div class="col-xs-12 col-md-5" :class="{ 'offset-md-1': selectedThread !== null }">
            <q-select
              v-model="statusId"
              outlined
              :options="statusOptions"
              stack-label
              label="Thread status"
              @focus="threadsEditStatus = true"
              @blur="threadsEditStatus = false"
            />
          </div>
        </div>
        <div class="q-pa-xl" style="background-color: #e8e8e8">
          <div v-if="displayThreadDetails">
            <div
              v-for="(threadItem, index) in selectedThread.threadItems"
              :key="index"
              class="row q-pa-xl inboundCommunications--thread-item"
            >
              <MInboundLetterThreadItem
                v-if="threadItem.type === 'inbound-letter'"
                :summary="threadItem.inboundLetter.summary"
                :sender="threadItem.inboundLetter.sender"
                :patient="threadItem.inboundLetter.patient"
                :inbound-letter-pages="threadItem.inboundLetter.inboundLetterPages"
              />
              <MOutboundSmsThreadItem
                v-else-if="threadItem.type === 'outbound-sms'"
                :status="threadItem.outboundSms.status"
                :message="threadItem.outboundSms.message"
                :to-number="threadItem.outboundSms.toNumber"
                :patient="threadItem.outboundSms.patient"
                :error-code="threadItem.outboundSms.errorCode"
                :error-message="threadItem.outboundSms.errorMessage"
                :created-at="threadItem.outboundSms.createdAt"
                :sent-at="threadItem.outboundSms.sentAt"
              />
              <MInboundSmsThreadItem
                v-else-if="threadItem.type === 'inbound-sms'"
                :status="threadItem.inboundSms.status"
                :message="threadItem.inboundSms.message"
                :from-number="threadItem.inboundSms.fromNumber"
                :patient="threadItem.inboundSms.patient"
                :created-at="threadItem.inboundSms.createdAt"
              />
              <div v-else>unsupported thread item yet.</div>
            </div>
          </div>
          <div v-else>
            You got no
            <span v-if="statusSelectedLabel != null">{{ statusSelectedLabel.toLowerCase() }} </span>
            inbound thread yet.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MInboundCommunicationsSideNav from "./MInboundCommunicationsSideNav.vue";
import MInboundLetterThreadItem from "./thread-items/MInboundLetterThreadItem.vue";
import MOutboundSmsThreadItem from "./thread-items/MOutboundSmsThreadItem.vue";
import MInboundSmsThreadItem from "./thread-items/MInboundSmsThreadItem.vue";
import { QBtn, QSelect } from "quasar";

export default {
  name: "MInboundCommunications",

  components: {
    MInboundCommunicationsSideNav,
    MInboundLetterThreadItem,
    MOutboundSmsThreadItem,
    MInboundSmsThreadItem,
    QSelect,
    QBtn,
  },

  props: {
    initialThreads: {
      type: Array,
      default: null,
    },
    staff: {
      type: Array,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: null,
    },
    byAssignedStaff: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    threads: [],
    threadSelectedId: null,
    threadsEditAssignee: false,
    staffId: null,
    staffOptions: [],
    statusId: null,
    threadsEditStatus: false,
    statusSelected: null,
  }),

  computed: {
    displayThreadDetails() {
      return this.selectedThread !== null;
    },
    selectedThread() {
      if (this.threads == undefined) {
        return null;
      }

      let thread = this.threads.filter((obj) => {
        return obj.id === this.threadSelectedId;
      });

      return thread.length > 0 ? thread[0] : null;
    },
    selectedThreadIndex() {
      return this.threads.findIndex((thread) => thread.id === this.selectedThread.id);
    },
    threadsFiltered() {
      if (this.statusSelected != null && this.threads != undefined) {
        return this.threads.filter((v) => v.status.value.indexOf(this.statusSelected) > -1);
      }

      return this.threads;
    },
    statusSelectedLabel() {
      let status = this.statusOptions.filter((v) => v.value == this.statusSelected);
      return status.length > 0 ? status[0].label : null;
    },
  },

  watch: {
    staffId() {
      if (this.threadsEditAssignee) {
        this.updateThreadStaff();
      }
    },
    statusId() {
      if (this.threadsEditStatus) {
        this.updateThreadStatus();
      }
    },
    selectedThread(selectedThread) {
      if (selectedThread != null) {
        this.staffId = selectedThread.staff;
        this.statusId = selectedThread.status;
      }
    },
    $route(newRoute) {
      if (newRoute.hasOwnProperty("query")) {
        this.checkQuery(newRoute.query);
      }
    },
    initialThreads(newInitialThreads) {
      this.threads = newInitialThreads;
    },
  },

  mounted() {
    this.threads = this.initialThreads;
    this.$dispatch("showSubnav", true);

    if (this.$route.hasOwnProperty("query")) {
      this.checkQuery(this.$route.query);
    }
  },

  methods: {
    checkQuery(query) {
      if (query.hasOwnProperty("threadId")) {
        this.threadSelectedId = query.threadId;
      }

      if (query.hasOwnProperty("status")) {
        this.statusSelected = query.status;
      }
    },
    selectThread(threadId) {
      this.threadSelectedId = threadId;
      this.$router.push({
        query: { status: this.statusSelected ? this.statusSelected : null, threadId: threadId },
      });
    },
    staffFilterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.staffOptions = this.staff.filter((v) => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
    updateThreadStaff() {
      if (this.byAssignedStaff) {
        this.$dispatch("updateCounters", {
          position: this.threads[this.selectedThreadIndex].status.value,
          addition: false,
        });
      }

      this.threadsEditAssignee = false;
      this.$set(this.$data.threads[this.selectedThreadIndex], "staff", this.staffId);
      this.$axios
        .post("/communication-centre/command/inbound-communications/edit-thread-staff", {
          threadId: this.selectedThread.id,
          staffId: this.staffId.value,
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    updateThreadStatus() {
      this.$dispatch("updateCounters", {
        position: this.threads[this.selectedThreadIndex].status.value,
        addition: false,
      });
      this.$dispatch("updateCounters", {
        position: this.statusId.value,
        addition: true,
      });
      this.threadsEditStatus = false;
      this.$set(this.$data.threads[this.selectedThreadIndex], "status", this.statusId);
      this.$axios
        .post("/communication-centre/command/inbound-communications/edit-thread-status", {
          threadId: this.selectedThread.id,
          status: this.statusId.value,
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>
<style>
.inboundCommunications--thread-item {
  background-color: white;
  width: 100%;
  margin-bottom: 35px;
}
</style>
