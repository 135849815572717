<script setup lang="ts">
import MCard from ".";
import MList, { MListItem } from "../MList";
import MDescriptionList, { MDescriptionListItem } from "../MDescriptionList";

const action = {
  label: "Issue",
  color: "secondary",
};

const dropdown = {
  label: "Issue",
  actions: [
    {
      label: "NHS",
    },
    {
      label: "Printed ",
    },
  ],
};

const badge = {
  text: "Badge",
  red: true,
};
</script>

<template>
  <div class="flex flex-col gap-3">
    <m-card title="Simple"> <span>Test Content</span> </m-card>

    <m-card title="Simple with footer">
      <span>Test Content</span>
      <template #footer> Footer </template>
    </m-card>

    <m-card title="Button action" :action="action"> <span>Test Content</span> </m-card>

    <m-card title="Button dropdown action" :action="dropdown"> <span>Test Content</span> </m-card>

    <m-card title="Button action with footer" :action="action" footer="Footer">
      <span>Test Content</span>
    </m-card>

    <m-card title="Button action with bespoke footer" :action="action">
      <span>Test Content</span>
      <template #footer> Bespoke Footer </template>
    </m-card>

    <m-card title="Button action with footer" :action="action">
      <span>Test Content</span>
      <template #footer> Footer </template>
    </m-card>

    <m-card title="Button badge text" badge="Cancelled"> <span>Test Content</span> </m-card>

    <m-card title="Button badge text with footer" badge="Cancelled" footer="Test Footer">
      <span>Test Content</span>
    </m-card>

    <m-card title="Button badge object" :badge="badge"> <span>Test Content</span> </m-card>

    <m-layout-stack class="scroll-area" style="height: 85px">
      <m-card title="Scrollable list" :badge="badge">
        <p>Test to prove the content does not overflow and is left aligned</p>
        <p>Test to prove the content does not overflow and is left aligned</p>
        <p>Test to prove the content does not overflow and is left aligned</p>
        <p>Test to prove the content does not overflow and is left aligned</p>
      </m-card>

      <m-card title="Test to prove this card is not visible in the group" :badge="badge">
        <span>Test Content</span>
      </m-card>

      <m-card title="Test to prove this card is not visible in the group" :badge="badge">
        <span>Test Content</span>
      </m-card>
    </m-layout-stack>

    <hr class="w-full" />

    <m-card no-shadow> <span>No shadow</span> </m-card>

    <m-card vertical-line> <span>Vertical line</span> </m-card>

    <m-card vertical-line no-shadow><span>Vertical line + No shadow </span></m-card>

    <m-card title="Headline information" expand>
      <template #headline>
        <div class="flex">This is the headline information replacement</div>
      </template>

      <span>Hidden content</span>
    </m-card>

    <m-card title="Expanded" expand expanded>
      <template #headline>
        <div class="flex">This is the headline information replacement</div>
      </template>

      <span>Content</span>
    </m-card>

    <div style="width: 150px">
      <m-card title="Regular----" :action="{ label: 'action' }"></m-card>
    </div>

    <div style="width: 180px">
      <m-card
        title="Dropdown-"
        :action="{ label: 'action', actions: [{ label: 'test' }] }"
      ></m-card>
    </div>

    <m-card title="MList">
      <m-list>
        <m-list-item>item 1</m-list-item>
        <m-list-item>item 2</m-list-item>
        <m-list-item>item 3</m-list-item>
      </m-list>
    </m-card>

    <m-card>
      <m-list>
        <m-list-item>item 1 (no card title)</m-list-item>
        <m-list-item>item 2</m-list-item>
        <m-list-item>item 3</m-list-item>
      </m-list>
    </m-card>

    <m-card title="MDescriptionList">
      <m-description-list>
        <m-description-list-item label="test label" value="test value" />
        <m-description-list-item label="test label 1" value="test value 1" />
        <m-description-list-item label="test label 2" value="test value 2" />
      </m-description-list>
    </m-card>

    <m-card>
      <m-description-list>
        <m-description-list-item label="Scenario" value="Card has no title" />
        <m-description-list-item label="test label 1" value="test value 1" />
        <m-description-list-item label="test label 2" value="test value 2" />
      </m-description-list>
    </m-card>
  </div>
</template>
